export const findEmail = (email, email_list) => {
  let found = false;
  for (let i = 0; i < email_list.length; i++) {
    if (email_list[i] === email) {
      found = true;
      break;
    }
  }

  return found;
};
