import { createSelector } from "reselect/lib/index";
import {
  filterLocationsBySelectionAndSearch,
  remapTicketProperties,
  mutateHourlyDaily,
  groupTicketsByCountry,
  sortByDate,
} from "./helpers";
import { TicketStatus } from "../../reducers/alert/alertTicketReducer";

const locationsSelector = (state) => state.alert.selectedTicket.locations;
const filterListSelector = (state) => state.alertLAS.selectedFilters.byName;
const searchTextSelector = (state) => state.alertLAS.searchText;
const paginationSelector = (state) => state.alertLAS.page;
const ticketsSelector = (selectedState) => selectedState.tickets;
const timeSelector = (selectedState) => selectedState.times;
const chartDataSelector = (selectedState) => selectedState;
const ticketTypeSelector = (selectedState) => selectedState;
const selectedTicketsSelector = (state) => state.alert.selectedTicket;
const totalHomesInAlarmSelector = (homesInAlarm) => homesInAlarm;
const currentPrimaryDeployment = (state) =>
  state.me.selectedPartner.primaryDeployment;
const recentDateSelector = (recentDate) => recentDate;
const selectedLocationSelector = (state) =>
  state.alert.selectedTicket.selectedLocation;

export const selectPrimaryDeployment = (state) =>
  currentPrimaryDeployment(state);
/**
 * filteredLocations
 * Selects the filtered locations on the location page
 */

export const filteredLocations = createSelector(
  locationsSelector,
  filterListSelector,
  searchTextSelector,
  paginationSelector,
  selectedLocationSelector,
  currentPrimaryDeployment,
  (
    locations,
    filterList,
    searchText,
    page,
    selectedLocation,
    currentPrimaryDeployment
  ) =>
    filterLocationsBySelectionAndSearch(
      locations,
      filterList,
      searchText,
      page,
      selectedLocation,
      currentPrimaryDeployment
    )
);

export const getTicketsByGroupAndCountry = (andOthersSuffix = undefined) =>
  createSelector(
    ticketsSelector,
    timeSelector,
    totalHomesInAlarmSelector,
    (tickets, time, totalHomesInAlarm) =>
      groupTicketsByCountry(tickets, time, totalHomesInAlarm, andOthersSuffix)
  );

/**
 * getSelectedTicket
 * Selects an invidividual ticket
 */
export const getSelectedTicket = createSelector(
  selectedTicketsSelector,
  totalHomesInAlarmSelector,
  (ticket, totalHomesInAlarm) =>
    remapTicketProperties(ticket, totalHomesInAlarm)
);

/**
 * getTimeseries
 * Human readable chart data
 */
export const getTimeseries = createSelector(
  [chartDataSelector, totalHomesInAlarmSelector],
  (selectors) => mutateHourlyDaily(selectors[0], selectors[1])
);

/**
 * get Locations
 */
export const getOngoingLocations = createSelector(
  ticketTypeSelector,
  (locations) => {
    return locations
      .map((location) => {
        return {
          ...location,
          ...{
            lat: Number(location.latitude),
            long: Number(location.longitude),
          },
        };
      })
      .filter((location) => location.status !== TicketStatus.Resolved);
  }
);

/**
 * Recent Date
 */
export const getRecentDate = createSelector(recentDateSelector, (hourly) => {
  if (hourly.length) {
    return hourly.slice().sort(sortByDate).reverse()[0].timestamp;
  }
});
