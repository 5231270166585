import * as version from "../reducers/versionReducer";
import * as monitorMap from "../reducers/monitorMapReducer";
import * as nhmc from "../reducers/monitorKPIReducer";
import * as admin from "../reducers/adminReducer";
import * as monitorV2 from "../reducers/monitorReducer";
import { UserRoles } from "../reducers/meReducer";

export const AvailableCharts = {
  TrendLine: "TrendLine",
  BarChart: "BarChart",
};

//version selector
export const versionSelector = (state) =>
  version.versionSelector(state.version);

//version two selectors

//Map Selector

export const layersOnSelector = (state) =>
  monitorMap.layersOnSelector(state.monitorMap);

export const mapSelectListSelector = (state) =>
  monitorMap.mapSelectListSelector(state.monitorMap);

//nhmc selectors

export const nhmcRawDataSelector = (state) =>
  nhmc.nhmcRawDataSelector(state.KPI);
export const kpiNameSelector = (state, ownProps) =>
  nhmc.kpiNameSelector(state.KPI, ownProps);
export const getKPIDataByName = (state, ownProps) =>
  nhmc.getKPIDataByName(state.KPI, ownProps);
export const getKPITotalPctAlarm = (state, ownProps) =>
  nhmc.getKPITotalPctAlarm(state.KPI, ownProps);
export const getChartType = (state, ownProps) =>
  nhmc.getChartType(state.KPI, ownProps);
export const getChartData = (state, ownProps) =>
  nhmc.getChartData(state.KPI, ownProps);
export const getChartYAtt = (state, ownProps) =>
  nhmc.getChartYAtt(state.KPI, ownProps);

export const getYAxisSelectedOption = (state, ownProps) =>
  nhmc.getYAxisSelectedOption(state.KPI, ownProps);
//admin selectors

export const selectAdminEmailGroup = (state) =>
  admin.selectAdminEmailGroup(state.admin);

export const selectAlertTypesGrouped = (state) =>
  admin.selectAlertTypesGrouped(state.admin);

//monitor selectors
export const getLocationNumber = (state, ownProps) =>
  monitorV2.getLocationNumber(state.monitor, ownProps);

export const selectCanChangeAdminConfiguration = (state) =>
  [UserRoles.Admin, UserRoles.Partner].includes(state.me.role);
