import React from "react";

import "./dataError.scss";

const DataError = () => (
  <div className="solve-dash-err">
    <div className="solve-data-loading-error">
      Oops! Unfortunately, there was an error getting the data. Try refreshing
      your browser.
      <br />
      <br />
      If this error persists, please contact Plume support.
    </div>
  </div>
);

export default DataError;
