import React from "react";
import PlumeIcon from "./PlumeIcon";
import { SlSection } from "../Section/Section";

import "./Footer.scss";

const SlFooter = () => (
  <SlSection className="sl-footer">
    <PlumeIcon />
  </SlSection>
);

export { SlFooter };
