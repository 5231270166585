import React from "react";
import PropTypes from "prop-types";

import SelectedText from "../../Atoms/SelectedText";
import NumberComp from "../../Atoms/Number";

import "./selectTextNumber.scss";

const SelectTextNumber = ({
  name,
  number,
  selected,
  selectLayer,
  formatNumber,
}) => {
  function handleMouseDown() {
    selectLayer(name);
  }
  return (
    <div
      onMouseDown={() => {
        handleMouseDown();
      }}
      className="sl-select-text-number-wrapper"
    >
      <SelectedText selected={selected}>{name}</SelectedText>
      <NumberComp formatNumber={formatNumber}>{number}</NumberComp>
    </div>
  );
};

SelectTextNumber.propTypes = {
  name: PropTypes.string.isRequired,
  number: PropTypes.number.isRequired,
  selected: PropTypes.bool.isRequired,
  selectLayer: PropTypes.func.isRequired,
};

export default SelectTextNumber;
