import producer from "immer";
import { changeQLDataToObject } from "../helpers/buildFilterOptions";
import { mutateNestedChild } from "../helpers/mutateNestedChild";

import {
  ADD_ALERT_FILTER,
  DELETE_FILTER_ALERT,
  HIDE_FILTERS,
  SET_ALERT_TABLE_PAGINATION,
  SHOW_HIDE_FILTERS,
  SHOW_FILTERS,
  SET_FILTER_OPTIONS,
  SET_FILTER_ERROR,
  ADD_FILTER_FROM_BOOKMARKS,
  SET_SEARCH_TEXT_ALERT,
  SET_PAGE,
} from "../../actions/alert/alertLASActions";
import { RESET_ALERT } from "../../actions/alert/alertActions";

export const Init_State = {
  showFilters: false,
  filtersSelectionData: null,
  filterErr: null,
  selectedFilters: { byName: {} },
  searchText: "",
  first: 25,
  offset: 0,
  locationsData: null,
  ticketErr: false,
  page: 0,
};

const alertLASReducer = producer((draftState, action = { type: null }) => {
  switch (action.type) {
    case ADD_ALERT_FILTER:
      const type = action.payload.type === "CSP" ? "ISP" : action.payload.type;
      draftState.selectedFilters.byName[action.payload.filter] = {
        typeOfFilter: type,
        name: action.payload.filter,
        filterKeys: [type, action.payload.filter],
      };
      return;

    case ADD_FILTER_FROM_BOOKMARKS:
      draftState.selectedFilters.byName[action.payload.filter] = {
        typeOfFilter: action.payload.keyList[0],
        name: action.payload.filter,
        filterKeys: action.payload.keyList,
      };
      return;
    case DELETE_FILTER_ALERT:
      const keyList = action.payload.keyList.map((key) =>
        key === "ISP" ? "CSP" : key
      );
      delete draftState.selectedFilters.byName[action.payload.filter];
      mutateNestedChild(draftState.filtersSelectData.byName, false, keyList);
      return;
    case SHOW_HIDE_FILTERS:
      draftState.showFilters = !draftState.showFilters;
      return;
    case HIDE_FILTERS:
      draftState.showFilters = false;
      return;
    case SHOW_FILTERS:
      draftState.showFilters = true;
      return;
    case SET_FILTER_OPTIONS:
      draftState.filtersSelectData = changeQLDataToObject(action.payload.data);
      return;
    case SET_ALERT_TABLE_PAGINATION:
      draftState.first = action.payload.first;
      draftState.offset = action.payload.offset;
      return;
    case SET_FILTER_ERROR:
      draftState.filterErr = true;
      draftState.filtersSelectionData = null;
      return;
    case SET_SEARCH_TEXT_ALERT:
      draftState.searchText = action.payload.text;
      return;
    case RESET_ALERT:
      if (window?.location?.search.indexOf("filter-") === -1) {
        draftState.selectedFilters = Init_State.selectedFilters;
      }
      draftState.searchText = "";
      return;
    case SET_PAGE:
      draftState.page = action.payload;
      return;
    default:
      return;
  }
}, Init_State);

export default alertLASReducer;
