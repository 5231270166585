import React, { useEffect } from "react";

import PropTypes from "prop-types";
import FilterRow from "../../../Molecules/Table/FilterRow";
import "./PawGraph.scss";
import { usePawGraphShapes } from "./hooks/usePawGraphShapes";
import { useRenderPawGraph } from "./hooks/useRenderPawGraph";

const PawGraph = ({ items }) => {
  const sortedItems = items.sort((a, b) => b.value - a.value);
  const { rectangles, svgDescriptor } = usePawGraphShapes(sortedItems);
  const renderPawGraph = useRenderPawGraph(svgDescriptor);

  useEffect(() => {
    const svg = renderPawGraph.createMainSvg();
    rectangles.map((item, index) => {
      const mainG = renderPawGraph.createMainG(svg, item, index);
      renderPawGraph.createRect(mainG, item, index);
      renderPawGraph.createText(mainG, item);
    });
  }, [rectangles, renderPawGraph, sortedItems]);

  return (
    <div className="PawGraph">
      <div id="PawGraphContainer">
        <div className="PawGraph__info"></div>
      </div>
    </div>
  );
};

FilterRow.propTypes = {
  items: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    color: PropTypes.string.isRequired,
  }).isRequired,
};
export { PawGraph };
