import cloneDeep from "lodash.clonedeep";
import { TicketStatus } from "../../reducers/alert/alertTicketReducer";

export function parseDateDaily(date) {
  let time = new Date(date);
  return new Date(time.getTime() - time.getTimezoneOffset() * -60000);
}

export function sortFunction(a, b) {
  return a.ts.getTime() - b.ts.getTime();
}

export function minsSince(date) {
  let seconds = Math.floor((new Date() - date) / 1000);
  let interval = Math.floor(seconds / 60);

  return interval;
}

export const mapNamesToData = {
  "Offline Network": "offlineNetwork",
  "Unstable Network": "unstableNetwork",
  "Poor Coverage": "poorCoverage",
  "High Congestion": "highCongestion",
  "CSP Outage": "ispOutage",
  "Evil Twin": "evilTwin",
};

export const mapTimeToData = {
  "24 Hr": "hourly",
  "7 Day": "daily",
  "30 Day": "daily",
};

//selector for data for NHMC charts

export function selectNHMCData(data, kpi, time) {
  const newData = cloneDeep(data);

  const dataTime = mapTimeToData[time];
  const dataKPI = mapNamesToData[kpi];
  return newData.partner[dataKPI][dataTime].pctTrouble.map((d) => {
    d.ts = parseDateDaily(new Date(d.ts));

    return d;
  });
}

const mapTicketStatusToTranslationKey = {
  [TicketStatus.Ongoing]: "ticket.statuses.ongoing",
  [TicketStatus.Reported]: "ticket.statuses.reported",
  [TicketStatus.Resolved]: "ticket.statuses.resolved",
};

export const getTranslationKey = (statusLabel) => {
  if (!statusLabel) {
    return "";
  }
  const status = statusLabel.toLowerCase();
  return mapTicketStatusToTranslationKey[status] || statusLabel;
};
