import React from "react";

const PlumeIcon = () => (
  <svg
    className={"sl-plume-icon"}
    width="100"
    height="26"
    viewBox="0 0 161 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.5639 11.9837L66.8524 11.9834C66.5251 11.9834 66.2318 12.2306 66.2148 12.56C66.1965 12.9115 66.4735 13.2025 66.8181 13.2025L66.8191 13.2022H68.2975C68.4805 13.2022 68.6288 13.3517 68.6288 13.5361V32.3204C68.6288 32.5048 68.4805 32.6543 68.2977 32.6543L66.8331 32.6545C66.5446 32.6545 66.2827 32.8518 66.2256 33.1369C66.1472 33.5291 66.4424 33.8733 66.8173 33.8733L66.8191 33.8731L73.4606 33.8733C73.7491 33.8733 74.011 33.6761 74.0681 33.391C74.1465 32.9988 73.8513 32.6545 73.4764 32.6545L71.9962 32.6543C71.8134 32.6543 71.665 32.5048 71.665 32.3204V13.5361C71.665 13.3517 71.8134 13.2019 71.9962 13.2019H75.8936C78.3942 13.2019 79.66 15.3042 79.66 18.0597C79.66 20.9802 78.728 23.1617 75.8936 23.1617C75.7722 23.1617 73.4441 23.1683 73.3232 23.1713C72.9894 23.1713 72.7189 23.4441 72.7189 23.7807C72.7189 24.1154 72.9864 24.3863 73.3174 24.3896V24.3904H76.5639C79.6575 24.3904 82.8504 22.2659 82.8504 18.1714C82.8504 14.077 79.6575 11.9837 76.5639 11.9837Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.564 31.1147L102.578 31.1064C101.831 31.512 100.91 31.936 100.302 32.1125C98.8062 32.5469 97.9633 31.592 97.9633 29.9257L97.9568 20.2288C97.9576 19.8593 97.6606 19.5596 97.2942 19.5596H93.1442C92.8167 19.5596 92.5235 19.8071 92.5067 20.1366C92.4887 20.4879 92.7656 20.7779 93.1102 20.7779H94.5906C94.7736 20.7779 94.9219 20.9274 94.9219 21.112L94.9221 29.2211C94.9221 32.0085 95.3155 33.3977 97.281 33.9953L97.282 33.9885C97.2835 33.9892 97.2852 33.9897 97.2867 33.9905C98.5623 34.489 100.144 34.0425 101.174 33.4268C102.204 32.8108 103.186 32.1534 103.186 32.1534C103.517 31.9244 103.58 31.5539 103.364 31.2607C103.159 30.9814 102.873 30.9425 102.564 31.1147Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.356 32.6078L107.858 32.6075H107.805C107.622 32.6075 107.474 32.458 107.474 32.2736V20.2276C107.474 19.8586 107.177 19.5596 106.811 19.5596H102.66C102.333 19.5596 102.039 19.8071 102.022 20.1366C102.005 20.4879 102.281 20.7779 102.626 20.7779H104.106C104.289 20.7779 104.438 20.9274 104.438 21.112V33.1586C104.438 33.5276 104.734 33.8266 105.1 33.8266H106.74L106.74 33.8268H108.39V33.8261C108.734 33.8256 109.011 33.5351 108.993 33.1838C108.976 32.8548 108.683 32.6078 108.356 32.6078Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M91.0072 32.6545C91.0072 32.6545 90.0504 32.6545 89.528 32.6543C89.345 32.6543 89.1977 32.5048 89.1977 32.3204L89.1947 12.6519C89.1945 12.2829 88.898 11.9839 88.5322 11.9839L84.3774 11.9834C84.0809 11.9834 83.8065 12.1849 83.7536 12.4794C83.6845 12.8658 83.9772 13.2025 84.3478 13.2025L84.3486 13.2022H85.8273C86.01 13.2022 86.1585 13.3517 86.1585 13.5361V32.3204C86.1585 32.505 86.01 32.6545 85.8273 32.6545L84.3586 32.6543C84.0964 32.6543 83.8505 32.8157 83.7724 33.068C83.6424 33.488 83.9499 33.8733 84.3466 33.8733H90.9777C91.2741 33.8733 91.5486 33.6718 91.6012 33.3776C91.6705 32.9909 91.3778 32.6545 91.0072 32.6545Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M147.233 24.4402L142.305 24.4404C142.008 24.4404 141.733 24.642 141.681 24.9364C141.612 25.3228 141.904 25.6592 142.275 25.6592L149.946 25.6585C150.311 25.6585 150.608 25.3605 150.608 24.9915C150.608 24.8117 150.608 24.6031 150.607 24.3745C150.603 21.1548 147.702 19.3076 144.458 19.3076C141.214 19.3076 137.578 22.0728 137.578 26.653C137.578 26.8768 137.585 27.1917 137.598 27.4066C137.917 32.572 143.399 35.4309 148.028 33.196C148.649 32.896 149.562 32.4119 150.102 32.0664C150.343 31.9121 150.54 31.5661 150.306 31.207C150.073 30.8481 149.729 30.8953 149.425 31.06C148.35 31.6424 146.789 32.078 145.711 32.078C142.375 32.078 140.615 29.9286 140.615 26.2762L140.623 25.072C140.877 22.4125 142.467 20.5259 144.461 20.5259C146.361 20.5259 147.478 21.8084 147.565 24.0922C147.572 24.2816 147.421 24.4402 147.233 24.4402Z"
      fill="white"
    />
    <path
      d="M156.741 19.708C158.211 19.708 159.289 18.602 159.289 17.16C159.289 15.718 158.253 14.612 156.741 14.612C155.243 14.612 154.207 15.718 154.207 17.16C154.207 18.602 155.285 19.708 156.741 19.708ZM156.741 19.106C155.677 19.106 154.837 18.294 154.837 17.16C154.837 16.026 155.677 15.214 156.741 15.214C157.819 15.214 158.659 16.026 158.659 17.16C158.659 18.294 157.819 19.106 156.741 19.106ZM156.811 16.432C156.993 16.432 157.147 16.558 157.147 16.74C157.147 16.922 157.007 17.048 156.811 17.048H156.475V16.432H156.811ZM157.203 17.594C157.567 17.482 157.833 17.16 157.833 16.726C157.833 16.208 157.413 15.802 156.867 15.802H155.817V18.462H156.475V17.622H156.503L157.091 18.462H157.875L157.203 17.594Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.361 32.608H115.914C115.731 32.608 115.583 32.4585 115.583 32.2741V20.2281C115.583 19.8591 115.286 19.5601 114.921 19.5601L111.641 19.5596V19.5603C111.319 19.5608 111.056 19.8154 111.038 20.1361C111.027 20.344 111.157 20.5342 111.341 20.6273L111.642 20.7789H112.215C112.398 20.7789 112.547 20.9284 112.547 21.1127V32.2741C112.547 32.4585 112.398 32.608 112.215 32.608H111.651C111.647 32.608 111.643 32.607 111.639 32.607C111.306 32.607 111.035 32.8798 111.035 33.2164C111.035 33.5528 111.305 33.8256 111.639 33.8258L111.642 33.8273L117.395 33.8266C117.739 33.8266 118.016 33.5359 117.998 33.1846C117.981 32.8553 117.688 32.608 117.361 32.608Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M126.013 32.7086C126.009 32.7086 126.006 32.7096 126.002 32.7096H125.44C125.256 32.7096 125.108 32.5601 125.108 32.3757V24.3055L125.107 24.2964C125.107 21.509 124.611 20.2503 122.748 19.5222C121.473 19.0235 119.89 19.47 118.861 20.0859C117.831 20.7016 116.849 21.3593 116.849 21.3593C116.518 21.5881 116.455 21.9588 116.67 22.252C116.876 22.5314 117.162 22.5703 117.471 22.398L117.457 22.4061C118.204 22.0008 119.125 21.5767 119.733 21.4002C121.229 20.9656 122.072 21.9207 122.072 23.5868L122.072 24.0391L122.071 32.4869C122.071 32.6098 121.972 32.7096 121.85 32.7096H121.175C121.171 32.7096 121.168 32.7086 121.164 32.7086C120.83 32.7086 120.559 32.9813 120.559 33.318C120.559 33.6544 120.83 33.9271 121.163 33.9274L121.166 33.9289H122.072L122.072 33.9287H125.108V33.9289H126.014V33.9282C126.138 33.9279 126.254 33.8893 126.35 33.8241C126.353 33.8216 126.356 33.8185 126.36 33.816C126.387 33.7963 126.414 33.7751 126.438 33.7511C126.443 33.7456 126.448 33.739 126.453 33.7332C126.474 33.7112 126.493 33.6882 126.51 33.6632C126.516 33.6546 126.521 33.6448 126.527 33.6357C126.541 33.6122 126.555 33.5882 126.566 33.5627C126.571 33.5508 126.574 33.5382 126.579 33.5261C126.588 33.5018 126.596 33.4776 126.602 33.4518C126.605 33.4372 126.606 33.422 126.608 33.4069C126.611 33.3887 126.616 33.371 126.617 33.3523C126.617 33.3483 126.615 33.3438 126.615 33.3397C126.615 33.3324 126.617 33.3253 126.617 33.318C126.617 32.9813 126.347 32.7086 126.013 32.7086Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M135.777 32.7086C135.773 32.7086 135.77 32.7096 135.766 32.7096H135.205C135.022 32.7096 134.874 32.5601 134.874 32.3757V24.3055L134.873 24.2964C134.873 21.509 134.377 20.2503 132.514 19.5222C131.238 19.0235 129.656 19.47 128.626 20.0859C127.596 20.7016 126.615 21.3593 126.615 21.3593C126.283 21.5881 126.221 21.9588 126.436 22.252C126.641 22.5314 126.927 22.5703 127.236 22.398L127.223 22.4061C127.97 22.0008 128.891 21.5767 129.499 21.4002C130.994 20.9656 131.837 21.9207 131.837 23.5868L131.838 24.0391L131.837 32.3757C131.837 32.5601 131.689 32.7096 131.506 32.7096H130.942C130.938 32.7096 130.935 32.7086 130.931 32.7086C130.692 32.7086 130.487 32.8498 130.389 33.0528C130.388 33.0564 130.385 33.0594 130.384 33.0632C130.371 33.0907 130.362 33.1195 130.353 33.149C130.351 33.1579 130.347 33.1665 130.345 33.1756C130.339 33.2013 130.336 33.2281 130.333 33.2554C130.332 33.2657 130.329 33.2756 130.328 33.2862C130.328 33.2902 130.329 33.2943 130.329 33.2985C130.328 33.3051 130.327 33.3114 130.327 33.318C130.327 33.3301 130.33 33.3415 130.33 33.3533C130.331 33.3592 130.331 33.365 130.332 33.3705C130.359 33.6812 130.614 33.9259 130.928 33.9271L130.932 33.9289H131.838L131.838 33.9287H134.874L134.874 33.9289H135.78V33.9282C136.102 33.9276 136.365 33.6731 136.383 33.3523C136.385 33.314 136.38 33.2766 136.373 33.2397C136.335 32.9404 136.084 32.7086 135.777 32.7086Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.156 20.8503L104.023 20.8223L104.156 20.8503Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6331 15.9421C11.9663 17.1044 11.952 18.5305 12.6126 19.6965L12.6139 19.6985L17.761 28.6812C17.8466 28.8315 18.0059 28.9234 18.1777 28.9214L20.2816 28.9216C20.4526 28.9199 20.5586 28.7327 20.4734 28.583L14.7025 18.4994C14.5843 18.2906 14.5257 18.0597 14.526 17.8274C14.5265 17.5935 14.5861 17.3625 14.706 17.1539L20.5641 6.91375C21.5822 5.1406 23.4596 4.04858 25.4914 4.04807L38.3273 4.0453C38.9889 4.0453 39.525 3.50434 39.525 2.8371C39.5248 2.16961 38.9884 1.62891 38.3268 1.62891L25.4909 1.63168C22.6044 1.63244 19.9371 3.1836 18.4907 5.70277L12.6331 15.9421Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8076 32.0845C14.4722 33.248 15.6897 33.9736 17.0211 33.9794L17.0236 33.9797L27.3104 33.9751C27.4822 33.9756 27.6409 33.8824 27.7251 33.7314L28.7773 31.8939C28.8612 31.7433 28.7535 31.5575 28.5822 31.557L17.0381 31.5557C16.7997 31.5544 16.5721 31.4903 16.373 31.3739C16.1725 31.2564 16.0037 31.089 15.8845 30.8799L10.0204 20.6433C9.00701 18.8674 9.00801 16.6816 10.0237 14.9067L16.4391 3.69424C16.7697 3.11641 16.5734 2.37745 16.0004 2.04383C15.4272 1.71021 14.6945 1.90846 14.364 2.4863L7.94828 13.699C6.50568 16.2204 6.50418 19.3258 7.94403 21.8487L13.8076 32.0845Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28.1575 39.1827C29.4887 39.184 30.7207 38.4832 31.3915 37.3234L31.3928 37.3212L36.5324 28.3342C36.6186 28.1842 36.6178 27.9993 36.5304 27.8503L35.4787 26.0125C35.3913 25.864 35.178 25.865 35.0918 26.0143L29.3187 36.0966C29.1985 36.3042 29.0294 36.4708 28.8299 36.5868C28.629 36.7032 28.4007 36.7668 28.1618 36.7663L16.4394 36.7699C14.4078 36.7671 12.5315 35.6733 11.5151 33.8989L5.09487 22.689C4.76408 22.1114 4.03138 21.9134 3.45845 22.247C2.88551 22.5809 2.68919 23.3199 3.02023 23.8974L9.44046 35.1076C10.8843 37.6283 13.5499 39.1825 16.4364 39.1862L28.1575 39.1827Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M41.5208 30.0139C42.1874 28.8517 42.2019 27.4253 41.5411 26.2595L41.5401 26.2575L36.3927 17.2749C36.3073 17.1246 36.148 17.0327 35.9763 17.0344L33.8723 17.0342C33.701 17.0362 33.5951 17.2233 33.6805 17.3731L39.4514 27.4566C39.5696 27.6652 39.6282 27.8963 39.628 28.1287C39.6275 28.3623 39.5679 28.5936 39.4479 28.802L33.5899 39.0423C32.5717 40.8155 30.6944 41.9075 28.6626 41.9077L15.8266 41.9105C15.165 41.9108 14.6289 42.4517 14.6289 43.119C14.6289 43.7862 15.1655 44.3272 15.8269 44.3269L28.6631 44.3241C31.5495 44.3236 34.2169 42.7722 35.6632 40.253L41.5208 30.0139Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.3952 14.1315C39.7307 12.968 38.5132 12.2424 37.1817 12.2363H37.1792L26.8924 12.2406C26.7204 12.2404 26.5619 12.3336 26.4778 12.4843L25.4256 14.3219C25.3417 14.4724 25.4493 14.6585 25.6206 14.6588L37.1647 14.6603C37.4031 14.6613 37.6305 14.7257 37.8298 14.8421C38.0304 14.9593 38.1992 15.127 38.3181 15.3359L44.1824 25.5727C45.1958 27.3486 45.1948 29.5344 44.1792 31.3091L37.7637 42.5218C37.4329 43.0996 37.6295 43.8385 38.2024 44.1722C38.7753 44.5055 39.508 44.3075 39.8388 43.7297L46.2546 32.5168C47.6972 29.9953 47.6987 26.89 46.2588 24.367L40.3952 14.1315Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.0739 6.93615C24.7424 6.93489 23.5107 7.63546 22.8398 8.79542L22.8386 8.79744L17.6989 17.7846C17.6128 17.9344 17.6133 18.1195 17.7009 18.2685L18.7527 20.1063C18.84 20.2548 19.0534 20.2538 19.1395 20.1046L24.9127 10.022C25.0329 9.81445 25.2019 9.64802 25.4015 9.5321C25.6023 9.41568 25.8307 9.35204 26.0696 9.35254L37.7917 9.34901C39.8235 9.35178 41.6999 10.4456 42.7163 12.22L49.1365 23.4299C49.4673 24.0075 50.2 24.2055 50.7729 23.8718C51.3459 23.538 51.5419 22.799 51.2111 22.2212L44.7909 11.0113C43.347 8.49034 40.6812 6.93641 37.795 6.93262L26.0739 6.93615Z"
      fill="white"
    />
  </svg>
);

export default PlumeIcon;
