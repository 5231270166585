import React, { useEffect } from "react";
import MapAll from "./Map";
import MonitorHeader from "./MonitorHeader";
import KPICont from "./KPISquares";

import "./monitorDash.scss";

import { SlPage } from "../../components/communal/Atoms/Page/Page";
import { SlSection } from "../communal/Atoms/Section/Section";
import TopmostSection from "../communal/Organisms/TopmostSection";
import MonitorLocationHistory from "./monitorLocationHistory/monitor-location-history";
import { preloadAlertData } from "../../../actions/alert/alertActions";
import { useDispatch, useSelector } from "react-redux";
import DataError from "../communal/Atoms/DataError";

function MonitorDash() {
  const dispatch = useDispatch();
  const isError = useSelector((state) => state.KPI.data_error);

  useEffect(() => {
    dispatch(preloadAlertData());
  }, [dispatch]);

  if (isError) {
    return (
      <div className="signal-monitor-error">
        <DataError />
      </div>
    );
  }

  return (
    <div className="signal-monitor">
      <SlPage>
        <TopmostSection>
          <MonitorHeader />
        </TopmostSection>

        <MapAll />

        <SlSection>
          <KPICont />
          <MonitorLocationHistory />
        </SlSection>
      </SlPage>
    </div>
  );
}

export default MonitorDash;
