import React, { forwardRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { SlIcon } from "../Icon/Icon";
import XIcon from "../../../../../assets/svg/x-icon.svg";
import "./inputText.scss";

const InputText = forwardRef((props, ref) => {
  const {
    placeholderText,
    size,
    type,
    hasError,
    isValid,
    form,
    field,
    onInputChange,
    disabled,
    clearable,
  } = props;

  const [value, setValue] = useState(field?.value || "");

  useEffect(() => {
    setValue(field?.value || "");
  }, [field?.value]);

  const classNames = classnames("Sl-input-text", {
    "Sl-input-text--small": size === "small",
    "Sl-input-text--error": hasError,
    "Sl-input-text--valid": isValid,
  });

  const onChange = ({ target }) => {
    setValue(target.value);
    if (form && field) {
      form.setFieldValue(field.name, target.value);
    } else {
      onInputChange(target.value);
    }
  };

  const handleClear = (event) => {
    event.stopPropagation();
    onInputChange("");
    setValue("");
  };

  let valueProp = form && field ? { value: field.value } : {};

  return (
    <div className={classNames}>
      <input
        ref={ref}
        tabIndex="0"
        className="Sl-input-text-input"
        type={type}
        disabled={disabled}
        name={field?.name || ""}
        {...valueProp}
        onChange={onChange}
        value={value}
        placeholder={placeholderText}
      />
      {clearable && value && (
        <SlIcon
          width={15}
          height={15}
          svgFile={XIcon}
          handleClick={(e) => handleClear(e)}
        />
      )}
    </div>
  );
});

InputText.propTypes = {
  placeholderText: PropTypes.string.isRequired,
  form: PropTypes.object,
  field: PropTypes.object,
  onInputChange: PropTypes.func,
  size: PropTypes.string,
  type: PropTypes.string,
  hasError: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default InputText;
