import { getColorByLabelId } from "../../../../../../helpers/predictHelpers";
import FormattedMessage from "../../../../../../translations/FormattedMessage";
import React from "react";

export const StackedAreaChartTooltip = ({ active, payload, label, groups }) => {
  if (active && payload && payload.length) {
    return (
      <div className="sl-stacked-area-chart__tooltip">
        <div className="sl-stacked-area-chart__tooltip-label">{label}</div>
        {groups.map((group, index) => (
          <div key={group} className="sl-stacked-area-chart__tooltip-group">
            <div
              className="sl-stacked-area-chart__tooltip-group-indicator"
              style={{ backgroundColor: getColorByLabelId(group) }}
            ></div>
            <FormattedMessage id={group} /> ({payload[index].value})
          </div>
        ))}
      </div>
    );
  }

  return null;
};
