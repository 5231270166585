import randomInt from "d3-random/src/int";

export function center(geoData, geo = false) {
  if (geoData.length < 10) return [geoData[0].geo.lat, geoData[0].geo.long];
  const sampleData = sampleTheData(geoData, 102, geo);
  const radianData = sampleData.map((d) => {
    return { lat: degreesToRadians(d.lat), lon: degreesToRadians(d.lon) };
  });
  const cartCoordinates = radianData.map((d) => {
    return cartesianCoordinates(d.lat, d.lon);
  });

  const [meanX, meanY, meanZ] = geoMean(cartCoordinates);

  const centerLocations = convertToLatLon(meanX, meanY, meanZ);
  return centerLocations; // [lat,long]
}

function degreesToRadians(point) {
  return (point * Math.PI) / 180;
}

//pass radians into this

function cartesianCoordinates(radianLat, radianLon) {
  const x = Math.cos(radianLat) * Math.cos(radianLon);
  const y = Math.cos(radianLat) * Math.sin(radianLon);
  const z = Math.sin(radianLat);

  return { x: x, y: y, z: z };
}

function sampleTheData(data, samples, geo) {
  const sampleData = [];
  for (let i = 0; i < samples; i++) {
    const sampleIndex = randomInt(data.length)();
    const sample = data[sampleIndex];
    const lat = geo ? sample.geo.lat : sample.cordinates[1];
    const lon = geo ? sample.geo.long : sample.cordinates[0];

    const sampleObject = { lat: lat, lon: lon };
    if (!isNaN(sampleObject.lat) && !isNaN(sampleObject.lon)) {
      sampleData.push(sampleObject);
    }
  }
  return sampleData;
}

function geoMean(cordinates) {
  let initialValue = 0;
  let total = cordinates.length;
  const sumX = cordinates.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.x;
  }, initialValue);
  const sumY = cordinates.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.y;
  }, initialValue);
  const sumZ = cordinates.reduce((accumulator, currentValue) => {
    return accumulator + currentValue.z;
  }, initialValue);

  return [sumX / total, sumY / total, sumZ / total];
}

function convertToLatLon(meanX, meanY, meanZ) {
  const lon = (Math.atan2(meanY, meanX) * 180) / Math.PI;
  const hyp = Math.sqrt(meanX * meanX + meanY * meanY);
  const lat = (Math.atan2(meanZ, hyp) * 180) / Math.PI;

  return [lat, lon];
}
