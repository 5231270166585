export function mapNamesToId(selections) {
  const mapObject = {};

  selections.forEach((selection) => {
    mapObject[selection.name] = selection.id;
  });

  return mapObject;
}

export function makeSelections(selections) {
  return selections.map((s) => {
    return {
      value: s.name,
      primaryDeployment: s.primaryDeployment,
    };
  });
}

export function makeSelectionAlertTicket(selected, viewingTicket) {
  if (viewingTicket) return [false, []];

  return [true, [{ name: selected.name }]];
}

export const replaceISPWithCSP = (copy) => (copy === "ISP" ? "CSP" : copy);
