import { PREDICTIVE_DATA_RECIEVED } from "../actions/predictionActions";

const initial_state = {
  cards: [
    {
      name: "Offline Network",
      data: {
        mapData: null,
        chartData: null,
      },
    },
    {
      name: "High Congestion",
      data: {
        mapData: null,
        chartData: null,
      },
    },
    {
      name: "Unstable NetWorks",
      data: {
        mapData: null,
        chartData: null,
      },
    },
    {
      name: "Poor Coverage",
      data: {
        mapData: null,
        chartData: null,
      },
    },
    {
      name: "QoE",
      data: {
        mapData: null,
        chartData: null,
      },
    },
  ],
};

function predictiveReducer(state = initial_state, action) {
  switch (action.type) {
    case PREDICTIVE_DATA_RECIEVED:
      return {
        ...state,
        ...{
          cards: state.cards.map((d, i) => {
            if (i === action.payload.index) {
              d.data.chartData = action.payload.data;
            }
            return d;
          }),
        },
      };
    default:
      return state;
  }
}

export default predictiveReducer;
