import React from "react";
import PropTypes from "prop-types";

import "./toggleStyles.scss";

const SlToggle = ({ handleOnChange, checked, toggleID, isDisabled }) => {
  const toggleChange = (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (isDisabled) {
      return;
    }
    handleOnChange(toggleID);
  };

  return (
    <div className="SL-alert-config-switch">
      <div className="container">
        <label
          data-id={`${toggleID}`}
          className="switch"
          htmlFor="checkbox"
          onClick={(e) => {
            toggleChange(e);
          }}
        >
          <input
            disabled={isDisabled}
            type="checkbox"
            id="checkbox"
            checked={checked}
          />
          <div className="slider round"></div>
        </label>
      </div>
    </div>
  );
};

SlToggle.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

export { SlToggle };
