import React, { useEffect, useState } from "react";
import SearchAndSearchFilterMenu from "../Table/SearchAndSearchFilterMenu";
import FilterMenu from "../FilterMenu";
import { SlIcon } from "../../Atoms/Icon/Icon";
import { SlButton } from "../../Atoms/Button/Button";
import { SlText } from "../../Atoms/Text/Text";
import ShareIcon from "../../../../../assets/svg/icon-share.svg";
import DownloadIcon from "../../../../../assets/svg/icon-download.svg";
import DownTriangleIcon from "../../../../../assets/svg/icon-down-triangle.svg";

import "./lasSearchAndAction.scss";
import { useParams } from "react-router";
import { labelMap } from "../../../../../actions/graphQL_queries/alertQuery";
import { SlDropdown } from "../Dropdown/Dropdown";
import { SlItem } from "../../Atoms/Item/Item";
import { SlScrollbars } from "../../Atoms/Scrollbar/Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { addFilter } from "../../../../../actions/alert/alertLASActions";
import CheckmarkCircle from "../../../../../assets/svg/checkmark-circle.svg";

import { values } from "lodash";
import { replaceISPWithCSP } from "../../../../Header/ISPSelector/helpers";
import FormattedMessage from "../../../../../translations/FormattedMessage";
import { useTranslation } from "react-i18next";
import { TicketStatus } from "../../../../../reducers/alert/alertTicketReducer";
import { getTranslationKey } from "../../../../helpers";

const addFlexboxToSlItemParagraph = (current) => ({
  ...current,
  p7Destressed: `${current.p7Destressed} Sl-LAS-action-search-item-paragraph`,
});

const LasSearchAndAction = ({
  getFilterOptionsData,
  showFilterOptions,
  filterOptions,
  toggleFilter,
  setSearchNewText,
  selectedTicket,
}) => {
  useEffect(() => {
    getFilterOptionsData && getFilterOptionsData();
  }, [getFilterOptionsData]);

  useEffect(() => {
    if (filterOptions?.byName) {
      setFilterOptions(Object.keys(filterOptions.byName));
    }
  }, [filterOptions]);

  const { ticketId, partnerId } = useParams();
  const { t } = useTranslation();
  const [_filterOptions, setFilterOptions] = useState([]);
  const dispatch = useDispatch();
  const selectedFilters = useSelector((state) =>
    values(state.alertLAS?.selectedFilters.byName)
  );
  const onClickEmail = () => {
    const directLink = `${window.origin}/alert/${partnerId}/${ticketId}?type=${
      labelMap[selectedTicket.type]
    }`;
    const csvLink = `${window.origin}/alert/${partnerId}/${ticketId}/csv?type=${
      labelMap[selectedTicket.type]
    }`;
    document.location.href = `mailto:?body=Direct%20link:%20${directLink}%0d%0aCSV%20download:%20${csvLink}&subject=Ticket%20id:%20${ticketId}%20is%20in%20alert`;
  };

  const onClickDownload = () => {
    window.open(
      `/alert/${partnerId}/${ticketId}/csv?type=${
        labelMap[selectedTicket.type]
      }`
    );
  };

  if (filterOptions === null) {
    return <div className="Sl-LAS-action-search-cont"></div>;
  }

  const filterButton = (
    <SlButton
      destressed
      backgroundOpacity={0.1}
      backgroundOpacityHighlighted={0.2}
      start={<SlIcon width={12} height={21} svgFile={DownTriangleIcon} />}
    >
      <SlText subtitle4>
        <FormattedMessage id="ticket.selectFilters" />
      </SlText>
    </SlButton>
  );

  return (
    <div className="sl-alert-details-header">
      <div className="sl-button-group">
        <SlDropdown preventDismissOnClick align={"left"} trigger={filterButton}>
          <SlScrollbars attach={false} autoHeightMax={322}>
            {_filterOptions.map((type) => (
              <SlDropdown
                preventDismissOnClick
                trigger={
                  <SlItem
                    classes={addFlexboxToSlItemParagraph}
                    onClick={() => {}}
                  >
                    <span>{type}</span>
                    <SlIcon
                      svgFile={CheckmarkCircle}
                      color={
                        selectedFilters.some((filter) => {
                          const typeOfFilter = replaceISPWithCSP(
                            filter.typeOfFilter
                          );
                          return typeOfFilter === type;
                        })
                          ? "action-primary"
                          : "contrast-2"
                      }
                      width={20}
                      height={20}
                    />
                  </SlItem>
                }
              >
                <SlScrollbars autoHeightMax={322}>
                  {Object.keys(
                    filterOptions.byName[type].selections.byName
                  ).map((option) => {
                    return (
                      <SlItem
                        classes={addFlexboxToSlItemParagraph}
                        onClick={() => dispatch(addFilter(option, type))}
                      >
                        <span>
                          {type === "Status"
                            ? t(getTranslationKey(option))
                            : option}
                        </span>
                        <SlIcon
                          svgFile={CheckmarkCircle}
                          width={20}
                          height={20}
                          color={
                            selectedFilters.some(
                              (filter) => filter.name === option
                            )
                              ? "action-primary"
                              : "contrast-2"
                          }
                        />
                      </SlItem>
                    );
                  })}
                </SlScrollbars>
              </SlDropdown>
            ))}
          </SlScrollbars>
        </SlDropdown>
      </div>

      {showFilterOptions ? (
        <FilterMenu
          actionKeys={[]}
          selectionAction={toggleFilter}
          options={filterOptions === undefined ? null : filterOptions.byName}
        />
      ) : null}
      <SearchAndSearchFilterMenu
        placeHolderText={t("ticket.searchPlaceholder")}
        searchAction={setSearchNewText}
      />

      <div className="sl-button-group">
        <SlButton
          backgroundOpacity={0.1}
          backgroundOpacityHighlighted={0.2}
          onClick={onClickEmail}
          start={<SlIcon width={21} height={21} svgFile={ShareIcon} />}
        >
          <SlText subtitle4>
            <FormattedMessage id="share" />
          </SlText>
        </SlButton>

        <SlButton
          backgroundOpacity={0.1}
          backgroundOpacityHighlighted={0.2}
          onClick={onClickDownload}
          start={<SlIcon width={21} height={21} svgFile={DownloadIcon} />}
        >
          <SlText subtitle4>
            <FormattedMessage id="download" />
          </SlText>
        </SlButton>
      </div>
    </div>
  );
};

export default LasSearchAndAction;
