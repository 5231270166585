import { useState } from "react";

export const useRangeSliderThrottling = ({ initialValue, onUpdate }) => {
  const [prevValue, setPrevValue] = useState(initialValue);
  const [currentValue, setCurrentValue] = useState(initialValue);
  const [isUndoable, setIsUndoable] = useState(false);
  const [isKeyDown, setIsKeyDown] = useState(false);

  const onMouseUp = (event) => {
    if (prevValue !== event.target.value) {
      setIsUndoable(true);
      onUpdate(event.target.value);
    } else {
      setIsUndoable(false);
    }
  };

  const onMouseDown = (event) => {
    if (!isKeyDown) {
      setPrevValue(event.target.value);
    }
  };

  const onUndo = () => {
    setCurrentValue(prevValue);
    setIsUndoable(false);
    onUpdate(prevValue);
  };

  const onKeyDown = (event) => {
    onMouseDown(event);
    setIsKeyDown(true);
  };

  const onKeyUp = (event) => {
    onMouseUp(event);
    setIsKeyDown(false);
  };

  const onValueChanged = ({ target }) => {
    setCurrentValue(target.value);
  };

  return {
    onMouseDown,
    onMouseUp,
    onKeyUp,
    onKeyDown,
    currentValue,
    onValueChanged,
    onUndo,
    isUndoable,
  };
};
