export const Routes = {
  Index: "/",
  Login: "/login",
  LoginCallback: "/implicit/callback",

  // Secured
  CsvPage: "/alert/:partnerId/:ticketId/csv",
  Monitor: "/monitor",
  Alert: "/alert",
  AlertWithPartnerId: "/alert/:partnerId",
  AlertReal: "/alert-real",
  TicketPage: "/alert/:partnerId/:ticketId",
  Predict: "/predict",
  Solve: "/solve",
  Admin: "/admin",
  Locations: "/locations",
};

export const FeatureFlags = {
  Predict: "predict",
};

export const featureFlagsConfiguration = {
  [FeatureFlags.Predict]: [
    "jmiazga@plume.com",
    "rwoodman@plume.com",
    "rik@plume.com",
    "lkrhlikar@plume.com",
    "mheer@plume.com",
    "nkrajnc@plume.com",
    "pawel.hypta@qualitytaskforce.com",
    "jkocjan@plume.com",
    "mzahariev@plume.com",
    "gristov@plume.com",
    "aleksanda.franczak@qualitytaskforce.com",
    "monika.luczak@qualitytaskforce.com",
    "szymon.hatylak@qualitytaskforce.com",
  ],
};

// export const PREDICT_DATA_SOURCE = `https://plume-1.hasura.app/v1/graphql`; // Hasura production data server
export const PREDICT_DATA_SOURCE = `https://signal-mockserver.herokuapp.com/graphql`; // hosted mock data server
// export const PREDICT_DATA_SOURCE = `http://localhost:3001/graphql`; // local mock data server

export const mapColorsToPredictItemData = [
  {
    dataLabelId: "predict.callInVolumePrediction.dataLabels.other",
    color: "#8EB7B7",
  },
  {
    dataLabelId:
      "predict.callInVolumePrediction.dataLabels.internetPerformance",
    color: "#F78673",
  },
  {
    dataLabelId: "predict.callInVolumePrediction.dataLabels.podConnection",
    color: "#A9FBC6",
  },
  {
    dataLabelId: "predict.callInVolumePrediction.dataLabels.networkOffline",
    color: "#FA1478",
  },
  {
    dataLabelId: "predict.callInVolumePrediction.dataLabels.deviceConnection",
    color: "#FFC500",
  },
  {
    dataLabelId: "predict.callInVolumePrediction.dataLabels.deviceSetup",
    color: "#D583D9",
  },
];
export const callInVolumePeriodDateFormat = "hA MMM DD";
export const historicalTrendXAxisDateFormat = "MMM D";
export const ticketDateFormat = "Do MMM LT";
export const mapTooltipDateTimeFormat = "Do MMM YYYY, h:mma";

// array of alert ids for which we should show Threshold change control
export const adminAlertsThresholdShow = [
  "ispLocationOfflineAbsolute",
  "ispLocationOfflineRelative",
];
