export const CHANGE_SELECTOR = "CHANGE_SELECTOR";
export const PAGINATION_CHANGE = "PAGINATION_CHANGE";
export const SET_LENGTH = "SET_LENGTH";

export function changeSelector(key, value) {
  return {
    type: CHANGE_SELECTOR,
    payload: {
      key: key,
      value: value,
    },
  };
}

export function paginationChange(selector) {
  return {
    type: PAGINATION_CHANGE,
    payload: {
      selector,
    },
  };
}

export function setLength(length) {
  return {
    type: SET_LENGTH,
    payload: {
      length,
    },
  };
}
