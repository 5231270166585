import React, { useMemo } from "react";
import { connect } from "react-redux";
import { selectAdminEmailGroup } from "../../../../../selectors";

import {
  addEmailToGroupThunk,
  deleteEmailToGroupThunk,
} from "../../../../../actions/admin-action";

import { InputAndDeleteList } from "./";
import { validateEmail } from "../../Molecules/InputForm/helpers";
import { objectToArray } from "./helpers";

import EmailIcon from "../../../../../assets/svg/email.svg";
import PlusIcon from "../../../../../assets/svg/plus.svg";
import { useTranslation } from "react-i18next";

const InputAndDeleteListCont = ({
  addEmail,
  list,
  onDelete,
  isEditingDisabled,
}) => {
  const { t } = useTranslation();
  const deleteListArray = useMemo(() => objectToArray(list), [list]);
  const validateInput = (inputText) => validateEmail(inputText);

  if (list === undefined) {
    return <div className="sl-admin-email-block"></div>;
  }

  const addEmailAction = (group) => {
    return (email) => {
      addEmail(email, group);
    };
  };

  const deleteAction = (group) => {
    return (text) => {
      onDelete(text, group);
    };
  };

  return (
    <div className="sl-admin-email-block">
      {Object.keys(list).map((key) => {
        if (key === "consumerOutreachGroup") {
          return null;
        }

        return (
          <InputAndDeleteList
            description={t("admin.emailNotifications.sectionDescription")}
            deleteList={deleteListArray[key]}
            onDelete={deleteAction(key)}
            iconPath={EmailIcon}
            placeholderText={t("admin.emailNotifications.placeholderText")}
            onSubmit={addEmailAction(key)}
            submitTitle={t("admin.emailNotifications.addContact")}
            submitIconPath={PlusIcon}
            validateInput={validateInput}
            isEditingDisabled={isEditingDisabled}
            validColor={"rgba(0, 255, 140, 0.85)"}
            key={key}
          />
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => ({
  list: selectAdminEmailGroup(state),
});

const mapDispatchToProps = (dispatch) => ({
  addEmail: (email, group) => {
    dispatch(addEmailToGroupThunk(email, group));
  },
  onDelete: (text, group) => {
    dispatch(deleteEmailToGroupThunk(text, group));
  },
});

const InputAndDeleteListEmail = connect(
  mapStateToProps,
  mapDispatchToProps
)(InputAndDeleteListCont);

export { InputAndDeleteListEmail };
