import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";

import "./textAndRedirect.scss";

const TextAndRedirect = ({ text, redirectURL, unMountFunc, time = 2000 }) => {
  const [redirect, setRedirect] = useState(false);

  function setRedirectToTrue() {
    setTimeout(function () {
      setRedirect(true);
    }, time);
  }

  useEffect(() => {
    return () => {
      if (unMountFunc) {
        unMountFunc();
      }
    };
  });
  const rederectEl = redirect ? <Redirect to={redirectURL} /> : null;

  setRedirectToTrue();
  return (
    <div className="sl-text-red-wrapper">
      <div className="sl-redirect">{text}</div>
      {rederectEl}
    </div>
  );
};

TextAndRedirect.propTypes = {
  text: PropTypes.string.isRequired,
  redirectURL: PropTypes.string.isRequired,
  unMountFunc: PropTypes.func,
  time: PropTypes.number,
};

export default TextAndRedirect;
