import React from "react";
import "./TopmostSection.scss";
import { SlSection } from "../../Atoms/Section/Section";
import Navigation from "../Navigation/Navigation";

const TopmostSection = (props) => {
  return (
    <SlSection className="sl-topmost-section">
      <div className="sl-topmost-section__inner">
        <Navigation />
        {props.children}
      </div>
    </SlSection>
  );
};

export default TopmostSection;
