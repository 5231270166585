import {
  OPEN_SIDE_PANEL,
  CLOSE_SIDE_PANEL,
  SIDE_PANEL_MOVING,
  SIDE_PANEL_STOPPED,
} from "../actions/sidePanelActions";

const INIT_STATE = {
  sidePanelOpen: false,
  sidePanelMoving: false,
};

export default function sidePanel(state = INIT_STATE, action = { type: null }) {
  switch (action.type) {
    case OPEN_SIDE_PANEL:
      return { ...state, ...{ sidePanelOpen: true } };
    case CLOSE_SIDE_PANEL:
      return { ...state, ...{ sidePanelOpen: false } };
    case SIDE_PANEL_MOVING:
      return { ...state, ...{ sidePanelMoving: true } };
    case SIDE_PANEL_STOPPED:
      return { ...state, ...{ sidePanelMoving: false } };
    default:
      return state;
  }
}
