import React from "react";
import PropTypes from "prop-types";
import FormattedMessage from "../../../../../translations/FormattedMessage";
import { SlText } from "../Text/Text";

import "./titleUpdate.scss";

const TitleUpdate = ({ title, time }) => {
  return (
    <div className="sl-title-time-update-wrapper-relative">
      <div className="sl-title-time-update-wrapper">
        <SlText p7 style={{ color: "#dee0e2" }}>
          {title} {<FormattedMessage id="update" />} {time}
        </SlText>
      </div>
    </div>
  );
};

TitleUpdate.propTypes = {
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export { TitleUpdate };
