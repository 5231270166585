import React, { useEffect } from "react";
import { connect } from "react-redux";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";

import KPIMenu from "./KPIMenu/KPIMenu";
import Chart from "./Chart";
import Legend from "./Legend";

import { SlTimeSelector } from "../../communal/TimeSelector/TimeSelector";
import PuffLoader from "react-spinners/PuffLoader";

import { getKPITotalPctAlarm, getLocationNumber } from "../../../../selectors";

import {
  getNMHCData,
  changeKPIChartTime,
} from "../../../../actions/monNHMCActions";

import "./KPISquares.scss";

import { selectNHMCData } from "../../../helpers";
import { percentFormat } from "../../../helpers/numberFormat";
import { SlChartHeader } from "../../communal/Molecules/BlockHeader/ChartHeader";
import { SlHeader } from "../../communal/Molecules/BlockHeader/Header";
import { SlText } from "../../communal/Atoms/Text/Text";
import { SlIcon } from "../../communal/Atoms/Icon/Icon";
import InfoIcon from "../../../../assets/svg/icon-info.svg";
import DownLoadButton from "../../communal/Atoms/DownLoadButton";
import FormattedMessage from "../../../../translations/FormattedMessage";

const KPISquareComp = ({
  changeTime,
  data,
  index,
  totalLocationsMonitored,
  name,
  labelId,
  percentInAlarm,
  time,
  times,
}) => {
  const nameForIdAttribute = name === "CSP Outage" ? "ISP Outage" : name;
  return (
    <div
      className={classNames("signal-monitoring-kpi-square", {
        "marg-right-kpi": index % 2 === 0,
      })}
    >
      <SlChartHeader
        start={
          <>
            <SlText subtitle1m>
              <FormattedMessage id={labelId} />
            </SlText>
            <KPIMenu name={name} />
          </>
        }
        /* FES-341: New Relic expects this ID */
        end={
          <SlText
            id={`sl-${nameForIdAttribute.replace(" ", "-")}-kpi-number`}
            subtitle1m
          >
            {percentFormat(percentInAlarm)}
          </SlText>
        }
      />
      <Chart
        name={name}
        data={selectNHMCData(data, name, time)}
        time={time}
        locationNumbers={totalLocationsMonitored}
      />
      <Legend name={name} />
      <span className="signal-monitoring-kpi-time-more">
        <SlTimeSelector times={times} changeAction={changeTime} id={name} />
      </span>
    </div>
  );
};

const mapStateToPropsKPI = (state, ownProps) => {
  const locationNumbers =
    state.monitor.mapData === null
      ? { total: 0, alarm: 0 }
      : getLocationNumber(state, ownProps);
  return {
    percentInAlarm: getKPITotalPctAlarm(state, ownProps),
    locationNumbers: locationNumbers,
  };
};

const KPISquare = connect(mapStateToPropsKPI, undefined)(KPISquareComp);

const KPICont = ({
  changeTime,
  data,
  KPIs,
  getData,
  isLoading,
  isError,
  totalLocationsMonitored,
  totalLocationsInAlarm,
}) => {
  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const networkAlarmHistoryInfo = (
    <>
      <div
        data-html={true}
        data-tip={ReactDOMServer.renderToStaticMarkup(
          <SlText p7>
            <FormattedMessage id="monitor.networkAlarmHistory.sectionTooltip" />
          </SlText>
        )}
        data-for="network-alarm-history"
      >
        <SlIcon width={26} height={26} svgFile={InfoIcon} />
      </div>
    </>
  );

  if (isLoading) {
    return (
      <div className="signal-monitoring-kpi-square-cont">
        <div className="Sl-monitor-loader">
          <PuffLoader color={"#ffffff"} />
        </div>
      </div>
    );
  }
  if (!totalLocationsMonitored) {
    return (
      <>
        <ReactTooltip
          place="right"
          effect="solid"
          backgroundColor="#606060"
          className="section-header-tooltip"
          id="network-alarm-history"
        ></ReactTooltip>
        <SlHeader
          start={<SlText titleSignal>Network Alarm History</SlText>}
          horizontalLine={true}
          bottomMargin={true}
        >
          {networkAlarmHistoryInfo}
        </SlHeader>
        <SlText p7 destressed>
          No Locations Monitored
        </SlText>
      </>
    );
  }

  if (isError) {
    return (
      <div className="signal-monitoring-kpi-square-cont">
        <div className="Sl-monitor-loader">
          <FormattedMessage id="error" />
          <span className="Sl-err-ref">
            <FormattedMessage id="refresh" />
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="kpi-cont">
      <ReactTooltip
        place="right"
        effect="solid"
        backgroundColor="#606060"
        className="section-header-tooltip"
        id="network-alarm-history"
      ></ReactTooltip>
      <SlHeader
        start={
          <SlText titleSignal>
            <FormattedMessage id="monitor.networkAlarmHistory.sectionTitle" />
          </SlText>
        }
        end={<DownLoadButton disabled={!totalLocationsInAlarm} />}
        horizontalLine={true}
        bottomMargin={true}
      >
        {networkAlarmHistoryInfo}
      </SlHeader>
      <div className="signal-monitoring-kpi-square-cont">
        {Object.keys(KPIs).map((kpi, i) => (
          <KPISquare
            {...KPIs[kpi]}
            key={i}
            index={i}
            data={data}
            changeTime={changeTime}
            totalLocationsMonitored={totalLocationsMonitored}
          />
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  KPIs: state.KPI.KPIs.byName,
  isLoading: state.KPI.dataLoading,
  isError: state.KPI.data_error,
  data: state.KPI.data,
  totalLocationsInAlarm:
    state.monitor.data === null
      ? null
      : state.monitor.data.partner.homesInAlarm,
  totalLocationsMonitored:
    state.monitor.data === null
      ? null
      : state.monitor.data.partner.homesMonitored,
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => {
    dispatch(getNMHCData());
  },
  changeTime: (name, time) => {
    dispatch(changeKPIChartTime(name, time));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(KPICont);
