import React from "react";
import "../LoginPage.scss";
import { Redirect } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";

export const CustomLoginCallback = () => {
  const renderError = (props) => {
    const { error } = props;
    if (error) {
      const { name, errorCode, errorSummary } = error;

      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { error: { name: name, description: errorSummary } },
          }}
        />
      );
    }
  };
  return <LoginCallback errorComponent={renderError} />;
};
