import React from "react";

import "./Page.scss";

const SlPage = ({ className, children, ...props }) => (
  <div {...props} className={`sl-page ${className}`}>
    {children}
  </div>
);

export { SlPage };
