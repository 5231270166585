import { SlText } from "../../../communal/Atoms/Text/Text";
import { SlCircle } from "../../../communal/Atoms/Circle/Circle";
import React from "react";
import "./TileValueIndicator.scss";
import PropTypes from "prop-types";

export const TileValueIndicator = ({ topCopy, bottomCopy, value }) => (
  <div className="TileValueIndicator">
    <SlText p8 destressed classNames="TileValueIndicator__text">
      {topCopy}
    </SlText>
    <SlCircle>
      <SlText
        subtitle2m
        classNames="TileValueIndicator__circle-value"
        style={{ backgroundColor: "transparent", color: "#6269ff" }}
      >
        {value}
      </SlText>
    </SlCircle>
    <SlText p8 destressed classNames="TileValueIndicator__text">
      {bottomCopy}
    </SlText>
  </div>
);

TileValueIndicator.propTypes = {
  topCopy: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  value: PropTypes.number.isRequired,
  bottomCopy: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};
