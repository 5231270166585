import React from "react";
import PropTypes from "prop-types";
import DescriptionToggle from "../../Molecules/DescriptionToggle";
import ListTitleSlider from "../../Molecules/ListTitleSlider";
import ThresholdControl from "../../Molecules/ThresholdControl";

import "./alertConfigPanel.scss";

const AlertConfigPanel = ({
  enabled,
  alertID,
  alertThreshold,
  channels,
  description,
  handleToggleChange,
  handleThresholdChange,
  channelThrottleChange,
  isEditingDisabled,
}) => {
  const handleThresholdChangeWithAlertIndex = (value) => {
    handleThresholdChange(parseInt(value), alertID);
  };

  return (
    <div className="sl-alert-config-panel">
      <DescriptionToggle
        on={enabled}
        description={description}
        handleToggleChange={handleToggleChange}
        toggleID={alertID}
        isEditingDisabled={isEditingDisabled}
      />
      <div className="sl-alert-config-panel-row">
        <ListTitleSlider
          list={channels}
          alertID={alertID}
          channelThrottleChange={channelThrottleChange}
          isEditingDisabled={isEditingDisabled}
        />
        {alertThreshold && (
          <ThresholdControl
            value={alertThreshold}
            id={alertID}
            isEditingDisabled={isEditingDisabled}
            handleValueChange={handleThresholdChangeWithAlertIndex}
          />
        )}
      </div>
    </div>
  );
};

AlertConfigPanel.propTypes = {
  alert: PropTypes.object.isRequired,
  isEditingDisabled: PropTypes.bool,
};

export default AlertConfigPanel;
