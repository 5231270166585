import React, { useState, useEffect } from "react";
import { SlItem } from "../../Item/Item";
import { SlText } from "../../Text/Text";

import "./FancyStackedBar.scss";

import * as d3 from "d3";
import FormattedMessage from "../../../../../../translations/FormattedMessage";

const SlStackedBar = ({ legend, data, unit }) => {
  const BAR_CHART_ANIMATION_DELAY = 50;
  const [animatedData, setAnimatedData] = useState([]);
  const maxValue = (data) =>
    Math.max(...data.map((val) => val.values.reduce((a, b) => a + b, 0)));
  const max = maxValue(data) || 1;
  const percentScale = d3.scaleLinear().domain([0, max]).range([0, max]);
  const calculateYScale = (isPercentage) =>
    percentScale
      .nice()
      .ticks(10)
      .filter((tick) => (isPercentage ? tick : Number.isInteger(tick)))
      .slice(1)
      .reverse();
  const calculateHeight = (maxValue, value) =>
    value ? (percentScale(value) / maxValue) * 100 : 0;
  const convertValuesToZero = (_data) =>
    _data.map((__data) => ({
      ...__data,
      values: __data.values.map((x) => 0),
    }));

  const [noData, setNoData] = useState(false);

  const renderLabel = (data, i) => {
    if (data.length <= 7) {
      return true;
    }
    if (data.length > 7 && data.length < 30) {
      if (i % 4 === 0) {
        return true;
      }
      if (i === data.length - 1) {
        return true;
      }
    }
    if (data.length === 30) {
      if (i % 5 === 0) {
        return true;
      }
      if (i === data.length - 1) {
        return true;
      }
    }
    if (data.length > 30) {
      if (i % 9 === 0) {
        return true;
      }
      if (i === data.length - 1) {
        return true;
      }
    }
  };

  const noDataString = noData && (
    <SlItem
      className="sl-stacked-bar__no-data"
      start={
        <span role="img" aria-label="party">
          🎉
        </span>
      }
    >
      <SlText p8>
        <FormattedMessage id="fancyStackedBar.noDataInfo" />
      </SlText>
    </SlItem>
  );

  const animateBarChartOnDataChange = () => {
    setAnimatedData([]);
    if (data.length === 24) {
      data[data.length - 1].label = "Now";
    }

    setTimeout(() => {
      setAnimatedData(convertValuesToZero(data));
    }, BAR_CHART_ANIMATION_DELAY);

    setTimeout(() => {
      setAnimatedData(data);
    }, BAR_CHART_ANIMATION_DELAY * 2);
  };

  useEffect(() => {
    setNoData(data.every((item) => item.values[0] === 0));
    animateBarChartOnDataChange(data);
    // eslint-disable-next-line
  }, [JSON.stringify(data)]); // SB: data requires deep comparison check

  const bar = (value, item) => (
    <div
      className="bar"
      key={item}
      style={{
        height: `${calculateHeight(maxValue(data), value)}%`,
      }}
    >
      <span className="tooltip tooltip__title">
        <SlText p8 style={{ color: "#4d4d4d", fontWeight: 700 }}>
          <FormattedMessage
            id={
              value === 1
                ? "fancyStackedBar.tooltipInfoSingle"
                : "fancyStackedBar.tooltipInfo"
            }
            params={{
              prefix: `${d3.format(",")(value.toFixed(2))}${
                unit.percentage ? "%" : ""
              }`,
            }}
          />
        </SlText>
        <SlText p8 style={{ color: "#4d4d4d" }}>
          {item.tooltipDate}
        </SlText>
      </span>
    </div>
  );

  return (
    <div className="sl-stacked-bar">
      <div className="sl-stacked-bar__top"></div>
      <div className="contain">
        <figure
          id="two"
          className={`bar-chart ${noData ? "bar-chart--no-data" : null}`}
        >
          <div className="bar-row bars">
            {noDataString}
            <div className="y-axis">
              {calculateYScale(unit.percentage).map((item, i) => (
                <div key={i} className="segment">
                  <SlText p8 destressed classNames="label">
                    {d3.format(",")(item)}
                    {item && unit.percentage ? <sup>%</sup> : ""}
                  </SlText>
                </div>
              ))}
            </div>
            <div className="x-axis">
              {animatedData.map((item, i) => (
                <div key={i} className="year wrap">
                  <div className="col">
                    {item.values.map((value) => bar(value, item))}
                  </div>
                  {renderLabel(animatedData, i) && (
                    <SlText p8 destressed classNames="label">
                      {item.label}
                    </SlText>
                  )}
                </div>
              ))}
            </div>
          </div>
        </figure>
      </div>
    </div>
  );
};

export { SlStackedBar };
