import React from "react";
import FormattedMessage from "../../../../../../translations/FormattedMessage";
import { SlText } from "../../Text/Text";

import "./trendLegend.scss";

const TrendLegend = () => {
  return (
    <div className="signal-chart-legend-cont">
      <div className="signal-chart-legend">
        <span className="signal-chart-trendline-line"></span>
        <SlText p8 classNames="signal-chart-trendline-line-title">
          <FormattedMessage id="monitor.KPIs.trendLegend" />
        </SlText>
      </div>
    </div>
  );
};

export default TrendLegend;
