export const transfomrQueryToDownloadObject = (data) => {
  const kpiKeys = {
    evilTwin: true,
    ispOutage: true,
    unstableNetwork: true,
    offlineNetwork: true,
  };
  const downloadData = [];

  Object.keys(data.partner).forEach((key) => {
    if (kpiKeys[key] !== undefined) {
      data.partner[key].troublePoints.forEach((point) => {
        const new_data = {
          KPI: key,
          id: point.id,
          long: point.geo.long,
          lat: point.geo.lat,
        };
        downloadData.push(new_data);
      });
    }
  });
  return downloadData;
};
