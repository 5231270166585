const NMHCQuery = `query tenantsHomeMonitered($id:ID){
                                            partner(id:$id){
                                              name
                                             unstableNetwork{
                                               totalPctAlarm,
                                               pctWarning,
                                               pctTrouble,
                                                 daily(daysBack: 30){
                                                   pctWarning{
                                                     ts,
                                                     val,
                                                   }
                                                 pctTrouble{
                                                   ts,
                                                   val,
                                                  }
                                                }
                                                hourly(hoursBack: 24){
                                                  pctWarning{
                                                    ts,
                                                    val,
                                                  }
                                                  pctTrouble{
                                                    ts,
                                                    val,
                                                   }
                                                 }
                                             }
                                             ispOutage{
                                                 totalPctAlarm,
                                                 pctWarning,
                                                 pctTrouble,
                                                 daily(daysBack: 30){
                                                   pctWarning{
                                                     ts,
                                                     val,
                                                   }
                                                 pctTrouble{
                                                   ts,
                                                   val,
                                                  }
                                                }
                                                hourly(hoursBack: 24){
                                                  pctWarning{
                                                    ts,
                                                    val,
                                                  }
                                                  pctTrouble{
                                                    ts,
                                                    val,
                                                   }
                                                }
                                             }
                                              evilTwin{
                                                 totalPctAlarm,
                                                 pctWarning,
                                                 pctTrouble,
                                                 daily(daysBack: 30){
                                                   pctWarning{
                                                     ts,
                                                     val,
                                                   }
                                                 pctTrouble{
                                                   ts,
                                                   val,
                                                  }
                                                }
                                                hourly(hoursBack: 24){
                                                  pctWarning{
                                                    ts,
                                                    val,
                                                  }
                                                  pctTrouble{
                                                    ts,
                                                    val,
                                                   }
                                                }
                                             }
                                             offlineNetwork{
                                                 totalPctAlarm,
                                                 pctWarning,
                                                 pctTrouble,
                                                   daily(daysBack: 30){
                                                     pctWarning{
                                                       ts,
                                                       val,
                                                     }
                                                   pctTrouble{
                                                     ts,
                                                     val,
                                                    }
                                                  }
                                                  hourly(hoursBack: 24){
                                                    pctWarning{
                                                      ts,
                                                      val,
                                                    }
                                                    pctTrouble{
                                                      ts,
                                                      val,
                                                     }
                                                  }
                                               }
                                             }
                                           }`;

export { NMHCQuery };
