import producer from "immer";
import { changeQLDataToObject } from "./helpers/buildFilterOptions";
import { mutateNestedChild } from "./helpers/mutateNestedChild";

import {
  ADD_FILTER,
  DELETE_FILTER,
  HIDE_FILTERS,
  SET_MON_LOCATION_DATA,
  SET_MON_TABLE_SEARCH_FILTERS,
  SET_MON_TABLE_SEARCH_LOCATIONS,
  SET_MON_TABLE_PAGINATION,
  SHOW_HIDE_FILTERS,
  SHOW_FILTERS,
  SET_FILTER_OPTIONS,
  SET_FILTER_ERROR,
  SET_SEARCH_TEXT,
} from "../actions/monLASActions";

export const Init_State = {
  showFilters: false,
  filtersSelectionData: null,
  filterSearchArray: [],
  filterErr: null,
  selectedFilters: { byName: {} },
  searchText: "",
  first: 25,
  offset: 0,
  locationsData: null,
  filterSearchLocations: [],
};

const monLASReducer = producer((draftState, action = { type: null }) => {
  switch (action.type) {
    case ADD_FILTER:
      draftState.selectedFilters.byName[action.payload.filter] = {
        typeOfFilter: action.payload.keyList[0],
        name: action.payload.filter,
        filterKeys: action.payload.keyList,
      };

      mutateNestedChild(
        draftState.filtersSelectData.byName,
        true,
        action.payload.keyList
      );
      return;
    case DELETE_FILTER:
      delete draftState.selectedFilters.byName[action.payload.filter];
      mutateNestedChild(
        draftState.filtersSelectData.byName,
        false,
        action.payload.keyList
      );
      return;
    case SET_MON_LOCATION_DATA:
      draftState.locationsData = action.payload.data;
      return;
    case SHOW_HIDE_FILTERS:
      draftState.showFilters = !draftState.showFilters;
      return;
    case HIDE_FILTERS:
      draftState.showFilters = false;
      return;
    case SHOW_FILTERS:
      draftState.showFilters = true;
      return;
    case SET_FILTER_OPTIONS:
      draftState.filtersSelectData = changeQLDataToObject(action.payload.data);
      return;
    case SET_MON_TABLE_SEARCH_FILTERS:
      draftState.filterSearchArray = action.payload.filters;
      return;
    case SET_MON_TABLE_SEARCH_LOCATIONS:
      draftState.filterSearchLocations = action.payload.locations;
      return;
    case SET_MON_TABLE_PAGINATION:
      draftState.first = action.payload.first;
      draftState.offset = action.payload.offset;
      return;
    case SET_FILTER_ERROR:
      draftState.filterErr = true;
      draftState.filtersSelectionData = null;
      return;
    case SET_SEARCH_TEXT:
      draftState.searchText = action.payload.text;
      return;
    default:
      return;
  }
}, Init_State);

export default monLASReducer;
