import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { SlToggle } from "../../Atoms/Toggle";

import "./alertEnabledToggle.scss";
import FormattedMessage from "../../../../../translations/FormattedMessage";

const AlertEnabledToggle = ({
  on,
  handleToggleChange,
  toggleID,
  isEditingDisabled,
}) => {
  const textId = `admin.alerts.${on ? "enabled" : "disabled"}`;
  return (
    <span
      className={classNames("sl-admin-alert-toggle-wrapper", {
        "sl-admin-alert-toggle-wrapper-off": !on,
        "sl-admin-alert-toggle-wrapper--disabled": isEditingDisabled,
      })}
    >
      <span
        className={classNames("sl-admin-alert-toggle-title", {
          "sl-admin-alert-toggle-title-off": !on,
        })}
      >
        <FormattedMessage id={textId} />
      </span>
      <SlToggle
        handleOnChange={handleToggleChange}
        checked={on}
        toggleID={toggleID}
        isDisabled={isEditingDisabled}
      />
    </span>
  );
};

AlertEnabledToggle.propTypes = {
  on: PropTypes.bool.isRequired,
  handleOnChange: PropTypes.func.isRequired,
  toggleID: PropTypes.number.isRequired,
  isEditingDisabled: PropTypes.bool,
};

export default AlertEnabledToggle;
