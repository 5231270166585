import {
  LOADING_ME_DATA,
  LOADED_ME_DATA,
  LOADED_ME_DATA_ERROR,
} from "../actions/meActions";

const init_state = {
  loading_data: true,
  data_errors: false,
  data_errors_type: [],
};

export default function mainReducer(state = init_state, action = null) {
  switch (action.type) {
    case LOADING_ME_DATA:
      return { ...state, loading_data: true };
    case LOADED_ME_DATA:
      return { ...state, loading_data: false };
    case LOADED_ME_DATA_ERROR:
      return {
        ...state,
        data_errors: true,
        data_errors_type: [...state.data_errors_type, action.payload],
      };
    default:
      return state;
  }
}
