import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { AvailableCharts, getChartType } from "../../../../../selectors";

import TrendLegend from "../../../communal/Atoms/Charts/TrendLine/TrendLegend";
import BarLegend from "../../../communal/Atoms/Charts/StackedBarChartComp/BarLegend";

import "./legend.scss";

const Legend = ({ chart, name }) => {
  switch (chart) {
    case AvailableCharts.TrendLine:
      return <TrendLegend />;
    case AvailableCharts.BarChart:
      return <BarLegend name={name} />;
    default:
      return <TrendLegend />;
  }
};

const mapStateToProps = (state, ownProps) => ({
  chart: getChartType(state, ownProps),
});

Legend.propTypes = {
  name: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, undefined)(Legend);
