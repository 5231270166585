import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import FilterOption from "../../Atoms/FilterOption";
import { hideFilters } from "../../../../../actions/monLASActions";

import "./filterMenu.scss";

const FilterMenu = ({
  closeMenu,
  options,
  selectable,
  selectionAction,
  actionKeys,
}) => {
  const wrapperRef = useRef(null);

  function clickOutsideFunc() {
    if (!selectable) {
      closeMenu();
    }
  }

  function useOutsideAlerter(ref, onClick) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onClick();
        }
      }

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
      //eslint-disable-next-line
    }, [ref]);
  }
  useOutsideAlerter(wrapperRef, clickOutsideFunc);

  if (options === null) {
    return null;
  }

  return (
    <div className="Sl-filter-menu-group-cont">
      <div ref={wrapperRef} className="Sl-filter-menu-group">
        {Object.keys(options).map((op, i) => {
          let count =
            options[op].count !== undefined ? options[op].count : false;
          return (
            <FilterOption
              key={i}
              actionKeys={[...actionKeys, op]}
              options={options[op]}
              selectable={selectable}
              selectionAction={selectionAction}
              count={count}
            >
              {op}
            </FilterOption>
          );
        })}
      </div>
    </div>
  );
};

const mapDisptachToprops = (dispatch) => ({
  closeMenu: () => {
    dispatch(hideFilters());
  },
});

export default connect(undefined, mapDisptachToprops)(FilterMenu);
