import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./Menu.scss";

const SlMenu = ({ children, size }) => {
  const classNames = classnames(
    "sl-menu",
    { narrow: size === "narrow" },
    { wide: size === "wide" }
  );

  return <div className={classNames}>{children}</div>;
};

SlMenu.propTypes = {
  size: PropTypes.string,
};

export { SlMenu };
