import { sleep } from "./helpers";

export const OPEN_SIDE_PANEL = "OPEN_SIDE_PANEL";
export const CLOSE_SIDE_PANEL = "CLOSE_SIDE_PANEL";
export const SIDE_PANEL_MOVING = "SIDE_PANEL_MOVING";
export const SIDE_PANEL_STOPPED = "SIDE_PANEL_STOPPED";

export function closeSidePanel() {
  return {
    type: CLOSE_SIDE_PANEL,
  };
}

export function openSidePanel() {
  return {
    type: OPEN_SIDE_PANEL,
  };
}

export function sidePanelMoving() {
  return {
    type: SIDE_PANEL_MOVING,
  };
}

export function sidePanelStopped() {
  return {
    type: SIDE_PANEL_STOPPED,
  };
}

//thunks
function closeSidePanelThunk() {
  return async function (dispatch, getState) {
    // tells all other visualizations that side panel is about to move
    dispatch(sidePanelMoving());
    //close sidepanel
    dispatch(closeSidePanel());

    //wait for side panel to open
    await sleep(200);

    //tells al other visualizations that the side panel has stopped moving
    dispatch(sidePanelStopped());
  };
}

function openSidePanelThunk() {
  return async function (dispatch, getState) {
    // tells all other visualizations that side panel is about to move
    dispatch(sidePanelMoving());
    //open sidepanel
    dispatch(openSidePanel());

    //wait for side panel to open
    await sleep(200);

    //tells al other visualizations that the side panel has stopped moving
    dispatch(sidePanelStopped());
  };
}

export { closeSidePanelThunk, openSidePanelThunk };
