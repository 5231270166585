import React from "react";
import "./Text.scss";

const SlText = ({
  id,
  destressed,
  primary,
  positive,
  titleSignal,
  titleM,
  heading3m,
  subtitle1m,
  subtitle2m,
  subtitle3,
  subtitle4,
  subtitle4m,
  subtitle6,
  p7,
  p8,
  style,
  classNames,
  children,
}) => (
  <>
    {titleSignal && (
      <h1 id={id} className={"sl-text sl-text--title--signal"} style={style}>
        {children}
      </h1>
    )}
    {titleM && (
      <h1 id={id} className={"sl-text sl-text--title--m"} style={style}>
        {children}
      </h1>
    )}
    {heading3m && (
      <h2 id={id} className={"sl-text sl-text--heading--3m"} style={style}>
        {children}
      </h2>
    )}
    {subtitle1m && (
      <div id={id} className={"sl-text sl-text--subtitle--1m"} style={style}>
        {children}
      </div>
    )}
    {subtitle2m && (
      <div
        id={id}
        className={`sl-text sl-text--subtitle--2m ${classNames || ""}`}
        style={style}
      >
        {children}
      </div>
    )}
    {subtitle3 && (
      <div id={id} className={"sl-text sl-text--subtitle--3"} style={style}>
        {children}
      </div>
    )}
    {subtitle4 && (
      <div
        id={id}
        className={`sl-text sl-text--subtitle--4 ${classNames}`}
        style={style}
      >
        {children}
      </div>
    )}
    {subtitle4m && (
      <div
        id={id}
        className={`sl-text sl-text--subtitle--4m ${classNames}`}
        style={style}
      >
        {children}
      </div>
    )}
    {subtitle6 && (
      <div id={id} className={"sl-text sl-text--subtitle--6"} style={style}>
        {children}
      </div>
    )}
    {p7 && !destressed && !primary && !positive && (
      <p
        id={id}
        className={`sl-text sl-text--paragraph--7 ${classNames || ""}`}
        style={style}
      >
        {children}
      </p>
    )}
    {p7 && destressed && (
      <p
        id={id}
        className={`sl-text sl-text--paragraph--7-destressed ${
          classNames || ""
        }`}
        style={style}
      >
        {children}
      </p>
    )}
    {p7 && primary && (
      <p
        id={id}
        className={"sl-text sl-text--paragraph--7-primary"}
        style={style}
      >
        {children}
      </p>
    )}
    {p7 && positive && (
      <p
        id={id}
        className={"sl-text sl-text--paragraph--7-positive"}
        style={style}
      >
        {children}
      </p>
    )}
    {p8 && destressed && (
      <p
        id={id}
        className={`sl-text sl-text--paragraph--8-destressed ${
          classNames || ""
        }`}
        style={style}
      >
        {children}
      </p>
    )}

    {p8 && !destressed && (
      <p
        id={id}
        className={`sl-text sl-text--paragraph--8 ${classNames || ""}`}
        style={style}
      >
        {children}
      </p>
    )}
  </>
);

export { SlText };
