import { useState, useEffect } from "react";
import WebMercatorViewport from "viewport-mercator-project";
import { getBounds } from "./Map-utils";
import { DEFAULT_WORLD_VIEW } from "../../../../../utils/constants";

const MAP_MAX_ZOOM = 10;

export const useMap = ({
  width,
  height,
  markers,
  latitude,
  longitude,
  zoom,
}) => {
  const [viewport, setViewport] = useState({
    latitude,
    longitude,
    zoom,
    width: width || 400,
    height: height || 400,
  });

  const setPosition = (width, height, markers) => {
    if (width && height && markers?.length) {
      const MARKERS_BOUNDS = getBounds(markers);
      setViewport((viewport) => {
        const nextViewport = new WebMercatorViewport({
          ...viewport,
          width,
          height,
        }).fitBounds(MARKERS_BOUNDS, {
          padding: 20,
        });
        return {
          ...nextViewport,
          ...{
            transitionDuration: 300,
            zoom:
              nextViewport.zoom > MAP_MAX_ZOOM
                ? MAP_MAX_ZOOM
                : nextViewport.zoom < 0
                ? 0
                : nextViewport.zoom,
          },
        };
      });
    } else {
      setViewport({
        latitude: DEFAULT_WORLD_VIEW.lat,
        longitude: DEFAULT_WORLD_VIEW.long,
        zoom: DEFAULT_WORLD_VIEW.zoom,
        width: width || 400,
        height: height || 400,
      });
    }
  };

  useEffect(() => {
    setPosition(width, height, markers);
  }, [width, height, markers]);

  if (viewport.reset) {
    setPosition(width, height, markers);
  }

  const onViewportChange = (nextViewport) => setViewport(nextViewport);

  return {
    onViewportChange,
    viewport,
  };
};
