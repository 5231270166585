import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const FormattedMessage = ({ id, params = {} }) => {
  const { t } = useTranslation();
  const message = t(id, params);
  return <>{message}</>;
};

FormattedMessage.propTypes = {
  id: PropTypes.string.isRequired,
  params: PropTypes.object,
};

export default FormattedMessage;
