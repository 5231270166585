import React from "react";
import PropTypes from "prop-types";
import TableHeaders from "../../../Atoms/Table/TableHeaders";
import { SlTableRow } from "../TableRow/TableRow";
import "./table.scss";
import FormattedMessage from "../../../../../../translations/FormattedMessage";

const Table = ({ data, headers, keys, selectedRow }) => {
  if (data === null) {
    return (
      <div className="Sl-table-cont">
        <table className="Sl-view-table">
          <TableHeaders headers={headers} />
          <tbody></tbody>
        </table>
      </div>
    );
  }
  return (
    <div className="Sl-table-cont">
      <table className="Sl-view-table">
        <colgroup>
          {keys.map((_, i) => (
            <col key={i}></col>
          ))}
        </colgroup>
        <TableHeaders headers={headers} />
        <tbody>
          {!data.length && (
            <tr>
              <td>
                <FormattedMessage id="ticket.table.noMatchesFound" />
              </td>
            </tr>
          )}
          {data.map((d, index) => (
            <SlTableRow
              key={index}
              rowData={d}
              keys={keys}
              selected={
                selectedRow.length && d[selectedRow[0]] === selectedRow[1]
              }
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

Table.propTypes = {
  keys: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
};

export default Table;
