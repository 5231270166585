import axios from "axios";

import { getOktaToken } from "../helpers/Token/Token";
import { unNormalizeAlerts } from "./adminHelpers";
import { findEmail } from "./adminHelpers/helpers";

import { GET_CLOUD_NOTIFICATIONS_URL } from "../utils/constants";
import { getOktaClientId } from "../utils/environments";

export const FETCH_ALERT = "FETCH_ALERT";
export const FETCH_ALERT_SUCCESS = "FETCH_ALERT_SUCCESS";
export const FETCH_ALERT_ERROR = "FETCH_ALERT_ERROR";
export const CHANGE_ALERT_ENABLE = "CHANGE_ALERT_ENABLE";
export const CHANGE_ALERT_ID = "CHANGE_ALERT_ID";
export const CHANGE_CHANNEL_ENABLE = "CHANGE_CHANNEL_ENABLE";
export const CHANGE_CHANNEL_THROTTLING = "CHANGE_CHANNEL_THROTTLING";
export const CLEAR_ERROR_ALERT = "CLEAR_ERROR_ALERT";
export const PATCH_ALERT = "PATCH_ALERT";
export const PATCH_ALERT_SUCCESS = "PATCH_ALERT_SUCCESS";
export const PATCH_ALERT_ERROR = "PATCH_ALERT_ERROR";
export const CHANGE_ALERT_THRESHOLD = "CHANGE_ALERT_THRESHOLD";

export const UPDATE_OPERATIONAL_GROUP = "UPDATE_OPERATIONAL_GROUP";
export const UPDATE_CONSUMER_OUTREACH_GROUP = "UPDATE_CONSUMER_OUTREACH_GROUP";

export const ADD_EMAIL_TO_GROUP = "ADD_EMAIL_GROUP";
export const DELETE_EMAIL_TO_GROUP = "DELETE_EMAIL_TO_GROUP";

//action creators
export const addEmailToGroup = (email, group) => ({
  type: ADD_EMAIL_TO_GROUP,
  payload: {
    group: group,
    email,
  },
});

export const deleteEmailToGroup = (text, group) => ({
  type: DELETE_EMAIL_TO_GROUP,
  payload: {
    text,
    group,
  },
});

export function fetchAlert() {
  return {
    type: FETCH_ALERT,
  };
}

export function fetchAlertSuccess(data) {
  return {
    type: FETCH_ALERT_SUCCESS,
    payload: {
      data: data,
    },
  };
}

export function fetchAlertError(err) {
  return {
    type: FETCH_ALERT_ERROR,
    payload: {
      err,
    },
  };
}

export function changeChannelEnable(alertIndex, channelIndex) {
  return {
    type: CHANGE_CHANNEL_ENABLE,
    payload: {
      alertIndex,
      channelIndex,
    },
  };
}

export function changeChannelThrottling(val, alertIndex, channelIndex) {
  return {
    type: CHANGE_CHANNEL_THROTTLING,
    payload: {
      val,
      alertIndex,
      channelIndex,
    },
  };
}

export function changeAlertEnable(index) {
  return {
    type: CHANGE_ALERT_ENABLE,
    payload: {
      index,
    },
  };
}

export function changeAlertID(id) {
  return {
    type: CHANGE_ALERT_ID,
    payload: {
      id,
    },
  };
}

export const changeAlertThreshold = (value, alertIndex) => ({
  type: CHANGE_ALERT_THRESHOLD,
  payload: {
    alertIndex,
    value,
  },
});

export const clearError = () => (dispatch) => {
  dispatch({ type: CLEAR_ERROR_ALERT });
};

export const patchAlertEnable = (index) => async (dispatch, getState) => {
  dispatch(changeAlertEnable(index));
  const defaultID = getState().admin.alerts.id === "default";
  const alerts = unNormalizeAlerts(getState().admin.alerts);
  const patchData = Object.assign({ teamMembers: ["null@plume.com"] }, alerts);
  const partnerId = getState().me.selectedPartner.id;
  const primaryDeployment = getState().me.selectedPartner.primaryDeployment;
  const alert_url = `${GET_CLOUD_NOTIFICATIONS_URL(
    primaryDeployment
  )}/signal/partners/${partnerId}?client_id=${getOktaClientId()}`;
  const token = getOktaToken(localStorage);

  try {
    if (defaultID) {
      const response = await axios.post(alert_url, patchData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const id = response.data.id;
      dispatch(changeAlertID(id));
    } else {
      await axios.patch(alert_url, patchData, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  } catch (err) {
    dispatch(fetchAlertError(err));
  }
};

export const getAlerts = () => async (dispatch, getState) => {
  const partnerId = getState().me.selectedPartner.id;
  const primaryDeployment = getState().me.selectedPartner.primaryDeployment;
  const alert_url = `${GET_CLOUD_NOTIFICATIONS_URL(
    primaryDeployment
  )}/signal/partners/${partnerId}?client_id=${getOktaClientId()}`;
  const token = getOktaToken(localStorage);

  try {
    dispatch(fetchAlert());
    // Note Authorization header may be overwritten by axios interceptor
    const response = await axios.get(alert_url, {
      headers: { Authorization: `Bearer  + ${token}` },
    });
    const data = response.data;
    dispatch(fetchAlertSuccess(data));
  } catch (err) {
    dispatch(fetchAlertError(err));
  }
};

export const throttlingPatchChange = (val, alertIndex, channelIndex) => async (
  dispatch,
  getState
) => {
  dispatch(changeChannelThrottling(val, alertIndex, channelIndex));
  const defaultID = getState().admin.alerts.id === "default";
  const alerts = unNormalizeAlerts(getState().admin.alerts);
  const patchData = Object.assign({ teamMembers: ["null@plume.com"] }, alerts);
  const partnerId = getState().me.selectedPartner.id;
  const primaryDeployment = getState().me.selectedPartner.primaryDeployment;
  const alert_url = `${GET_CLOUD_NOTIFICATIONS_URL(
    primaryDeployment
  )}/signal/partners/${partnerId}?client_id=${getOktaClientId()}`;
  const token = getOktaToken(localStorage);

  try {
    if (defaultID) {
      const response = await axios.post(alert_url, patchData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const id = response.data.id;
      dispatch(changeAlertID(id));
    } else {
      await axios.patch(alert_url, patchData, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  } catch (err) {
    dispatch(fetchAlertError(err));
  }
};

export const addEmailToGroupThunk = (emailText, group) => async (
  dispatch,
  getState
) => {
  const current_email_list = Object.keys(
    getState().admin.alerts[group].byText
  ).map((email) => email);
  // if email is already added this thunk does nothing
  if (findEmail(emailText, current_email_list)) {
    return;
  }
  dispatch(addEmailToGroup(emailText, group));

  const defaultID = getState().admin.alerts.id === "default";
  const alerts = unNormalizeAlerts(getState().admin.alerts);
  const patchData = Object.assign({ teamMembers: ["null@plume.com"] }, alerts);
  const partnerId = getState().me.selectedPartner.id;
  const primaryDeployment = getState().me.selectedPartner.primaryDeployment;
  const alert_url = `${GET_CLOUD_NOTIFICATIONS_URL(
    primaryDeployment
  )}/signal/partners/${partnerId}?client_id=${getOktaClientId()}`;
  const token = getOktaToken(localStorage);

  try {
    if (defaultID) {
      const response = await axios.post(alert_url, patchData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const id = response.data.id;
      dispatch(changeAlertID(id));
    } else {
      await axios.patch(alert_url, patchData, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  } catch (err) {
    dispatch(fetchAlertError(err));
  }
};

export const deleteEmailToGroupThunk = (text, group) => async (
  dispatch,
  getState
) => {
  dispatch(deleteEmailToGroup(text, group));

  const defaultID = getState().admin.alerts.id === "default";
  const alerts = unNormalizeAlerts(getState().admin.alerts);
  const patchData = Object.assign({ teamMembers: ["null@plume.com"] }, alerts);
  const partnerId = getState().me.selectedPartner.id;
  const primaryDeployment = getState().me.selectedPartner.primaryDeployment;
  const alert_url = `${GET_CLOUD_NOTIFICATIONS_URL(
    primaryDeployment
  )}/signal/partners/${partnerId}?client_id=${getOktaClientId()}`;
  const token = getOktaToken(localStorage);

  try {
    if (defaultID) {
      const response = await axios.post(alert_url, patchData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const id = response.data.id;
      dispatch(changeAlertID(id));
    } else {
      await axios.patch(alert_url, patchData, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  } catch (err) {
    dispatch(fetchAlertError(err));
  }
};

export const alertThresholdPatchChange = (val, alertIndex) => async (
  dispatch,
  getState
) => {
  dispatch(changeAlertThreshold(val, alertIndex));
  const defaultID = getState().admin.alerts.id === "default";
  const alerts = unNormalizeAlerts(getState().admin.alerts);
  const patchData = Object.assign({ teamMembers: ["null@plume.com"] }, alerts);
  const partnerId = getState().me.selectedPartner.id;
  const primaryDeployment = getState().me.selectedPartner.primaryDeployment;
  const alert_url = `${GET_CLOUD_NOTIFICATIONS_URL(
    primaryDeployment
  )}/signal/partners/${partnerId}?client_id=${getOktaClientId()}`;
  const token = getOktaToken(localStorage);

  try {
    if (defaultID) {
      const response = await axios.post(alert_url, patchData, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const id = response.data.id;
      dispatch(changeAlertID(id));
    } else {
      await axios.patch(alert_url, patchData, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }
  } catch (err) {
    dispatch(fetchAlertError(err));
  }
};
