import React from "react";

export const Download = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.832 15.2636V4.08431C12.832 3.62407 12.4589 3.25098 11.9987 3.25098C11.5385 3.25098 11.1654 3.62407 11.1654 4.08431V15.2636L7.97593 12.3641C7.63538 12.0545 7.10834 12.0796 6.79875 12.4202C6.48916 12.7607 6.51426 13.2878 6.85481 13.5974L11.1579 17.5092C11.6346 17.9427 12.3628 17.9427 12.8395 17.5092L17.1426 13.5974C17.4831 13.2878 17.5082 12.7607 17.1987 12.4202C16.8891 12.0796 16.362 12.0545 16.0215 12.3641L12.832 15.2636ZM6.58203 19.501C6.58203 19.9612 6.95513 20.3343 7.41536 20.3343H16.582C17.0423 20.3343 17.4154 19.9612 17.4154 19.501C17.4154 19.0407 17.0423 18.6676 16.582 18.6676H7.41536C6.95513 18.6676 6.58203 19.0407 6.58203 19.501Z"
      fill="white"
    />
  </svg>
);

export const Email = () => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 10.001V9.71973V2.00098C18 1.44869 17.5523 1.00098 17 1.00098H5C4.44772 1.00098 4 1.44869 4 2.00098V9.71973V10.001"
      stroke="white"
    />
    <path d="M1 8.00098L11 14.001L21 8.00098" stroke="white" />
    <path
      d="M18 6.00098H20C20.5523 6.00098 21 6.44869 21 7.00098V18.001C21 18.5533 20.5523 19.001 20 19.001H2C1.44772 19.001 1 18.5533 1 18.001V7.00098C1 6.44869 1.44772 6.00098 2 6.00098H4"
      stroke="white"
    />
    <line x1="7" y1="3.50098" x2="15" y2="3.50098" stroke="white" />
    <line x1="7" y1="5.50098" x2="15" y2="5.50098" stroke="white" />
    <line x1="7" y1="7.50098" x2="15" y2="7.50098" stroke="white" />
  </svg>
);

export const ArrowDown = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.7197 7.90901C19.0126 7.61612 19.4874 7.61612 19.7803 7.90901C20.0732 8.2019 20.0732 8.67678 19.7803 8.96967L12.25 16.5L4.71967 8.96967C4.42678 8.67678 4.42678 8.2019 4.71967 7.90901C5.01256 7.61612 5.48744 7.61612 5.78033 7.90901L12.25 14.3787L18.7197 7.90901Z"
      fill="white"
    />
  </svg>
);

export const ArrowUp = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.28033 16.091C4.98744 16.3839 4.51256 16.3839 4.21967 16.091C3.92678 15.7981 3.92678 15.3232 4.21967 15.0303L11.75 7.5L19.2803 15.0303C19.5732 15.3232 19.5732 15.7981 19.2803 16.091C18.9874 16.3839 18.5126 16.3839 18.2197 16.091L11.75 9.62132L5.28033 16.091Z"
      fill="white"
    />
  </svg>
);

export const BookMarkIcon = () => (
  <svg
    width="13"
    height="20"
    viewBox="0 0 14 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66552 11.3284L0.500002 16.8773L0.5 0.499999L13.5 0.499998L13.5 16.8773L7.33448 11.3284L7 11.0273L6.66552 11.3284Z"
      stroke="#DEE0E2"
    />
  </svg>
);

export const DeleteTextX = () => (
  <svg focusable="false" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="#939597"
      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
    ></path>
  </svg>
);

export const SearchIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.521484 7.41406C0.521484 11.1318 3.55371 14.1729 7.28027 14.1729C8.73926 14.1729 10.1016 13.6982 11.209 12.9072L15.375 17.0732C15.5684 17.2666 15.8232 17.3633 16.0869 17.3633C16.6582 17.3633 17.0713 16.9238 17.0713 16.3613C17.0713 16.0977 16.9834 15.8516 16.79 15.6582L12.6504 11.5098C13.5205 10.3672 14.0391 8.95215 14.0391 7.41406C14.0391 3.69629 10.998 0.655273 7.28027 0.655273C3.55371 0.655273 0.521484 3.69629 0.521484 7.41406ZM12.5801 7.41406C12.5801 10.3145 10.1807 12.7139 7.28027 12.7139C4.37109 12.7139 1.98047 10.3145 1.98047 7.41406C1.98047 4.51367 4.37109 2.11426 7.28027 2.11426C10.1807 2.11426 12.5801 4.51367 12.5801 7.41406Z"
      fill="#939597"
    />
  </svg>
);

export const ShareIcon = () => (
  <svg
    width="20"
    height="35"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19 11V10.7188V3C19 2.44772 18.5523 2 18 2H6C5.44772 2 5 2.44772 5 3V10.7188V11"
      stroke="white"
    />
    <path d="M2 9L12 15L22 9" stroke="white" />
    <path
      d="M19 7H21C21.5523 7 22 7.44772 22 8V19C22 19.5523 21.5523 20 21 20H3C2.44772 20 2 19.5523 2 19V8C2 7.44772 2.44772 7 3 7H5"
      stroke="white"
    />
    <line x1="8" y1="4.5" x2="16" y2="4.5" stroke="white" />
    <line x1="8" y1="6.5" x2="16" y2="6.5" stroke="white" />
    <line x1="8" y1="8.5" x2="16" y2="8.5" stroke="white" />
  </svg>
);

export const TriangleIcon = () => (
  <svg
    width="12"
    height="9"
    viewBox="0 0 12 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9L0.803847 2.51244e-08L11.1962 -8.834e-07L6 9Z"
      fill="#C4C4C4"
    />
  </svg>
);

export const TripleDots = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="12.5" cy="17.5" r="1.5" fill="white" />
    <circle cx="12.5" cy="12.5" r="1.5" fill="white" />
    <circle cx="12.5" cy="7.5" r="1.5" fill="white" />
  </svg>
);

export const SignOutIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 8V40H21V42H4V6H21V8H6ZM32.2929 31.2929L38.5858 25H15V23H38.5858L32.2929 16.7071L33.7071 15.2929L42.4142 24L33.7071 32.7071L32.2929 31.2929Z"
      fill="#3C396F"
    />
  </svg>
);
