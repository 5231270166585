import { SlText } from "../Text/Text";
import FormattedMessage from "../../../../../translations/FormattedMessage";
import moment from "moment";
import { SlOverlay } from "../Overlay/Overlay";
import React from "react";
import PropTypes from "prop-types";

export const OverlayUpdated = ({ recentDate }) => {
  if (!recentDate) {
    return null;
  }
  return (
    <SlOverlay>
      <SlText p7 destressed>
        <FormattedMessage
          id="updated"
          params={{ timeString: moment(recentDate).fromNow() }}
        />
      </SlText>
    </SlOverlay>
  );
};

OverlayUpdated.propTypes = {
  recentDate: PropTypes.string,
};
