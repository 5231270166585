import React from "react";
import PropTypes from "prop-types";

import { SlIcon } from "../Icon/Icon";

import CheckMarkIcon from "../../../../../assets/svg/checkmark-circle.svg";

import "./TextAndCheckMark.scss";

const TextAndCheckMark = ({ onClick, children, selected }) => {
  function handleClick() {
    onClick && onClick(children);
  }
  return (
    <div onMouseDown={handleClick} className="sl-text-and-check-mark-wrapper">
      <span className="sl-text-and-check-mark-text"> {children} </span>
      {selected ? (
        <SlIcon color={"action-primary"} svgFile={CheckMarkIcon} />
      ) : null}
    </div>
  );
};

TextAndCheckMark.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export { TextAndCheckMark };
