import React, { useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { compose } from "redux";

import { changeSelectedPartner } from "../../../actions/meActions";
import {
  mapNamesToId,
  makeSelections,
  makeSelectionAlertTicket,
} from "./helpers";
import { getNMHCData } from "../../../actions/monNHMCActions";
import {
  getMonitorHeaderData,
  getMonitorMapData,
  getMonitorSparkLineMapData,
} from "../../../actions/monitorActions";
import {
  getLocationsMonitoredData,
  getPredict24HourData,
  getPredict7DayData,
} from "../../../actions/predictActions";
import "./ispselector.scss";
import { withOktaAuth } from "@okta/okta-react";
import {
  alertQuery,
  getPartnerInfo,
  resetAlert,
} from "../../../actions/alert/alertActions";
import { getLocationsData } from "../../../actions/location/locationActions";
import { SlDropdown } from "../../components/communal/Molecules/Dropdown/Dropdown";
import { SlScrollbars } from "../../components/communal/Atoms/Scrollbar/Scrollbar";
import { SlItem } from "../../components/communal/Atoms/Item/Item";
import { SlIcon } from "../../components/communal/Atoms/Icon/Icon";
import { SlButton } from "../../components/communal/Atoms/Button/Button";
import { SlText } from "../../components/communal/Atoms/Text/Text";
import SlInputText from "../../components/communal/Atoms/InputText";
import CaretDownIcon from "../../../assets/svg/icon-caret.svg";
import CaretUpIcon from "../../../assets/svg/icon-caret-up.svg";
import SearchIcon from "../../../assets/svg/icon-search.svg";

export function Selector({
  getNMHCData,
  getMonitorHeaderData,
  getMonitorMapData,
  getMonitorSparkLineMapData,
  selections,
  selected,
  changeValue,
  getAlertData,
  getPredictData,
  getLocationsData,
  resetAlert,
  role,
}) {
  const [menuIsActive, setMenuIsActive] = useState(false);
  const [lcFilterString, setLcFilterString] = useState("");

  const mapNameToID = useMemo(() => mapNamesToId(selections), [selections]);
  const selectionNameData = useMemo(() => makeSelections(selections), [
    selections,
  ]);
  const inputEl = useRef(null);
  const location = useLocation();
  const isATicketPathname =
    location.pathname
      .toLowerCase()
      .match(`[a-z0-9/]*alert/[a-z0-9]+/[a-z0-9]+`) !== null;
  const [allowSelections, selection] = useMemo(
    () => makeSelectionAlertTicket(selected, isATicketPathname),
    [selected, isATicketPathname]
  );

  async function changePartner(name, primaryDeployment) {
    const newPartner = { id: mapNameToID[name], name: name, primaryDeployment };
    changeValue(newPartner);
    getNMHCData();
    getMonitorHeaderData();
    getMonitorMapData();
    getMonitorSparkLineMapData();
    resetAlert();
    getAlertData();
    getPredictData();
    getLocationsData();
  }

  const onDropdownChange = (newState) => {
    setMenuIsActive(newState);
  };

  const onInputChange = (inputText) => {
    setLcFilterString(inputText.toLowerCase());
    inputEl.current.focus();
  };

  const onSelection = (e, isp) => {
    if (isp.value !== selected.name) {
      changePartner(isp.value, isp.primaryDeployment);
    }
  };

  if (selections.length === 1 || !allowSelections) {
    return (
      <span className="isp-selector-cont">
        <SlButton
          noHover
          backgroundOpacity={0}
          backgroundOpacityHighlighted={0}
        >
          <SlText subtitle4>{selected?.name}</SlText>
        </SlButton>
      </span>
    );
  } else {
    const filteredSelections = selectionNameData.filter(
      (isp) =>
        !lcFilterString.length ||
        isp.value?.toLowerCase().includes(lcFilterString)
    );

    const triggerButton = () => {
      if (menuIsActive) {
        return (
          <SlButton
            backgroundOpacity={0.1}
            backgroundOpacityHighlighted={0.1}
            size={"full"}
            end={<SlIcon width={15} height={15} svgFile={CaretUpIcon} />}
          >
            <SlText subtitle4>{selected.name}</SlText>
          </SlButton>
        );
      } else {
        return (
          <SlButton
            backgroundOpacity={0}
            backgroundOpacityHighlighted={0.1}
            size={"full"}
            end={<SlIcon width={15} height={15} svgFile={CaretDownIcon} />}
          >
            <SlText subtitle4>{selected?.name}</SlText>
          </SlButton>
        );
      }
    };

    return (
      <div className="isp-selector-cont">
        <SlDropdown
          size={"wide"}
          align={"right"}
          trigger={triggerButton()}
          autoFocusEl={inputEl}
          onNewActiveState={onDropdownChange}
        >
          <SlItem
            leftPadding={"8px"}
            start={<SlIcon width={15} height={15} svgFile={SearchIcon} />}
            backgroundColor="rgba(75, 75, 75, 0.8)"
            hasFocus={true}
          >
            <SlInputText
              clearable
              ref={inputEl}
              placeholderText={"Filter by name"}
              size={"small"}
              onInputChange={onInputChange}
            />
          </SlItem>

          <SlScrollbars autoHeightMax={322}>
            {filteredSelections.length ? (
              filteredSelections.map((isp, i) => (
                <SlItem key={i} onClick={(e) => onSelection(e, isp)}>
                  {isp.value}
                </SlItem>
              ))
            ) : (
              <SlItem>No matching result</SlItem>
            )}
          </SlScrollbars>
        </SlDropdown>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    selected: state.me.selectedPartner,
    selections: state.me.partners,
    role: state.me.role,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeValue: (value) => {
      dispatch(changeSelectedPartner(value));
      dispatch(resetAlert());
    },
    getNMHCData: () => {
      dispatch(getNMHCData());
    },
    getMonitorHeaderData: () => {
      dispatch(getMonitorHeaderData());
    },
    getMonitorMapData: () => {
      dispatch(getMonitorMapData());
    },
    getMonitorSparkLineMapData: () => {
      dispatch(getMonitorSparkLineMapData());
    },
    getAlertData: () => {
      dispatch(getPartnerInfo());
      dispatch(alertQuery({ type: "CSP Outage", period: "hourly" }));
      dispatch(alertQuery({ type: "Evil Twin", period: "daily" }));
      dispatch(
        alertQuery({ type: "Total Locations Offline", period: "hourly" })
      );
      dispatch(
        alertQuery({ type: "Locations Offline Outage", period: "hourly" })
      );
    },
    getPredictData: () => {
      dispatch(getLocationsMonitoredData());
      dispatch(getPredict24HourData());
      dispatch(getPredict7DayData());
    },
    getLocationsData: (data) => {
      if (data?.locationId) {
        dispatch(getLocationsData());
      }
    },
    resetAlert: () => {
      dispatch(resetAlert());
    },
  };
};

Selector.propTypes = {
  selections: PropTypes.array,
  selected: PropTypes.object,
  changeValue: PropTypes.func,
  role: PropTypes.string,
};

export default compose(
  withOktaAuth,
  connect(mapStateToProps, mapDispatchToProps)
)(Selector);
