import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PuffLoader from "react-spinners/PuffLoader";
import SelectTextNumber from "../../Molecules/SelectTextNumber";

import {
  getMonitorMapNumbers,
  resetMonitorMapNumbers,
} from "../../../../../actions/monitorActions";
import { turnOnOffLayer } from "../../../../../actions/mapActions";
import { mapSelectListSelector } from "../../../../../selectors";

import "./selectList.scss";
import { useTranslation } from "react-i18next";

// you can use this component independent of redux
//import the component and pass your own getData function
export const SelectList = ({
  getData,
  data,
  changeSelection,
  ispID,
  loading,
  resetData,
  formatNumber,
}) => {
  const { t } = useTranslation();
  const handleSelectLayer = (name) => {
    changeSelection(name);
  };

  useEffect(() => {
    getData();
    return () => {
      resetData();
    };
  }, [getData, resetData, ispID]);

  if (data === null || loading) {
    return (
      <div className="sl-select-list-wrapper">
        <PuffLoader color={"#ffffff"} />
      </div>
    );
  }
  return (
    <div className="sl-select-list-wrapper">
      {data.map((d) => {
        return (
          <SelectTextNumber
            name={d.labelId ? t(d.labelId) : d.name}
            key={d.index}
            number={d.value}
            selected={d.on}
            selectLayer={() => handleSelectLayer(d.labelId)}
            formatNumber={formatNumber}
          />
        );
      })}
    </div>
  );
};

SelectList.propTypes = {
  getData: PropTypes.func.isRequired,
  data: PropTypes.array,
  changeSelection: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: mapSelectListSelector(state),
  ispID: state.me.selectedPartner.id,
  loading: state.monitorMap.layorSelectorDataLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => {
    dispatch(getMonitorMapNumbers());
  },
  resetData: () => {
    dispatch(resetMonitorMapNumbers());
  },
  changeSelection: (name) => {
    dispatch(turnOnOffLayer(name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectList);
