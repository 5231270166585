import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import "./delete.scss";

const Delete = ({ onDelete, children, isDisabled, text }) => {
  const onClick = () => {
    if (isDisabled) {
      return;
    }
    onDelete(text);
  };
  const deleteClasses = cx("sl-delete", {
    "sl-delete--disabled": isDisabled,
  });

  return (
    <div onClick={() => onClick()} className="sl-delete-wrapper">
      <div className={deleteClasses}>
        <div className="sl-delete-item-replacement-label">X</div>
        <div className="sl-delete-item-label"> {children}</div>
      </div>
    </div>
  );
};

Delete.propTypes = {
  onDelete: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
};

export default Delete;
