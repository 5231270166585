import { CompositeLayer } from "@deck.gl/core";
import { ScatterplotLayer } from "@deck.gl/layers";
import Supercluster from "supercluster";
function getIconSize(size) {
  return Math.min(100, size) / 100 + 1;
}

class ClusterLayer extends CompositeLayer {
  shouldUpdateState({ changeFlags }) {
    return changeFlags.somethingChanged;
  }

  updateState({ props, oldProps, changeFlags }) {
    const rebuildIndex =
      changeFlags.dataChanged || props.sizeScale !== oldProps.sizeScale;

    if (rebuildIndex) {
      const index = new Supercluster({ maxZoom: 16, radius: props.sizeScale });
      index.load(
        props.data.map((d) => ({
          geometry: { coordinates: props.getPosition(d) },
          properties: d,
        }))
      );
      this.setState({ index });
    }
    const z = Math.floor(this.context.viewport.zoom);
    if (rebuildIndex || z !== this.state.z) {
      this.setState({
        data: this.state.index.getClusters([-180, -85, 180, 85], z),
        z,
      });
    }
  }

  getPickingInfo({ info, mode }) {
    const pickedObject = info.object && info.object.properties;
    if (pickedObject) {
      if (pickedObject.cluster && mode !== "hover") {
        info.objects = this.state.index
          .getLeaves(pickedObject.cluster_id, 25)
          .map((f) => f.properties);
      }
      info.object = pickedObject;
    }
    return info;
  }

  renderLayers() {
    const { data } = this.state;
    const { iconAtlas, iconMapping, sizeScale } = this.props;

    return new ScatterplotLayer(
      this.getSubLayerProps({
        id: "point",
        data,
        opacity: this.props.opacity,
        filled: true,
        radiusScale: 2,
        radiusUnits: "pixels",
        getPosition: (d) => d.geometry.coordinates,
        radiusMinPixels: 1,
        radiusMaxPixels: this.props.radiusMaxPixels,
        getRadius: (d) => this.props.getRadius(d.properties.point_count),
        getFillColor: (d) => this.props.getFillColor,
      })
    );
  }
}

export { ClusterLayer };
