import FileSaver from "file-saver";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { useEffect } from "react";
import { getTicket, resetAlert } from "../../../../actions/alert/alertActions";
import { useLocation, useParams } from "react-router";
import {
  filteredLocations,
  getSelectedTicket,
} from "../../../../selectors/alert/alert.selectors";
import { updatePartner } from "../../../../actions/meActions";
import { SlCenter } from "../../communal/Atoms/Center/Center";
import { SlText } from "../../communal/Atoms/Text/Text";
import { SlPanelLoader } from "../../communal/Atoms/PanelLoader/PanelLoader";
import "./CsvPage.scss";
import { setTicketErr } from "../../../../actions/alert/alertTicketActions";
import FormattedMessage from "../../../../translations/FormattedMessage";

function handleExport(csv, ticketId) {
  const csvData = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  FileSaver.saveAs(csvData, `locations-${ticketId}.csv`);
}

const convertToCSV = (data) => {
  let columnDelimiter = ",";
  let lineDelimiter = "\n";

  let result, ctr, keys, keysForHeading;

  if (data === null || !data.length) {
    return null;
  }

  keys = Object.keys(data[0]);
  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach((item) => {
    ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) {
        result += columnDelimiter;
      }

      result +=
        typeof item[key] === "string" && item[key].includes(columnDelimiter)
          ? `"${item[key]}"`
          : item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

const useQuery = () => new URLSearchParams(useLocation().search);

export const CsvPage = () => {
  const selectedTicket = useSelector((state) => getSelectedTicket(state));

  let query = useQuery();

  const locations = useSelector((state) => filteredLocations(state));

  const selectedPartners = useSelector((state) => state.me.partners);

  const [downloaded, setDownloaded] = useState(false);

  const { ticketId, partnerId } = useParams();
  const dispatch = useDispatch();

  const loadTicket = (ticketId) => {
    const type = query.get("type");

    const notFoundPartner = selectedPartners.find((p) => p.id === partnerId);
    if (notFoundPartner === undefined) {
      dispatch(setTicketErr());
    } else {
      let period = "hourly";
      dispatch(updatePartner(partnerId));
      dispatch(resetAlert("selectedTicket"));

      if (type === "evilTwinAlerts") {
        period = "daily";
      }

      if (!type) {
        period = "all";
      }

      dispatch(
        getTicket({
          ticketId,
          period,
          type,
        })
      );
    }
  };

  const mapLocationsForCSV = (locations) => {
    return locations.map((location) => {
      const mappedLocation = {
        ticketId: location.ticketId,
        locationId: location.locationId,
        customerId: location.customerId,
        url: location.url,
        partnerId: location.partnerId,
        status: location.status,
        csp: location.isp,
        city: location.city,
        zip: location.zip,
        country: location.country,
        latitude: location.latitude,
        longitude: location.longitude,
        timeStamp: location.timeStamp,
      };
      return mappedLocation;
    });
  };

  useEffect(() => {
    if (selectedTicket.locations.length > 0 || selectedTicket.empty === true) {
      setDownloaded(true);
      handleExport(convertToCSV(mapLocationsForCSV(locations)), ticketId);
    }
  }, [locations, selectedTicket, ticketId]);

  useEffect(() => {
    dispatch(resetAlert());
    dispatch(updatePartner(partnerId));
    loadTicket(ticketId);
    // eslint-disable-next-line
  }, [dispatch, partnerId, ticketId]);

  return (
    <div className="sl-csv-page">
      <SlCenter>
        <div>
          {downloaded ? (
            <SlText p7>
              <FormattedMessage id="csv.fileDownloadedMessage" />
            </SlText>
          ) : (
            <>
              <h2>
                <FormattedMessage id="csv.downloading" />
              </h2>
              <SlPanelLoader />
            </>
          )}
        </div>
      </SlCenter>
    </div>
  );
};
