import { NMHCQuery } from "./graphQL_queries/NHMC";

import { getdata } from "./AsynchActions";

export const FETCHING_NMHC_DATA = "FETCHING_NMHC_DATA ";
export const MONITOR_NMHC_DATA_ERR = "MONITOR_NMHC_DATA_ERR";
export const MONITOR_NMHC_DATA_RECIEVED = "MONITOR_NMHC_DATA_RECIEVED";
export const CHANGE_KPI_CHART_TIME = "CHANGE_KPI_CHART_TIME";
export const SHOW_HIDE_MORE_MENU = "SHOW_HIDE_MORE_MENU";
export const SHOW_OPTIONS = "SHOW_OPTIONS";
export const CHANGE_KPI_OPTION_SELECTION = "CHANGE_KPI_OPTION_SELECTION";
export const SHOW_CHANGE_MENU = "SHOW_CHANGE_MENU";
export const CLOSE_CHANGE_MENU = "CLOSE_CHANGE_MENU";
export const CHANGE_Y_AXIS = "CHANGE_Y_AXIS";

export const fetchingNMHCData = () => ({
  type: FETCHING_NMHC_DATA,
});

export const monitorNMHCDataErr = () => ({
  type: MONITOR_NMHC_DATA_ERR,
});

export const monitorNMHCDataRecieved = (data) => ({
  type: MONITOR_NMHC_DATA_RECIEVED,
  payload: {
    data,
  },
});

export const showHideMoreMenu = (name) => ({
  type: SHOW_HIDE_MORE_MENU,
  payload: {
    name,
  },
});

export const changeKPIChartTime = (name, time) => ({
  type: CHANGE_KPI_CHART_TIME,
  payload: {
    time,
    name,
  },
});

export const changeKPIOptionSelection = (name, option, section) => ({
  type: CHANGE_KPI_OPTION_SELECTION,
  payload: {
    name,
    option,
    section,
  },
});

export const showOptions = (name) => ({
  type: SHOW_OPTIONS,
  payload: {
    name,
  },
});

export const showChangeMenu = (name, option) => ({
  type: SHOW_CHANGE_MENU,
  payload: {
    name,
    option,
  },
});

export const closeChangeMenu = (name) => ({
  type: CLOSE_CHANGE_MENU,
  payload: {
    name,
  },
});

export const changeYAxis = (bool, name) => ({
  type: CHANGE_Y_AXIS,
  payload: {
    bool,
    name,
  },
});

export const getNMHCData = getdata(
  fetchingNMHCData,
  monitorNMHCDataRecieved,
  monitorNMHCDataErr,
  NMHCQuery
);
