import React from "react";
import PropTypes from "prop-types";
import AlertTitleAndChannelPannel from "../../Molecules/AlertTitleAndChannelPannel";

import "./alertAdminList.scss";
import { SlSection } from "../../../communal/Atoms/Section/Section";
import { SlText } from "../../../communal/Atoms/Text/Text";
import { adminAlertsThresholdShow } from "../../../../../config";

const getThresholdForAlert = (alert) =>
  adminAlertsThresholdShow.includes(alert.type)
    ? alert.alertThreshold
    : undefined;

const AlertAdminList = ({
  title,
  alerts,
  alertOnOff,
  channelThrottleChange,
  handleThresholdChange,
  isEditingDisabled,
}) => {
  return (
    <SlSection>
      <div className="sl-admin-alerts-list-wrapper">
        <SlText subtitle1m>{title}</SlText>
        <div className="sl-admin-alert-list-list">
          {alerts.map((alert, i) => (
            <AlertTitleAndChannelPannel
              key={i}
              alertOnOff={alertOnOff}
              channelThrottleChange={channelThrottleChange}
              handleThresholdChange={handleThresholdChange}
              isEditingDisabled={isEditingDisabled}
              {...alert}
              alertThreshold={getThresholdForAlert(alert)}
            />
          ))}
        </div>
      </div>
    </SlSection>
  );
};

AlertAdminList.propTypes = {
  title: PropTypes.string.isRequired,
  alerts: PropTypes.array.isRequired,
  alertOnOff: PropTypes.func.isRequired,
  channelThrottleChange: PropTypes.func.isRequired,
  handleThresholdChange: PropTypes.func,
  isEditingDisabled: PropTypes.bool,
};

export default AlertAdminList;
