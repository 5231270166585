import React, { useEffect } from "react";
import Table from "../../../components/communal/Molecules/Table/HTMLTable";
import { SlCenter } from "../../../components/communal/Atoms/Center/Center";
import { SlButton } from "../../../components/communal/Atoms/Button/Button";
import { SlText } from "../../../components/communal/Atoms/Text/Text";
import "./alertLocationAlarmScores.scss";
import FilterRow from "../../../components/communal/Molecules/Table/FilterRow";
import {
  deletFilterThunk,
  setPage,
} from "../../../../actions/alert/alertLASActions";
import AlertLasSearchAndActionCon from "./AlertLasSearchAndActionCon";
import { filteredLocations } from "../../../../selectors/alert/alert.selectors";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FormattedMessage from "../../../../translations/FormattedMessage";

export const AlertLocationAlarmScores = ({ headers, keys }) => {
  const dispatch = useDispatch();
  const selectedLocation = useSelector(
    (state) => state.alert.selectedTicket.selectedLocation
  );
  const filters = useSelector((state) => state.alertLAS.selectedFilters.byName);
  const page = useSelector((state) => state.alertLAS.page);
  const locationData = useSelector((state) => filteredLocations(state));
  const { t } = useTranslation();
  const translatedHeaders = headers.map((h) => t(h));

  useEffect(() => {
    dispatch(setPage(1));
  }, [dispatch]);

  return (
    <div className="sl-alert-location-alarm-score">
      <AlertLasSearchAndActionCon />
      <FilterRow
        filters={filters}
        deleteFilterAction={(filter, keyList) =>
          dispatch(deletFilterThunk(filter, keyList))
        }
      />
      <Table
        data={locationData}
        keys={keys}
        selectedRow={["locationId", selectedLocation]}
        headers={translatedHeaders}
      />
      {locationData.length && !locationData[locationData?.length - 1]?.last ? (
        <SlCenter className="sl-table-footer">
          <SlButton
            backgroundOpacity={0.1}
            backgroundOpacityHighlighted={0.2}
            onClick={() => dispatch(setPage(page + 1))}
          >
            <SlText p7>
              <FormattedMessage id="ticket.loadMore" />
            </SlText>
          </SlButton>
        </SlCenter>
      ) : null}
    </div>
  );
};
