export function changeQLDataToObject(optionData) {
  if (optionData === undefined) {
    return null;
  }

  if (optionData.length !== undefined) {
    let data = {};
    optionData.forEach((filter) => {
      data[filter.name] = {
        selected: false,
        options: changeQLDataToObject(filter["options"]),
        selections: changeSelectionArrayToObject(filter["selections"]),
      };
    });
    return { byName: data };
  }
  return optionData;
}

function changeSelectionArrayToObject(selections) {
  if (selections === undefined) return null;
  let selectionObj = {};

  selections.forEach((sel) => {
    selectionObj[sel.name] = { ...sel, ...{ selected: false } };
  });

  return { byName: selectionObj };
}
