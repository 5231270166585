import React, { Fragment } from "react";
import { SecureRoute } from "@okta/okta-react";

import Header from "../Header";
import { SlFooter } from "../components/communal/Atoms/Footer/Footer";
import "./secure.scss";

function holderRender(Component, history) {
  return (
    <Fragment>
      <Header />
      <div className="">
        <Component />
      </div>
      <SlFooter />
    </Fragment>
  );
}

function SecureRouteComponent({ component: Component, ...rest }) {
  return (
    <SecureRoute
      {...rest}
      render={({ history }) => holderRender(Component, history)}
    />
  );
}

export default SecureRouteComponent;
