import axios from "axios";

import { authClient } from "./okta";

if (axios.interceptors) {
  axios.interceptors.request.use(
    async (config) => {
      const token = await authClient.tokenManager.get("idToken");
      if (!token || authClient.tokenManager.hasExpired(token)) {
        authClient.signOut({
          idToken: token,
        });
      } else {
        const authToken = token.idToken ? token.idToken : token.value;
        config.headers.Authorization = `Bearer ${authToken}`;
        return config;
      }
    },
    (error) => Promise.reject(error)
  );
}
