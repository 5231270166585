import React, { useCallback, useEffect } from "react";
import { SlTile } from "../../Molecules/Tile/Tile";
import { Col, Row } from "react-flexbox-grid";
import { PawGraph } from "../../Atoms/Charts/PawGraph/PawGraph";
import "./CallInVolumePrediction.scss";
import { TileValueIndicator } from "../../../PredictPage/Molecules/TileValueIndicator/TileValueIndicator";
import { LineChartIcon } from "../../Atoms/Icons";
import { useSelector, useDispatch } from "react-redux";
import {
  PeriodsLabels,
  selectActivePeriod,
  selectHistoricalTrend,
  selectPeriodsWithTotalItems,
} from "../../../../../reducers/predictReducer";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { callInVolumePeriodDateFormat } from "../../../../../config";
import { SlText } from "../../Atoms/Text/Text";
import FormattedMessage from "../../../../../translations/FormattedMessage";
import { SlStackedAreaChart } from "../../Atoms/Charts/StackedAreaChart/StackedAreaChart";
import { getColorByLabelId } from "../../../../../helpers/predictHelpers";
import {
  getHistoricalTrendData,
  predictSetCallInVolumeActivePeriod,
  predictSetDataScopeInDays,
} from "../../../../../actions/predictActions";

const addSpacingsToTile = (current) => ({
  ...current,
  root: `${current.root} CallInVolumePrediction__tile`,
});

const getDuration = (period) => {
  const duration = moment.duration(
    moment(period.periodEnd).diff(moment(period.periodStart))
  );
  const diffInDays = parseInt(duration.asDays());
  const diffInHours = parseInt(duration.asHours());

  return {
    diffInDays,
    diffInHours,
  };
};

const CallInVolumePrediction = () => {
  const dispatch = useDispatch();
  const callInVolumePredictionPeriodsWithValue = useSelector(
    selectPeriodsWithTotalItems
  );
  const selectedPeriod = useSelector(selectActivePeriod);
  const setSelectedPeriod = (periodLabel) => {
    dispatch(predictSetCallInVolumeActivePeriod(periodLabel));
  };
  const { t } = useTranslation();

  const getMessagesForValueIndicator = useCallback(
    (period) => {
      const periodEnd = moment(period.periodEnd);
      const { diffInDays, diffInHours } = getDuration(period);
      let messageId =
        diffInDays > 1
          ? "predict.callInVolumePrediction.nextDays"
          : "predict.callInVolumePrediction.nextHours";
      if (periodEnd <= moment()) {
        messageId = "predict.callInVolumePrediction.lastDays";
      }
      const message = t(messageId)?.split(" ");
      return {
        top: message[0],
        value: diffInDays > 1 ? diffInDays : diffInHours,
        bottom: message[1],
      };
    },
    [t]
  );

  useEffect(() => {
    if (!selectedPeriod) {
      setSelectedPeriod(PeriodsLabels.Next24Hours);
    }
  }, [selectedPeriod]);

  return (
    <Row>
      <Col md={4}>
        {callInVolumePredictionPeriodsWithValue &&
          callInVolumePredictionPeriodsWithValue.map((period) => (
            <SlTile
              key={`${period.periodStart}${period.periodEnd}`}
              onClick={() => setSelectedPeriod(period.label)}
              icon={
                <TileValueIndicator
                  value={getMessagesForValueIndicator(period).value}
                  topCopy={getMessagesForValueIndicator(period).top}
                  bottomCopy={getMessagesForValueIndicator(period).bottom}
                />
              }
              isSelected={period.label === selectedPeriod}
              title={period.total}
              subtitle={t(
                "predict.callInVolumePrediction.locationsMostLikelyToCall",
                {
                  periodStart: period.periodStart.format(
                    callInVolumePeriodDateFormat
                  ),
                  periodEnd: period.periodEnd.format(
                    callInVolumePeriodDateFormat
                  ),
                }
              )}
              classes={addSpacingsToTile}
            />
          ))}
        <SlTile
          icon={<LineChartIcon />}
          title={t("predict.callInVolumePrediction.historicalTrend")}
          onClick={() => setSelectedPeriod("historicalTrend")}
          isSelected={selectedPeriod === "historicalTrend"}
          classes={addSpacingsToTile}
        />
      </Col>
      <Col md={8}>
        <div className="CallInVolumePrediction__content">
          <CallInVolumePredictionContent selectedPeriod={selectedPeriod} />
        </div>
      </Col>
    </Row>
  );
};

const CallInVolumePredictionContent = ({ selectedPeriod }) => {
  const { t } = useTranslation();
  const historicalTrend = useSelector(selectHistoricalTrend);
  const periods = useSelector(selectPeriodsWithTotalItems);
  const dispatch = useDispatch();

  const setSelectedScope = (valueInDays) => {
    dispatch(predictSetDataScopeInDays(valueInDays));
    dispatch(getHistoricalTrendData());
  };

  const getPawGraphItems = useCallback(() => {
    if (!selectedPeriod || selectedPeriod === "historicalTrend") {
      return;
    }
    const fullSelectedPeriod = periods.find((p) => p.label === selectedPeriod);
    return fullSelectedPeriod?.data.map((item) => {
      const color = getColorByLabelId(item.labelId);
      return {
        label: item.labelId ? t(item.labelId) : "",
        color,
        value: item.value,
      };
    });
  }, [t, selectedPeriod, periods]);

  const isLast90DaysPeriod = useCallback(() => {
    if (!selectedPeriod || selectedPeriod === "historicalTrend") {
      return false;
    }
    const { diffInDays } = getDuration(selectedPeriod);
    return diffInDays === 90;
  }, [selectedPeriod]);

  const pawGraphItems = getPawGraphItems();

  if (selectedPeriod === "historicalTrend") {
    return (
      <>
        {historicalTrend.data && (
          <SlStackedAreaChart
            data={historicalTrend.data}
            onSelectScope={(valueInDays) => setSelectedScope(valueInDays)}
            dataScopeInDays={historicalTrend.dataScopeInDays}
          />
        )}
      </>
    );
  } else if (isLast90DaysPeriod()) {
    return (
      <SlText titleSignal>
        <FormattedMessage id="predict.callInVolumePrediction.last90DaysPlaceholder" />
      </SlText>
    );
  }

  return <>{pawGraphItems?.length && <PawGraph items={pawGraphItems} />}</>;
};

export { CallInVolumePrediction };
