import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import StackedBarChart from "./StackedBarChart";

import { useResizeChart } from "../../../../../../hooks/useResizeChart";

import "./stackedBarChart.scss";

const StackedBarChartComp = ({ data, time, locationNumbers, yAtt }) => {
  let el = useRef(null);
  let initialRender = useRef(true);
  let chart = useRef(null);

  useEffect(() => {
    if (chart.current != null && initialRender.current === false) {
      chart.current.reRenderAxis({
        yAtt: yAtt,
      });
    }
  }, [yAtt]);

  useEffect(() => {
    if (chart.current != null && initialRender.current === false) {
      chart.current.rerender({
        data: data,
        timeInterval: time,
      });
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (el.current != null && initialRender.current === true) {
      chart.current = new StackedBarChart({
        el: el.current,
        data: data,
        yAtt: yAtt,
        totalNumber: locationNumbers,
        timeInterval: time,
      });

      chart.current.render();

      initialRender.current = false;
    }
    // eslint-disable-next-line
  }, [el.current]);

  useResizeChart(chart, el);
  return (
    <div className="Sl-stacked-bar-chart-cont">
      <div ref={el} className="Sl-stacked-bar-chart-chart"></div>
    </div>
  );
};

StackedBarChartComp.propTypes = {
  data: PropTypes.array.isRequired,
};

export default StackedBarChartComp;
