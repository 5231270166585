import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import { SlButton } from "../Button/Button";
import { SlIcon } from "../Icon/Icon";
import { SlText } from "../Text/Text";
import arrowDownImage from "../../../../../assets/svg/icon-download.svg";
import { transfomrQueryToDownloadObject } from "./helpers";

import "./downLoadButton.scss";
import FormattedMessage from "../../../../../translations/FormattedMessage";

export const DownLoadButton = ({ id, rawData, disabled = false }) => {
  const [, setDownLoadData] = useState(false);
  const [downloadingData, setDownloadingData] = useState(null);

  const handleDataDownLoad = (rawData) => {
    const downLoadDataFormatted = transfomrQueryToDownloadObject(rawData);
    setDownloadingData(downLoadDataFormatted);
    setDownLoadData(true);
  };

  useEffect(() => {
    if (rawData != null) {
      handleDataDownLoad(rawData);
    }
  }, [rawData]);

  if (id === null || downloadingData === null || disabled) {
    return (
      <div className="Sl-monitor-download-button">
        <SlButton
          backgroundOpacity={0.1}
          backgroundOpacityHighlighted={0.1}
          disabled={true}
          start={<SlIcon svgFile={arrowDownImage} />}
        >
          <SlText p7>
            <FormattedMessage id="download" />
          </SlText>
        </SlButton>
      </div>
    );
  }

  return (
    <div className="Sl-monitor-download-button">
      <CSVLink data={downloadingData} filename={"signal-data-monitor.csv"}>
        <SlButton
          backgroundOpacity={0.1}
          backgroundOpacityHighlighted={0.2}
          disabled={disabled}
          start={<SlIcon svgFile={arrowDownImage} />}
        >
          <SlText p7>
            <FormattedMessage id="download" />
          </SlText>
        </SlButton>
      </CSVLink>
    </div>
  );
};

const mapStateToProps = (state) => ({
  id:
    state.me.selectedPartner === null || state.me.selectedPartner === undefined
      ? null
      : state.me.selectedPartner.id,
  rawData: state.monitor.mapData === null ? null : state.monitor.mapData,
});

export default connect(mapStateToProps, undefined)(DownLoadButton);
