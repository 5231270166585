import React from "react";
import { SIGNAL_VERSION } from "../../../utils/constants";
import FormattedMessage from "../../../translations/FormattedMessage";

const SignalVersion = () => (
  <div className="signal-api-version-cont">
    <span className="signal-v-title">
      <FormattedMessage id="userMenu.signalVersion" />
      &nbsp;
    </span>
    <span className="signalv-v">{SIGNAL_VERSION}</span>
  </div>
);

export default SignalVersion;
