import React from "react";
import PropTypes from "prop-types";
import TitleAndSlider from "../TitleAndSlider";

import "./listTitleSlider.scss";

const ListTitleSlider = ({
  alertID,
  list,
  channelThrottleChange,
  isEditingDisabled,
}) => {
  return (
    <div className="Sl-channel-pannel-wrapper">
      <div className="Sl-channel-config-elements-wrapper">
        {list.map((data, index) => (
          <div className="Sl-channel-config-element" key={data.type}>
            <TitleAndSlider
              alertIndex={alertID}
              channelIndex={index}
              isDisabled={isEditingDisabled}
              time={data.throttlingConfig.redZoneInSeconds}
              handleOnChangeTime={channelThrottleChange}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

ListTitleSlider.propTypes = {
  alertID: PropTypes.number.isRequired,
  list: PropTypes.array.isRequired,
  channelThrottleChange: PropTypes.func,
  isEditingDisabled: PropTypes.bool,
};

export default ListTitleSlider;
