export const mutateNestedChild = (draftState, value, keys) => {
  if (keys.length === 0) return draftState;
  let child = draftState;
  keys.forEach((key, i) => {
    if (i === keys.length - 1) {
      if (child.selections.byName[key]) {
        child.selections.byName[key].selected = value;
      }
    } else if (i === 0) {
      child = child[key];
    } else {
      child = draftState.options.byName[key];
    }
  });
};
