import React from "react";
import classNames from "classnames";

import "./Header.scss";

const SlHeader = ({
  start,
  end,
  horizontalLine = false,
  bottomMargin = false,
  children,
}) => (
  <div
    className={classNames("sl-header", {
      "sl-header__horizontal-line": horizontalLine,
      "sl-header__bottom-margin": bottomMargin,
    })}
  >
    {(start || children) && (
      <div className="sl-header__start">
        <div>{start && start}</div>
        <div>{children && children}</div>
      </div>
    )}
    {end && <div className="sl-header__end">{end}</div>}
  </div>
);

export { SlHeader };
