import { createSelector } from "reselect";
import producer from "immer";

import { normTextArray } from "./helpers/normalizeAdmin";
import { adminPanelData } from "./adminReducer/helpers";

import {
  ADD_EMAIL_TO_GROUP,
  CHANGE_ALERT_ENABLE,
  CHANGE_ALERT_THRESHOLD,
  CHANGE_CHANNEL_ENABLE,
  CHANGE_ALERT_ID,
  CHANGE_CHANNEL_THROTTLING,
  DELETE_EMAIL_TO_GROUP,
  FETCH_ALERT,
  FETCH_ALERT_SUCCESS,
  FETCH_ALERT_ERROR,
} from "../actions/admin-action";

const INIT_STATE = {
  alerts: null,
  error: false,
  loading: true,
};

const alertReducer = producer((draftState, action = { type: null }) => {
  switch (action.type) {
    case CHANGE_ALERT_ENABLE:
      draftState.alerts.alerts.forEach((alert, i) => {
        if (i === action.payload.index) {
          const status = !alert.enabled;
          alert.enabled = status;
          alert.channels = alert.channels.map((channel) => {
            channel.enabled = status;
            return channel;
          });
        }
      });
      return;
    case CHANGE_CHANNEL_ENABLE:
      draftState.alerts.alerts.forEach((alert, i) => {
        if (i === action.payload.alertIndex) {
          alert.channels.forEach((channel, j) => {
            if (j === action.payload.channelIndex)
              channel.enabled = !channel.enabled;
          });
        }
      });
      return;
    case CHANGE_ALERT_THRESHOLD:
      draftState.alerts.alerts.forEach((alert, i) => {
        if (i === action.payload.alertIndex)
          alert.alertThreshold = action.payload.value;
      });
      return;
    case CHANGE_ALERT_ID:
      draftState.alerts.id = action.payload.id;
      return;
    case CHANGE_CHANNEL_THROTTLING:
      draftState.alerts.alerts.forEach((alert, i) => {
        if (i === action.payload.alertIndex) {
          alert.channels.forEach((channel, j) => {
            if (j === action.payload.channelIndex)
              channel.throttlingConfig.redZoneInSeconds = action.payload.val;
          });
        }
      });
      return;

    case FETCH_ALERT:
      draftState.loading = true;
      draftState.error = false;
      return;

    case FETCH_ALERT_ERROR:
      draftState.loading = false;
      draftState.error = true;
      return;

    case FETCH_ALERT_SUCCESS:
      draftState.loading = false;
      draftState.error = false;
      const alerts = action.payload.data;
      alerts.operationalGroup = normTextArray(alerts.operationalGroup);
      alerts.consumerOutreachGroup = normTextArray(
        alerts.consumerOutreachGroup
      );
      draftState.alerts = alerts;
      return;
    case ADD_EMAIL_TO_GROUP:
      const emails = Object.keys(
        draftState.alerts[action.payload.group].byText
      );
      const emailExists = emails.find(
        (email) => email.toLowerCase() === action.payload.email.toLowerCase()
      );
      if (emailExists) {
        return;
      }
      draftState.alerts[action.payload.group].byText[action.payload.email] = {
        text: action.payload.email,
      };
      return;

    case DELETE_EMAIL_TO_GROUP:
      delete draftState.alerts[action.payload.group].byText[
        action.payload.text
      ];
      return;
    default:
      return;
  }
}, INIT_STATE);

//selectors
const selectAdminAlerts = (state) => state.alerts;
const selectAdminAlertsAlerts = (state) =>
  state.alerts === null ? null : state.alerts.alerts;

export const AlertGroups = {
  CSPAlerts: "admin.alerts.cspAlertsGroup",
  ConsumerAlerts: "admin.alerts.consumerAlertsGroup",
};

export const selectAlertTypesGrouped = createSelector(
  [selectAdminAlertsAlerts],
  (alerts) => {
    if (selectAdminAlertsAlerts === null) return null;

    const groupTitleToId = {
      "CSP Alerts": AlertGroups.CSPAlerts,
      "Consumer Notifications": AlertGroups.ConsumerAlerts,
    };

    const alertsGrouped = {
      [AlertGroups.CSPAlerts]: [],
      [AlertGroups.ConsumerAlerts]: [],
    };

    alerts.forEach((alert) => {
      const alertData = adminPanelData[alert.type];
      const newAlert = { ...alert, ...alertData };
      const groupId = groupTitleToId[newAlert.group];
      alertsGrouped[groupId].push(newAlert);
      alertsGrouped[groupId].sort((a, b) => b.type.localeCompare(a.type));
    });
    return alertsGrouped;
  }
);

export const selectAdminEmailGroup = createSelector(
  [selectAdminAlerts],
  (adminAlerts) => {
    const groupData = {};
    groupData["operationalGroup"] = adminAlerts["operationalGroup"];
    groupData["consumerOutreachGroup"] = adminAlerts["consumerOutreachGroup"];
    return groupData;
  }
);

export default alertReducer;
