import React from "react";
import produce from "immer";
import { createSelector } from "reselect";

import { TURN_ON_OFF_LAYER, TURN_OFF_LAYER } from "../actions/mapActions";
import {
  FETCHING_MONITOR_MAP_NUMBERS,
  MONITOR_MAP_NUMBERS_RECEIVED,
  MONITOR_MAP_NUMBERS_ERR,
  RESET_MONITOR_MAP_NUMBERS,
} from "../actions/monitorActions";

import { styleSelector } from "../components/components/MonitorDash/Map/Layers";

const Init_State = {
  layers: [
    {
      name: "Unstable Network",
      on: false,
      labelId: "monitor.map.layers.unstableNetwork",
    },
    { name: "CSP Outage", on: false, labelId: "monitor.map.layers.cspOutage" },
    {
      name: "Offline Network",
      on: true,
      labelId: "monitor.map.layers.offlineNetwork",
    },
    { name: "Evil Twin", on: false, labelId: "monitor.map.layers.evilTwin" },
  ],
  layerSelectorData: null,
  layerSelectorError: false,
  layerSelectorDataLoading: false,
};

export const monitorMap = produce((draft, action) => {
  switch (action.type) {
    case MONITOR_MAP_NUMBERS_RECEIVED:
      draft.layerSelectorData = action.payload.data.partner.alarmLocationCounts;
      draft.layerSelectorDataLoading = false;
      return;
    case FETCHING_MONITOR_MAP_NUMBERS:
      draft.layerSelectorDataLoading = true;
      return;
    case MONITOR_MAP_NUMBERS_ERR:
      draft.layerSelectorError = true;
      return;
    case TURN_ON_OFF_LAYER:
      draft.layers.forEach((layer) => {
        if (layer.labelId === action.layer) {
          layer.on = !layer.on;
        }
      });
      return;
    case TURN_OFF_LAYER:
      draft.layers.forEach((layer) => {
        if (layer.labelId === action.layer) {
          layer.on = false;
        }
      });
      return;
    case RESET_MONITOR_MAP_NUMBERS:
      draft.layerSelectorData = null;
      return;
    default:
      return;
  }
}, Init_State);

class Layer {
  constructor(name, on, value, labelId) {
    this.name = name;
    this.on = on;
    this.value = value;
    this.labelId = labelId;
  }
}

const queriesMAP = {
  "High Congestion": "highCongestion",
  "Unstable Network": "unstable_networks",
  "Offline Network": "offline_networks",
  "Poor Coverage": "poorCoverage",
  "CSP Outage": "isp_outage",
  "Evil Twin": "evil_twin",
};

//selector

const LayerSelectorIcon = ({ style }) => {
  const iconStyle = {
    ...style,
    height: "16px",
    width: "16px",
    borderRadius: "16px",
    display: "inline-block",
  };
  return <div className="sl-map-layer-selector" style={iconStyle}></div>;
};

const selectLayerData = (state) => {
  if (state.layerSelectorData === null) return state.layerSelectorData;
  const data = {};
  state.layerSelectorData.forEach((d) => {
    data[d.id] = {
      text: d.id,
      value: d.alarmCount,
      icon: <LayerSelectorIcon style={styleSelector[d.id]} />,
    };
  });
  return data;
};
const selectLayers = (state) => state.layers;

export const mapSelectListSelector = createSelector(
  selectLayers,
  selectLayerData,
  (layers, data) => {
    if (data === null) return null;

    let layerData = [];
    layers.forEach((layer) => {
      const value =
        data[queriesMAP[layer.name]] === undefined
          ? 0
          : data[queriesMAP[layer.name]].value;
      layerData.push(new Layer(layer.name, layer.on, value, layer.labelId));
    });
    return layerData;
  }
);

export const layersOnSelector = (state) => {
  const layersOn = state.layers.filter((layer) => layer.on === true);

  return layersOn.map((layer) => ({
    labelId: layer.labelId,
    icon: <LayerSelectorIcon style={styleSelector[layer.name]} />,
  }));
};

export default monitorMap;
