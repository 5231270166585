import PredictiveData, {
  PredictiveDataInterface,
} from "../models/Predictive/PredictiveData";

export const GETTING_PREDICTIVE_DATA = "GETTING_PREDICTIVE_DATA";
export const PREDICTIVE_DATA_RECIEVED = "PREDICTIVE_DATA_RECIEVED";

export function gettingPredictiveData() {
  return {
    type: GETTING_PREDICTIVE_DATA,
  };
}

function predictiveDataRecieved(data, index) {
  return {
    type: PREDICTIVE_DATA_RECIEVED,
    payload: {
      data: data,
      index: index,
    },
  };
}

export function getPredictiveData(index) {
  return function (dispatch, getState) {
    const chartInstance = new PredictiveDataInterface({
      lT20: Math.random() * 30,
      twentyT50: Math.random() * 30,
      MT50: Math.random() * 30,
    });
    const cardData = new PredictiveData({ data: chartInstance });

    dispatch(predictiveDataRecieved(cardData, index));
  };
}
