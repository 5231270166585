export const CHANGE_SELECTED_CHART = "CHANGE_SELECTED_CHART";

export function changeSelectedChart(index) {
  return {
    type: CHANGE_SELECTED_CHART,
    payload: {
      index,
    },
  };
}
