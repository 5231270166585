import React from "react";
import PropTypes from "prop-types";
import { format as d3Format } from "d3-format";

import "./number.scss";

const NumberComp = ({ children, formatNumber }) => {
  const format = formatNumber ? formatNumber : d3Format("");
  return <span className="sl-number">{format(children)}</span>;
};

NumberComp.propTypes = {
  children: PropTypes.number.isRequired,
};

export default NumberComp;
