import { request } from "graphql-request";
import { SIGNAL_MOCK_URL } from "../../utils/constants";
import { getDataMockedThunk } from "../AsynchActions";
import { selectionQuery } from "../graphQL_queries/selectionFilters";
import { setAlertLocationData } from "./alertActions";
import { ALERT_LAS_LOCATION_QUERY } from "../graphQL_queries/alertLasLocationQuery";
import * as queryString from "query-string";

export const ADD_ALERT_FILTER = "ADD_ALERT_FILTER";
export const DELETE_FILTER_ALERT = "DELETE_FILTER_ALERT";
export const SHOW_FILTERS = "SHOW_FILTERS";
export const SHOW_HIDE_FILTERS = "SHOW_HIDE_FILTERS";
export const HIDE_FILTERS = "HIDE_FILTERS";
export const SET_FILTER_OPTIONS = "SET_FILTER_OPTIONS";
export const SET_FILTER_ERROR = "SET_FILTER_ERROR";
export const SET_ALERT_LOCATION_DATA = "SET_ALERT_LOCATION_DATA";
export const SET_SEARCH_TEXT_ALERT = "SET_SEARCH_TEXT_ALERT";
export const SET_ALERT_TABLE_PAGINATION = "SET_ALERT_TABLE_PAGINATION";
export const ADD_FILTER_FROM_BOOKMARKS = "ADD_FILTER_FROM_BOOKMARKS";
export const SET_PAGE = "SET_PAGE";

//actions
export const addFilter = (filter, type) => ({
  type: ADD_ALERT_FILTER,
  payload: {
    filter,
    type,
  },
});

export const addFilterFromBookmarks = (filter, keyList) => ({
  type: ADD_FILTER_FROM_BOOKMARKS,
  payload: {
    filter,
    keyList,
  },
});

export const setPage = (pageNumber) => ({
  type: SET_PAGE,
  payload: pageNumber,
});

export const updateFiltersInUrl = (selectedFilters) => {
  return () => {
    const updateQueryParams = (params) => {
      let url = new URL(document.location.href);
      let searchParams = new URLSearchParams(url.search.slice(1));

      Object.keys(params).forEach((key) =>
        searchParams.append(`filter-${params[key].typeOfFilter}`, key)
      );

      window.history.replaceState(
        "",
        "",
        `?${new URLSearchParams(searchParams).toString()}`
      );
    };
    updateQueryParams(selectedFilters);
  };
};

export const deleteFiltersInUrl = (selectedFilters, keyList) => {
  return () => {
    const parsed = queryString.parse(
      window.location.search.replaceAll("filter-", "")
    );

    const val = parsed[keyList[0]];

    if (Array.isArray(val)) {
      parsed[keyList[0]] = parsed[keyList[0]].filter((e) => e !== keyList[1]);
    }

    if (val === keyList[1]) {
      delete parsed[keyList[0]];
    }

    let stringedQuery = queryString.stringify(parsed);

    window.history.replaceState(
      "",
      "",
      stringedQuery ? `?${stringedQuery}` : window.location.href.split("?")[0]
    );
  };
};

export const deleteFilter = (filter, keyList) => ({
  type: DELETE_FILTER_ALERT,
  payload: {
    filter,
    keyList,
  },
});

export const showFilters = () => ({
  type: SHOW_FILTERS,
});

export const showHideFilters = () => ({
  type: SHOW_HIDE_FILTERS,
});

export const hideFilters = () => ({
  type: HIDE_FILTERS,
});

export const setFilterOptions = (data) => {
  return {
    type: SET_FILTER_OPTIONS,
    payload: {
      data,
    },
  };
};

export const setFiltersFromLocations = (payload) => {
  let locations = [];

  if (payload.partner.ticket) {
    locations = payload.partner.ticket.locations;
  } else {
    let type = Object.keys(payload.partner);
    if (payload.partner[type]) {
      locations = payload.partner[type].ticket.locations;
    }
  }

  let filter = [
    {
      name: "Status",
      selections: [],
    },
    {
      name: "City",
      selections: [],
    },
    {
      name: "Zip",
      selections: [],
    },
    {
      name: "Country",
      selections: [],
    },
    {
      name: "CSP",
      selections: [],
    },
  ];

  locations.forEach((location) => {
    if (location.status) {
      filter[0].selections.push({ name: location.status });
    }
    if (location.city) {
      filter[1].selections.push({ name: location.city });
    }
    if (location.zip) {
      filter[2].selections.push({ name: location.zip });
    }
    if (location.country) {
      filter[3].selections.push({ name: location.country });
    }
    if (location.isp) {
      filter[4].selections.push({ name: location.isp });
    }
  });

  return (dispatch) => {
    dispatch(setFilterOptions(filter));
  };
};

export const setFilterError = () => ({
  type: SET_FILTER_ERROR,
});

export const setSearchTextAlert = (text) => ({
  type: SET_SEARCH_TEXT_ALERT,
  payload: {
    text,
  },
});

export const setMonTablePagination = (offset, first) => ({
  type: SET_ALERT_TABLE_PAGINATION,
  payload: {
    offset,
    first,
  },
});

export const getFiltersData = getDataMockedThunk(
  setFilterOptions,
  setFilterError,
  selectionQuery
);

//thunk
export const getLocationsThunkFilter = (filter, keyList, active) => {
  return async (dispatch, getState) => {
    if (active) {
      dispatch(deletFilterThunk(filter, keyList));
    } else {
      dispatch(addFilter(filter, keyList));
    }
    dispatch(setPage(1));
    dispatch(updateFiltersInUrl(getState().alertLAS.selectedFilters.byName));
  };
};

//thunk
export const getLocationsThunkFilterDemo = (filter, keyList) => {
  return async (dispatch, getState) => {
    dispatch(addFilter(filter, keyList));
    dispatch(updateFiltersInUrl(getState().alertLAS.selectedFilters.byName));
    const rawFilters = getState().alertLAS.selectedFilters.byName;
    const filters = Object.keys(rawFilters).map((key) => {
      return {
        typeOfFilter: rawFilters[key].typeOfFilter,
        value: rawFilters[key].name,
      };
    });

    const searchFilter = getState().alertLAS.searchText;
    const first = getState().alertLAS.first;
    const offset = getState().alertLAS.offset;
    const variables = {
      filters: filters,
      search: searchFilter,
      first: first,
      offset: offset,
    };

    const endPoint = SIGNAL_MOCK_URL;
    const query = ALERT_LAS_LOCATION_QUERY;
    try {
      const data = await request(endPoint, query, variables);
      dispatch(setAlertLocationData(data));
    } catch (err) {
      console.log("====>", err);
    }
  };
};

export const getLocationsThunkSearch = (searchText = "") => {
  return async (dispatch, getState) => {
    dispatch(setSearchTextAlert(searchText));
    const rawFilters = getState().alertLAS.selectedFilters.byName;
    const filters = Object.keys(rawFilters).map((key) => {
      return {
        typeOfFilter: rawFilters[key].typeOfFilter,
        value: rawFilters[key].name,
      };
    });

    const searchFilter = getState().alertLAS.searchText;
    const first = getState().alertLAS.first;
    const offset = getState().alertLAS.offset;
    const variables = {
      filters: filters,
      search: searchFilter,
      first: first,
      offset: offset,
    };

    const endPoint = SIGNAL_MOCK_URL;
    const query = ALERT_LAS_LOCATION_QUERY;
    try {
      const data = await request(endPoint, query, variables);
      dispatch(setAlertLocationData(data));
    } catch (err) {
      console.log("====>", err);
    }
  };
};

export const deletFilterThunk = (filter, keyList) => {
  return async (dispatch, getState) => {
    dispatch(deleteFilter(filter, keyList));
    dispatch(deleteFiltersInUrl(filter, keyList));
  };
};

export const deletFilterThunkDemo = (filter, keyList) => {
  return async (dispatch, getState) => {
    dispatch(deleteFilter(filter, keyList));
    dispatch(deleteFiltersInUrl(filter, keyList));
    const rawFilters = getState().alertLAS.selectedFilters.byName;
    const filters = Object.keys(rawFilters).map((key) => {
      return {
        typeOfFilter: rawFilters[key].typeOfFilter,
        value: rawFilters[key].name,
      };
    });

    const searchFilter = getState().alertLAS.searchText;
    const first = getState().alertLAS.first;
    const offset = getState().alertLAS.offset;
    const variables = {
      filters: filters,
      search: searchFilter,
      first: first,
      offset: offset,
    };

    const endPoint = SIGNAL_MOCK_URL;
    const query = ALERT_LAS_LOCATION_QUERY;
    try {
      const data = await request(endPoint, query, variables);
      dispatch(setAlertLocationData(data));
    } catch (err) {
      console.log("====>", err);
    }
  };
};

export const changePaginationThunk = (newOffset, newFirst) => {
  return async (dispatch, getState) => {
    dispatch(setMonTablePagination(newOffset, newFirst));
    const rawFilters = getState().alertLAS.selectedFilters.byName;
    const filters = Object.keys(rawFilters).map((key) => {
      return {
        typeOfFilter: rawFilters[key].typeOfFilter,
        value: rawFilters[key].name,
      };
    });

    const searchFilter = getState().alertLAS.searchText;
    const first = getState().alertLAS.first;
    const offset = getState().alertLAS.offset;
    const variables = {
      filters: filters,
      search: searchFilter,
      first: first,
      offset: offset,
    };

    const endPoint = SIGNAL_MOCK_URL;
    const query = ALERT_LAS_LOCATION_QUERY;
    try {
      const data = await request(endPoint, query, variables);
      dispatch(setAlertLocationData(data));
    } catch (err) {
      console.log("====>", err);
    }
  };
};
