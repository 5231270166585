import * as d3 from "d3";
import cx from "classnames";

const addTranslate = (value) => `translate(${value.x},${value.y})`;
const inPixels = (value) => `${value}px`;

export const useRenderPawGraph = (svgDescriptor) => {
  const createMainSvg = () => {
    const container = d3.select("#PawGraphContainer");
    container.select("svg").remove();
    return container
      .append("svg")
      .attr("width", svgDescriptor.width)
      .attr("viewBox", `0 0 ${svgDescriptor.width} 600`)
      .attr("height", 600);
  };

  const createMainG = (mainSvg, item, index) => {
    return mainSvg
      .append("g")
      .attr(
        "class",
        cx("PawGraph__element", {
          "PawGraph__element--first": index === 0,
        })
      )
      .attr("x", item.x)
      .attr("y", item.y)
      .on("mouseover", () => {
        const info = d3.select(".PawGraph__info");
        info
          .attr(
            "class",
            cx("PawGraph__info", {
              [item.tooltipPosition.direction]: true,
            })
          )
          .style("left", inPixels(item.tooltipPosition.x))
          .style("top", inPixels(item.tooltipPosition.y))
          .style("width", inPixels(item.size))
          .style("height", inPixels(item.size))
          .style("visibility", "visible")
          .text(item.label);
      })
      .on("mouseout", () => {
        const info = d3.select(".PawGraph__info");
        info.style("visibility", "hidden");
      })
      .attr("width", item.size)
      .attr("height", item.size);
  };

  const createRect = (gElement, item, index) => {
    return gElement
      .append("rect")
      .attr("class", "PawGraph__rect")
      .attr("x", item.x)
      .attr("y", item.y)
      .attr("rx", item.borderRadius)
      .attr("ry", item.borderRadius)
      .attr("width", item.size)
      .attr("height", item.size)
      .attr("fill", item.color)
      .attr(
        "transform",
        index > 0 ? addTranslate(svgDescriptor.translation) : ""
      )
      .append("title");
  };

  const createText = (gElement, item) => {
    gElement
      .append("text")
      .style("text-anchor", "middle")
      .attr("class", "PawGraph__label")
      .attr("x", item.textPosition.x)
      .attr("y", item.textPosition.y)
      .attr("width", item.size)
      .attr("dy", "0.35em")
      .text(item.value)
      .attr("fill", "#fff");
  };

  return {
    createMainG,
    createText,
    createRect,
    createMainSvg,
  };
};
