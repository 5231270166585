import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SlText } from "../Atoms/Text/Text";

import "./timeSelector.scss";
import FormattedMessage from "../../../../translations/FormattedMessage";
//SlTimeSelector takes an object with  of times and an dispatched action

const decoupleTime = (timeString) => {
  if (!timeString) {
    return;
  }
  const [amount, unit] = timeString.split(" ");
  let unifiedUnit;
  if (["day", "days"].includes(unit.toLowerCase())) {
    unifiedUnit = "day";
  } else if (["hr", "hours"].includes(unit.toLowerCase())) {
    unifiedUnit = "hour";
  } else {
    unifiedUnit = "all";
  }

  return {
    amount: parseInt(amount),
    unit: unifiedUnit,
  };
};

const SlTimeSelector = ({ times, changeAction, id }) => {
  return (
    <div className="sl-time-selector-cont">
      <div className="sl-time-selector">
        {Object.keys(times).map((time, index) => {
          const decoupledTime = decoupleTime(time);
          const translationKey = `timeSelector.${decoupledTime.unit}`;
          return (
            <span
              key={`${time}-key`}
              onClick={() => {
                changeAction(id, time);
              }}
              className={classNames("sl-time-selection", {
                "sl-time-selection--selected": times[time],
              })}
            >
              <SlText p7 destressed={!times[time]}>
                <FormattedMessage
                  id={translationKey}
                  params={{ count: decoupledTime.amount }}
                />
              </SlText>
            </span>
          );
        })}
      </div>
    </div>
  );
};

SlTimeSelector.propTypes = {
  times: PropTypes.object.isRequired,
  changeAction: PropTypes.func,
};

export { SlTimeSelector };
