import React, { useEffect } from "react";
import { SlPage } from "../../components/communal/Atoms/Page/Page";
import { SlSection } from "../../components/communal/Atoms/Section/Section";
import "./LocationsPage.scss";
import { useDispatch, useSelector } from "react-redux";
import { getLocationsData } from "../../../actions/location/locationActions";
import { SlHorizontalBar } from "../communal/Atoms/Charts/HorizontalBar/HorizontalBar";

export const LocationsPage = () => {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.location);

  useEffect(() => {
    dispatch(getLocationsData());
  }, [dispatch]);

  return (
    <div className="signal-locations">
      <SlPage>
        <SlSection>
          {location.locations.partner && (
            <>
              <SlHorizontalBar
                label={"Offline network"}
                data={
                  location.locations.partner.locationAlarmHistory.offlineNetwork
                }
                timeframe={168}
              />
              <SlHorizontalBar
                label={"Unstable Network"}
                data={
                  location.locations.partner.locationAlarmHistory
                    .unstableNetwork
                }
                timeframe={168}
              />
            </>
          )}
        </SlSection>
      </SlPage>
    </div>
  );
};
