import React from "react";
import { Security } from "@okta/okta-react";

import { SecureRoute } from "@okta/okta-react";
import SecureV2 from "../components/SecureRoutes";

import "./App.css";
import { getOktaClientId, getOktaIssuer } from "../utils/environments";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";

import { Router, Route } from "react-router-dom";
import { LoginPage } from "../components/components/LoginPage/LoginPage";
import { CustomLoginCallback } from "../components/components/LoginPage/CustomLoginCallback/CustomLoginCallback";
import { createBrowserHistory } from "history";
import { Switch } from "react-router";
import AppContainer from "./AppContainer";
import { Routes } from "../config";

const oktaAuth = new OktaAuth({
  issuer: getOktaIssuer(),
  clientId: getOktaClientId(),
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ["openid", "email", "signal"],
  response_type: "id_token",
});

const App = () => {
  const history = createBrowserHistory();

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    if (originalUri) {
      window.location = toRelativeUrl(originalUri, window.location.origin);
    } else {
      window.location = Routes.Index;
    }
  };

  const onAuthRequired = () => {
    window.history.pushState({}, "", "/login");
    window.location = window.location; // eslint-disable-line no-self-assign
  };

  return (
    <Router history={history}>
      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={onAuthRequired}
        restoreOriginalUri={restoreOriginalUri}
      >
        <AppContainer>
          <Switch>
            <Route path={Routes.Login} component={LoginPage} />
            <Route
              path={Routes.LoginCallback}
              component={CustomLoginCallback}
            />
            <SecureRoute path={Routes.Index} component={SecureV2} />
          </Switch>
        </AppContainer>
      </Security>
    </Router>
  );
};

export default App;
