import { FeatureFlags, Routes } from "../config";
import EyeIcon from "../assets/svg/icon-eye-primary.svg";
import EyeIconSecondary from "../assets/svg/icon-eye-secondary.svg";
import BellIcon from "../assets/svg/icon-bell-primary.svg";
import BellIconSecondary from "../assets/svg/icon-bell-secondary.svg";
import WaveIcon from "../assets/svg/icon-wave.svg";
import LightbulbIcon from "../assets/svg/icon-lightbulb.svg";
import { useFeatureFlag } from "./useFeatureFlag";

export const useNavigation = () => {
  const isPredictAvailable = useFeatureFlag(FeatureFlags.Predict);

  const navigationItems = [
    {
      route: Routes.Monitor,
      labelId: "navigation.monitor",
      icon: EyeIcon,
      iconCurrent: EyeIconSecondary,
      visible: true,
    },
    {
      route: Routes.Alert,
      labelId: "navigation.alert",
      icon: BellIcon,
      iconCurrent: BellIconSecondary,
      visible: true,
    },
    {
      route: Routes.Predict,
      labelId: "navigation.predict",
      icon: WaveIcon,
      // @TODO: Add proper icon for current state
      iconCurrent: WaveIcon,
      visible: Boolean(isPredictAvailable()),
    },
    {
      route: Routes.Solve,
      labelId: "navigation.solve",
      icon: LightbulbIcon,
      iconCurrent: LightbulbIcon,
      visible: false,
    },
  ];

  return {
    items: navigationItems.filter((item) => item.visible),
    comingSoonItems: navigationItems.filter((item) => !item.visible),
  };
};
