import React from "react";

import "./Tile.scss";
import { SlText } from "../../Atoms/Text/Text";
import { useClassNames } from "../../../../../hooks/useClassNames";
import cx from "classnames";
import { ChevronRightIcon } from "../../Atoms/Icons.jsx";
import PropTypes from "prop-types";

export const SlTile = ({
  title,
  subtitle,
  icon,
  classes,
  isSelected,
  onClick,
}) => {
  const styles = useClassNames(
    {
      root: cx("sl-tile", {
        "sl-tile--selected": Boolean(isSelected),
      }),
      text: "sl-tile__text",
      textWrapper: "sl-tile__text-wrapper",
      icon: "sl-tile__icon",
      chevron: "sl-tile__chevron",
    },
    classes
  );
  const titleSlTextProps = subtitle
    ? { subtitle2m: true, classNames: "sl-tile__text--title" }
    : { p7: true };

  return (
    <div className={styles.root} onClick={onClick}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.text}>
        <div className={styles.textWrapper}>
          <SlText {...titleSlTextProps}>{title}</SlText>
          {subtitle && <SlText p8>{subtitle}</SlText>}
        </div>
      </div>
      <ChevronRightIcon className={styles.chevron} />
    </div>
  );
};

SlTile.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  subtitle: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  onClick: PropTypes.func,
  icon: PropTypes.object,
  isSelected: PropTypes.bool,
};
