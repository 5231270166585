import React from "react";
import PropTypes from "prop-types";

import "./menuHeader.scss";

const MenuHeader = ({ children }) => {
  return <div className="sl-menu-header-wrapper">{children}</div>;
};

MenuHeader.propTypes = {
  children: PropTypes.string.isRequired,
};

export { MenuHeader };
