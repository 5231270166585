import { mapKPINameToData } from "./helpers";
import { createSelector } from "reselect";

import {
  FETCHING_MONITOR_HEADER_DATA,
  MONITOR_HEADER_DATA_ERR,
  MONITOR_HEADER_DATA_RECIEVED,
  FETCHING_MONITOR_MAP_DATA,
  MONITOR_MAP_DATA_ERR,
  MONITOR_MAP_DATA_RECIEVED,
  FETCHING_SPARKLINE_MAP_DATA,
  MONITOR_SPARKLINE_MAP_DATA_ERR,
  MONITOR_SPARKLINE_MAP_DATA_RECIEVED,
} from "../actions/monitorActions";

const Init_State = {
  data: null,
  data_error: false,
  dataLoading: true,
  mapData: null,
  mapData_error: false,
  mapData_loading: true,
  sparkLineData: null,
  sparkLineData_error: false,
  sparkLineData_loading: true,
  mapDataTime: null,
};

const monitorReducer = (state = Init_State, action = { type: null }) => {
  switch (action.type) {
    case FETCHING_MONITOR_HEADER_DATA:
      return { ...state, dataLoading: true, data_error: false };
    case MONITOR_HEADER_DATA_ERR:
      return { ...state, data_error: true };
    case MONITOR_HEADER_DATA_RECIEVED:
      const state_new = {
        ...state,
        dataLoading: false,
        data: action.payload.data,
      };
      return state_new;
    case FETCHING_MONITOR_MAP_DATA:
      return { ...state, mapData_loading: true, mapData_error: false };
    case MONITOR_MAP_DATA_ERR:
      return { ...state, mapData_error: true };
    case MONITOR_MAP_DATA_RECIEVED:
      return { ...state, mapData_loading: false, mapData: action.payload.data };
    case FETCHING_SPARKLINE_MAP_DATA:
      return { ...state, sparkLineData_loading: true, mapData_error: false };
    case MONITOR_SPARKLINE_MAP_DATA_ERR:
      return { ...state, sparkLineData_error: true };
    case MONITOR_SPARKLINE_MAP_DATA_RECIEVED:
      return {
        ...state,
        sparkLineData_loading: false,
        sparkLineData: action.payload.data,
        mapDataTime: new Date(),
      };
    default:
      return state;
  }
};

export default monitorReducer;

//selectors

const getNameSelctor = (state, ownProps) => ownProps.name;
const getMapData = (state, ownProps) => state.mapData.partner;

export const getLocationNumber = createSelector(
  [getNameSelctor, getMapData],
  (name, data) => {
    const locationNumbers = {};
    const alarmNumber =
      name === "CSP Outage"
        ? alarmSum(data, name)
        : data[mapKPINameToData[name]].troublePoints.length;
    const warningNumber =
      data[mapKPINameToData[name]].warningPoints === null
        ? 0
        : data[mapKPINameToData[name]].warningPoints.length;

    locationNumbers.alarm = alarmNumber;
    locationNumbers.total = alarmNumber + warningNumber;
    return locationNumbers;
  }
);

const alarmSum = (data, name) => {
  if (data[mapKPINameToData[name]].troublePoints.length === 0) return 0;
  let alarmSum = 0;
  data[mapKPINameToData[name]].troublePoints.forEach((point) => {
    alarmSum += point.alarmCount;
  });
  return alarmSum;
};
