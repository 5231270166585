import * as d3 from "d3";

class BaseXYFile {
  constructor(options) {
    this.margin = { l: 45, t: 25, r: 45, b: 20 };
    this.data = options.data;
    this.el = options.el;
    this.width =
      options.el.getBoundingClientRect().width -
      (this.margin.l + this.margin.r);
    this.height =
      options.el.getBoundingClientRect().height -
      (this.margin.t + this.margin.b);
    this.xAtt = options.xAtt;
    this.yAtt = options.yAtt;
    this.time = options.time;
    this.gcolorStart = options.gcolorStart;
    this.class =
      options.class === undefined ? "trendline-chart-chart" : options.class;
    this.totalNumber =
      options.totalNumber === undefined ? 0 : options.totalNumber;
    this.makeTotalData();
  }

  makeSVG() {
    this.mainSVG = d3
      .select(this.el)
      .append("svg")
      .attr("height", this.height + (this.margin.t + this.margin.b))
      .attr("width", this.width + (this.margin.l + this.margin.r))
      .attr("class", `signal-ui-trendline ${this.class}`);

    this.mainG = this.mainSVG
      .append("g")
      .attr("class", "signal-trendline-main-g")
      .attr("transform", `translate(${this.margin.l}, ${this.margin.t})`);
  }

  makeTotalData() {
    this.data = this.data.map((d) => {
      d.total = Math.round(d.value * this.totalNumber);
      return d;
    });
  }

  formatValue() {
    switch (this.yAtt) {
      case "value":
        return d3.format(".1%");
      case "total":
        return d3.format(".0f");
      default:
        return;
    }
  }

  makeScales() {
    const yMax = d3.max(this.data, (d) => d[this.yAtt]);

    const yMultiplier = yMax === 1 ? 0 : 0.05;

    this.yScale = d3
      .scaleLinear()
      .domain([0, yMax + yMultiplier])
      .range([this.height, 0])
      .nice(5);

    const startDate =
      this.data[0] === undefined ? new Date() : this.data[0][this.xAtt];
    const endDate =
      this.data[0] === undefined
        ? new Date()
        : this.data[this.data.length - 1][this.xAtt];
    this.xScale = d3
      .scaleTime()
      .domain([startDate, endDate])
      .range([0, this.width]);
  }

  makeAxis() {
    //xScale ticks
    const length = this.data.length;
    const leftMiddle = Math.floor(length / 4);
    const rightMiddle = length - 1 - leftMiddle;
    const tickIndexes = [
      0,
      Math.floor(length / 4),
      Math.floor(length / 2),
      rightMiddle,
      length - 1,
    ];
    const xTicks = tickIndexes.map((d) => {
      return length === 0
        ? null
        : this.data[d] === undefined
        ? null
        : this.data[d][this.xAtt];
    });

    this.xAxis = d3
      .axisBottom(this.xScale)
      .tickValues(xTicks)
      .tickFormat(this.getXAxisFormat());

    this.mainG
      .append("g")
      .attr("class", "trendline-axis-x")
      .call(this.xAxis)
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .selectAll(".tick line")
          .attr("stroke-opacity", 0.5)
          .attr("stroke-dasharray", "2,2")
          .attr("y2", this.height)
      )
      .call((g) =>
        g
          .selectAll(".tick text")
          .attr("x", 0)
          .attr("dy", this.height + 8)
      )
      .attr("font-size", "12px")
      .attr("y", "12")
      .attr("font-weight", "400")
      .attr("opacity", 0.5)
      .attr("font-family", "wigrumweb");

    //yAxis
    const formatYTick = (d) => {
      if (d === 1) {
        return d3.format(".0%")(d);
      }
      return d3.format(".1%")(d);
    };
    const yAxisFormat = this.yAtt === "value" ? formatYTick : (d) => d;
    const yAxis = d3
      .axisRight(this.yScale)
      .ticks(5)
      .tickSize(this.width)
      .tickFormat((d) => yAxisFormat(d));
    this.mainG
      .append("g")
      .attr("class", "trend-line-y-axis")
      .call(yAxis)
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .selectAll(".tick line")
          .attr("stroke-opacity", 0.5)
          .attr("stroke-dasharray", "2,2")
          .attr("x2", this.width)
      )
      .call((g) => g.selectAll(".tick text").attr("x", -45).attr("dy", 3))
      .attr("font-size", "10px")
      .attr("font-weight", "400")
      .attr("opacity", 0.5)
      .attr("font-family", "wigrumweb");
  }

  getXAxisFormat() {
    switch (this.time) {
      case "30 Day":
        return d3.timeFormat("%b %d");
      case "7 Day":
        return d3.timeFormat("%b %d");
      case "24 Hr":
        return d3.timeFormat("%H:%M %p");
      default:
        return;
    }
  }

  getXAxisFormatPopover() {
    switch (this.time) {
      case "30 Day":
        return d3.timeFormat("%b %d");
      case "7 Day":
        return d3.timeFormat("%b %d");
      case "24 Hr":
        return d3.timeFormat("%H:%M");
      default:
        return;
    }
  }

  reMakeAxis() {
    const length = this.data.length;
    const leftMiddle = Math.floor(length / 4);
    const rightMiddle = length - 1 - leftMiddle;
    const tickIndexes = [
      0,
      Math.floor(length / 4),
      Math.floor(length / 2),
      rightMiddle,
      length - 1,
    ];
    const xTicks = tickIndexes.map((d) => {
      return length === 0
        ? null
        : this.data[d] === undefined
        ? null
        : this.data[d][this.xAtt];
    });

    this.xAxis = d3
      .axisBottom(this.xScale)
      .tickValues(xTicks)
      .tickFormat(this.getXAxisFormat());

    this.mainG
      .select(".trendline-axis-x")
      .call(this.xAxis)
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .selectAll(".tick line")
          .attr("stroke-opacity", 0.5)
          .attr("stroke-dasharray", "2,2")
          .attr("y2", this.height)
      )
      .call((g) =>
        g
          .selectAll(".tick text")
          .attr("x", 0)
          .attr("dy", this.height + 6)
      )
      .attr("font-size", "12px")
      .attr("font-weight", "400")
      .attr("opacity", 0.5)
      .attr("font-family", "wigrumweb");

    //yAxis
    const yAxisFormat = this.yAtt === "value" ? d3.format(".1%") : (d) => d;
    const yAxis = d3
      .axisRight(this.yScale)
      .ticks(5)
      .tickSize(this.width)
      .tickFormat((d) => yAxisFormat(d));
    this.mainG
      .selectAll(".trend-line-y-axis")
      .call(yAxis)
      .call((g) => g.select(".domain").remove())
      .call((g) =>
        g
          .selectAll(".tick line")
          .attr("stroke-opacity", 0.5)
          .attr("stroke-dasharray", "2,2")
          .attr("x2", this.width)
      )
      .call((g) => g.selectAll(".tick text").attr("x", -43).attr("dy", 3))
      .attr("font-size", "10px")
      .attr("font-weight", "400")
      .attr("opacity", 0.5)
      .attr("font-family", "wigrumweb");
  }
}

export default BaseXYFile;
