import React from "react";
import PropTypes from "prop-types";
import Filter from "../../../Atoms/Table/Filter";
import "./filterRow.scss";
import { replaceISPWithCSP } from "../../../../../Header/ISPSelector/helpers";

const FilterRow = ({ filters, deleteFilterAction }) => {
  if (filters === undefined) {
    return <span className="Sl-table-filter-row"></span>;
  }

  return (
    <span className="Sl-table-filter-row">
      {Object.keys(filters).map((filter) => (
        <Filter
          filterData={filters[filter]}
          onClickAction={(a, keyList) => deleteFilterAction(filter, keyList)}
        >
          {replaceISPWithCSP(filters[filter]?.typeOfFilter)}: {filter}
        </Filter>
      ))}
    </span>
  );
};

FilterRow.propTypes = {
  filters: PropTypes.object,
};

export default FilterRow;
