import GraphQLService from "./GraphQLService";
import { PREDICT_DATA_SOURCE } from "../config";
import {
  CALL_VOLUME_PREDICTION_PERIODS_QUERY,
  CALL_VOLUME_PREDICTION_24HOUR_AGGREGATE_QUERY,
  CALL_VOLUME_PREDICTION_7DAY_AGGREGATE_QUERY,
  LOCATIONS_MONITORED_QUERY,
  PREDICT_SUMMARY_DATA_QUERY,
  HISTORICAL_TREND_QUERY,
} from "../actions/graphQL_queries/predict";

export default class PredictService extends GraphQLService {
  constructor() {
    super();
    this.client = this.getClientWithToken(PREDICT_DATA_SOURCE);
  }
  async getCallInVolumePeriods(partnerId) {
    return await this.client.request(
      CALL_VOLUME_PREDICTION_PERIODS_QUERY(partnerId)
    );
  }

  async getPredictSummaryData(partnerId) {
    return await this.client.request(PREDICT_SUMMARY_DATA_QUERY(partnerId));
  }

  async getLocationsMonitoredData(partnerId) {
    return await this.client.request(LOCATIONS_MONITORED_QUERY(partnerId));
  }

  async getPredict24HourData(partnerId) {
    return await this.client.request(
      CALL_VOLUME_PREDICTION_24HOUR_AGGREGATE_QUERY(partnerId)
    );
  }

  async getPredict7DayData(partnerId) {
    return await this.client.request(
      CALL_VOLUME_PREDICTION_7DAY_AGGREGATE_QUERY(partnerId)
    );
  }

  async getHistoricalTrendData(partnerId, periodStart, periodEnd) {
    return await this.client.request(
      HISTORICAL_TREND_QUERY(partnerId, periodStart, periodEnd)
    );
  }
}
