import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getMonitorHeaderData } from "../../../../actions/monitorActions";
import RadarIcon from "../../../../assets/svg/icon-radar.svg";
import AlarmIcon from "../../../../assets/svg/icon-alarm.svg";
import { SlIcon } from "../../communal/Atoms/Icon/Icon";
import { SlMetric } from "../../communal/Molecules/Metric/Metric";
import { SlPanelLoader } from "../../communal/Atoms/PanelLoader/PanelLoader";
import FormattedMessage from "../../../../translations/FormattedMessage";

function MonitorHeader({ isLoading, isError, data, getMonitorData }) {
  useEffect(() => {
    getMonitorData();
  }, [getMonitorData]);

  if (isLoading) {
    return <SlPanelLoader />;
  }

  if (isError) {
    return (
      <div className="Sl-monitor-loader">
        <FormattedMessage id="error" />
        <span className="Sl-err-ref">
          <FormattedMessage id="refreshPage" />
        </span>
      </div>
    );
  }

  const homesMonitored = data.partner.homesMonitored;
  const homesInAlarm = data.partner.homesInAlarm;

  return (
    <>
      <SlMetric
        icon={<SlIcon svgFile={RadarIcon} width={40} height={40} />}
        value={homesMonitored}
        title={<FormattedMessage id="monitor.header.totalLocationsMonitored" />}
        id={"sl-home-monitor-number"} /* FES-341: New Relic expects this ID */
      />
      <SlMetric
        icon={<SlIcon svgFile={AlarmIcon} width={40} height={40} />}
        value={homesInAlarm}
        title={<FormattedMessage id="monitor.header.totalLocationsInAlarm" />}
        id={"sl-homes-in-alarm-number"} /* FES-341: New Relic expects this ID */
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.monitor.dataLoading,
  isError: state.monitor.data_error,
  data: state.monitor.data,
});

const mapDispatchToProps = (dispatch) => ({
  getMonitorData: () => {
    dispatch(getMonitorHeaderData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MonitorHeader);
