import React from "react";

import "./Section.scss";

const SlSection = ({ contrast, children, style, className }) => (
  <div
    style={style}
    className={`sl-section${
      contrast ? ` sl-section--contrast-${contrast}` : ""
    }${className ? ` ${className}` : ""}`}
  >
    <div className="sl-section__inner">{children}</div>
  </div>
);

export { SlSection };
