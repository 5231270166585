import * as d3 from "d3";
import { Delaunay } from "d3-delaunay";
import BaseXYFile from "./BaseXYFile";

import { sleep } from "../../../../../../actions/helpers";

class TrendLine extends BaseXYFile {
  makeLines() {
    const line = d3
      .line()
      .x((d) => this.xScale(d[this.xAtt]))
      .y((d) => this.yScale(d[this.yAtt]));

    this.mainG
      .append("g")
      .attr("class", "trendline-path-group")
      .append("path")
      .attr("class", "trednline-path")
      .datum(this.data)
      .attr("d", line)
      .attr("fill", "none")
      .attr("stroke", this.gcolorStart)
      .attr("stroke-width", "3px");
  }

  areaGenerator() {
    this.area = d3
      .area()
      .x((d) => this.xScale(d[this.xAtt]))
      .y1((d) => this.yScale(d[this.yAtt]))
      .y0(this.yScale(0));
  }

  renderArea() {
    this.areaGenerator();
    if (this.gcolorStop !== "null") {
      this.mainG
        .append("g")
        .attr("class", "trend-line-area")
        .append("path")
        .attr("class", "trend-line-area-path")
        .datum(this.data)
        .attr("d", this.area)
        .attr("fill", this.gcolorStart)
        .attr("opacity", "0.45")
        .attr("stroke", "none");
    }
  }

  makeDelaunay() {
    const delaunay = Delaunay.from(
      this.data,
      (d) => this.xScale(d[this.xAtt]) + this.margin.l,
      (d) => this.yScale(0)
    );

    const handleMouseMove = (el) => {
      d3.select(".trend-line-mouse-move-layer").remove();
      d3.selectAll(".Sl-trendline-popover-cont").remove();
      const [mx, my] = d3.mouse(this.el);
      const index = delaunay.find(mx, my);
      const dataClosest = this.data[index];
      const y = this.yScale(dataClosest[this.yAtt]);
      const x = this.xScale(dataClosest[this.xAtt]);

      const date = this.getXAxisFormatPopover()(dataClosest[this.xAtt]);
      const value = this.formatValue()(dataClosest[this.yAtt]);
      const mouseLayerG = this.mainG
        .append("g")
        .attr("class", "trend-line-mouse-move-layer");

      mouseLayerG
        .append("line")
        .attr("x1", x)
        .attr("x2", x)
        .attr("y1", 0)
        .attr("y2", this.height)
        .attr("stroke", "rgb(250,250,250)")
        .attr("stroke-dasharray", "4 2 4 2");

      mouseLayerG
        .append("circle")
        .attr("cx", x)
        .attr("cy", y)
        .attr("r", 5)
        .attr("fill", this.gcolorStart);

      d3.select(this.el)
        .append("div")
        .attr("class", "Sl-trendline-popover-cont")
        .append("div")
        .attr("class", "Sl-trendline-popover")
        .style("bottom", "30px")
        .style("left", `${x + 5}px`)
        .html((d) => popOverHTML(value, date));

      function popOverHTML(value, date) {
        return `
             <span class="Sl-trendline-popover-value">
               ${value}:
             </span>
             <span class="Sl-trendline-popover-time">
               ${date}
             </span>
          `;
      }
    };

    this.mainSVG.on("mousemove", handleMouseMove);
    this.mainSVG.on("mouseout", () => {
      d3.select(".trend-line-mouse-move-layer").remove();
      d3.selectAll(".Sl-trendline-popover-cont").remove();
    });
  }

  removeLine() {
    this.mainG.select(".trendline-path-group").remove();
  }

  removeArea() {
    this.mainG.select(".trend-line-area").remove();
  }

  render() {
    this.makeSVG();
    this.makeScales();
    this.makeAxis();
    this.makeLines();
    this.renderArea();
    this.makeDelaunay();
    this.motionDot();
  }

  remove() {
    this.mainSVG.remove();
  }

  redraw(options) {
    this.height = options.height - (this.margin.t + this.margin.b);
    this.width = options.width - (this.margin.l + this.margin.r);

    this.render();
  }

  async motionDot() {
    const data = this.data[this.data.length - 1];
    const y = this.yScale(data[this.yAtt]);
    const x = this.xScale(data[this.xAtt]);
    this.mainG.selectAll(".motion-circle").remove();
    const circle = this.mainG
      .append("circle")
      .attr("class", "motion-circle")
      .attr("cx", x)
      .attr("cy", y)
      .attr("r", 5)
      .attr("fill", this.gcolorStart);

    this.motion = true;
    let i = 0;
    while (this.motion) {
      if (i % 2 === 0) {
        circle.attr("fill", this.gcolorStart);
      } else {
        circle.attr("fill", "rgba(0,0,0,0)");
      }
      i++;

      await sleep(1000);
    }
  }

  stopMotion() {
    this.motion = false;
  }

  rerender(options) {
    this.removeArea();
    this.data = options.data;
    this.makeTotalData();
    this.time = options.time;
    this.makeScales();
    this.reMakeAxis();
    this.removeLine();
    this.makeLines();
    this.renderArea();
    this.makeDelaunay();
    this.motionDot();
  }

  rerenderYaxis(options) {
    this.yAtt = options.yAtt;
    this.removeArea();
    this.makeScales();
    this.reMakeAxis();
    this.removeLine();
    this.makeLines();
    this.renderArea();
    this.makeDelaunay();
  }
}

export default TrendLine;
