import { GraphQLClient } from "graphql-request";
import { GET_CLOUD_GRAPHQL_URL } from "../utils/constants";
import { getOktaToken } from "../helpers/Token/Token";

import { getGraphQLData } from "./helpers";

import {
  MAP_QUERY,
  HEADER_QUERY,
  TIME_DATA,
  MAP_DATA_NUMBER_QUERY,
} from "./graphQL_queries/monitor";

export const FETCHING_MONITOR_HEADER_DATA = "FETCHING_MONITOR_HEADER_DATA";
export const MONITOR_HEADER_DATA_ERR = "MONITOR_HEADER_DATA_ERR";
export const MONITOR_HEADER_DATA_RECIEVED = "MONITOR_HEADER_DATA_RECIEVED";
export const FETCHING_MONITOR_MAP_DATA = "FETCHING_MONITOR_MAP_DATA";
export const MONITOR_MAP_DATA_ERR = "MONITOR_MAP_DATA_ERR";
export const MONITOR_MAP_DATA_RECIEVED = "MONITOR_MAP_DATA_RECIEVED";

export const FETCHING_SPARKLINE_MAP_DATA = "FETCHING_SPARKLINE_MAP_DATA";
export const MONITOR_SPARKLINE_MAP_DATA_ERR = "MONITOR_SPARKLINE_MAP_DATA_ERR";
export const MONITOR_SPARKLINE_MAP_DATA_RECIEVED =
  "MONITOR_SPARKLINE_MAP_DATA_RECIEVED";

export const FETCHING_MONITOR_MAP_NUMBERS = "FETCHING_MONITOR_MAP_NUMBERS";
export const MONITOR_MAP_NUMBERS_RECEIVED = "MONITOR_MAP_NUMBERS_RECEIVED";
export const MONITOR_MAP_NUMBERS_ERR = "MONITOR_MAP_NUMBERS_ERR";
export const RESET_MONITOR_MAP_NUMBERS = "RESET_MONITOR_MAP_NUMBERS";

const fetchingMonitorHeaderData = () => ({
  type: FETCHING_MONITOR_HEADER_DATA,
});

const monitorHeaderDataErr = () => ({
  type: MONITOR_HEADER_DATA_ERR,
});

const monitorHeaderDataRecieved = (data) => ({
  type: MONITOR_HEADER_DATA_RECIEVED,
  payload: {
    data,
  },
});

const fetchingMonitorMapData = () => ({
  type: FETCHING_MONITOR_MAP_DATA,
});

const monitorMapDataErr = () => ({
  type: MONITOR_MAP_DATA_ERR,
});

const monitorMapDataRecieved = (data) => ({
  type: MONITOR_MAP_DATA_RECIEVED,
  payload: {
    data,
  },
});

//sparkline actions
const fetchingSparkLineMonitorMapData = () => ({
  type: FETCHING_SPARKLINE_MAP_DATA,
});

const monitorSparkLineMapDataErr = () => ({
  type: MONITOR_SPARKLINE_MAP_DATA_ERR,
});

const monitorSparkLineMapDataRecieved = (data) => ({
  type: MONITOR_SPARKLINE_MAP_DATA_RECIEVED,
  payload: {
    data,
  },
});

const monitorMapDataReceived = (data) => ({
  type: MONITOR_MAP_NUMBERS_RECEIVED,
  payload: {
    data,
  },
});

const fetchingMonitorMapNumbers = () => ({
  type: FETCHING_MONITOR_MAP_NUMBERS,
});

const monitorMapNumbersErr = () => ({
  type: MONITOR_MAP_NUMBERS_ERR,
});

export const resetMonitorMapNumbers = () => ({
  type: RESET_MONITOR_MAP_NUMBERS,
});

//thunk
export const getMonitorHeaderData = () => {
  return async (dispatch, getState) => {
    dispatch(fetchingMonitorHeaderData());
    const primaryDeployment = getState().me.selectedPartner.primaryDeployment;
    const endpoint = GET_CLOUD_GRAPHQL_URL(primaryDeployment);
    const oktaToken = getOktaToken(localStorage);
    const client = new GraphQLClient(endpoint, {
      headers: { Authorization: `Bearer ${oktaToken}` },
    });
    const variables = { id: getState().me.selectedPartner.id };
    try {
      const data = await client.request(HEADER_QUERY, variables);
      dispatch(monitorHeaderDataRecieved(data));
    } catch (err) {
      console.log("====> monitor header err", err);
      dispatch(monitorHeaderDataErr());
    }
  };
};

export const getMonitorMapData = () => {
  return async (dispatch, getState) => {
    dispatch(fetchingMonitorMapData());

    const primaryDeployment = getState().me.selectedPartner.primaryDeployment;
    const endpoint = GET_CLOUD_GRAPHQL_URL(primaryDeployment);
    const oktaToken = getOktaToken(localStorage);
    const client = new GraphQLClient(endpoint, {
      headers: { Authorization: `Bearer ${oktaToken}` },
    });

    const variables = { id: getState().me.selectedPartner.id };

    try {
      const data = await client.request(MAP_QUERY, variables);
      dispatch(monitorMapDataRecieved(data));
    } catch (err) {
      console.log("====> monitor header err", err);
      dispatch(monitorMapDataErr());
    }
  };
};

export const getMonitorSparkLineMapData = () => {
  return async (dispatch, getState) => {
    dispatch(fetchingSparkLineMonitorMapData());

    const primaryDeployment = getState().me.selectedPartner.primaryDeployment;
    const endpoint = GET_CLOUD_GRAPHQL_URL(primaryDeployment);
    const oktaToken = getOktaToken(localStorage);
    const client = new GraphQLClient(endpoint, {
      headers: { Authorization: `Bearer ${oktaToken}` },
    });
    const variables = { id: getState().me.selectedPartner.id };

    try {
      const data = await client.request(TIME_DATA, variables);
      dispatch(monitorSparkLineMapDataRecieved(data));
    } catch (err) {
      console.log("====> monitor header err", err);
      dispatch(monitorSparkLineMapDataErr());
    }
  };
};

export const getMonitorMapNumbers = getGraphQLData(
  MAP_DATA_NUMBER_QUERY,
  fetchingMonitorMapNumbers,
  monitorMapDataReceived,
  monitorMapNumbersErr
);
