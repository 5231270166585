import React, { useRef } from "react";
import PropTypes from "prop-types";
import { HeaderAndSelectedText } from "../HeaderAndSelectedText/HeaderAndSelectedText";

import "./SelectionList.scss";

const SlSelectionList = ({ selections, optionClicked }) => {
  const wrapperRef = useRef(null);

  return (
    <div ref={wrapperRef} className="sl-selection-list">
      {Object.keys(selections).map((key, i) => {
        return (
          <HeaderAndSelectedText
            key={i}
            data={{ [key]: selections[key] }}
            optionClicked={(_key) => optionClicked && optionClicked(_key, key)}
          />
        );
      })}
    </div>
  );
};

SlSelectionList.propTypes = {
  selections: PropTypes.object.isRequired,
  optionClicked: PropTypes.func.isRequired,
};

export { SlSelectionList };
