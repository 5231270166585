import React from "react";
import PropTypes from "prop-types";
import { SlText } from "../Text/Text";

import "./Item.scss";
import { Link } from "react-router-dom";
import { useClassNames } from "../../../../../hooks/useClassNames";

const SlSeparator = () => <div className="sl-separator"></div>;

export { SlSeparator };

const SlLinkItem = (props) => {
  if (props.to !== null) {
    return (
      <Link to={props.to} className="sl-link-item">
        {props.children}
      </Link>
    );
  } else {
    return <>{props.children}</>;
  }
};

const SlItem = ({
  children,
  onClick,
  size,
  emphasis,
  inactive,
  backgroundColor,
  start,
  end,
  className,
  leftPadding = 0,
  to,
  hasFocus,
  classes,
  style,
}) => {
  let styles = style || {};

  if (backgroundColor) {
    styles.backgroundColor = backgroundColor;
  }

  if (size === "full") {
    styles.width = "100%";
  }

  if (start || end) {
    styles.display = "flex";
    styles.flexDirection = "row";
    styles.justifyContent = end ? "space-between" : "flex-start";
    styles.alignItems = "center";
  } else {
    styles.display = "block";
  }

  let classNames =
    `sl-item` +
    `${children && children.type === React.Fragment ? " multiline" : ""}` +
    `${onClick ? " actionable" : ""}` +
    `${to ? " actionable" : ""}` +
    `${hasFocus ? " hasFocus" : ""}` +
    `${className ? ` ${className}` : ""}`;

  const classnames = useClassNames(
    {
      itemContent: "sl-item-content",
      itemText: "sl-item-text",
      itemInput: "sl-item-input",
      p7Destressed: "sl-item-text-paragraph",
    },
    classes
  );

  return (
    <SlLinkItem to={to ? to : null}>
      <div className={classNames} onClick={onClick}>
        <div className={classnames.itemContent} style={styles}>
          {start && (
            <span className="sl-item-start" style={{ marginLeft: leftPadding }}>
              {start}
            </span>
          )}
          {
            /* for input fields, let the child style the text inside */
            hasFocus && <span className={classnames.itemInput}>{children}</span>
          }
          {!hasFocus && (
            <span className={classnames.itemText}>
              {!inactive && !emphasis && (
                <SlText p7 classNames={classnames.p7Destressed}>
                  {children}
                </SlText>
              )}
              {inactive && (
                <SlText p7 destressed>
                  {children}
                </SlText>
              )}
              {emphasis && <SlText subtitle4m>{children}</SlText>}
            </span>
          )}
          {end && end}
        </div>
      </div>
    </SlLinkItem>
  );
};

SlItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  size: PropTypes.string,
  emphasis: PropTypes.bool,
  backgroundColor: PropTypes.string,
  start: PropTypes.element,
  end: PropTypes.element,
};

export { SlItem };
