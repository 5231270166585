import React from "react";

import { getSphereName } from "../../../utils/environments";
import FormattedMessage from "../../../translations/FormattedMessage";

function MenuSphere() {
  return (
    <div className="signal-men-sphere">
      <FormattedMessage id="userMenu.sphere" />
      {getSphereName()}
    </div>
  );
}

export default MenuSphere;
