const PREDICT_SUMMARY_DATA_QUERY = (id) => `query PredictSummaryDataQuery {
	signal_p_monitored_locations_aggregate(args: {p_partner_id: "${id}"}) {
	  aggregate {
	    sum {
	      count
	    }
	  }
	}
	predict_p_call_volume_prediction_24hour_aggregate(args: {p_partner_id: "${id}"}) {
	  aggregate {
	    sum {
	      count
	    }
	  }
	}
	pipelineTimestamp24Hour: predict_p_pipeline_run_timestamp(args: {p_name: "call_volume_prediction_24hour"}) {
	  ts
	}
	predict_p_call_volume_prediction_7day_aggregate(args: {p_partner_id: "${id}"}) {
	  aggregate {
	    sum {
	      count
	    }
	  }
	}
	pipelineTimestamp7Day: predict_p_pipeline_run_timestamp(args: {p_name: "call_volume_prediction_7day"}) {
	  ts
	}
      }`;

const LOCATIONS_MONITORED_QUERY = (
  id
) => `query LocationsMonitoredAggregateQuery {
	signal_p_monitored_locations_aggregate(args: {p_partner_id: "${id}"}) {
	  aggregate {
	    sum {
	      count
	    }
	  }
	}
}`;

const CALL_VOLUME_PREDICTION_PERIODS_QUERY = (
  partnerId
) => `query CallInVolumePeriodsQuery {
  callInVolumePeriods(partnerId:"${partnerId}") {
      periodStart,
      periodEnd,
      value,
      data {
          labelId,
          value,
      },
  }
}`;

const CALL_VOLUME_PREDICTION_24HOUR_AGGREGATE_QUERY = (
  id
) => `query CallVolumePrediction24HourAggregateQuery {
	predict_p_call_volume_prediction_24hour_aggregate(args: {p_partner_id: "${id}"}) {
	  aggregate {
	    sum {
	      count
	    }
	  }
	}
	predict_p_pipeline_run_timestamp(args: {p_name: "call_volume_prediction_24hour"}) {
	  ts
	}
}`;

const CALL_VOLUME_PREDICTION_7DAY_AGGREGATE_QUERY = (
  id
) => `query CallVolumePrediction7DayAggregateQuery {
	predict_p_call_volume_prediction_7day_aggregate(args: {p_partner_id: "${id}"}) {
	  aggregate {
	    sum {
	      count
	    }
	  }
	}
	predict_p_pipeline_run_timestamp(args: {p_name: "call_volume_prediction_7day"}) {
	  ts
	}
}`;

const HISTORICAL_TREND_QUERY = (partnerId, periodStart, periodEnd) => `
query HistoricalTrendDataQuery {
	 historicalTrend(partnerId:"${partnerId}", periodStart:"${periodStart.toISOString()}", periodEnd:"${periodEnd.toISOString()}") {
    days {
      day,
      data {
        labelId,
        value,
      }
    }
  }
}`;

export {
  PREDICT_SUMMARY_DATA_QUERY,
  LOCATIONS_MONITORED_QUERY,
  CALL_VOLUME_PREDICTION_24HOUR_AGGREGATE_QUERY,
  CALL_VOLUME_PREDICTION_7DAY_AGGREGATE_QUERY,
  CALL_VOLUME_PREDICTION_PERIODS_QUERY,
  HISTORICAL_TREND_QUERY,
};
