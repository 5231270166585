import { useCallback } from "react";
import { useSelector } from "react-redux";
import { featureFlagsConfiguration } from "../config";

export const useFeatureFlag = (featureName) => {
  const me = useSelector((state) => state.me);
  return useCallback(() => {
    try {
      return Boolean(featureFlagsConfiguration[featureName].includes(me.email));
    } catch (error) {
      return false;
    }
  }, [me, featureName]);
};
