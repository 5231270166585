import React from "react";
import { SlText } from "../Text/Text";

import "./Chip.scss";

const SlChip = ({ children, color, fill, className }) => {
  let classNames = [];

  if (color) {
    classNames.push(`sl-color--${color}`);
  } else {
    classNames.push(`sl-color--primary`);
  }

  if (fill === "outline") {
    classNames.push(`sl-chip--outline`);
  }

  if (fill === "full") {
    classNames.push(`sl-chip--full`);
  }

  if (className) {
    classNames.push(className);
  }

  return <div className={["sl-chip", ...classNames].join(" ")}>{children}</div>;
};

export { SlChip };
