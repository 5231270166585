import React, { useCallback } from "react";
import PropTypes from "prop-types";

import { SlSlider } from "../../Atoms/Slider/Slider";
import { useRangeSliderThrottling } from "../../../../../hooks/useRangeSliderThrottling";
import { SlButton } from "../../../communal/Atoms/Button/Button";
import { SlText } from "../../../communal/Atoms/Text/Text";
import cx from "classnames";
import FormattedMessage from "../../../../../translations/FormattedMessage";

import "./RangeSlider.scss";

const SlRangeSlider = ({
  value,
  onChange,
  showUndoButton = true,
  labelAdditionalClassName = "",
  valueFormatter,
  isDisabled,
  ...sliderSettings
}) => {
  const {
    onValueChanged,
    onUndo,
    currentValue,
    isUndoable,
    ...sliderControlEvents
  } = useRangeSliderThrottling({
    initialValue: value,
    onUpdate: onChange,
  });

  const formattedValue = useCallback(
    () => (valueFormatter ? valueFormatter(currentValue) : currentValue),
    [currentValue, valueFormatter]
  );

  const classes = cx("sl-range-slider", {
    "sl-range-slider--disabled": isDisabled,
  });

  return (
    <div className={classes}>
      <div className="sl-range-slider-pill">
        <SlText
          subtitle4
          classNames={`sl-range-slider-label ${labelAdditionalClassName}`}
        >
          {formattedValue()}
        </SlText>
        <SlSlider
          {...sliderSettings}
          value={currentValue}
          isDisabled={isDisabled}
          {...sliderControlEvents}
          handleValueChange={onValueChanged}
        />
      </div>
      {showUndoButton && (
        <div className="sl-range-slider-undo">
          <SlButton
            disabled={!isUndoable || isDisabled}
            onClick={() => onUndo()}
          >
            <SlText subtitle4>
              <FormattedMessage id="undo" />
            </SlText>
          </SlButton>
        </div>
      )}
    </div>
  );
};

SlRangeSlider.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  showUndoButton: PropTypes.bool,
  isDisabled: PropTypes.bool,
  valueFormatter: PropTypes.func,
};

export { SlRangeSlider };
