import { useMemo } from "react";
import * as d3 from "d3";

const translation = {
  x: 450,
  y: 350,
};

const TooltipDirection = {
  Top: "top",
  Right: "right",
  Bottom: "bottom",
  Left: "left",
};

export const usePawGraphShapes = (
  items,
  configuration = {
    totalWidth: 800,
    totalHeight: 800,
    margin: 24,
  }
) => {
  const total = useMemo(() => {
    return items.reduce((acc, current) => acc + current.value, 0);
  }, [items]);
  const { totalWidth, totalHeight, margin } = configuration;
  const scale = d3.scaleSqrt().domain([0, total]).range([30, 300]);
  const radiusScale = d3.scaleLinear().domain([0, total]).range([35, 150]);
  const largest = items[0];
  const largestValue = scale(largest.value);

  const getStartingPoint = () => {
    return {
      x: totalWidth / 2 - largestValue / 2 + 50,
      y: totalHeight / 2 - largestValue / 2,
    };
  };

  const getArcLength = () => {
    const clone = [...items];
    delete clone[0];
    return scale(items.reduce((acc, item) => (acc += item.value), 0)) / 1.5;
  };

  const getAdjacentCircles = () => {
    const clone = [...items];
    delete clone[0];
    const mappedItems = clone.map((item) => ({
      ...item,
      value: scale(item.value),
      realValue: item.value,
    }));
    return mappedItems.reverse().filter((i) => i !== undefined);
  };

  const getPercentage = (value) => ((value / total) * 100).toFixed(2);

  const getArcDescriptor = () => {
    const adjacent = getAdjacentCircles().reverse();
    const diag = (largestValue * Math.sqrt(2)) / 2;

    return {
      x: getStartingPoint().x,
      y: getStartingPoint().y,
      radius: diag / 2 + adjacent[0].value,
    };
  };

  const getRectangles = () => {
    const first = [
      {
        x: getStartingPoint().x,
        y: getStartingPoint().y,
        textPosition: {
          x: getStartingPoint().x + largestValue / 2,
          y: getStartingPoint().y + largestValue / 2,
        },
        tooltipPosition: {
          x: getStartingPoint().x,
          y: getStartingPoint().y + largestValue + 24,
          direction: TooltipDirection.Bottom,
        },
        size: largestValue,
        borderRadius: radiusScale(largest.value),
        color: largest.color,
        value: largest.value,
        label: `${largest.label} (${getPercentage(largest.value)}%)`,
      },
    ];
    const arcradius = getArcDescriptor().radius;
    const getN = () => (Math.PI * 2 * arcradius) / getArcLength();
    const rest = getAdjacentCircles().map((item, index) => {
      const ang = (Math.PI * 2 * (index - 2)) / getN();
      const sin = Math.sin(ang + Math.PI);
      const cos = Math.cos(ang + Math.PI);
      const cx = arcradius * sin - 80;
      const cy = arcradius * cos - 30;

      const getPosition = () => {
        if (sin > 0 && cos > 0) {
          // right
          return {
            x: cx + translation.x + item.value + margin,
            y: cy + translation.y,
            direction: TooltipDirection.Right,
          };
        } else if (sin > 0 && cos < 0) {
          // top
          return {
            x: cx + translation.x,
            y: cy + translation.y - item.value - margin,
            direction: TooltipDirection.Top,
          };
        } else {
          // left
          return {
            x: cx + translation.x - item.value - margin,
            y: cy + translation.y,
            direction: TooltipDirection.Left,
          };
        }
      };
      const tooltipPosition = getPosition();
      return {
        x: cx,
        y: cy,
        textPosition: {
          x: cx + translation.x + item.value / 2,
          y: cy + translation.y + item.value / 2,
        },
        tooltipPosition,
        size: item.value,
        borderRadius: radiusScale(item.realValue),
        color: item.color,
        value: item.realValue,
        label: `${item.label} (${getPercentage(item.realValue)}%)`,
      };
    });
    return [...first, ...rest];
  };

  return {
    rectangles: getRectangles(),
    svgDescriptor: {
      width: totalWidth,
      height: totalHeight,
      translation,
    },
  };
};
