import { getdata } from "../AsynchActions";
import {
  alertDataQuery,
  alertTicketQuery,
  partnerInfo,
} from "../graphQL_queries/alertQuery";
import { setFiltersFromLocations } from "./alertLASActions";

export const CHANGE_ALERT_TIME = "CHANGE_ALERT_TIME";
export const CHANGE_ALERT_TIME_SUCCESS = "CHANGE_ALERT_TIME_SUCCESS";

export const TOGGLE_TICKET = "TOGGLE_TICKET";

export const GET_DEMO_DATA_SUCCESS = "GET_DEMO_DATA_SUCCESS";
export const GET_DEMO_TICKET_SUCCESS = "GET_DEMO_TICKET_SUCCESS";
export const GET_DEMO_TICKET_FAIL = "GET_DEMO_TICKET_FAIL";
export const GET_DEMO_DATA_FAIL = "GET_DEMO_DATA_FAIL";

export const SWITCH_CHART_UNIT = "SWITCH_CHART_UNIT";
export const CHANGE_CHART_UNIT = "CHANGE_CHART_UNIT";
export const SET_ALERT_LOCATION_DATA = "SET_ALERT_LOCATION_DATA";

export const ON_FETCH_ALERT_DATA = "ON_FETCH_ALERT_DATA";
export const ON_FETCH_ALERT_DATA_SUCCESS = "ON_FETCH_ALERT_DATA_SUCCESS";
export const ON_FETCH_ALERT_DATA_FAIL = "ON_FETCH_ALERT_DATA_FAIL";

export const ON_FETCH_TICKET_DATA = "ON_FETCH_TICKET_DATA";
export const ON_FETCH_TICKET_DATA_SUCCESS = "ON_FETCH_TICKET_DATA_SUCCESS";
export const ON_FETCH_TICKET_DATA_FAIL = "ON_FETCH_TICKET_DATA_FAIL";
export const SET_SELECTED_LOCATION = "SET_SELECTED_LOCATION";

export const RESET_ALERT = "RESET_ALERT";

export const resetAlert = (payload) => {
  return {
    payload,
    type: RESET_ALERT,
  };
};

export const onFetchAlertData = (payload) => {
  return {
    payload,
    type: ON_FETCH_ALERT_DATA,
  };
};

export const getAlertDataSuccess = (payload) => {
  return {
    payload,
    type: ON_FETCH_ALERT_DATA_SUCCESS,
  };
};

export const getAlertDataFail = () => {
  return {
    type: ON_FETCH_ALERT_DATA_FAIL,
  };
};

export const changeAlertTimeSuccess = (id, time) => {
  return {
    type: CHANGE_ALERT_TIME_SUCCESS,
    payload: {
      time,
      name: id,
    },
  };
};

export const changeAlertTime = (params) => {
  return (dispatch, getState) => {
    const willBeHourly = params.time === "24 hours";
    const willBeDaily = params.time !== "24 hours";

    params.period = willBeHourly ? "hourly" : "daily";

    if (willBeDaily && !getState().alert[params.type].daily.length) {
      dispatch(alertQuery(params));
    }

    if (willBeHourly && !getState().alert[params.type].hourly.length) {
      dispatch(alertQuery(params));
    }

    dispatch(changeAlertTimeSuccess(params.type, params.time));
  };
};

export const changeAlertTimeSelectedTicket = (params) => {
  return (dispatch, getState) => {
    const willBeHourly = params.time === "24 hours";
    const willBeDaily = params.time !== "24 hours";

    params.period = willBeHourly ? "hourly" : "daily";

    if (willBeDaily && !getState().alert.selectedTicket.daily.length) {
      dispatch(getTicket(params));
    }

    if (willBeHourly && !getState().alert.selectedTicket.hourly.length) {
      dispatch(getTicket(params));
    }

    dispatch(changeAlertTimeSuccess("selectedTicket", params.time));
  };
};

export const toggleTicket = (name) => {
  return {
    type: TOGGLE_TICKET,
    payload: {
      name,
    },
  };
};

export const alertQuery = getdata(
  onFetchAlertData,
  getAlertDataSuccess,
  getAlertDataFail,
  alertDataQuery
);

export const getPartnerInfo = getdata(
  onFetchAlertData,
  getAlertDataSuccess,
  getAlertDataFail,
  partnerInfo
);

export const onFetchTicketData = () => {
  return {
    type: ON_FETCH_TICKET_DATA,
  };
};

export const preloadAlertData = () => {
  return (dispatch, getState) => {
    if (!getState().alert.loaded) {
      dispatch(resetAlert());
      dispatch(getPartnerInfo());
      dispatch(alertQuery({ type: "CSP Outage", period: "hourly" }));
      dispatch(alertQuery({ type: "Evil Twin", period: "daily" }));
      dispatch(
        alertQuery({ type: "Total Locations Offline", period: "hourly" })
      );
      dispatch(
        alertQuery({ type: "Locations Offline Outage", period: "hourly" })
      );
    }
  };
};

export const getTicketDataSuccess = (payload) => {
  return {
    payload,
    type: ON_FETCH_TICKET_DATA_SUCCESS,
  };
};

export const getTicketDataFail = () => {
  return {
    type: ON_FETCH_TICKET_DATA_FAIL,
  };
};

export const setSelectedLocation = (payload) => {
  return {
    payload,
    type: SET_SELECTED_LOCATION,
  };
};

export const getTicket = getdata(
  onFetchTicketData,
  [setFiltersFromLocations, getTicketDataSuccess],
  getTicketDataFail,
  alertTicketQuery
);

export const switchChartUnit = (payload) => {
  return {
    payload,
    type: SWITCH_CHART_UNIT,
  };
};

export const changeChartUnit = (payload) => {
  return {
    payload,
    type: CHANGE_CHART_UNIT,
  };
};

export const setAlertLocationData = (data) => ({
  type: SET_ALERT_LOCATION_DATA,
  payload: {
    data,
  },
});
