import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";
import { Col, Row } from "react-flexbox-grid";
import { SlHeader } from "../../communal/Molecules/BlockHeader/Header";
import { SlButton } from "../../communal/Atoms/Button/Button";
import { SlPanelLoader } from "../../communal/Atoms/PanelLoader/PanelLoader";
import { SlHorizontalBar } from "../../communal/Atoms/Charts/HorizontalBar/HorizontalBar";
import {
  getLocationsData,
  resetLocation,
} from "../../../../actions/location/locationActions";
import LocationHistoryMessage from "./LocationHistoryMessage";
import SearchAndSearchFilterMenu from "../../communal/Molecules/Table/SearchAndSearchFilterMenu";
import { SlText } from "../../communal/Atoms/Text/Text";
import FormattedMessage from "../../../../translations/FormattedMessage";
import { useTranslation } from "react-i18next";
import { SlIcon } from "../../communal/Atoms/Icon/Icon";
import InfoIcon from "../../../../assets/svg/icon-info.svg";
import "./monitorLocationHistory.scss";

function MonitorLocationHistory() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const locationHistory = useSelector((state) => {
    return state.location.locations;
  });

  const onSearch = (locationId) => {
    if (locationId) {
      dispatch(
        getLocationsData({ locationId: locationId.replaceAll(" ", "") })
      );
    }
  };

  const [locationId, setLocationId] = useState("");

  useEffect(() => {
    dispatch(resetLocation());
  }, [dispatch]);

  return (
    <div className="monitor-location-history">
      <ReactTooltip
        place="right"
        effect="solid"
        backgroundColor="#606060"
        className="section-header-tooltip"
      ></ReactTooltip>
      <SlHeader
        start={
          <SlText titleSignal>
            <FormattedMessage id="monitor.locationHistory.sectionTitle" />
          </SlText>
        }
        horizontalLine={true}
        bottomMargin={true}
      >
        <div
          data-html={true}
          data-tip={ReactDOMServer.renderToStaticMarkup(
            <SlText p7>
              <FormattedMessage id="monitor.locationHistory.sectionTooltip" />
            </SlText>
          )}
        >
          <SlIcon width={26} height={26} svgFile={InfoIcon} />
        </div>
      </SlHeader>

      <Row className="mon-search-box">
        <Col sm={10}>
          <SearchAndSearchFilterMenu
            placeHolderText={t("monitor.locationHistory.inputPlaceholderText")}
            searchAction={(e) => {
              setLocationId(e);
            }}
          />
        </Col>
        <Col sm={2}>
          <SlButton
            backgroundOpacity={0.1}
            backgroundOpacityHighlighted={0.2}
            size={"full"}
            onClick={() => {
              onSearch(locationId);
            }}
          >
            <SlText subtitle4m>
              <FormattedMessage id="search" />
            </SlText>
          </SlButton>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          {locationHistory.loading ? (
            <SlPanelLoader />
          ) : (
            locationHistory.loaded && (
              <LocationHistoryTemplate locationHistory={locationHistory} />
            )
          )}
        </Col>
      </Row>
    </div>
  );
}

const LocationHistoryTemplate = ({ locationHistory }) => {
  const { t } = useTranslation();
  const dataLength =
    locationHistory.partner.locationAlarmHistory.offlineNetwork.length +
    locationHistory.partner.locationAlarmHistory.unstableNetwork.length +
    locationHistory.partner.locationAlarmHistory.evilTwin.length +
    locationHistory.partner.locationAlarmHistory.ispOutage.length;

  if (dataLength === 0) {
    return <LocationHistoryMessage />;
  }

  const bars = [
    {
      labelId: "monitor.KPIs.offlineNetwork",
      data: locationHistory.partner.locationAlarmHistory.offlineNetwork,
    },
    {
      labelId: "monitor.KPIs.unstableNetwork",
      data: locationHistory.partner.locationAlarmHistory.unstableNetwork,
    },
    {
      labelId: "monitor.KPIs.evilTwin",
      data: locationHistory.partner.locationAlarmHistory.evilTwin,
    },
    {
      labelId: "monitor.KPIs.cspOutage",
      data: locationHistory.partner.locationAlarmHistory.ispOutage,
    },
  ];

  return (
    <>
      {bars.map((item) => (
        <SlHorizontalBar
          key={item.labelId}
          label={t(item.labelId)}
          data={item.data}
          timeframe={168}
          withAxis={item.labelId === "monitor.KPIs.cspOutage"}
        />
      ))}
    </>
  );
};

export default MonitorLocationHistory;
