import React from "react";

function SolvePage() {
  return (
    <div style={{ margin: "50px", textAlign: "center", color: "white" }}>
      Solve Dashboard Coming Soon
    </div>
  );
}

export default SolvePage;
