import { CHANGE_SELECTED_CHART } from "../actions/solveRingCharts_action";

const INIT_STATE = {
  ringCharts: [
    { title: "Pod Placement", selected: true },
    { title: "CSP Outage", selected: false },
    { title: "Parallel Networks", selected: false },
  ],
};

export default function ringChartsReducer(
  state = INIT_STATE,
  action = { type: null }
) {
  switch (action.type) {
    case CHANGE_SELECTED_CHART:
      return {
        ...state,
        ...{
          ringCharts: state.ringCharts.map((d, i) => {
            if (i === action.payload.index) {
              d.selected = true;
              return d;
            }
            d.selected = false;
            return d;
          }),
        },
      };
    default:
      return state;
  }
}
