import { format } from "d3-format";
import i18n from "./../../../../../translations/i18n";

export const formatTime = (value) => {
  if (value < 60 * 60) {
    return i18n.t("timeSelector.minute", { count: value / 60 });
  }
  if (value % (60 * 60) === 0) {
    return i18n.t("timeSelector.hour", { count: value / (60 * 60) });
  }
  const minutes = value % (60 * 60);
  const hoursMessage = i18n.t("timeSelector.hour", {
    count: Math.floor(value / (60 * 60)),
  });
  const minutesMessage = i18n.t("timeSelector.minute", { count: minutes / 60 });
  return `${hoursMessage} ${minutesMessage}`;
};

export const formatPercentage = (value) => {
  return format(".0%")(value / 100);
};
