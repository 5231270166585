import { VERSION_TWO_AUTHENTICATED_SUCCESS } from "../actions/v2Actions";

const INIT_STATE = {
  v2_auth: false,
};

function v2Reducer(state = INIT_STATE, action = { type: null }) {
  switch (action.type) {
    case VERSION_TWO_AUTHENTICATED_SUCCESS:
      return { ...state, v2_auth: true };
    default:
      return state;
  }
}

export default v2Reducer;
