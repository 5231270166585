import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "react-router";
import MonitorDash from "../components/MonitorDash";
import PredictPage from "../components/PredictPage/PredictPage";
import SolvePage from "../components/SolvePage";

import "./secure.scss";

import ConfigTempPage from "../components/NewAdminPage/Template/ConfigTempPage";

import SecureRoute from "./SecureRoute";
import DataError from "../components/communal/Atoms/DataError";
import { TicketPage } from "../components/TicketPage/TicketPage";
import RedirectMonitor from "../components/communal/Atoms/RedirectCustom/RedirectMonitor";
import { CsvPage } from "../components/TicketPage/csv/CsvPage";
import { HIDE_ADMIN_MENU } from "../../utils/constants";

import { LocationsPage } from "../components/LocationsPage/LocationsPage";
import { AlertPage } from "../components/AlertPage/AlertPage";
import { SlPanelLoader } from "../components/communal/Atoms/PanelLoader/PanelLoader";
import { SlCenter } from "../components/communal/Atoms/Center/Center";

import TextAndRedirect from "../components/communal/Atoms/TextAndRedirect";
import { getUserAndPartners } from "../../actions/meActions";
import { Routes } from "../../config";

function SecureV2() {
  const dispatch = useDispatch();
  const loadedWithErrors = useSelector((state) => state.main.data_errors_type);
  const loadingData = useSelector((state) => state.main.loading_data);

  useEffect(() => {
    dispatch(getUserAndPartners());
  }, [dispatch]);

  if (loadedWithErrors?.length && !loadedWithErrors.includes("kappa")) {
    return (
      <SlCenter>
        <DataError />
      </SlCenter>
    );
  }

  if (loadingData) {
    return <SlPanelLoader />;
  }

  return (
    <Switch>
      <SecureRoute path={Routes.Index} exact component={RedirectMonitor} />
      <SecureRoute path={Routes.CsvPage} exact component={CsvPage} />
      <SecureRoute path={Routes.Monitor} exact component={MonitorDash} />
      <SecureRoute path={Routes.TicketPage} exact component={TicketPage} />
      <SecureRoute path={Routes.AlertWithPartnerId} component={AlertPage} />
      <SecureRoute path={Routes.Alert} exact component={AlertPage} />
      <SecureRoute path={Routes.AlertReal} exact component={AlertPage} />
      <SecureRoute path={Routes.Predict} exact component={PredictPage} />
      <SecureRoute path={Routes.Solve} exact component={SolvePage} />
      {!HIDE_ADMIN_MENU && (
        <SecureRoute path={Routes.Admin} exact component={ConfigTempPage} />
      )}
      <SecureRoute path={Routes.Locations} exact component={LocationsPage} />
      <SecureRoute
        component={() => (
          <TextAndRedirect
            time={1000}
            text={"404 url not found redirecting to monitor page..."}
            redirectURL={"/monitor"}
          />
        )}
      />
    </Switch>
  );
}

export default SecureV2;
