import React, { useState, useEffect } from "react";

import moment from "moment";
import "./HorizontalBar.scss";
import { SlTooltip } from "../../Tooltip/Tooltip";

const SlHorizontalBar = ({ label, timeframe, data, withAxis }) => {
  const [dateMap, setDateMap] = useState([]);

  const hour = (modifier) => {
    const d = new Date();
    d.setHours(d.getHours() + modifier);
    return d.setMinutes(0, 0, 0);
  };

  const setTimeFrame = () => {
    let _dateMap = [];
    Array.from(Array(timeframe)).forEach((item, i) => {
      _dateMap = [..._dateMap, hour(-i)];
    });
    setDateMap(_dateMap.reverse());
  };

  const getClass = (timestamp) => {
    const matchingNotificaton = data.find(
      (item) => timestamp >= item.from && timestamp <= item.to
    );
    return matchingNotificaton
      ? `sl-horizontal-bar__inner__segment--${matchingNotificaton.type}`
      : null;
  };

  const renderLabel = (data, i) => {
    let date = moment(data[i]);
    return date.hour() === 0;
  };

  useEffect(() => {
    setTimeFrame();
    // Disable eslint here see https://github.com/facebook/react/issues/15084
    // eslint-disable-next-line
  }, [timeframe]);

  const xAxis = (
    <div className={"x-axis"}>
      {dateMap.map((item, i) => (
        <div style={{ width: `${100 / timeframe}%` }} key={i}>
          {renderLabel(dateMap, i) && (
            <>
              <span className="x-axis__tick"></span>
              <span className="x-axis__date">
                {moment(item).format("dddd")}
              </span>
              <span className="x-axis__day">{moment(item).format("l")}</span>
            </>
          )}
        </div>
      ))}
    </div>
  );

  const template = (
    <div
      className={`sl-horizontal-bar-container ${
        withAxis ? "sl-horizontal-bar-container--with-axis" : ""
      }`}
    >
      <div className="sl-horizontal-bar">
        <div className="sl-horizontal-bar__label">{label}</div>

        <div className="sl-horizontal-bar__inner">
          {dateMap.map((item, i) => (
            <div
              key={i}
              className={`sl-horizontal-bar__inner__segment ${getClass(item)}`}
              style={{ width: `${100 / timeframe}%` }}
            >
              <SlTooltip>
                <span>{moment(item).format("MMMM Do, YYYY")}</span>
                <span>{moment(item).format("h:mm:ss a")}</span>
              </SlTooltip>
            </div>
          ))}
        </div>
      </div>
      {withAxis ? xAxis : null}
    </div>
  );

  return data.length >= 0 ? template : null;
};

export { SlHorizontalBar };
