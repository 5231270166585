import { getOktaToken } from "../helpers/Token/Token";
import { GraphQLClient } from "graphql-request";

export default class GraphQLService {
  constructor() {
    this.oktaToken = getOktaToken(localStorage);
  }
  getClientWithToken(endpoint) {
    return new GraphQLClient(endpoint, {
      headers: { Authorization: `Bearer ${this.oktaToken}` },
    });
  }
}
