import React from "react";

import "./tableHeaders.scss";

const TableHeaders = ({ headers }) => (
  <thead className="Sl-table-header">
    <tr>
      {headers.map((h) => (
        <th scope="col">{h}</th>
      ))}
    </tr>
  </thead>
);

export default TableHeaders;
