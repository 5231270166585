import producer from "immer";
import { createSelector } from "reselect";
import {
  CHANGE_KPI_CHART_TIME,
  CHANGE_Y_AXIS,
  CLOSE_CHANGE_MENU,
  FETCHING_NMHC_DATA,
  MONITOR_NMHC_DATA_ERR,
  MONITOR_NMHC_DATA_RECIEVED,
  SHOW_CHANGE_MENU,
  SHOW_HIDE_MORE_MENU,
  SHOW_OPTIONS,
  CHANGE_KPI_OPTION_SELECTION,
} from "../actions/monNHMCActions";

import { mapKPINameToData, parseDateDaily, sortDates } from "./helpers";

export const INIT_STATE_NORM = {
  data: null,
  dataLoading: true,
  data_error: false,
  KPIs: {
    byName: {
      "CSP Outage": {
        name: "CSP Outage",
        labelId: "monitor.KPIs.cspOutage",
        chart: "TrendLine",
        data: null,
        time: "30 Day",
        times: {
          "30 Day": true,
          "7 Day": false,
          "24 Hr": false,
        },
        options: {
          "Change Chart": {
            changemenuOpen: false,
            options: {
              TrendLine: true,
              BarChart: false,
            },
          },
          "Y Axis Measure": {
            changemenuOpen: false,
            options: {
              Percentage: true,
              Number: false,
            },
          },
        },
        option_open: false,
      },
      "Evil Twin": {
        name: "Evil Twin",
        labelId: "monitor.KPIs.evilTwin",
        chart: "TrendLine",
        data: null,
        time: "30 Day",
        times: {
          "30 Day": true,
          "7 Day": false,
        },
        options: {
          "Change Chart": {
            changemenuOpen: false,
            options: {
              TrendLine: true,
              BarChart: false,
            },
          },
          "Y Axis Measure": {
            changemenuOpen: false,
            options: {
              Percentage: true,
              Number: false,
            },
          },
        },
        option_open: false,
      },
      "Unstable Network": {
        name: "Unstable Network",
        labelId: "monitor.KPIs.unstableNetwork",
        chart: "TrendLine",
        data: null,
        time: "30 Day",
        times: {
          "30 Day": true,
          "7 Day": false,
          "24 Hr": false,
        },
        options: {
          "Change Chart": {
            changemenuOpen: false,
            options: {
              TrendLine: true,
              BarChart: false,
            },
          },
          "Y Axis Measure": {
            changemenuOpen: false,
            options: {
              Percentage: true,
              Number: false,
            },
          },
        },
        option_open: false,
      },
      "Offline Network": {
        name: "Offline Network",
        labelId: "monitor.KPIs.offlineNetwork",
        chart: "TrendLine",
        data: null,
        time: "30 Day",
        times: {
          "30 Day": true,
          "7 Day": false,
          "24 Hr": false,
        },
        options: {
          "Change Chart": {
            changemenuOpen: false,
            options: {
              TrendLine: true,
              BarChart: false,
            },
          },
          "Y Axis Measure": {
            changemenuOpen: false,
            options: {
              Percentage: true,
              Number: false,
            },
          },
        },
        option_open: false,
      },
    },
  },
};

//pruduce will curry an object

const monitorKPIReducer = producer((draftState, action = { type: null }) => {
  switch (action.type) {
    case FETCHING_NMHC_DATA:
      draftState.dataLoading = true;
      draftState.data_error = false;
      return;
    case MONITOR_NMHC_DATA_ERR:
      draftState.data_error = true;
      draftState.dataLoading = false;
      return;
    case MONITOR_NMHC_DATA_RECIEVED:
      draftState.dataLoading = false;
      draftState.data = action.payload.data;
      Object.keys(draftState.KPIs.byName).forEach((kpi) => {
        draftState.KPIs.byName[kpi].data =
          action.payload.data.partner[mapKPINameToData[kpi]];
      });

      return;
    case CHANGE_KPI_CHART_TIME:
      Object.keys(draftState.KPIs.byName[action.payload.name].times).forEach(
        (time) => {
          draftState.KPIs.byName[action.payload.name].times[time] =
            time === action.payload.time;
        }
      );
      draftState.KPIs.byName[action.payload.name].time = action.payload.time;
      return;
    case CHANGE_Y_AXIS:
      if (action.payload.bool) {
        draftState.KPIs.byName[action.payload.name].options[
          "Y Axis Measure"
        ].options.Percentage = true;

        draftState.KPIs.byName[action.payload.name].options[
          "Y Axis Measure"
        ].options.Number = false;
      } else {
        draftState.KPIs.byName[action.payload.name].options[
          "Y Axis Measure"
        ].options.Percentage = false;

        draftState.KPIs.byName[action.payload.name].options[
          "Y Axis Measure"
        ].options.Number = true;
      }

      return;
    case SHOW_HIDE_MORE_MENU:
      draftState.KPIs.byName[action.payload.name].option_open = !draftState.KPIs
        .byName[action.payload.name].option_open;
      return;
    case SHOW_OPTIONS:
      draftState.KPIs.byName[action.payload.name].option_open = true;
      return;
    case CHANGE_KPI_OPTION_SELECTION:
      Object.keys(
        draftState.KPIs.byName[action.payload.name].options[
          action.payload.section
        ].options
      ).forEach((option) => {
        draftState.KPIs.byName[action.payload.name].options[
          action.payload.section
        ].options[option] = option === action.payload.option;
      });
      draftState.KPIs.byName[action.payload.name].option_open = false;
      return;

    case SHOW_CHANGE_MENU:
      Object.keys(draftState.KPIs.byName[action.payload.name].options).forEach(
        (option) => {
          draftState.KPIs.byName[action.payload.name].options[
            option
          ].changemenuOpen = false;
        }
      );

      draftState.KPIs.byName[action.payload.name].options[
        action.payload.option
      ].changemenuOpen = true;
      return;

    case CLOSE_CHANGE_MENU:
      Object.keys(draftState.KPIs.byName[action.payload.name].options).forEach(
        (option) => {
          draftState.KPIs.byName[action.payload.name].options[
            option
          ].changemenuOpen = false;
        }
      );
      return;
    default:
      break;
  }
}, INIT_STATE_NORM);

//selectors

export const nhmcRawDataSelector = (state) => state.data;
export const kpiNameSelector = (state, ownProps) => ownProps.name;

export const getKPIDataByName = (state, ownProps) => {
  return state.KPIs.byName[ownProps.name].data;
};

export const getKPIByName = (state, ownProps) =>
  state.KPIs.byName[ownProps.name];

export const getKPIdata = (state, ownProps) =>
  state.KPIs.byName[ownProps.name].data;
export const getKPIChartTime = (state, ownProps) =>
  state.KPIs.byName[ownProps.name].time;
export const getKPIName = (state, ownProps) => ownProps.name;
export const getKPITotalPctAlarm = createSelector(
  [getKPIDataByName],
  (data) => {
    return data.totalPctAlarm === null ? 0 : data.totalPctAlarm;
  }
);

export const getKPIOptions = (state, ownProps) => {
  const kpi = state.KPIs.byName[ownProps.name];
  return kpi.options;
};

export const getChartOptions = (state, ownProps) => {
  const kpi = state.KPIs.byName[ownProps.name];
  return kpi.options["Change Chart"].options;
};

export const getChartOptionYatt = (state, ownProps) => {
  const kpi = state.KPIs.byName[ownProps.name];
  return kpi.options["Y Axis Measure"].options;
};
export const getChartType = createSelector(
  [getChartOptions],
  (chartOptions) => {
    let chart;
    Object.keys(chartOptions).forEach((key) => {
      if (chartOptions[key]) chart = key;
    });
    return chart;
  }
);

export const getChartData = createSelector(
  [getKPIdata, getKPIChartTime, getChartType, getKPIName],
  (data, time, chartType, name) => {
    switch (chartType) {
      case "TrendLine":
        return makeDataTrendLine(data, time);
      case "BarChart":
        return makeDataBarChart(data, time);
      default:
        return makeDataTrendLine(data, time);
    }
  }
);

export const getChartYAtt = createSelector([getChartOptionYatt], (yOptions) => {
  let yAtt;
  Object.keys(yOptions).forEach((key) => {
    if (yOptions[key]) yAtt = key;
  });
  return yAtt;
});

function makeDataTrendLine(data, time) {
  switch (time) {
    case "30 Day":
      return data.daily.pctTrouble
        .map((d) => ({ time: parseDateDaily(d.ts), value: Math.min(d.val, 1) }))
        .sort(sortDates);
    case "7 Day":
      const selectedData = data.daily.pctTrouble
        .map((d) => ({ time: parseDateDaily(d.ts), value: Math.min(d.val, 1) }))
        .sort(sortDates);
      const length = selectedData.length;
      return selectedData.slice(length - 7, length);
    case "24 Hr":
      return data.hourly.pctTrouble
        .map((d) => ({ time: parseDateDaily(d.ts), value: Math.min(d.val, 1) }))
        .sort(sortDates);
    default:
      return data.daily.pctTrouble
        .map((d) => ({ time: parseDateDaily(d.ts), value: Math.min(d.val, 1) }))
        .sort(sortDates);
  }
}

function makeDataBarChart(data, time) {
  const data_map = {};
  if (time !== "24 Hr") {
    data.daily.pctTrouble.forEach((d) => {
      data_map[d.ts] = {
        alarm: Math.min(d.val, 1),
        warning: 0,
        good: 1 - Math.min(d.val, 1),
        time: parseDateDaily(d.ts),
      };
    });

    if (data.daily.pctWarning != null) {
      data.daily.pctWarning.forEach((d) => {
        data_map[d.ts].warning = Math.min(d.val, 1);
        data_map[d.ts].good = 1 - Math.min(d.val + data_map[d.ts].alarm, 1);
      });
    }
  } else {
    data.hourly.pctTrouble.forEach((d) => {
      data_map[d.ts] = {
        alarm: Math.min(d.val, 1),
        warning: 0,
        good: 1 - Math.min(d.val, 1),
        time: parseDateDaily(d.ts),
      };
    });

    if (data.hourly.pctWarning != null) {
      data.hourly.pctWarning.forEach((d) => {
        data_map[d.ts].warning = Math.min(d.val, 1);
        data_map[d.ts].good = 1 - Math.min(d.val + data_map[d.ts].alarm, 1);
      });
    }
  }

  const data_array = Object.keys(data_map)
    .map((key) => data_map[key])
    .sort(sortDates);
  switch (time) {
    case "30 Day":
      return data_array;
    case "7 Day":
      const length = data_array.length;
      return data_array.slice(length - 7, length);
    case "24 Hr":
      return data_array;
    default:
      return data_array;
  }
}

export const getYAxisoption = (state, ownProps) =>
  state.KPIs.byName[ownProps.name].options["Y Axis Measure"].options;

export const getYAxisSelectedOption = createSelector(
  [getYAxisoption],
  (yOptions) => {
    let option = null;
    Object.keys(yOptions).forEach((key) => {
      if (yOptions[key]) {
        option = key;
      }
    });
    return option;
  }
);

export default monitorKPIReducer;
