import React from "react";

import "./barLegend.scss";
import FormattedMessage from "../../../../../../translations/FormattedMessage";

const BarLegend = ({ name }) => {
  if (name === "Offline") {
    return (
      <div className="signal-chart-legend-cont">
        <div className="signal-chart-legend">
          <span className="signal-chart-trendline-bar signal-chart-trendline-bar-alarm"></span>
          <span className="signal-chart-trendline-bar-title">
            <FormattedMessage id="monitor.KPIs.barLegend.offline" />
          </span>
          <span className="signal-chart-trendline-bar signal-chart-trendline-bar-warning"></span>
          <span className="signal-chart-trendline-bar-title">
            <FormattedMessage id="monitor.KPIs.barLegend.partial" />
          </span>
          <span className="signal-chart-trendline-bar signal-chart-trendline-bar-good"></span>
          <span className="signal-chart-trendline-bar-title">
            <FormattedMessage id="monitor.KPIs.barLegend.online" />
          </span>
        </div>
      </div>
    );
  }
  return (
    <div className="signal-chart-legend-cont">
      <div className="signal-chart-legend">
        <span className="signal-chart-trendline-bar signal-chart-trendline-bar-alarm"></span>
        <span className="signal-chart-trendline-bar-title">
          <FormattedMessage id="monitor.KPIs.barLegend.alarm" />
        </span>
        <span className="signal-chart-trendline-bar signal-chart-trendline-bar-warning"></span>
        <span className="signal-chart-trendline-bar-title">
          <FormattedMessage id="monitor.KPIs.barLegend.warning" />
        </span>
        <span className="signal-chart-trendline-bar signal-chart-trendline-bar-good"></span>
        <span className="signal-chart-trendline-bar-title">
          <FormattedMessage id="monitor.KPIs.barLegend.good" />
        </span>
      </div>
    </div>
  );
};

export default BarLegend;
