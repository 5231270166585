import { maxBy, minBy } from "lodash";

const getMinOrMax = (markers, minOrMax, latOrLng) => {
  if (minOrMax === "max") {
    return Number(maxBy(markers, (value) => value[latOrLng])[latOrLng]);
  } else {
    return Number(minBy(markers, (value) => value[latOrLng])[latOrLng]);
  }
};

export const getBounds = (markers) => {
  const maxLat = getMinOrMax(markers, "max", "lat");
  const minLat = getMinOrMax(markers, "min", "lat");
  const maxLng = getMinOrMax(markers, "max", "long");
  const minLng = getMinOrMax(markers, "min", "long");
  const southWest = [minLng, minLat];
  const northEast = [maxLng, maxLat];
  return [southWest, northEast];
};

export const mapDataForTooltip = (locations) => {
  if (!locations.length) return;
  let markerSets = [];
  locations = Array.from(new Set(locations))
    .sort((a, b) => (a.lat > b.lat ? 1 : -1))
    .map((location) => {
      markerSets.push(locations.filter((el) => el.lat === location.lat));
      return location;
    });
  const result = Object.values(
    markerSets
      .map((markerSet) => {
        return { ...markerSet[0], ...idMapper(markerSet) };
      })
      .reduce((prev, curr) => {
        if (!prev[curr.uniqId]) prev[curr.uniqId] = curr;
        return prev;
      }, {})
  );
  return result;
};

const idMapper = (markerSet) => {
  let ids = [];
  let uniqId;
  if (markerSet[0].id) {
    markerSet.map((set) => {
      ids.push(set.id);
      uniqId = `${set.id}_${set.city}_${set.lat}_${set.long}`;
    });
  }
  if (markerSet[0].locationId && markerSet[0].ticketId) {
    markerSet.map((set) => {
      ids.push({ locationId: set.locationId, ticketId: set.ticketId });
      uniqId = `${set.locationId}_${set.ticketId}_${set.city}_${set.lat}_${set.long}`;
    });
  }
  ids = Array.from(new Set(ids));
  return { ids, uniqId };
};
