import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./Tooltip.scss";

const SlTooltip = ({
  children,
  title,
  color,
  bgColor,
  interaction,
  onClick,
  durationMSecs,
}) => {
  const [wasTriggered, setWasTriggered] = useState(false);

  const handleTargetClick = (event) => {
    if (durationMSecs && !wasTriggered) {
      setWasTriggered(true);
      setTimeout(() => {
        setWasTriggered(false);
      }, durationMSecs);
    } else if (!durationMSecs) {
      setWasTriggered(!wasTriggered);
    }
    onClick(event);
  };

  const classNames = classnames(
    "sl-tooltip",
    { "sl-tooltip--hoverable": interaction === "hover" },
    { "sl-tooltip--clickable": interaction === "click" && !wasTriggered },
    {
      "sl-tooltip--clickable--wastriggered":
        interaction === "click" && wasTriggered,
    }
  );

  const tooltipStyle = {
    color,
    backgroundColor: bgColor,
  };

  return (
    <div className={classNames}>
      <div className="sl-tooltip-title" style={tooltipStyle}>
        {title}
      </div>
      <div
        className="sl-tooltip-target"
        onClick={(event) =>
          interaction === "click" && onClick && handleTargetClick(event)
        }
      >
        {children}
      </div>
    </div>
  );
};

SlTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
  interaction: PropTypes.oneOf(["hover", "click"]).isRequired,
  onClick: PropTypes.func,
  durationMsecs: PropTypes.number,
};

export { SlTooltip };
