import React, { useState, Fragment } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { SlIcon } from "../Icon/Icon";
import Plus from "../../../../../assets/svg/x-icon.svg";
import "./plumeIconTextButton.scss";

export default function PlumeIconTextButton({
  text,
  icon,
  onClick,
  backGroundColor = "black",
}) {
  const [hovered, setHovered] = useState(false);

  function handleMouseEnter() {
    setHovered(true);
  }

  function handleMouseLeave() {
    setHovered(false);
  }

  function handleOnClick() {
    onClick();
  }

  const DeleteX = (
    <Fragment>
      <span className="sl-plume-text-button-x">
        <SlIcon width={22} height={22} svgFile={Plus} />
      </span>
    </Fragment>
  );

  const IconTextButton = (
    <span className={classNames("sl-plume-text-button-text-icon")}>
      <span className="sl-plume-text-button-icon"> {icon}</span>
      <span className="sl-plume-text-button-text">{text}</span>
    </span>
  );

  const styles = { backgroundColor: backGroundColor };

  return (
    <div
      style={styles}
      className={classNames("sl-plume-text-button-wraper")}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => handleOnClick()}
    >
      {hovered ? DeleteX : IconTextButton}
    </div>
  );
}

PlumeIconTextButton.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  backGroundColor: PropTypes.string,
};
