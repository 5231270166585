import React, { useEffect } from "react";
import { SlPage } from "../../components/communal/Atoms/Page/Page";
import "./AlertPage.scss";
import { SlMetric } from "../../components/communal/Molecules/Metric/Metric";
import AlertData from "../../components/communal/Organisms/AlertData/AlertData";
import { useDispatch, useSelector } from "react-redux";
import { SlPanelLoader } from "../communal/Atoms/PanelLoader/PanelLoader";
import TopmostSection from "../communal/Organisms/TopmostSection";
import { preloadAlertData } from "../../../actions/alert/alertActions";

import RadarIcon from "../../../assets/svg/icon-radar.svg";
import AlarmIcon from "../../../assets/svg/icon-alarm.svg";
import { SlIcon } from "../communal/Atoms/Icon/Icon";
import DataError from "../communal/Atoms/DataError";
import { useTranslation } from "react-i18next";
import { AlertDataLabels } from "../../../reducers/alert/alertReducer";

export const AlertPage = () => {
  const state = useSelector((state) => {
    return {
      dataLoading: state.KPI.dataLoading,
      ...state.alert["All"],
    };
  });
  const isError = useSelector((state) => state.KPI.data_error);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(preloadAlertData());
  }, [dispatch]);

  if (isError) {
    return (
      <div className="signal-alert-error">
        <DataError />
      </div>
    );
  }
  const availableAlertsCollection = Object.values(AlertDataLabels);
  const iconSizes = {
    width: 40,
    height: 40,
  };

  return (
    <div className="signal-alert">
      <SlPage>
        <TopmostSection>
          {!state.homesMonitored && state.dataLoading ? (
            <SlPanelLoader />
          ) : (
            <>
              <SlMetric
                icon={<SlIcon svgFile={RadarIcon} {...iconSizes} />}
                value={state.homesMonitored}
                title={t("alert.totalLocationsMonitored")}
              />
              <SlMetric
                icon={<SlIcon svgFile={AlarmIcon} {...iconSizes} />}
                value={state.homesInAlert}
                title={t("alert.totalLocationsInAlert")}
              />
            </>
          )}
        </TopmostSection>
        {availableAlertsCollection.map((alertLabel) => (
          <AlertData key={alertLabel} label={alertLabel} />
        ))}
      </SlPage>
    </div>
  );
};
