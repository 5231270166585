import { isEqual } from "lodash";

const findGeoPoints = (data) => {
  let noPoints = true;
  let longestKey = "";
  let largestLength = 0;

  Object.keys(data).forEach((key) => {
    if (data[key].troublePoints.length > 0) noPoints = false;
    if (data[key].troublePoints.length > largestLength) {
      longestKey = key;
      largestLength = data[key].troublePoints.length;
    }
  });

  if (noPoints) {
    return [false, ""];
  }

  return [true, longestKey];
};

const mapIdsForTooltip = ({ partner }) => {
  Object.keys(partner).map((layerType) => {
    let pointSets = [];
    if (!partner[layerType].troublePoints.length) {
      return;
    }
    if (partner[layerType].troublePoints[0].ids) {
      return;
    }
    partner[layerType].troublePoints.map((point) => {
      pointSets.push(
        partner[layerType].troublePoints.filter((el) => {
          return isEqual(el.geo, point.geo);
        })
      );
    });
    partner[layerType].troublePoints = Array.from(
      new Set(pointSets.map((set) => set[0].id))
    )
      .map((id) => pointSets.find((set) => set[0].id === id))
      .map((pointSet) => {
        let res = { ...pointSet[0] };
        res.ids = [];
        pointSet.map((el) => res.ids.push(el.id));
        return res;
      });
  });
  return { partner };
};

export { findGeoPoints, mapIdsForTooltip };
