import { GraphQLClient } from "graphql-request";
import { GET_CLOUD_GRAPHQL_URL } from "../utils/constants";
import { getOktaToken } from "../helpers/Token/Token";
//helpers especially for parsing our date string

function parseDate(string) {
  let date = new Date(string);
  date = new Date(date.getTime());

  return date;
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const getGraphQLData = (
  QUERY,
  gettingDataAction,
  dataReceivedAction,
  dataErrorAction
) => {
  // returns a  thunk
  return () => {
    return async (dispatch, getState) => {
      dispatch(gettingDataAction());

      const primaryDeployment = getState().me.selectedPartner.primaryDeployment;
      const endpoint = GET_CLOUD_GRAPHQL_URL(primaryDeployment);

      const oktaToken = getOktaToken(localStorage);
      const client = new GraphQLClient(endpoint, {
        headers: { Authorization: `Bearer ${oktaToken}` },
      });
      const variables = { id: getState().me.selectedPartner.id };

      try {
        const data = await client.request(QUERY, variables);
        dispatch(dataReceivedAction(data));
      } catch (err) {
        dispatch(dataErrorAction());
      }
    };
  };
};

export { parseDate, sleep, getGraphQLData };
