import React from "react";
import PropTypes from "prop-types";
import Delete from "../../Atoms/Delete";
import { SlText } from "../../Atoms/Text/Text";

import "./deleteList.scss";

const DeleteList = ({ list, onDelete, isEditingDisabled }) => {
  return (
    <div className="sl-delete-list">
      {list.map((item) => {
        return (
          <Delete
            key={item}
            text={item}
            onDelete={onDelete}
            isDisabled={isEditingDisabled}
          >
            <SlText p7>{item}</SlText>
          </Delete>
        );
      })}
    </div>
  );
};

DeleteList.propTypes = {
  list: PropTypes.array.isRequired,
  onDelete: PropTypes.func.isRequired,
  isEditingDisabled: PropTypes.bool,
};
export default DeleteList;
