import React from "react";
import PropTypes from "prop-types";

import { SlIcon } from "../Icon/Icon";

import "./ZoomInOutHome.scss";
import { SlButton } from "../Button/Button";
import cx from "classnames";

import Minus from "../../../../../assets/svg/minus.svg";
import Plus from "../../../../../assets/svg/plus.svg";
import Home from "../../../../../assets/icons/Icon_Recenter.svg";

const ZoomInOutHome = ({
  zoomInAction,
  zoomOutAction,
  handleHomeClick,
  expanded,
}) => {
  const classes = cx("sl-zoom-in-out-home-wrapper", {
    "sl-zoom-in-out-home-wrapper--expanded": Boolean(expanded),
  });
  return (
    <div className={classes}>
      <div className="sl-zoom-in-out-home">
        <SlButton
          round
          onClick={(ev) => {
            ev.stopPropagation();
            zoomInAction();
          }}
        >
          <SlIcon svgFile={Plus} />
        </SlButton>
        <SlButton
          round
          onClick={(ev) => {
            ev.stopPropagation();
            handleHomeClick();
          }}
        >
          <SlIcon svgFile={Home} />
        </SlButton>
        <SlButton
          round
          onClick={(ev) => {
            ev.stopPropagation();
            zoomOutAction();
          }}
        >
          <SlIcon svgFile={Minus} />
        </SlButton>
      </div>
    </div>
  );
};

ZoomInOutHome.propTypes = {
  zoomInAction: PropTypes.func.isRequired,
  zoomOutAction: PropTypes.func.isRequired,
  handleHomeClick: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
};

export { ZoomInOutHome };
