import { normTextToArr } from "../../reducers/helpers/normalizeAdmin";

export function unNormalizeAlerts(alerts) {
  const unNormAlerts = {
    ...alerts,
    operationalGroup: normTextToArr(alerts.operationalGroup, "byText"),
    consumerOutreachGroup: normTextToArr(
      alerts.consumerOutreachGroup,
      "byText"
    ),
  };

  return unNormAlerts;
}
