import React from "react";

import "./ButtonsGroup.scss";
import { useClassNames } from "../../../../../hooks/useClassNames";
import { SlText } from "../../Atoms/Text/Text";
import cx from "classnames";

const getClassNameForButtonsGroupElement = (option, currentValue, classes) => {
  return cx(classes.option, {
    [classes.optionSelected]: option.valueInDays === currentValue,
  });
};

const SlButtonsGroup = ({
  options,
  classes,
  currentValue,
  onOptionSelected,
}) => {
  const styles = useClassNames(
    {
      root: "sl-buttons-group",
      option: "sl-buttons-group__option",
      optionSelected: "sl-buttons-group__option--selected",
    },
    classes
  );

  const onClick = (option) => {
    onOptionSelected && onOptionSelected(option);
  };

  return (
    <div className={styles.root}>
      {options.map((option, index) => (
        <span
          key={option.label.concat(index)}
          onClick={() => onClick(option)}
          className={getClassNameForButtonsGroupElement(
            option,
            currentValue,
            styles
          )}
        >
          <SlText p7 destressed>
            {option.label}
          </SlText>
        </span>
      ))}
    </div>
  );
};

export { SlButtonsGroup };
