import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import IDItem from "../../../Atoms/Table/IDItem";
import { SlTableItem } from "../../../Atoms/Table/TableItem/TableItem";
import { SlChip } from "../../../Atoms/Chip/Chip";
import { SlText } from "../../../Atoms/Text/Text";

import "./TableRow.scss";
import FormattedMessage from "../../../../../../translations/FormattedMessage";
import { getTranslationKey } from "../../../../../helpers";
import { useTranslation } from "react-i18next";

const SlTableRow = ({ rowData, keys, selected }) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const newData = [];
    keys.forEach((k) => {
      newData.push(rowData[k]);
    });
    setData(newData);
  }, [rowData, keys]);

  const getStatusChip = (status) => (
    <SlChip
      color={status.match(/reported|resolved/g) ? "resolved" : "primary"}
      fill={status.match(/reported|resolved/g) ? "fill" : "outline"}
    >
      <SlText
        p7
        primary={status.match(/ongoing/g)}
        positive={status.match(/resolved/g)}
      >
        <FormattedMessage id={getTranslationKey(status)} />
      </SlText>
    </SlChip>
  );

  return (
    <tr
      className={`sl-tablerow-cont ${
        selected ? "sl-tablerow-cont--selected" : null
      }`}
    >
      {data.map((r, i) =>
        keys[i] === "LocationID" ||
        keys[i] === "locationId" ||
        keys[i] === "locationID" ? (
          <IDItem classProp={keys[i]}>{r}</IDItem>
        ) : (
          <SlTableItem classProp={keys[i]}>
            {keys[i] === "url" && (
              <a href={r} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="view" />
              </a>
            )}
            {keys[i] === "status" && getStatusChip(r)}
            {keys[i] !== "url" &&
              keys[i] !== "status" &&
              (r || t("notAvailable"))}
          </SlTableItem>
        )
      )}
    </tr>
  );
};

SlTableRow.propTypes = {
  RowData: PropTypes.object.isRequired,
  keys: PropTypes.array.isRequired,
};

export { SlTableRow };
