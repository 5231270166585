import React from "react";

import PropTypes from "prop-types";
import MenuOpener from "../../Atoms/MenuOpener/MenuOpener";
import PlumeIconTextButton from "../../Atoms/PlumeIconTextButton/PlumeIconTextButton";
import { useTranslation } from "react-i18next";

export default function PlumeSelectionMenu({
  MenuIcon,
  MenuText,
  selections,
  selectionClick,
  menuOpenClose,
}) {
  const { t } = useTranslation();
  const handleMenuClick = () => {
    menuOpenClose();
  };

  const handleSelectionClick = (selectionText) => {
    selectionClick(selectionText);
  };

  const menuText = MenuText || t("monitor.map.layersMenuDefaultCopy");

  return (
    <div className="sl-plume-selection-menu-wrapper">
      <div className="sl-plume-selection-menu-selection" />
      <MenuOpener text={menuText} icon={MenuIcon} onClick={handleMenuClick} />
      {selections.map((selection) => (
        <div
          key={selection.labelId}
          className="sl-plume-selectionmenu-selection"
        >
          <PlumeIconTextButton
            text={t(selection.labelId)}
            onClick={() => handleSelectionClick(selection.labelId)}
            icon={selection.icon}
          />
        </div>
      ))}
    </div>
  );
}

PlumeSelectionMenu.propTypes = {
  MenuIcon: PropTypes.element.isRequired,
  MenuText: PropTypes.string,
  selections: PropTypes.array.isRequired,
  selectionClick: PropTypes.func.isRequired,
  menuOpenClose: PropTypes.func.isRequired,
};
