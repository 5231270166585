export function normTextArray(arr) {
  const normData = { byText: {} };
  arr.forEach((text) => {
    normData.byText[text] = { text: text };
  });

  return normData;
}

export function normTextToArr(norm, by) {
  const arr = [];
  Object.keys(norm[by]).forEach((key) => {
    arr.push(key);
  });

  return arr;
}
