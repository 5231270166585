import _, { groupBy } from "lodash";
import moment from "moment";
import { GET_NOC_URL } from "../../utils/constants";
import { ticketDateFormat } from "../../config";
import { TicketStatus } from "../../reducers/alert/alertTicketReducer";

export const subtractDaysFromDate = (days, mostRecentDate) => {
  let d = new Date(mostRecentDate);
  return d.setDate(d.getDate() - days);
};

export const filterDateBasedOnSelection = (times, date = new Date()) => {
  return (ticket) => {
    let thisDate = new Date(ticket.timestamp);
    let startDate = subtractDaysFromDate(7, date);
    if (times["24 hours"]) {
      startDate = subtractDaysFromDate(1, date);
    }
    if (times["7 days"]) {
      startDate = subtractDaysFromDate(7, date);
    }
    if (times["30 days"]) {
      startDate = subtractDaysFromDate(30, date);
    }
    if (times["90 days"]) {
      startDate = subtractDaysFromDate(90, date);
    }
    if (times["All time"]) {
      startDate = subtractDaysFromDate(9999, date);
    }
    return thisDate >= startDate && thisDate <= date;
  };
};

export const numberOfDaysForTimeSelection = (times) => {
  let length = 24;

  if (times["24 hours"]) {
    length = 24;
  }

  if (times["7 days"]) {
    length = 7;
  }

  if (times["30 days"]) {
    length = 30;
  }

  if (times["90 days"]) {
    length = 90;
  }

  if (times["All time"]) {
    length = 99999;
  }

  return length;
};

export const applyPercentage = (unit, percentModifier) => {
  return (val) => {
    let modified = { ...val, ...{} };
    modified.alarm = Number(val.alarm);
    if (percentModifier && unit.percentage && modified.alarm) {
      modified.alarm = (Number(val.alarm) / percentModifier) * 100;
    }
    return modified;
  };
};

/**
 * For use with a map function, takes data from the API and converts into human-readable for the chart
 * @param times
 * @returns {function(*): {values: Array, label: string, tooltipDate: string}}
 */
export const convertForTimeSeries = (times) => {
  return (val) => {
    let values = [];
    const timestamp = new Date(val.timestamp);
    values.push(Number(val.alarm));
    return {
      values,
      label: moment(timestamp).format(
        times && times["24 hours"] ? "h a" : "M/D"
      ),
      tooltipDate: moment(timestamp).format("dddd, MMMM Do"),
      timestamp: val.timestamp,
    };
  };
};

/**
 * Filters a list of locations by the selected filter(s) - [AND/OR] logic
 * @param locations
 * @param filters
 * @returns {*}
 */

const filterLocationsBySelection = (locations, filters) =>
  locations.filter((location) =>
    filters.some(
      (filter) => location[filter.typeOfFilter.toLowerCase()] === filter.name
    )
  );

/** Filters a list of locations by the selected parent(s) - [AND] LOGIC*/

const filterLocationsByMatchingParent = (locations, filters) => {
  let groupedFilters = groupBy(
    filters.map((filter) => ({
      name: filter.name,
      type: filter.typeOfFilter.toLowerCase(),
    })),
    "type"
  );
  let groupedFiltersKeys = Object.keys(groupedFilters);
  return locations.filter((location) =>
    groupedFiltersKeys.every((filter) =>
      groupedFilters[filter].some(
        (_filter) => _filter.name === location[filter]
      )
    )
  );
};

/**
 * Filters a list of locations by search string
 * @param locations
 * @param searchText
 * @returns {*}
 */
const filterLocationsBySearch = (locations, searchText) => {
  return locations.filter((location) => {
    for (let i = 0, props = Object.keys(location); i < props.length; i++) {
      let property = props[i];
      if (
        location[property]?.toLowerCase().indexOf(searchText.toLowerCase()) > -1
      ) {
        return true;
      }
    }
    return false;
  });
};

/**
 * Handles the filtering of a list of locations by selected filter AND OR search string
 * @param locations
 * @param filterList
 * @param searchText
 * @returns {*}
 */
export const filterLocationsBySelectionAndSearch = (
  locations,
  filterList,
  searchText,
  page = 1,
  selectedLocation,
  currentPrimaryDeployment
) => {
  const DEFAULT_PAGE_SIZE = 10;

  if (!_.isEmpty(filterList)) {
    let filters = Object.entries(filterList).map((filter) => filter[1]);
    locations = filterLocationsBySelection(locations, filters);
    locations = filterLocationsByMatchingParent(locations, filters);
  }

  if (searchText) {
    locations = filterLocationsBySearch(locations, searchText);
  }

  let paginatedLocations = paginate(locations, DEFAULT_PAGE_SIZE, page);

  paginatedLocations = paginatedLocations.map((location) => ({
    ...location,
    timeStamp: moment(location.timeStamp).format(ticketDateFormat),
    url: `${GET_NOC_URL(currentPrimaryDeployment)}/${location.locationId}`,
  }));

  if (
    paginatedLocations.length &&
    paginatedLocations?.length === locations?.length
  ) {
    paginatedLocations[paginatedLocations.length - 1].last = true;
  }

  paginatedLocations = paginatedLocations.map((location) => {
    if (location.locationId === selectedLocation) {
      location.selected = true;
    }
    return location;
  });

  return paginatedLocations;
};

export const ticketMap = {
  ispOutage: "CSP Outage",
  evilTwin: "Evil Twin",
  totalLocationsOffline: "Total Locations Offline",
  locationOfflineOutage: "Locations Offline Outage",
  locationsOfflineOutage: "Locations Offline Outage",
  ispOutageAlerts: "CSP Outage",
  evilTwinAlerts: "Evil Twin",
  totalLocationsOfflineAlerts: "Total Locations Offline",
  locationsOfflineOutageAlerts: "Locations Offline Outage",
};
/**
 * Mutates ticket data from the API into data that is more useful for the front-end. For example,
 * converting the timestamp to a javascript date object and returning the time in milliseconds
 * @param ticket
 * @param draftState
 * @returns {*}
 */
export const remapTicketProperties = (
  ticket,
  totalHomesInAlarm,
  andOthersSuffix = undefined
) => {
  let mutatedTicket = { ...{}, ...ticket };

  if (ticket.type) mutatedTicket.type = ticketMap[mutatedTicket.type];

  if (ticket.timestamp)
    mutatedTicket.timestamp = new Date(ticket.timestamp).getTime();
  if (ticket.alarm) {
    let alarm = ticket.alarm;
    mutatedTicket.alarm = (Number(alarm) / totalHomesInAlarm) * 100;
    mutatedTicket.alarmNominal = Number(alarm);
  }
  if (ticket.city) {
    mutatedTicket.city = truncateTicket(ticket.city, 3, andOthersSuffix);
  }
  if (ticket.country) {
    mutatedTicket.country = truncateTicket(ticket.country, 3, andOthersSuffix);
  }
  if (ticket.type === "evilTwin" && ticket.status === TicketStatus.Resolved) {
    mutatedTicket.status = TicketStatus.Reported;
  }
  return mutatedTicket;
};

export const truncateTicket = (
  stringWithCommas,
  max = 3,
  andOthersSuffix = "and others..."
) => {
  const split = stringWithCommas.split(",");
  if (split.length > max) {
    return `${split.splice(0, max).toString()} ${andOthersSuffix}`;
  } else {
    return stringWithCommas;
  }
};
/**
 * Creates a human-readable timeseries for the chart display, based on data from the API
 * @param state
 * @returns {*}
 */
export const mutateHourlyDaily = (state, percentModifier) => {
  let timeSelection = state.times,
    unit = state.times["24 hours"] ? "hourly" : "daily",
    timeSeries = state[unit];

  return timeSeries
    .map(applyPercentage(state.chartUnit, percentModifier))
    .map(convertForTimeSeries(timeSelection))
    .sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp))
    .reverse()
    .slice(0, numberOfDaysForTimeSelection(timeSelection))
    .reverse();
};

export const sortByDate = () => (a, b) =>
  new Date(b.timestamp) - new Date(a.timestamp);

export const groupTicketsByCountry = (
  tickets,
  timeSelector,
  totalHomesInAlarm,
  andOtherSuffix = undefined
) => {
  let allTickets = tickets.map((ticket) =>
    remapTicketProperties(ticket, totalHomesInAlarm, andOtherSuffix)
  );

  let filteredTickets = allTickets
    .sort(sortByDate)
    .filter(filterDateBasedOnSelection(timeSelector));
  const groupedTickets = _.groupBy(filteredTickets, "country");
  const groupedTicketsByKey = Object.keys(groupedTickets);
  const locations = filteredTickets
    .filter((ticket) => ticket.geo)
    .map((ticket) => ({
      nominal: ticket.nominal,
      lat: ticket.geo?.lat,
      long: ticket.geo?.long,
      id: ticket.id,
      city: ticket.city,
      timestamp: ticket.timestamp,
    }));
  const allOngoingLocations = allTickets
    .filter((ticket) => ticket.geo && ticket.status === TicketStatus.Ongoing)
    .map((ticket) => ({
      nominal: ticket.nominal,
      lat: ticket.geo?.lat,
      long: ticket.geo?.long,
      id: ticket.id,
      city: ticket.city,
      timestamp: ticket.timestamp,
    }));

  return {
    all: allTickets,
    filteredTickets,
    groupedTickets: groupedTickets,
    groupedTicketsByKey,
    locations,
    allOngoingLocations,
  };
};

function paginate(array, page_size = 10, page_number = 1) {
  if (!page_number) {
    return array;
  } else {
    return array.slice(0, page_number * page_size);
  }
}
