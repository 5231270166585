import React from "react";

import "./Table.scss";

const SlTable = (props) => (
  <div className={`sl-table${props.className ? ` ${props.className}` : null}`}>
    {props.children}
  </div>
);

export { SlTable };
