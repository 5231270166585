import React from "react";
import PropTypes from "prop-types";
import { SlText } from "../../../communal/Atoms/Text/Text";
import { SlRangeSlider } from "../RangeSlider/RangeSlider";
import { formatTime } from "../RangeSlider/helpers";
import FormattedMessage from "../../../../../translations/FormattedMessage";

import "./titleAndSlider.scss";

const TitleAndSlider = ({
  alertIndex,
  channelIndex,
  handleOnChangeTime,
  time,
  isDisabled,
}) => {
  const handleChannelThrottleChange = (value) => {
    const timeInSeconds = Number(value);
    handleOnChangeTime(timeInSeconds, alertIndex, channelIndex);
  };

  return (
    <div className="SL-admin-checkbox-title">
      <div className="SL-admin-title-description-slider">
        <SlText subtitle4m classNames="SL-admin-checkbox-title">
          <FormattedMessage id="admin.alerts.emailNotificationTitle" />
        </SlText>
        <SlText p7 destressed classNames="sl-admin-notification-delay-desc">
          <FormattedMessage id="admin.alerts.emailNotificationDescription" />
        </SlText>
        <SlRangeSlider
          value={time}
          min={0}
          max={60 * 60 * 24 * 7}
          step={60}
          isDisabled={isDisabled}
          valueFormatter={formatTime}
          onChange={handleChannelThrottleChange}
          labelAdditionalClassName="sl-range-slider-label--time"
        />
      </div>
    </div>
  );
};

TitleAndSlider.propTypes = {
  alertIndex: PropTypes.number.isRequired,
  channelIndex: PropTypes.number.isRequired,
  time: PropTypes.number.isRequired,
  handleOnChangeTime: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default TitleAndSlider;
