import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withOktaAuth } from "@okta/okta-react";
import PropTypes from "prop-types";
import { HIDE_ADMIN_MENU } from "../../../utils/constants";
import MenuSphere from "./MenuSphere";
import APIVersion from "./APIVersion";
import SignalVersion from "./SignalVersion";
import { SlDropdown } from "../../components/communal/Molecules/Dropdown/Dropdown";
import { SlItem, SlSeparator } from "../../components/communal/Atoms/Item/Item";
import { SlIcon } from "../../components/communal/Atoms/Icon/Icon";
import { SlButton } from "../../components/communal/Atoms/Button/Button";
import { SlText } from "../../components/communal/Atoms/Text/Text";
import CaretDownIcon from "../../../assets/svg/icon-caret.svg";
import CaretUpIcon from "../../../assets/svg/icon-caret-up.svg";
import SignOutIcon from "../../../assets/svg/icon-sign-out.svg";
import { Routes } from "../../../config";
import FormattedMessage from "../../../translations/FormattedMessage";

const UserMenu = ({ userName, role, oktaAuth }) => {
  const [menuIsActive, setMenuIsActive] = useState(false);

  const logOut = async () => {
    oktaAuth.tokenManager.clear();
    await oktaAuth.signOut(Routes.Index);
  };

  const formatName = (name) => {
    if (name.length === 0) {
      return "";
    }
    if (name[0] === "" || name[0] === " ") {
      return name[0];
    }
    return name[0].slice(0, 1) + formatName(name.slice(1, name.length));
  };

  const onDropdownChange = (newState) => {
    setMenuIsActive(newState);
  };

  return (
    <div className="user-menu-cont">
      <SlDropdown
        align={"right"}
        trigger={
          <SlButton
            backgroundOpacity={0.2}
            backgroundOpacityHighlighted={0.2}
            size={"default"}
            end={
              menuIsActive ? (
                <SlIcon width={15} height={15} svgFile={CaretUpIcon} />
              ) : (
                <SlIcon width={15} height={15} svgFile={CaretDownIcon} />
              )
            }
          >
            <SlText subtitle4>
              {formatName(userName ? userName.split(" ") : "")}
            </SlText>
          </SlButton>
        }
        onNewActiveState={onDropdownChange}
      >
        <SlItem inactive>
          <div className="user-menu-header">
            <div>
              <FormattedMessage id="userMenu.loggedInAs" />
            </div>
            <div>{userName}</div>
          </div>
        </SlItem>
        {role !== "partnerReadOnly" && !HIDE_ADMIN_MENU && (
          <SlItem
            className="user-menu-option"
            to={Routes.Admin}
            emphasis={false}
          >
            <FormattedMessage id="userMenu.adminPanel" />
          </SlItem>
        )}

        <SlItem
          className="user-menu-signout user-menu-option"
          onClick={logOut}
          emphasis={false}
          start={<SlIcon svgFile={SignOutIcon} />}
        >
          <FormattedMessage id="userMenu.signOut" />
        </SlItem>

        <SlSeparator />

        <SlItem inactive>
          <APIVersion />
        </SlItem>

        <SlItem inactive>
          <SignalVersion />
        </SlItem>

        <SlItem inactive>
          <MenuSphere />
        </SlItem>
      </SlDropdown>
    </div>
  );
};

const mapStateToProps = (state) => ({
  role: state.me.role,
});

UserMenu.propTypes = {
  userName: PropTypes.string,
};

export default compose(
  withOktaAuth,
  connect(mapStateToProps, undefined)
)(UserMenu);
