import React from "react";
import "./PanelLoader.scss";
import { PuffLoader } from "react-spinners";

const SlPanelLoader = () => (
  <div className="sl-panel-loader">
    <PuffLoader color={"#ffffff"} />
  </div>
);

export { SlPanelLoader };
