import React from "react";
import InputForm from "../../Molecules/InputForm";
import DeleteList from "../../Molecules/DeleteList";

import "./inputAndDeleteList.scss";

export const InputAndDeleteList = ({
  deleteList,
  onDelete,
  iconPath,
  placeholderText,
  submitTitle,
  onSubmit,
  description,
  submitIconPath,
  isEditingDisabled,
}) => {
  return (
    <div className="sl-input-delete-list">
      <div className="sl-input-form-wrapper">
        <InputForm
          iconPath={iconPath}
          placeholderText={placeholderText}
          submitTitle={submitTitle}
          onSubmit={onSubmit}
          description={description}
          submitIconPath={submitIconPath}
          deleteList={deleteList}
          isEditingDisabled={isEditingDisabled}
        />
        {deleteList?.length !== 0 && (
          <DeleteList
            list={deleteList}
            onDelete={onDelete}
            isEditingDisabled={isEditingDisabled}
          />
        )}
      </div>
    </div>
  );
};
