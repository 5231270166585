export default class Token {
  constructor(token_storage) {
    this._tokenStorage = token_storage;
  }

  set tokenStorage(token_storage) {
    this._tokenStorage = token_storage;
  }

  get idToken() {
    const token = JSON.parse(this._tokenStorage).idToken.idToken;
    return token;
  }

  get accessToken() {
    const token = JSON.parse(this._tokenStorage).accessToken.accessToken;
    return token;
  }

  get email() {
    const token = JSON.parse(this._tokenStorage).idToken;
    return token?.claims?.email;
  }
}

const getOktaToken = (localStorage) => {
  if (localStorage["okta-token-storage"] === undefined) return "";
  return JSON.parse(localStorage["okta-token-storage"]).idToken.idToken;
};

export { getOktaToken };
