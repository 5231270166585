import producer from "immer";
import {
  GET_LOCATIONS_DATA_FAIL,
  GET_LOCATIONS_DATA_SUCCESS,
  ON_FETCH_LOCATIONS_DATA,
  RESET_LOCATION,
} from "../../actions/location/locationActions";

const INIT_STATE = {
  locations: {
    partner: {
      locationAlarmHistory: {
        offlineNetwork: [],
        unstableNetwork: [],
      },
    },
    loading: false,
    loaded: false,
  },
};

const locationReducer = producer((draftState, action) => {
  let payload = action.payload;
  switch (action.type) {
    case GET_LOCATIONS_DATA_SUCCESS:
      draftState.locations = payload;
      draftState.locations.loading = false;
      draftState.locations.loaded = true;
      return;
    case GET_LOCATIONS_DATA_FAIL:
      draftState.locations = { ...draftState.locations, ...payload };
      draftState.locations.loading = false;
      return;
    case ON_FETCH_LOCATIONS_DATA:
      draftState.locations.loading = payload.loading;
      draftState.locations.loading = true;
      return;
    case RESET_LOCATION:
      draftState.locations = INIT_STATE.locations;
      return;
    default:
      return;
  }
}, INIT_STATE);

export default locationReducer;
