import * as d3 from "d3";
import BaseXYChart from "./BaseXYChart";

class StackedBarChart extends BaseXYChart {
  makeBars() {
    this.mainG
      .selectAll(".Sl-stacked-bar-group")
      .data(this.stackedData)
      .join("g")
      .attr("fill", (d) => this.colorMap[d.key])
      .attr("class", "Sl-stacked-bar-group")
      .selectAll("rect")
      .data((d) => d)
      .join("rect")
      .attr("class", "Sl-stacked-bar-rect")
      .attr("x", (d, i) => this.xScale(d.data.time))
      .attr("y", (d) => this.yScale(d[1]))
      .attr("height", (d) => this.yScale(d[0]) - this.yScale(d[1]))
      .attr("width", this.xScale.bandwidth())
      .attr("rx", 3);
  }

  makePopOver() {
    const self = this;
    this.mainG.selectAll(".Sl-stacked-bar-rect").on("mouseenter", function (d) {
      d3.select(this).attr("stroke", "white").attr("stroke-width", "1px");
      const [mx, my] = d3.mouse(self.el);
      const specifier = d3.formatSpecifier("%");
      specifier.precision = d3.precisionFixed(0.1);
      const formatter = d3.format(specifier);
      const value = formatter(d[1] - d[0]);

      const date = self.getXAxisFormatPopover()(d.data.time);

      d3.select(self.el)
        .append("div")
        .attr("class", "Sl-stacked-popover-cont")
        .append("div")
        .attr("class", "Sl-stacked-popover")
        .style("bottom", `${self.height - my + 60}px`)
        .style("left", `${mx + 15}px`)
        .html((d) => popOverHTML(value, date));
    });

    this.mainG.selectAll(".Sl-stacked-bar-rect").on("mouseout", function () {
      d3.select(this).attr("stroke", "none");
      d3.selectAll(".Sl-stacked-popover-cont").remove();
    });

    function popOverHTML(value, date) {
      return `
         <span class="Sl-trendline-popover-value">
           ${value}:
         </span>
         <span class="Sl-trendline-popover-time">
           ${date}
         </span>
      `;
    }
  }
  removeBars() {
    this.mainG.selectAll(".Sl-stacked-bar-group").remove();
  }

  remove() {
    this.SVG.remove();
  }

  redraw(options) {
    this.height = options.height - (this.margin.t + this.margin.b);
    this.width = options.width - (this.margin.l + this.margin.r);

    this.render();
  }

  render() {
    this.makeSVG();
    this.createStackedData();
    this.makeScales();
    this.makeAxis();
    this.makeBars();
    this.makePopOver();
  }

  rerender(options) {
    this.removeBars();
    this.data = options.data;
    this.makeTotalData();
    this.timeInterval = options.timeInterval;
    this.createStackedData();
    this.makeScales();
    this.reMakeAxis();
    this.makeBars();
    this.makePopOver();
  }
  reRenderAxis(options) {
    this.removeBars();
    this.yAtt = options.yAtt;
    this.makeKeys();
    this.createStackedData();
    this.makeScales();
    this.reMakeAxis();
    this.makeBars();
    this.makePopOver();
  }
}

export default StackedBarChart;
