import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";
import { SlText } from "../../Atoms/Text/Text";
import { SlIcon } from "../../Atoms/Icon/Icon";
import InfoIcon from "../../../../../assets/svg/icon-info.svg";

import "./Metric.scss";

const SlMetric = ({ value, title, tooltip, id, icon }) => (
  <div className={"sl-metric"}>
    <ReactTooltip
      className="tooltip"
      place="right"
      effect="solid"
      backgroundColor="#606060"
    ></ReactTooltip>
    <div className={"sl-metric__icon"}>{icon}</div>

    <div className={"sl-metric__inner"}>
      <div className="sl-metric__value">
        <SlText id={id} heading3m>
          {d3.format(",")(value)}
        </SlText>
        {tooltip && (
          <div
            data-html={true}
            data-tip={ReactDOMServer.renderToStaticMarkup(
              <SlText p7>{tooltip}</SlText>
            )}
          >
            <SlIcon
              className={"sl-metric__tooltip"}
              width={26}
              height={26}
              svgFile={InfoIcon}
            />
          </div>
        )}
      </div>

      <SlText className={"sl-metric__title"} subtitle4>
        {title}
      </SlText>
    </div>
  </div>
);

SlMetric.propTypes = {
  icon: PropTypes.element,
  value: PropTypes.number.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  id: PropTypes.string,
};

export { SlMetric };
