import React from "react";
import "simplebar/src/simplebar.css";
import "./Scrollbar.scss";
import SimpleBarReact from "simplebar-react";

const SlScrollbars = ({ children, autoHeightMax, attach = true }) => {
  if (!attach) {
    return children;
  }
  return (
    <SimpleBarReact style={{ maxHeight: autoHeightMax }}>
      {children}
    </SimpleBarReact>
  );
};

export { SlScrollbars };
