import React from "react";
import PropTypes from "prop-types";
import { SlText } from "../../Text/Text";

const IDItem = ({ children }) => (
  <td className="Sl-row-id-item">
    <SlText p7 destressed>
      {children}
    </SlText>
  </td>
);

IDItem.propTypes = {
  children: PropTypes.string.isRequired,
};

export default IDItem;
