import React from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./StackedAreaChart.scss";
import { getColorByLabelId } from "../../../../../../helpers/predictHelpers";
import { StackedAreaChartTooltip } from "./StackedAreaChartTooltip";
import moment from "moment";
import { SlButtonsGroup } from "../../../Molecules/ButtonsGroup/ButtonsGroup";
import { useTranslation } from "react-i18next";
import { historicalTrendXAxisDateFormat } from "../../../../../../config";
import PropTypes from "prop-types";

const formatData = (data) => {
  return data.map((item) => ({
    ...item,
    day: moment(item.day).format(historicalTrendXAxisDateFormat),
  }));
};

const SlStackedAreaChart = ({ data, dataScopeInDays, onSelectScope }) => {
  const { t } = useTranslation();

  if (!data || data.length === 0) {
    return null;
  }

  const groups = Object.keys(data[0]).slice(1);
  const getTickInterval = () => {
    return Math.ceil(data.length / 8);
  };

  const getMessageForOptionButton = (numberOfDays) => {
    return t("predict.callInVolumePrediction.lastNumberOfDays", {
      days: Math.abs(numberOfDays),
    });
  };

  const getButtonsOptions = () => {
    return [-30, -60, -90].map((o) => ({
      label: getMessageForOptionButton(o),
      valueInDays: o,
    }));
  };

  return (
    <div className="sl-stacked-area-chart">
      <div className="sl-stacked-area-chart__times">
        <SlButtonsGroup
          classes={(current) => ({
            ...current,
            root: `${current.root} sl-stacked-area-chart__times-switchers`,
          })}
          currentValue={dataScopeInDays}
          onOptionSelected={(option) => onSelectScope(option.valueInDays)}
          options={getButtonsOptions()}
        />
      </div>
      <ResponsiveContainer minHeight={420}>
        <AreaChart
          width={800}
          height={550}
          data={formatData(data)}
          margin={{ top: 10, right: 30, left: 20, bottom: 0 }}
        >
          <XAxis dataKey="day" tickCount={35} interval={getTickInterval()} />
          <YAxis />
          <CartesianGrid />
          <Tooltip content={<StackedAreaChartTooltip groups={groups} />} />
          {groups.map((group) => (
            <Area
              key={group}
              type="linear"
              dataKey={group}
              stroke={getColorByLabelId(group)}
              stackId={1}
              fillOpacity={0.2}
              fill={getColorByLabelId(group)}
            />
          ))}
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

SlStackedAreaChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string,
      "predict.callInVolumePrediction.dataLabels.deviceConnection":
        PropTypes.number,
      "predict.callInVolumePrediction.dataLabels.deviceSetup": PropTypes.number,
      "predict.callInVolumePrediction.dataLabels.internetPerformance":
        PropTypes.number,
      "predict.callInVolumePrediction.dataLabels.networkOffline":
        PropTypes.number,
      "predict.callInVolumePrediction.dataLabels.other": PropTypes.number,
      "predict.callInVolumePrediction.dataLabels.podConnection":
        PropTypes.number,
    })
  ),
  dataScopeInDays: PropTypes.number.isRequired,
  onSelectScope: PropTypes.func.isRequired,
};

export { SlStackedAreaChart };
