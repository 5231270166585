import React from "react";
import { connect } from "react-redux";
import StyledInput from "../../../Atoms/StyledInput";
import { resetLocation } from "../../../../../../actions/location/locationActions";

const SearchAndSearchFilterMenu = (props) => {
  return (
    <div className="Sl-styled-input-cont">
      <StyledInput
        {...props}
        onDeleteText={() => props.resetLocation()}
      ></StyledInput>
      {/*<SearchFilterMenu {...props} ></SearchFilterMenu>*/}
    </div>
  );
};

const mapStateToProps = (state) => ({
  filterSearchArray: state.monitorLAS.filterSearchArray,
  filterSearchLocations: state.monitorLAS.filterSearchLocations,
});

const mapDispatchToProps = (dispatch) => {
  return {
    resetLocation: () => dispatch(resetLocation()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchAndSearchFilterMenu);
