export const adminPanelData = {
  ispOutageSummary: {
    group: "CSP Alerts",
    titleId: "admin.alerts.cspOutage.title",
    descriptionId: "admin.alerts.cspOutage.description",
    id: 0,
  },
  ispOutageClearSummary: {
    group: "CSP Alerts",
    titleId: "admin.alerts.cspOutageClear.title",
    descriptionId: "admin.alerts.cspOutageClear.description",
    id: 1,
  },
  ispLocationOfflineAbsolute: {
    group: "CSP Alerts",
    titleId: "admin.alerts.totalLocationsOffline.title",
    descriptionId: "admin.alerts.totalLocationsOffline.description",
    id: 2,
  },
  ispLocationOfflineRelative: {
    group: "CSP Alerts",
    titleId: "admin.alerts.locationsOfflineOutage.title",
    descriptionId: "admin.alerts.locationsOfflineOutage.description",
    id: 3,
  },
  ispLocationOfflineClearAbsolute: {
    group: "CSP Alerts",
    titleId: "admin.alerts.totalLocationsOfflineClear.title",
    descriptionId: "admin.alerts.totalLocationsOfflineClear.description",
    id: 4,
  },
  ispLocationOfflineClearRelative: {
    group: "CSP Alerts",
    titleId: "admin.alerts.locationsOfflineOutageClear.title",
    descriptionId: "admin.alerts.locationsOfflineOutageClear.description",
    id: 5,
  },
  evilBrother: {
    group: "Consumer Notifications",
    titleId: "admin.alerts.evilBrother.title",
    descriptionId: "admin.alerts.evilBrother.description",
    id: 6,
  },
  evilTwin: {
    group: "Consumer Notifications",
    titleId: "admin.alerts.evilTwin.title",
    descriptionId: "admin.alerts.evilTwin.description",
    id: 7,
  },
  ispEvilTwin: {
    group: "CSP Alerts",
    titleId: "admin.alerts.evilTwinParallelNetwork.title",
    descriptionId: "admin.alerts.evilTwinParallelNetwork.description",
    id: 8,
  },
  ispOutage: {
    group: "Consumer Notifications",
    titleId: "admin.alerts.cspOutageConsumer.title",
    descriptionId: "admin.alerts.cspOutageConsumer.description",
    id: 9,
  },
  ispOutageClear: {
    group: "Consumer Notifications",
    titleId: "admin.alerts.cspOutageClearConsumer.title",
    descriptionId: "admin.alerts.cspOutageClearConsumer.description",
    id: 10,
  },
  poorPodPlacement: {
    group: "Consumer Notifications",
    titleId: "admin.alerts.poorPodPlacement.title",
    descriptionId: "admin.alerts.poorPodPlacement.description",
    id: 11,
  },
};
