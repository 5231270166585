const MAP_QUERY = `query mapQuery($id:ID){
                                          partner(id:$id){
                                           offlineNetwork{
                                             warningPoints{
                                               id,
                                               geo{
                                                 lat
                                                 long
                                               }
                                             },
                                             troublePoints{
                                               id,
                                               alarmCount,
                                               geo{
                                                 lat
                                                 long
                                               }
                                             },
                                           }
                                           ispOutage{
                                             warningPoints{
                                               id,
                                               geo{
                                                 lat
                                                 long
                                               }
                                             },
                                             troublePoints{
                                               id,
                                               alarmCount,
                                               geo{
                                                 lat
                                                 long
                                               }
                                             },
                                           }
                                           evilTwin{
                                             warningPoints{
                                               id,
                                               geo{
                                                 lat
                                                 long
                                               }
                                             },
                                             troublePoints{
                                               id,
                                               alarmCount,
                                               geo{
                                                 lat
                                                 long
                                               }
                                             },
                                           }
                                           unstableNetwork{
                                             warningPoints{
                                               id,
                                               geo{
                                                 lat
                                                 long
                                               }
                                             },
                                             troublePoints{
                                               id,
                                               alarmCount,
                                               geo{
                                                 lat
                                                 long
                                               }
                                             },
                                           }
                                         }
                                       }`;

const HEADER_QUERY = `query MONITOR_HEAD($id:ID){
                                    partner(id:$id){
                                        name
                                        homesMonitored
                                        pctHomesInAlarm
                                        homesInAlarm
                                        homesMonitoredUpdatedAt
                                       }
                                      }`;

const TIME_DATA = ` query TIME_DATA($id:ID){
                                  partner(id:$id){
                                    name
                                    unstableNetwork{
                                      daily(daysBack: 30){
                                        pctWarning{
                                          ts,
                                          val,
                                        }
                                      pctTrouble{
                                        ts,
                                        val,
                                       }
                                     }
                                    },
                                    ispOutage{
                                      daily(daysBack: 30){
                                        pctWarning{
                                          ts,
                                          val,
                                        }
                                      pctTrouble{
                                        ts,
                                        val,
                                       }
                                     }
                                    },
                                    evilTwin{
                                      daily(daysBack: 30){
                                        pctWarning{
                                          ts,
                                          val,
                                        }
                                      pctTrouble{
                                        ts,
                                        val,
                                       }
                                     }
                                    },
                                    offlineNetwork{
                                      daily(daysBack: 30){
                                        pctWarning{
                                          ts,
                                          val,
                                        }
                                      pctTrouble{
                                        ts,
                                        val,
                                       }
                                     }
                                    },
                                  }
                                }`;

const MAP_DATA_NUMBER_QUERY = `query mapQueryDataNumbers($id:ID){
                                     partner(id:$id){
                                       id
                                       name
                                       alarmLocationCounts{
                                         id,
                                         alarmCount
                                    }
                                  }
                                }`;

export { MAP_QUERY, MAP_DATA_NUMBER_QUERY, HEADER_QUERY, TIME_DATA };
