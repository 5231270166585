export const mapKPINameToData = {
  "Offline Network": "offlineNetwork",
  "Unstable Network": "unstableNetwork",
  "Poor Coverage": "poorCoverage",
  "High Congestion": "highCongestion",
  "CSP Outage": "ispOutage",
  "Evil Twin": "evilTwin",
};

export function parseDateDaily(date) {
  let time = new Date(date);
  return new Date(time.getTime() - time.getTimezoneOffset() * -60000);
}

export function sortDates(a, b) {
  return a.time.getTime() - b.time.getTime();
}
