import producer from "immer";

import {
  PARTNER_NOT_FOUND,
  SET_TOKEN,
  SET_EMAIL,
  SET_DEPLOYMENT,
  SET_ME_NAME,
  SET_PARTNERS,
  SET_ROLE,
  CHANGE_SELECTED_PARTNER,
  CHANGE_DARK_MODE,
  CHANGE_PARTNER_TIME,
  SET_USER_NAME,
  RESET_PARTNERS,
  SET_PARTNER_IDS,
} from "../actions/meActions";

export const UserRoles = {
  Admin: "admin",
  AdminReadOnly: "adminReadOnly",
  Partner: "partner",
  PartnerReadOnly: "partnerReadOnly",
};

export const INIT_STATE = {
  id: null,
  token: null,
  deployment: null,
  primaryDeployment: null,
  name: null,
  partners: [],
  role: null,
  selectedPartner: null,
  darkMode: true,
  partnerNotFound: false,
};

const meReducer = producer((draftState, action = { type: null }) => {
  switch (action.type) {
    case SET_EMAIL:
      draftState.email = action.payload.email;
      return;
    case SET_TOKEN:
      draftState.token = action.payload.token;
      return;
    case SET_DEPLOYMENT:
      draftState.deployment = action.payload.deployment;
      return;
    case SET_ME_NAME:
      draftState.name = action.payload.name;
      return;
    case SET_PARTNER_IDS:
      draftState.partnerIds = action.payload.partnerIds;
      return;
    case RESET_PARTNERS:
      draftState = INIT_STATE;
      return;
    case SET_PARTNERS:
      draftState.partners = [
        ...draftState.partners,
        ...action.payload.partners.filter(
          (partner) =>
            !draftState.partners.some(
              (partner2) => partner2.name === partner.name
            )
        ),
      ].sort((a, b) => a.name.localeCompare(b.name));
      return;
    case SET_ROLE:
      draftState.role = action.payload.role;
      return;
    case CHANGE_DARK_MODE:
      draftState.darkMode = true;
      return;
    case CHANGE_SELECTED_PARTNER:
      draftState.selectedPartner = action.payload.new_partner || "";
      return;
    case CHANGE_PARTNER_TIME:
      draftState.partners.forEach((d, i) => {
        if (d.id === action.payload.partnerID) {
          d.time = action.payload.time;
          return d;
        } else {
          return d;
        }
      });
      draftState.selectedPartner.time = action.payload.time;
      return;
    case PARTNER_NOT_FOUND:
      draftState.partnerNotFound = true;
      return;
    case SET_USER_NAME:
      draftState.name = action.payload.name;
      return;
    default:
      return;
  }
}, INIT_STATE);

export default meReducer;
