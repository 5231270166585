import React, { useCallback, useEffect } from "react";
import { useHistory } from "react-router";
import { PuffLoader } from "react-spinners";
import { connect, useSelector } from "react-redux";

import AlertAdminList from "../../Organisms/AlertAdminList";
import { InputAndDeleteListEmail } from "../../../../components/communal/Organisms/InputAndDeleteList/InputAndDeleteListEmail";

import {
  addEmailToGroupThunk,
  alertThresholdPatchChange,
  deleteEmailToGroupThunk,
  getAlerts,
  patchAlertEnable,
  throttlingPatchChange,
} from "../../../../../actions/admin-action";
import {
  selectAdminEmailGroup,
  selectAlertTypesGrouped,
  selectCanChangeAdminConfiguration,
} from "../../../../../selectors";

import "./configTempPage.scss";
import { SlPage } from "../../../communal/Atoms/Page/Page";
import { SlSection } from "../../../communal/Atoms/Section/Section";
import { SlButton } from "../../../communal/Atoms/Button/Button";
import { SlIcon } from "../../../communal/Atoms/Icon/Icon";
import { SlText } from "../../../communal/Atoms/Text/Text";
import backArrow from "../../../../../assets/svg/icon-back-arrow.svg";
import { SlHeader } from "../../../communal/Molecules/BlockHeader/Header";
import cx from "classnames";
import { useTranslation } from "react-i18next";
import { AlertGroups } from "../../../../../reducers/adminReducer";
import FormattedMessage from "../../../../../translations/FormattedMessage";
import DataError from "../../../communal/Atoms/DataError";

const ConfigTempPage = ({
  alerts,
  addEmail,
  channelThrottleChange,
  deleteEmail,
  handleThresholdChange,
  getConfigData,
  loading,
  ispID,
  alertOnOff,
  canChangeAdminConfiguration,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const isError = useSelector((state) => state.admin.error);

  useEffect(() => {
    getConfigData();
    // eslint-disable-next-line
  }, [ispID]);

  const classes = cx("sl-admin-page", {
    "sl-admin-page--enabled": canChangeAdminConfiguration,
    "sl-admin-page--disabled": !canChangeAdminConfiguration,
  });

  const getSlPageProps = useCallback(() => {
    const slPageProps = {
      className: classes,
    };
    if (!isError && !loading) {
      slPageProps.id =
        "signal-admin-success"; /* FES-493: New Relic expects this ID */
    }

    return slPageProps;
  }, [isError, loading]);

  if (isError) {
    return (
      <div className="signal-admin-error">
        <DataError />
      </div>
    );
  }

  if (alerts === null || loading) {
    return <PuffLoader />;
  }

  return (
    <SlPage {...getSlPageProps()}>
      <SlSection>
        <SlButton round onClick={history.goBack}>
          <SlIcon svgFile={backArrow} />
        </SlButton>
      </SlSection>
      <SlSection>
        <SlHeader
          start={
            <SlText titleSignal>
              <FormattedMessage id="admin.alerts.sectionTitle" />
            </SlText>
          }
          horizontalLine
        />
      </SlSection>

      {Object.keys(alerts).map((groupTitleId) => {
        if (groupTitleId === AlertGroups.ConsumerAlerts) {
          return null;
        }
        return (
          <AlertAdminList
            key={groupTitleId}
            title={t(groupTitleId)}
            alerts={alerts[groupTitleId]}
            alertOnOff={alertOnOff}
            channelThrottleChange={channelThrottleChange}
            isEditingDisabled={!canChangeAdminConfiguration}
            handleThresholdChange={handleThresholdChange}
          />
        );
      })}
      <SlSection>
        <SlHeader
          start={
            <SlText titleSignal>
              <FormattedMessage id="admin.emailNotifications.sectionTitle" />
            </SlText>
          }
          horizontalLine
        />
        <InputAndDeleteListEmail
          addEmail={addEmail}
          deleteEmail={deleteEmail}
          isEditingDisabled={!canChangeAdminConfiguration}
        />
      </SlSection>
    </SlPage>
  );
};

const mapStateToProps = (state) => ({
  alerts: state.admin.alerts === null ? null : selectAlertTypesGrouped(state),
  emailList: state.admin.alerts === null ? null : selectAdminEmailGroup(state),
  ispID: state.me.selectedPartner.id,
  loading: state.admin.loading,
  canChangeAdminConfiguration: selectCanChangeAdminConfiguration(state),
});

const mapDispatchToProps = (dispatch) => ({
  getConfigData: () => {
    dispatch(getAlerts());
  },
  alertOnOff: (index) => {
    dispatch(patchAlertEnable(index));
  },
  channelThrottleChange: (val, alertIndex, channelIndex) => {
    dispatch(throttlingPatchChange(val, alertIndex, channelIndex));
  },
  addEmail: (email, group) => (email, group) => {
    dispatch(addEmailToGroupThunk(email, group));
  },
  deleteEmail: (textArr, group) => {
    dispatch(deleteEmailToGroupThunk(textArr, group));
  },
  handleThresholdChange: (val, alertIndex) => {
    dispatch(alertThresholdPatchChange(val, alertIndex));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigTempPage);
