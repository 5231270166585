import React, { useMemo, useState } from "react";
import { SlPage } from "../../components/communal/Atoms/Page/Page";
import { SlSection } from "../../components/communal/Atoms/Section/Section";
import { SlCenter } from "../../components/communal/Atoms/Center/Center";
import TextAndRedirect from "../../components/communal/Atoms/TextAndRedirect";
import { Routes, ticketDateFormat } from "../../../config";
import "./TicketPage.scss";

import {
  changeAlertTimeSelectedTicket,
  changeChartUnit,
  getTicket,
  resetAlert,
  setSelectedLocation,
  getPartnerInfo,
} from "../../../actions/alert/alertActions";

import { useEffect } from "react";
import { SlHeader } from "../communal/Molecules/BlockHeader/Header";
import { SlMap } from "../communal/Atoms/Map/Map";
import { Col, Row } from "react-flexbox-grid";
import { SlStackedBar } from "../communal/Atoms/Charts/FancyStackedBar/FancyStackedBar";
import { AlertLocationAlarmScores } from "../../stories/templates/AlertLocationAlarmScores";

import { useDispatch, useSelector } from "react-redux";
import { SlButton } from "../communal/Atoms/Button/Button";
import { SlIcon } from "../communal/Atoms/Icon/Icon";
import { useHistory, useLocation, useParams } from "react-router";
import moment from "moment";
import backArrow from "../../../assets/svg/icon-back-arrow.svg";
import { SlTimeSelector } from "../communal/TimeSelector/TimeSelector";
import * as queryString from "query-string";
import { addFilterFromBookmarks } from "../../../actions/alert/alertLASActions";

import {
  setTicketErr,
  removeTicketErr,
} from "../../../actions/alert/alertTicketActions";
import {
  getOngoingLocations,
  getRecentDate,
  getSelectedTicket,
  getTimeseries,
} from "../../../selectors/alert/alert.selectors";
import { SlPanelLoader } from "../communal/Atoms/PanelLoader/PanelLoader";

import { getLocationsData } from "../../../actions/location/locationActions";
import { DEFAULT_WORLD_VIEW } from "../../../utils/constants";
import { updatePartner } from "../../../actions/meActions";
import { MapTooltip } from "../communal/Organisms/MapTooltip/MapTooltip";
import { SlDropdown } from "../communal/Molecules/Dropdown/Dropdown";
import threeDotsIcon from "../../../assets/icons/three_dots.svg";
import { SlText } from "../communal/Atoms/Text/Text";
import { useTranslation } from "react-i18next";
import FormattedMessage from "../../../translations/FormattedMessage";
import { OverlayUpdated } from "../communal/Atoms/OverlayUpdated/OverlayUpdated";

const useQuery = () => new URLSearchParams(useLocation().search);

export const TicketPage = () => {
  const { push } = useHistory();
  const { ticketId, partnerId } = useParams();
  const dispatch = useDispatch();
  const selectedTicket = useSelector((state) => getSelectedTicket(state));
  const { t } = useTranslation();
  const yAxisUnitKey = t("ticket.chart.chartMenu.yAxisMeasure");
  const percentageOption = t("ticket.chart.chartMenu.percentage");
  const numberOption = t("ticket.chart.chartMenu.number");
  const barchartOption = t("ticket.chart.chartMenu.barChart");

  const handleDropdownSelect = (option, section) => {
    if (
      section === yAxisUnitKey &&
      typeof selections[yAxisUnitKey]?.options[percentageOption] !== "undefined"
    ) {
      const rawOption = selections[yAxisUnitKey]?.labels[option];
      dispatch(changeChartUnit({ name: "selectedTicket", type: rawOption }));
      setSelections((prev) => ({
        ...prev,
        [yAxisUnitKey]: {
          labels: {
            ...prev[yAxisUnitKey].labels,
          },
          options: {
            [numberOption]: option !== percentageOption,
            [percentageOption]: option === percentageOption,
          },
        },
      }));
    }
  };

  const timeSeries = useSelector((state) =>
    getTimeseries([state.alert.selectedTicket, state.alert.All.homesMonitored])
  );

  const recentDate = useSelector((state) =>
    getRecentDate(
      state.alert.selectedTicket?.hourly?.length
        ? state.alert.selectedTicket.hourly
        : state.alert.selectedTicket.daily
    )
  );

  const locations = useSelector((state) =>
    getOngoingLocations(state.alert.selectedTicket.locations)
  );

  const selectedPartners = useSelector((state) => state.me.partners);

  const ticketErr = useSelector((state) => {
    if (!state.alert.selectedTicket.loading) {
      return (
        state.alertTicket.ticketErr ||
        state.alert.selectedTicket.id == undefined
      );
    }
  });

  const [selections, setSelections] = useState({});

  let query = useQuery();
  const getDefaultSelections = (type) => {
    const yAxisUnit = {
      labels: {
        [numberOption]: "Number",
      },
      options: {
        [numberOption]: true,
      },
    };
    if (type === "evilTwinAlerts") {
      yAxisUnit.options[percentageOption] = false;
      yAxisUnit.labels[percentageOption] = "Percentage";
    }
    return {
      [yAxisUnitKey]: yAxisUnit,
      [t("ticket.chart.chartMenu.changeChart")]: {
        labels: {
          [barchartOption]: "Barchart",
        },
        options: {
          [barchartOption]: true,
        },
      },
    };
  };

  const loadTicket = () => {
    const type = query.get("type");
    const notFoundPartner = selectedPartners.find((p) => p.id === partnerId);

    if (notFoundPartner === undefined) {
      dispatch(setTicketErr());
    } else {
      let period = "hourly";
      dispatch(getPartnerInfo());
      dispatch(updatePartner(partnerId));
      dispatch(resetAlert("selectedTicket"));

      if (type === "evilTwinAlerts") {
        period = "daily";
      }

      if (!type) {
        period = "all";
      }
      setSelections(getDefaultSelections(type));

      dispatch(
        getTicket({
          ticketId,
          period,
          type,
        })
      );
    }
  };

  const onClickRow = (d) =>
    dispatch(getLocationsData({ locationId: d.locationId }));

  const mapLocations = useMemo(() => {
    let _locations = [];
    if (selectedTicket?.geo?.lat) {
      _locations = [
        {
          nominal: selectedTicket.nominal,
          lat: selectedTicket.geo?.lat,
          long: selectedTicket.geo?.long,
          id: selectedTicket.id,
          city: selectedTicket.city,
          timestamp: selectedTicket.timestamp,
        },
      ];
    } else if (locations) {
      _locations = locations;
    }

    return _locations;
  }, [selectedTicket, locations]);

  const handleBookmarkFilter = (key, string, keyList) => {
    dispatch(addFilterFromBookmarks(string, keyList));
  };

  const handleQueryParams = () => {
    const parsed = queryString.parse(location.search);
    Object.keys(parsed).forEach((key) => {
      if (key.indexOf("filter-") > -1) {
        const string = key.replace("filter-", "");
        if (Array.isArray(parsed[key])) {
          parsed[key].forEach((val) => {
            handleBookmarkFilter(string, val, [string, val]);
          });
        } else {
          handleBookmarkFilter(string, parsed[key], [string, parsed[key]]);
        }
      }

      if (key === "location") {
        dispatch(setSelectedLocation(parsed[key]));
      }
    });
  };

  let location = useLocation();

  useEffect(() => {
    if (partnerId) {
      loadTicket();
      handleQueryParams();
    }
    // eslint-disable-next-line
  }, [partnerId]);

  //Show error page in case of error
  if (ticketErr) {
    return (
      <SlCenter>
        <div className="sl-error-page-height">
          <TextAndRedirect
            text={t("ticket.ticketNotFoundMessage")}
            redirectURL={Routes.Alert}
            unMountFunc={() => {
              dispatch(removeTicketErr());
            }}
          />
        </div>
      </SlCenter>
    );
  }

  //If no error, go ahead and show ticket page
  return (
    <div className="signal-ticket">
      <SlPage>
        {selectedTicket.loading ? (
          <SlPanelLoader />
        ) : (
          <SlSection className={"sl-alert-data"}>
            <SlButton
              round
              onClick={() =>
                push(
                  location.pathname.slice(0, location.pathname.lastIndexOf("/"))
                )
              }
              className="ticket-back-arrow"
            >
              <SlIcon svgFile={backArrow} />
            </SlButton>

            <div className="ticket-information">
              <SlText subtitle2m>{selectedTicket.city}</SlText>
              <SlText p7>
                <FormattedMessage
                  id="ticket.ticketHeading"
                  params={{ id: selectedTicket.id }}
                />
              </SlText>
              <SlText p7 destressed>
                {moment(selectedTicket.timestamp).format(ticketDateFormat)}
              </SlText>
            </div>

            <SlHeader
              start={<SlText titleSignal>{selectedTicket.type}</SlText>}
              end={[
                <SlTimeSelector
                  id={"selectedTicket"}
                  key={1}
                  times={selectedTicket.times}
                  changeAction={(type, time) =>
                    dispatch(
                      changeAlertTimeSelectedTicket({
                        type: query.get("type"),
                        time,
                        ticketId,
                      })
                    )
                  }
                />,
              ]}
              horizontalLine={true}
            />
            <Row middle="xs" className="alert-row">
              <Col md={5}>
                {mapLocations?.length ? (
                  <SlMap
                    data={mapLocations}
                    tooltip={(tooltip) => (
                      <MapTooltip
                        hideTicketInfo
                        tooltip={tooltip}
                        label={selectedTicket.type}
                        partnerId={partnerId}
                      />
                    )}
                  >
                    <OverlayUpdated recentDate={recentDate} />
                  </SlMap>
                ) : (
                  <SlMap
                    zoom={DEFAULT_WORLD_VIEW.zoom}
                    lat={DEFAULT_WORLD_VIEW.lat}
                    long={DEFAULT_WORLD_VIEW.long}
                  >
                    <OverlayUpdated recentDate={recentDate} />
                  </SlMap>
                )}
              </Col>
              <Col md={7} className={"alert-barchart"}>
                <div className={"alert-barchart__header"}>
                  <SlText subtitle2m>
                    <FormattedMessage id="ticket.alertHistory" />
                  </SlText>
                  <SlDropdown
                    selections={selections}
                    onSelect={(option, section) => {
                      handleDropdownSelect(option, section);
                    }}
                    trigger={
                      <SlButton round>
                        <SlIcon svgFile={threeDotsIcon} />
                      </SlButton>
                    }
                  />
                </div>

                <div className={"alert-barchart__inner"}>
                  {timeSeries ? (
                    <SlStackedBar
                      unit={selectedTicket.chartUnit}
                      data={timeSeries}
                    />
                  ) : (
                    <SlPanelLoader />
                  )}
                </div>
              </Col>
            </Row>
            <AlertLocationAlarmScores
              headers={[
                "ticket.table.columns.locationId",
                "ticket.table.columns.status",
                "ticket.table.columns.city",
                "ticket.table.columns.zip",
                "ticket.table.columns.country",
                "ticket.table.columns.csp",
                "ticket.table.columns.url",
                "ticket.table.columns.customerId",
                "ticket.table.columns.ticketId",
                "ticket.table.columns.date",
              ]}
              keys={[
                "locationId",
                "status",
                "city",
                "zip",
                "country",
                "isp",
                "url",
                "customerId",
                "ticketId",
                "timeStamp",
              ]}
              onClickRow={onClickRow}
            />
          </SlSection>
        )}
      </SlPage>
    </div>
  );
};
