import React, { useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";
import "./PredictPage.scss";
import {
  getCallInVolumeData,
  getHistoricalTrendData,
  getPredictSummaryData,
  predictSetDataScopeInDays,
} from "../../../actions/predictActions";
import TopmostSection from "../communal/Organisms/TopmostSection";
import { useFeatureFlag } from "../../../hooks/useFeatureFlag";
import { FeatureFlags, Routes, ticketDateFormat } from "../../../config";
import { useRedirectToRoute } from "../../../hooks/useRedirectToRoute";
import { SlSection } from "../communal/Atoms/Section/Section";
import { SlMetric } from "../communal/Molecules/Metric/Metric";
import { SlIcon } from "../communal/Atoms/Icon/Icon";
import RadarIcon from "../../../assets/svg/icon-radar.svg";
import CallIcon from "../../../assets/svg/icon-call.svg";
import { CallInVolumePrediction } from "../communal/Organisms/CallInVolumePrediction/CallInVolumePrediction";
import { SlHeader } from "../communal/Molecules/BlockHeader/Header";
import { SlText } from "../communal/Atoms/Text/Text";
import ReactDOMServer from "react-dom/server";
import FormattedMessage from "../../../translations/FormattedMessage";
import InfoIcon from "../../../assets/svg/icon-info.svg";

const getPredictionTimestamp = (pipelineTimestamp) => {
  // the start of the prediction time window is the pipeline timestamp rounded up to the top of the next hour
  const predictionWindowStart = new Date(
    new Date(pipelineTimestamp).getDate() + 60 * 60 * 1000
  ).setMinutes(0);
  return moment(predictionWindowStart).format(ticketDateFormat);
};

const PredictPage = ({
  getData,
  getHistoricalTrendData,
  totalLocationsMonitored,
  predictedCallVolume24Hours,
  predictedCallVolume24HoursTimestamp,
  predictedCallVolume7Days,
  predictedCallVolume7DaysTimestamp,
}) => {
  const isPredictPageAvailable = useFeatureFlag(FeatureFlags.Predict);
  const redirectToRoute = useRedirectToRoute();

  useEffect(() => {
    getData();
    getHistoricalTrendData(moment().subtract(30, "days"), moment());
  }, [getData, getHistoricalTrendData]);

  if (!isPredictPageAvailable()) {
    redirectToRoute(Routes.Index);
    return null;
  }

  return (
    <div className="sl-predict-page">
      <TopmostSection>
        <SlMetric
          icon={<SlIcon svgFile={RadarIcon} width={40} height={40} />}
          value={totalLocationsMonitored}
          title={
            <FormattedMessage id="predict.header.totalLocationsMonitored" />
          }
        />
        <SlMetric
          icon={<SlIcon svgFile={CallIcon} width={40} height={40} />}
          value={predictedCallVolume24Hours}
          title={
            <FormattedMessage id="predict.header.predictedCallVolume24Hours" />
          }
          tooltip={
            <FormattedMessage
              id="predict.header.predictedCallVolume24HoursTooltip"
              params={{
                periodStart: getPredictionTimestamp(
                  predictedCallVolume24HoursTimestamp
                ),
              }}
            />
          }
        />
        <SlMetric
          icon={<SlIcon svgFile={CallIcon} width={40} height={40} />}
          value={predictedCallVolume7Days}
          title={
            <FormattedMessage id="predict.header.predictedCallVolume7Days" />
          }
          tooltip={
            <FormattedMessage
              id="predict.header.predictedCallVolume7DaysTooltip"
              params={{
                periodStart: getPredictionTimestamp(
                  predictedCallVolume7DaysTimestamp
                ),
              }}
            />
          }
        />
      </TopmostSection>
      <SlSection>
        <SlHeader
          start={
            <SlText titleSignal>
              <FormattedMessage id="predict.callInVolumePrediction.sectionTitle" />
            </SlText>
          }
          horizontalLine
        >
          <div
            data-html={true}
            data-tip={ReactDOMServer.renderToStaticMarkup(
              <SlText p7>
                <FormattedMessage id="predict.callInVolumePrediction.sectionTooltip" />
              </SlText>
            )}
          >
            <SlIcon width={26} height={26} svgFile={InfoIcon} />
          </div>
        </SlHeader>
        <CallInVolumePrediction />
      </SlSection>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoading: state.predict.dataIsLoading,
  hasErrors: state.predict.dataErrored,
  totalLocationsMonitored: state.predict.totalLocationsMonitored,
  predictedCallVolume24Hours: state.predict.predictedCallVolume24Hours,
  predictedCallVolume24HoursTimestamp:
    state.predict.predictedCallVolume24HoursTimestamp,
  predictedCallVolume7Days: state.predict.predictedCallVolume7Days,
  predictedCallVolume7DaysTimestamp:
    state.predict.predictedCallVolume7DaysTimestamp,
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => {
    dispatch(getPredictSummaryData());
    dispatch(getCallInVolumeData());
    dispatch(getHistoricalTrendData());
  },
  setDataScopeInDays: (dataScopeInDays) =>
    dispatch(predictSetDataScopeInDays(dataScopeInDays)),
  getHistoricalTrendData: () => {
    dispatch(getHistoricalTrendData());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PredictPage);
