import { getEnvironment } from "./environments";

export const API_URL =
  process.env.REACT_APP_API_URL ||
  "https://signal-ext.us-west-2.datavinci.dev.plume.tech"; // TODO FES-494 REMOVE ON MIGRATION

export const API_URL_BETA =
  process.env.REACT_APP_URL_BETA ||
  "https://signal-ext.us-west-2.datavinci.dev.plume.tech"; // TODO FES-494 REMOVE ON MIGRATION

export const API_URL_GAMMA =
  process.env.REACT_APP_URL_GAMMA ||
  "https://signal-ext.us-west-2.datavinci.dev.plume.tech"; // TODO FES-494 REMOVE ON MIGRATION

export const API_URL_KAPPA =
  process.env.REACT_APP_URL_KAPPA ||
  "https://piranha.eu-central-1.prod.kappa.plumenet.io"; // TODO FES-494 REMOVE ON MIGRATION

export const OKTA_BASE_URL =
  process.env.REACT_APP_OKTA_BASE || "https://dev-741674.oktapreview.com"; // TODO FES-494 REMOVE ON MIGRATION
export const OKTA_ISSUER =
  process.env.REACT_APP_OKTA_ISSUER || `${OKTA_BASE_URL}/oauth2/default`; // LEAVE
export const OKTA_CLIENT_ID =
  process.env.REACT_APP_OKTA_CLIENT_ID || "0oa12oet8abmbyIde0h8"; // TODO FES-494 REMOVE ON MIGRATION
export const OKTA_REDIRECT_URI =
  process.env.REACT_APP_OKTA_REDIRECT_URI ||
  `${window.location.origin}/implicit/callback`; // LEAVE
export const OKTA_LOGOUT_URI =
  process.env.REACT_APP_OKTA_LOGOUT_URI || window.location.origin; // LEAVE

export const SIGNAL_ENDPOINT_SPHERE =
  process.env.REACT_APP_SIGNAL_ENDPOINT_SPHERE || "ci"; // TODO FES-494 REMOVE ON MIGRATION
export const VERSION_ENDPOINT =
  process.env.REACT_APP_VERSION_ENDPOINT ||
  "https://signal-ext.us-west-2.datavinci.dev.plume.tech/reports/version"; // TODO FES-494 REMOVE ON MIGRATION

export const SIGNAL_VERSION = process.env.REACT_APP_VERSION;

export const SIGNAL_MOCK_URL =
  process.env.GRAPHQL_MOCK_SERVER ||
  process.env.REACT_APP_GRAPHQL_MOCK_SERVER ||
  "http://localhost:3001/graphql";

export const DEFAULT_WORLD_VIEW = {
  lat: 25,
  long: 0,
  zoom: 0.1,
};

export const DISPLAY_CLOUD_SELECTOR_FALLBACK =
  process.env.REACT_APP_DISPLAY_CLOUD_SELECTOR_FALLBACK === "true" || false;

export const HIDE_ADMIN_MENU =
  process.env.REACT_APP_HIDE_ADMIN_MENU === "true" || false;

export const NOC_URL_GAMMA =
  process.env.REACT_APP_NOC_URL_GAMMA || "https://dogfood.noc.plume.com"; // TODO FES-494 REMOVE ON MIGRATION
export const NOC_URL_KAPPA =
  process.env.REACT_APP_NOC_URL_KAPPA ||
  "https://piranha-dog1.dogfood.us-west-2.aws.plume.tech"; // TODO FES-494 REMOVE ON MIGRATION

const getUrlFor = (targetUri, primaryDeployment) => {
  const mapPrimaryDeploymentToUrlPrefix = {
    kappa: API_URL_KAPPA,
    gamma: API_URL_GAMMA,
  };
  const urlPrefix =
    mapPrimaryDeploymentToUrlPrefix[primaryDeployment] || API_URL_BETA;
  return urlPrefix + targetUri;
};

export const GET_CLOUD_GRAPHQL_URL = (primaryDeployment) => {
  const cloudEnvironment = getEnvironment();

  if (cloudEnvironment) {
    if (cloudEnvironment.id !== primaryDeployment) {
      console.log("Unexpected partner deployment: ", primaryDeployment);
    }
    return cloudEnvironment.graphqlUrl;
  } else {
    return getUrlFor("/reports/signal", primaryDeployment);
  }
};

export const GET_CLOUD_NOTIFICATIONS_URL = (primaryDeployment) => {
  const cloudEnvironment = getEnvironment();

  if (cloudEnvironment) {
    return cloudEnvironment.notificationsUrl;
  } else {
    return getUrlFor("/notifications", primaryDeployment);
  }
};

export const GET_NOC_URL = (primaryDeployment) => {
  const cloudEnvironment = getEnvironment();

  if (cloudEnvironment) {
    return cloudEnvironment.frontlineUrl;
  } else {
    const mapPrimaryDeploymentToNOC = {
      kappa: NOC_URL_KAPPA,
      gamma: NOC_URL_GAMMA,
    };
    return `${
      mapPrimaryDeploymentToNOC[primaryDeployment] || NOC_URL_GAMMA
    }/location`;
  }
};

export const GA_TRACKING_ID =
  process.env.REACT_APP_GA_TRACKING_ID || "UA-71279167-37";
export const TRANSLATION_FILE_PATH_TEMPLATE =
  process.env.REACT_APP_TRANSLATION_FILE_PATH_TEMPLATE || "/{{lng}}.json";
