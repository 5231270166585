import producer from "immer";
import {
  CHANGE_ALERT_TIME_SUCCESS,
  TOGGLE_TICKET,
  SWITCH_CHART_UNIT,
  ON_FETCH_ALERT_DATA_SUCCESS,
  ON_FETCH_TICKET_DATA_SUCCESS,
  RESET_ALERT,
  ON_FETCH_ALERT_DATA,
  ON_FETCH_ALERT_DATA_FAIL,
  ON_FETCH_TICKET_DATA,
  ON_FETCH_TICKET_DATA_FAIL,
  SET_SELECTED_LOCATION,
  CHANGE_CHART_UNIT,
} from "../../actions/alert/alertActions";
import { SET_ALERT_LOCATION_DATA } from "../../actions/alert/alertLASActions";

export const AlertDataLabels = {
  CSPOutage: "CSP Outage",
  EvilTwin: "Evil Twin",
  TotalLocationsOffline: "Total Locations Offline",
  LocationsOfflineOutage: "Locations Offline Outage",
};

const INIT_STATE = {
  loaded: false,
  All: {
    homesMonitored: 0,
    homesInAlert: 0,
  },
  [AlertDataLabels.CSPOutage]: {
    name: "CSP Outage",
    // @TODO: FES-411 will correct tooltip copy
    tooltipCopyId: "alert.alertData.tooltipCopies.cspOutage",
    mapData: [],
    chartData: [],
    showTickets: false,
    times: {
      "24 hours": true,
      "7 days": false,
      "30 days": false,
      "90 days": false,
      "All time": false,
    },
    chartUnit: {
      percentage: false,
      nominal: true,
    },
    history: [],
    timeseries: [],
    hourly: [],
    daily: [],
    tickets: [
      {
        locations: [],
      },
    ],
    locations: [],
    loading: false,
  },
  [AlertDataLabels.EvilTwin]: {
    name: "Evil Twin",
    // @TODO: FES-411 will correct tooltip copy
    tooltipCopyId: "alert.alertData.tooltipCopies.evilTwin",
    mapData: [],
    chartData: [],
    showTickets: false,
    times: {
      "7 days": true,
      "30 days": false,
      "90 days": false,
      "All time": false,
    },
    chartUnit: {
      percentage: false,
      nominal: true,
    },
    history: [],
    timeseries: [],
    daily: [],
    tickets: [
      {
        locations: [],
      },
    ],
    locations: [],
    loading: false,
  },
  [AlertDataLabels.TotalLocationsOffline]: {
    name: "Total Locations Offline",
    // @TODO: FES-411 will correct tooltip copy
    tooltipCopyId: "alert.alertData.tooltipCopies.totalLocationsOffline",
    mapData: [],
    chartData: [],
    showTickets: false,
    times: {
      "24 hours": true,
      "7 days": false,
      "30 days": false,
      "90 days": false,
      "All time": false,
    },
    chartUnit: {
      percentage: false,
      nominal: true,
    },
    history: [],
    timeseries: [],
    hourly: [],
    daily: [],
    tickets: [
      {
        locations: [],
      },
    ],
    locations: [],
    loading: false,
  },
  [AlertDataLabels.LocationsOfflineOutage]: {
    name: "Locations Offline Outage",
    // @TODO: FES-411 will correct tooltip copy
    tooltipCopyId: "alert.alertData.tooltipCopies.locationsOfflineOutage",
    mapData: [],
    chartData: [],
    showTickets: false,
    times: {
      "24 hours": true,
      "7 days": false,
      "30 days": false,
      "90 days": false,
      "All time": false,
    },
    chartUnit: {
      percentage: false,
      nominal: true,
    },
    history: [],
    timeseries: [],
    hourly: [],
    daily: [],
    tickets: [
      {
        locations: [],
      },
    ],
    locations: [],
    loading: false,
  },
  selectedTicket: {
    type: "",
    geo: {
      lat: 0,
      lng: 0,
    },
    chartUnit: {
      percentage: false,
      nominal: true,
    },
    timeseries: [],
    locations: [],
    times: {
      "24 hours": true,
      "7 days": false,
      "30 days": false,
      "90 days": false,
      "All time": false,
    },
    hourly: [],
    daily: [],
    loading: false,
    selectedLocation: "",
  },
};

const alertReducer = producer((draftState, action) => {
  let payload = action.payload;
  switch (action.type) {
    case ON_FETCH_ALERT_DATA:
      action.payload?.type && (draftState[action.payload?.type].loading = true);
      return;
    case ON_FETCH_ALERT_DATA_FAIL:
      action.payload?.type &&
        (draftState[action.payload?.type].loading = false);
      return;
    case ON_FETCH_ALERT_DATA_SUCCESS:
      draftState.loaded = true;
      if (payload.partner.homesMonitored) {
        draftState.All.homesMonitored = payload.partner.homesMonitored;
      }
      if (payload.partner.homesInAlert) {
        draftState.All.homesInAlert = payload.partner.homesInAlert;
      }
      payload.partner.ispOutageAlerts &&
        (draftState[AlertDataLabels.CSPOutage] = {
          ...draftState[AlertDataLabels.CSPOutage],
          ...payload.partner.ispOutageAlerts,
          ...{ loading: false },
        });
      payload.partner.evilTwinAlerts &&
        (draftState[AlertDataLabels.EvilTwin] = {
          ...draftState[AlertDataLabels.EvilTwin],
          ...payload.partner.evilTwinAlerts,
          /** REMOVE WHEN SOBI-2352 IS DONE **/
          ...{
            tickets: payload.partner.evilTwinAlerts.tickets.map((ticket) => ({
              ...ticket,
              type: "evilTwin",
            })),
          },
          /** END **/
          ...{ loading: false },
        });
      payload.partner.totalLocationsOfflineAlerts &&
        (draftState[AlertDataLabels.TotalLocationsOffline] = {
          ...draftState[AlertDataLabels.TotalLocationsOffline],
          ...payload.partner.totalLocationsOfflineAlerts,
          ...{ loading: false },
        });
      payload.partner.locationOfflineOutageAlerts &&
        (draftState[AlertDataLabels.LocationsOfflineOutage] = {
          ...draftState[AlertDataLabels.LocationsOfflineOutage],
          ...payload.partner.locationOfflineOutageAlerts,
          ...{ loading: false },
        });
      return;
    case ON_FETCH_TICKET_DATA:
      draftState.selectedTicket.loading = true;
      draftState.selectedTicket.empty = false;
      return;
    case ON_FETCH_TICKET_DATA_FAIL:
      draftState.selectedTicket.loading = false;
      return;
    case SET_SELECTED_LOCATION:
      draftState.selectedTicket.selectedLocation = payload;
      return;
    case ON_FETCH_TICKET_DATA_SUCCESS:
      let ticket;

      if (payload.partner.ticket) {
        ticket = payload.partner.ticket;
      } else {
        let type = Object.keys(payload.partner)[0];
        if (payload.partner[type]) {
          ticket = payload.partner[type].ticket;
          ticket.type = type.replace("Alerts", "");
        } else {
          draftState.selectedTicket.empty = true;
          draftState.selectedTicket.loading = false;
          return;
        }
      }

      draftState.selectedTicket = {
        ...draftState.selectedTicket,
        ...ticket,
        ...{
          loading: false,
        },
      };

      if (ticket.type === "evilTwin") {
        delete draftState.selectedTicket.times["24 hours"];
        draftState.selectedTicket.times["7 days"] = true;
      }

      return;
    case CHANGE_ALERT_TIME_SUCCESS:
      Object.keys(draftState[action.payload.name].times).forEach((key) => {
        draftState[action.payload.name].times[key] =
          key === action.payload.time;
      });
      return;
    case TOGGLE_TICKET:
      draftState[action.payload.name].showTickets = !draftState[
        action.payload.name
      ].showTickets;
      return;
    case SWITCH_CHART_UNIT:
      draftState[action.payload.name].chartUnit.percentage = !draftState[
        action.payload.name
      ].chartUnit.percentage;
      draftState[action.payload.name].chartUnit.nominal = !draftState[
        action.payload.name
      ].chartUnit.nominal;
      return;
    case CHANGE_CHART_UNIT:
      draftState[action.payload.name].chartUnit.percentage = false;
      draftState[action.payload.name].chartUnit.nominal = false;
      if (action.payload.type === "Percentage") {
        draftState[action.payload.name].chartUnit.percentage = true;
      }
      if (action.payload.type === "Number") {
        draftState[action.payload.name].chartUnit.nominal = true;
      }
      return;
    case SET_ALERT_LOCATION_DATA:
      draftState.selectedTicket.locations =
        action.payload.data.tickets[0].locations;
      return;
    case RESET_ALERT:
      if (action.payload) {
        draftState[action.payload] = INIT_STATE[action.payload];
      } else {
        draftState.All.homesInAlert = INIT_STATE.All.homesInAlert;
        draftState.All.homesMonitored = INIT_STATE.All.homesMonitored;
        draftState[AlertDataLabels.CSPOutage] =
          INIT_STATE[AlertDataLabels.CSPOutage];
        draftState[AlertDataLabels.EvilTwin] =
          INIT_STATE[AlertDataLabels.EvilTwin];
        draftState[AlertDataLabels.TotalLocationsOffline] =
          INIT_STATE[AlertDataLabels.TotalLocationsOffline];
        draftState[AlertDataLabels.LocationsOfflineOutage] =
          INIT_STATE[AlertDataLabels.LocationsOfflineOutage];
        draftState.selectedTicket = INIT_STATE.selectedTicket;
      }
      return;
    default:
      return;
  }
}, INIT_STATE);

export default alertReducer;
