import React, { useState } from "react";

import "./AlertTicketTable.scss";
import { SlText } from "../../Atoms/Text/Text";
import { SlIcon } from "../../Atoms/Icon/Icon";
import { SlChip } from "../../Atoms/Chip/Chip";
import { SlTable } from "../../Molecules/Table/Table";
import moment from "moment";
import { ticketDateFormat } from "../../../../../config";
import { useHistory } from "react-router";

import CopyIcon from "../../../../../assets/svg/icon-copy.svg";
import ShareIcon from "../../../../../assets/svg/icon-share.svg";
import DownloadIcon from "../../../../../assets/svg/icon-download.svg";
import { SlButton } from "../../Atoms/Button/Button";
import { useSelector } from "react-redux";
import { SlTooltip } from "../../Atoms/Tooltip/Tooltip";
import { labelMap } from "../../../../../actions/graphQL_queries/alertQuery";
import FormattedMessage from "../../../../../translations/FormattedMessage";
import { useTranslation } from "react-i18next";
import { getTranslationKey } from "../../../../helpers";

const AlertTicketTable = (props) => {
  const [activeButtonRowStatus, setActiveButtonRowStatus] = useState();
  const { push } = useHistory();
  const { t } = useTranslation();

  const partnerId = useSelector((state) => state.me.selectedPartner.id);

  const onClickBookmark = (event, id) => {
    setActiveButtonRowStatus(id);
    setTimeout(() => {
      setActiveButtonRowStatus(undefined);
    }, 2000);
    event.preventDefault();
    event.stopPropagation();
    window.navigator.clipboard.writeText(
      `${window.origin}/alert/${partnerId}/${id}`
    );
  };

  const onClickEmail = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    const directLink = `${window.origin}/alert/${partnerId}/${id}?type=${
      labelMap[props.type]
    }`;
    const csvLink = `${window.origin}/alert/${partnerId}/${id}/csv?type=${
      labelMap[props.type]
    }`;
    document.location.href = `mailto:?body=Direct%20link:%20${directLink}%0d%0aCSV%20download:%20${csvLink}&subject=Ticket%20id:%20${id}%20is%20in%20alert`;
  };

  const onClickDownload = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    const csvLink = `${window.origin}/alert/${partnerId}/${id}/csv?type=${
      labelMap[props.type]
    }`;
    window.open(csvLink, "_blank");
  };

  const ticketRows =
    props.data &&
    props.data.map((ticket, i) => (
      <tr
        key={i}
        onClick={() =>
          push(`/alert/${partnerId}/${ticket.id}?type=${labelMap[props.type]}`)
        }
      >
        <td className="ticket-status">
          <SlChip
            color={
              ticket.status.match(/reported|resolved/g) ? "resolved" : "primary"
            }
            className={ticket.status === "ongoing" ? "sl-animation--pulse" : ""}
            fill={
              ticket.status.match(/reported|resolved/g) ? "fill" : "outline"
            }
          >
            <SlText
              p7
              primary={ticket.status.match(/ongoing/g)}
              positive={ticket.status.match(/resolved/g)}
            >
              <FormattedMessage id={getTranslationKey(ticket.status)} />
            </SlText>
          </SlChip>
        </td>
        <td className="ticket-details">
          <SlText subtitle4m className="sl-alert-ticket-table__ticket-id">
            <FormattedMessage
              id="alert.ticketTable.ticketId"
              params={{ id: ticket.id }}
            />
          </SlText>
          <SlText p7 className="sl-alert-ticket-table__ticket-details">
            <span className="sl-alert-ticket-locations">
              <FormattedMessage
                id="alert.ticketTable.locationsLabel"
                params={{ count: ticket.nominal }}
              />
            </span>{" "}
            {ticket.city && (
              <FormattedMessage
                id="alert.ticketTable.inCity"
                params={{ city: ticket.city }}
              />
            )}
          </SlText>
        </td>
        <td className="ticket-actions">
          <div className="sl-alert-ticket-table__action-bar">
            <SlText p7 className="sl-typography--destressed">
              {ticket.timestamp &&
                moment(ticket.timestamp).format(ticketDateFormat)}
            </SlText>

            <div className="button-row">
              <SlTooltip interaction={"hover"} title={t("share")}>
                <SlButton
                  round
                  onClick={(event) => onClickEmail(event, ticket.id)}
                >
                  <SlIcon svgFile={ShareIcon} />
                </SlButton>
              </SlTooltip>

              <SlTooltip interaction={"hover"} title={t("download")}>
                <SlButton
                  round
                  onClick={(event) => onClickDownload(event, ticket.id)}
                >
                  <SlIcon svgFile={DownloadIcon} />
                </SlButton>
              </SlTooltip>

              <SlTooltip interaction={"hover"} title={t("copy")}>
                <SlButton
                  round
                  onClick={(event) => onClickBookmark(event, ticket.id)}
                >
                  <SlIcon svgFile={CopyIcon} />
                </SlButton>
              </SlTooltip>
            </div>
            <div
              className={`button-row-status ${
                activeButtonRowStatus !== ticket.id
                  ? "button-row-status--hidden"
                  : ""
              }`}
            >
              <SlText p7 positive>
                <FormattedMessage id="alert.ticketTable.urlCopied" />
              </SlText>
            </div>
          </div>
        </td>
      </tr>
    ));

  return (
    <SlTable className="sl-alert-ticket-table">
      <SlText subtitle2m>{props.title || t("other")}</SlText>
      <table>
        <tbody>{ticketRows}</tbody>
      </table>
    </SlTable>
  );
};

export { AlertTicketTable };
