import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormattedMessage from "../../../translations/FormattedMessage";

const APIVersion = ({ version, error }) => (
  <div className="signal-api-version-cont">
    <span className="signal-v-title">
      <FormattedMessage id="userMenu.apiVersion" />
      &nbsp;
    </span>
    <span className="signalv-v">
      {!error ? version : <FormattedMessage id="userMenu.apiVersionError" />}
    </span>
  </div>
);

APIVersion.propTypes = {
  version: PropTypes.string,
  error: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  version: state.version.version,
  error: state.version.versionError,
});

export default connect(mapStateToProps, undefined)(APIVersion);
