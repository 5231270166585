import { persistV2Auth } from "../reducers/localStorage";

export const VERSION_TWO_AUTHENTICATED_SUCCESS =
  "VERSION_TWO_AUTHENTICATED_SUCCESS";

const versionTwoAuthenticatedSuccess = () => ({
  type: VERSION_TWO_AUTHENTICATED_SUCCESS,
});

const versionTwoAuthenticated = () => {
  return async function (dispatch, getState) {
    dispatch(versionTwoAuthenticatedSuccess());
    persistV2Auth(getState());
  };
};

export { versionTwoAuthenticatedSuccess, versionTwoAuthenticated };
