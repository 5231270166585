import { useCallback, useEffect, useRef } from "react";

export const useToggleByClickOutside = (params) => {
  const rootRef = useRef(null);

  const handleDocumentClick = useCallback(
    (event) => {
      if (
        rootRef &&
        rootRef.current &&
        !rootRef.current.contains(event.target) &&
        params.state
      ) {
        params.toggler();
      }
    },
    [params]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleDocumentClick);

    return () => document.removeEventListener("mousedown", handleDocumentClick);
  }, [params.state, handleDocumentClick]);

  return {
    ref: rootRef,
  };
};
