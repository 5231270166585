import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import "./menuOpener.scss";

export default function MenuOpener({ text, icon, onClick }) {
  function handleOnClick() {
    onClick();
  }

  return (
    <div
      className={classNames("sl-plume-menu-opener-wrapper")}
      onMouseDown={handleOnClick}
    >
      <span className={classNames("sl-plume-text-button-text-icon")}>
        <span className="sl-plume-text-button-icon"> {icon}</span>
        <span className="sl-plume-text-button-text">{text}</span>
      </span>
    </div>
  );
}

MenuOpener.propTypes = {
  text: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
};
