import React from "react";
import PropTypes from "prop-types";

import { SlIcon } from "../Icon/Icon";

import LeftArrowIcon from "../../../../../assets/icons/left_arrow_icon.svg";

import "./expandLeft.scss";

const ExpandLeft = ({ handleExpansion }) => {
  function handleOnClick() {
    handleExpansion();
  }

  return (
    <div className="sl-expand-left-wrapper-wrapper">
      <div onMouseDown={handleOnClick} className="sl-expand-left-wrapper">
        <SlIcon svgFile={LeftArrowIcon} />
      </div>
    </div>
  );
};

ExpandLeft.propTypes = {
  handleExpansion: PropTypes.func.isRequired,
};

export { ExpandLeft };
