import React from "react";

import "./locationHistoryMessage.scss";
import FormattedMessage from "../../../../translations/FormattedMessage";

const LocationHistoryMessage = () => (
  <div className="Sl-LHM">
    <FormattedMessage id="monitor.locationHistory.noAlarmsFound" />
  </div>
);

export default LocationHistoryMessage;
