import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SlText } from "../../Text/Text";

import "./TableItem.scss";

const formatCell = (columnName, cellText) => {
  if (columnName === "isp-row-item") {
    return cellText[2].length <= 25
      ? cellText[2]
      : `${cellText[2].substr(0, 24).trim()}\u2026`;
  } else {
    return cellText;
  }
};

const SlTableItem = ({ children, classProp }) => {
  const showClassProp = !!classProp;
  const itemClass = `${classProp}-row-item`;

  return (
    <td className={classNames("sl-tableitem", { [itemClass]: showClassProp })}>
      {children || children === 0 ? (
        <SlText p7 destressed className="sl-tableitem-value">
          {formatCell(itemClass, children)}
        </SlText>
      ) : null}
    </td>
  );
};

SlTableItem.propTypes = {
  children: PropTypes.object,
  classProp: PropTypes.object,
};

export { SlTableItem };
