import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { format } from "d3-format";
import "./filterOptions.scss";
import FilterMenu from "../../Molecules/FilterMenu";
import { useSelector } from "react-redux";

const CheckIcon = ({ on }) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      opacity="0.2"
      className={classNames("Sl-options-icon", { "Sl-options-icon-on": on })}
    >
      <rect
        className={classNames("Sl-options-icon-rect", {
          "Sl-options-icon-rect-on": on,
        })}
        x="1"
        y="1"
        width="22"
        height="22"
        rx="11"
      />
      <path
        d="M8.53033 11.9697C8.23744 11.6768 7.76256 11.6768 7.46967 11.9697C7.17678 12.2626 7.17678 12.7374 7.46967 13.0303L9.96967 15.5303C10.2716 15.8323 10.7643 15.8216 11.0529 15.5068L16.5529 9.5068C16.8328 9.20146 16.8121 8.72703 16.5068 8.44714C16.2015 8.16724 15.727 8.18787 15.4471 8.49321L10.4764 13.9158L8.53033 11.9697Z"
        fill="#111111"
      />
    </g>
  </svg>
);

const FilterOption = ({
  children,
  count,
  options,
  selectable,
  selectionAction,
  actionKeys,
}) => {
  const [hover, setHover] = useState(false);
  const [subMenu, setSubMenu] = useState(false);
  const [selectionMenu, setSelectionMenu] = useState(false);
  const [onIcon, setOnIcon] = useState(false);

  const selections = useSelector(
    (state) => state.alertLAS.selectedFilters.byName
  );

  useEffect(() => {
    handleSelection();
    // eslint-disable-next-line
  }, [selections]);

  function handleSelection() {
    const optionsInSelection = Object.keys(options?.selections?.byName || []);
    const highlightedParent = optionsInSelection.some((v) =>
      Object.keys(selections).includes(v)
    );
    const isSelected = Object.keys(selections).some(
      (key) => key === options.name
    );
    setOnIcon(hover || highlightedParent || isSelected);
  }

  function handleHover() {
    setHover(true);
    showSubMenu();
  }

  function handleHoverOut() {
    setHover(false);
    hideSubMenu();
  }

  function showSubMenu() {
    if (options["options"]) {
      setSubMenu(true);
    } else if (options["selections"]) {
      setSelectionMenu(true);
    }
  }

  function hideSubMenu() {
    setSubMenu(false);
    setSelectionMenu(false);
  }

  function handleClick(active) {
    if (!selectable) {
      return;
    }

    selectionAction(children, actionKeys, active);
  }

  const filterMenu = subMenu ? (
    <div className="Sl-sub-filter-rel">
      <div className="Sl-sub-filter-abs">
        <FilterMenu
          selectionAction={selectionAction}
          actionKeys={actionKeys}
          selectable={false}
          options={options["options"].byName}
        ></FilterMenu>
      </div>
    </div>
  ) : null;

  const selectionMenuJSX = selectionMenu ? (
    <div className="Sl-sub-filter-rel">
      <div className="Sl-sub-filter-abs">
        <FilterMenu
          selectionAction={selectionAction}
          actionKeys={actionKeys}
          selectable={true}
          options={options["selections"].byName}
        ></FilterMenu>
      </div>
    </div>
  ) : null;

  const countJSX = count ? (
    <div className="Sl-filter-option-count">{format(",.0f")(count)}</div>
  ) : null;

  return (
    <div
      className="Sl-filter-option-cont"
      onMouseEnter={() => {
        handleHover();
      }}
      onMouseLeave={() => {
        handleHoverOut();
      }}
      onClick={() => {
        handleClick(onIcon);
      }}
    >
      <div
        className={classNames("Sl-filter-option", {
          "Sl-filter-option-count": count,
        })}
      >
        <span className="Sl-filter-text"> {children}</span>
        <CheckIcon on={onIcon} />
        {countJSX}
        {filterMenu}
        {selectionMenuJSX}
      </div>
    </div>
  );
};

export default FilterOption;
