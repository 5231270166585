import { getdata } from "../AsynchActions";
import { alertLocationHistory } from "../graphQL_queries/alertQuery";

export const GET_LOCATIONS_DATA_SUCCESS = "GET_LOCATIONS_DATA_SUCCESS";
export const GET_LOCATIONS_DATA_FAIL = "GET_LOCATIONS_DATA_FAIL";
export const ON_FETCH_LOCATIONS_DATA = "ON_FETCH_LOCATIONS_DATA";
export const RESET_LOCATION = "RESET_LOCATION";

export const onFetchLocationsData = () => {
  return {
    payload: {
      loading: true,
    },
    type: ON_FETCH_LOCATIONS_DATA,
  };
};

export const getLocationsDataSuccess = (payload) => {
  return {
    payload,
    type: GET_LOCATIONS_DATA_SUCCESS,
  };
};

export const getLocationsDataFail = () => {
  return {
    type: GET_LOCATIONS_DATA_FAIL,
  };
};

export const resetLocation = () => {
  return {
    type: RESET_LOCATION,
  };
};

export const getLocationsData = getdata(
  onFetchLocationsData,
  getLocationsDataSuccess,
  getLocationsDataFail,
  alertLocationHistory
);
