import React from "react";
import { connect } from "react-redux";
import PlumeSelectionMenu from "../PlumeSelectionMenu";
import { SlIcon } from "../../../Atoms/Icon/Icon";
import { layersOnSelector } from "../../../../../../selectors";
import { turnOnOffLayer } from "../../../../../../actions/mapActions";

import Plus from "../../../../../../assets/svg/plus.svg";

const mapStateToProps = (state) => ({
  selections: layersOnSelector(state),
  MenuText: undefined,
  MenuIcon: <SlIcon svgFile={Plus} />,
});

const mapDispatchToProps = (dispatch) => ({
  selectionClick: (layer) => {
    dispatch(turnOnOffLayer(layer));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PlumeSelectionMenu);
