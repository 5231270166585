import React, { useEffect, useState } from "react";
import { DISPLAY_CLOUD_SELECTOR_FALLBACK } from "../../../utils/constants";
import { useOktaAuth } from "@okta/okta-react";
import { getOktaBaseUrl } from "../../../utils/environments";
import { isRegionSpecificSignal } from "../../../utils/environments";
import { SlInput } from "../communal/Atoms/Input/Input";
import { SlButton } from "../communal/Atoms/Button/Button";
import { SlText } from "../communal/Atoms/Text/Text";
import FormattedMessage from "../../../translations/FormattedMessage";
import "./LoginPage.scss";
import { SlCenter } from "../communal/Atoms/Center/Center";
import { CustomLoginCallback } from "./CustomLoginCallback/CustomLoginCallback";
import { Redirect, useLocation } from "react-router-dom";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";

import PlumeIcon from "../communal/Atoms/Footer/PlumeIcon";

export const LoginPage = () => {
  const location = useLocation();
  const { authState, oktaAuth } = useOktaAuth();
  const [sessionToken, setSessionToken] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [active, setActive] = useState(false);
  const [sessionAvailable, setSessionAvailable] = useState(false);

  useEffect(() => {
    const abortController = new AbortController();

    if (location.state?.error) {
      setError(location.state?.error);
      oktaAuth.closeSession();
    } else {
      checkSessionAndStoreToken(abortController.signal);
    }
    const activeTimer = setTimeout(() => {
      setActive(true);
    }, 50);
    return () => {
      clearTimeout(activeTimer);
      abortController.abort();
    };
    // eslint-disable-next-line
  }, [location]);

  useEffect(() => {
    if (sessionAvailable && authState.isAuthenticated) {
      setSessionAvailable(false);
      window.location = "/";
    }
  }, [authState, sessionAvailable]);

  async function checkSessionAndStoreToken(abortSignal) {
    let abort = false;
    abortSignal.addEventListener("abort", () => {
      abort = true;
    });
    try {
      const session = await oktaAuth.session.get();
      if (!abort && session?.status === "ACTIVE") {
        const tokenresponse = await oktaAuth.token.getWithoutPrompt({
          responseType: "id_token",
          sessionToken: session,
        });

        let tokens = tokenresponse.tokens;
        // Store token
        if (!abort) {
          await oktaAuth.tokenManager.setTokens(tokens);
          setSessionAvailable(true);
        }
      }
    } catch (err) {
      setActive(true);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setActive(false);

    oktaAuth
      .signInWithCredentials({ username, password })
      .then((res) => {
        const sessionToken = res.sessionToken;
        setSessionToken(sessionToken);
        oktaAuth.signInWithRedirect({ sessionToken });
      })
      .catch((err) => {
        setActive(true);
        setError(err);
      });
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  if (sessionToken) {
    return null;
  }

  if (authState?.isAuthenticated) {
    // Redirect if customer is using the /login URL
    return <Redirect to="/" />;
  }

  const displayCiCloud = ["localhost", "-dev.plume.com"].some((url) =>
    window.location.hostname.includes(url)
  );

  const handleDeploymentSelection = (deployment) => {
    window.location.assign(`https://${deployment}.${window.location.hostname}`);
  };

  return (
    <div className={"sl-login-page"}>
      <ReactTooltip
        place="right"
        effect="solid"
        backgroundColor="#606060"
        className="forgot-link-tooltip"
      ></ReactTooltip>
      <SlCenter>
        <div className={"sl-login-page__inner"}>
          <SlText heading3m style={{ color: "white", textAlign: "center" }}>
            <FormattedMessage id="Signal" />
          </SlText>
          {DISPLAY_CLOUD_SELECTOR_FALLBACK && !isRegionSpecificSignal() && (
            <div
              className={`login-panel ${active ? "login-panel--active" : null}`}
            >
              <SlText titleM style={{ color: "#111111", textAlign: "center" }}>
                <FormattedMessage id="login.fallbackTitle" />
              </SlText>
              {[...(displayCiCloud ? ["CI"] : []), "Kappa", "Gamma"].map(
                (cloud) => (
                  <SlButton
                    className="deployment"
                    fill="outline"
                    color="page"
                    size="full"
                    onClick={() =>
                      handleDeploymentSelection(cloud.toLowerCase())
                    }
                  >
                    <SlText subtitle2m>{cloud}</SlText>
                  </SlButton>
                )
              )}
              <span
                id="username"
                /* FES-341: New Relic expects this ID */
              />
              <span
                id="submit"
                /* FES-341: New Relic expects this ID */
              />
              <span
                id="sl-sign-in-button"
                /* FES-341: New Relic expects this ID */
              />
            </div>
          )}
          {(!DISPLAY_CLOUD_SELECTOR_FALLBACK || isRegionSpecificSignal()) && (
            <div
              className={`login-panel ${active ? "login-panel--active" : null}`}
            >
              <SlText titleM style={{ color: "#111111", textAlign: "center" }}>
                <FormattedMessage id="login.title" />
              </SlText>
              <form onSubmit={handleSubmit}>
                <SlInput
                  required={true}
                  label={
                    <SlText subtitle4 style={{ color: "#8f9397" }}>
                      <FormattedMessage id="login.yourEmail" />
                    </SlText>
                  }
                  id={"username"} /* FES-341: New Relic expects this ID */
                  onChange={handleUsernameChange}
                  type={"email"}
                />

                <SlInput
                  required={true}
                  label={
                    <SlText subtitle4 style={{ color: "#8f9397" }}>
                      <FormattedMessage id="login.yourPassword" />
                    </SlText>
                  }
                  id={"submit"} /* FES-341: New Relic expects this ID */
                  onChange={handlePasswordChange}
                  type={"password"}
                  end={
                    <div
                      data-html={true}
                      data-tip={ReactDOMServer.renderToStaticMarkup(
                        <>
                          <SlText p7>
                            <FormattedMessage id="login.resetParagraph1" />
                          </SlText>
                          <SlText p7>
                            <FormattedMessage id="login.resetParagraph2" />
                          </SlText>
                        </>
                      )}
                    >
                      <a href={`${getOktaBaseUrl()}/signin/forgot-password`}>
                        <FormattedMessage id="login.forgot" />
                      </a>
                    </div>
                  }
                />

                {error?.name === "AuthApiError" ? (
                  <p className={"sl-color--danger"}>
                    There was a problem with your login credentials, please try
                    again.
                  </p>
                ) : null}
                {error && error?.name === "OAuthError" ? (
                  <p className={"sl-color--danger"}>
                    You have not been assigned to this application.
                  </p>
                ) : null}
                {error &&
                !["AuthApiError", "OAuthError"].includes(error?.name) ? (
                  <p className={"sl-color--danger"}>
                    There was an error, please try again.
                  </p>
                ) : null}

                <SlButton
                  dark
                  id={
                    "sl-sign-in-button"
                  } /* FES-341 New Relic expects this ID */
                  color={"#111111"}
                  disabled={!active}
                  size={"270px"}
                >
                  <SlText subtitle3>
                    <FormattedMessage id="login.signIn" />
                  </SlText>
                </SlButton>
              </form>
            </div>
          )}
        </div>
      </SlCenter>

      <PlumeIcon />
    </div>
  );
};
