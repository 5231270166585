import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";

import "./AlertData.scss";
import { Col, Row } from "react-flexbox-grid";
import { SlIcon } from "../../Atoms/Icon/Icon";
import InfoIcon from "../../../../../assets/svg/icon-info.svg";
import { SlButton } from "../../Atoms/Button/Button";
import {
  changeAlertTime,
  changeChartUnit,
  toggleTicket,
  getPartnerInfo,
} from "../../../../../actions/alert/alertActions";
import { alertQuery } from "../../../../../actions/alert/alertActions";
import { updatePartner } from "../../../../../actions/meActions";

import { SlSection } from "../../Atoms/Section/Section";
import { useDispatch, useSelector } from "react-redux";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";
import { SlHeader } from "../../Molecules/BlockHeader/Header";
import { SlTimeSelector } from "../../TimeSelector/TimeSelector";
import { SlStackedBar } from "../../Atoms/Charts/FancyStackedBar/FancyStackedBar";
import { SlMap } from "../../Atoms/Map/Map";

import { AlertTicketTable } from "../AlertTicketTable/AlertTicketTable";

import caretIcon from "../../../../../assets/svg/icon-caret.svg";
import threeDotsIcon from "../../../../../assets/icons/three_dots.svg";

import { SlPanelLoader } from "../../Atoms/PanelLoader/PanelLoader";
import {
  getOngoingLocations,
  getRecentDate,
  getTicketsByGroupAndCountry,
  getTimeseries,
} from "../../../../../selectors/alert/alert.selectors";
import { DEFAULT_WORLD_VIEW } from "../../../../../utils/constants";
import { SlScrollbars } from "../../Atoms/Scrollbar/Scrollbar";
import { MapTooltip } from "../MapTooltip/MapTooltip";

import { SlDropdown } from "../../Molecules/Dropdown/Dropdown";
import { SlText } from "../../Atoms/Text/Text";
import { useTranslation } from "react-i18next";
import FormattedMessage from "../../../../../translations/FormattedMessage";
import { AlertDataLabels } from "../../../../../reducers/alert/alertReducer";
import { OverlayUpdated } from "../../Atoms/OverlayUpdated/OverlayUpdated";

const translationsMap = {
  [AlertDataLabels.CSPOutage]: "alert.alertData.labels.cspOutage",
  [AlertDataLabels.EvilTwin]: "alert.alertData.labels.evilTwin",
  [AlertDataLabels.TotalLocationsOffline]:
    "alert.alertData.labels.totalLocationsOffline",
  [AlertDataLabels.LocationsOfflineOutage]:
    "alert.alertData.labels.locationsOfflineOutage",
};

const AlertData = ({ label, getAlertData }) => {
  const { partnerId } = useParams();
  const { t } = useTranslation();
  const translatedLabel = translationsMap[label]
    ? t(translationsMap[label])
    : "";
  const state = useSelector((state) => state.alert[label]);
  const partnerIdForMap = useSelector(
    (state) => partnerId || state.me?.selectedPartner?.id
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (partnerId) {
      dispatch(updatePartner(partnerId));
      getAlertData();
      // eslint-disable-next-line
    }
  }, [partnerId, dispatch, getAlertData]);

  const getTicketsByGroupAndCountrySelector = getTicketsByGroupAndCountry(
    t("ticket.andOthersLocationsSuffix")
  );
  const tickets = useSelector((state) =>
    getTicketsByGroupAndCountrySelector(state.alert[label])
  );

  const locations = useSelector((state) =>
    getOngoingLocations(state.alert[label].locations)
  );

  const timeseries = useSelector((state) =>
    getTimeseries([state.alert[label], state.alert.All.homesMonitored])
  );

  const recentDate = useSelector((state) =>
    getRecentDate(
      state.alert[label]?.hourly?.length
        ? state.alert[label].hourly
        : state.alert[label].daily
    )
  );

  const yAxisUnitKey = t("alert.alertData.chartMenu.yAxisMeasure");
  const mapLocations = useMemo(
    () =>
      tickets?.allOngoingLocations?.length
        ? tickets.allOngoingLocations
        : locations,
    [locations, tickets.allOngoingLocations]
  );

  const handleDropdownSelect = (option, section) => {
    if (
      section === yAxisUnitKey &&
      typeof selections[yAxisUnitKey]?.options?.Percentage !== "undefined"
    ) {
      dispatch(changeChartUnit({ name: label, type: option }));
      setSelections({
        ...selections,
        [yAxisUnitKey]: {
          options: {
            Number: option !== "Percentage",
            Percentage: option === "Percentage",
          },
        },
      });
    }
  };

  const getDefaultSelections = () => {
    const yAxisUnit = {
      options: {
        Number: state.chartUnit.nominal,
      },
    };
    if (label === AlertDataLabels.EvilTwin) {
      yAxisUnit.options.Percentage = state.chartUnit.percentage;
    }
    return {
      [yAxisUnitKey]: yAxisUnit,
      [t("alert.alertData.chartMenu.changeChart")]: {
        options: {
          Barchart: true,
        },
      },
    };
  };
  const [selections, setSelections] = useState(getDefaultSelections());

  const headerTicket = (
    <div
      className={`sl-alert-data__header ${state.showTickets ? "active" : ""}${
        tickets.filteredTickets.length ? " has-tickets" : ""
      }`}
      onClick={() => {
        document.activeElement.blur();
        return tickets.filteredTickets?.length && dispatch(toggleTicket(label));
      }}
    >
      <SlButton round disabled={!tickets.filteredTickets.length}>
        <SlIcon width={20} height={20} svgFile={caretIcon} />
      </SlButton>
      <SlText subtitle2m>
        <FormattedMessage
          id="alert.alertData.newTickets"
          params={{
            count: tickets.filteredTickets.length,
            time:
              tickets.filteredTickets.length >= 0
                ? Object.keys(state.times).filter((x) => state.times[x])[0]
                : null,
          }}
        />
      </SlText>
    </div>
  );

  const ticketView = (
    <SlScrollbars autoHeightMax={900}>
      {tickets.groupedTicketsByKey.map((key, i) => (
        <AlertTicketTable
          title={key}
          key={i}
          type={label}
          data={tickets.groupedTickets[key]}
        />
      ))}
    </SlScrollbars>
  );

  // @TODO: We must do such checks until we refactor way of storing labels (labelId instead of full name)
  const isCSPOutageSection = label === AlertDataLabels.CSPOutage;

  return (
    <SlSection contrast={state.showTickets ? 6 : 0} className={"sl-alert-data"}>
      <ReactTooltip
        place="right"
        effect="solid"
        backgroundColor="#606060"
        className="section-header-tooltip"
      ></ReactTooltip>
      <SlHeader
        start={<SlText titleSignal>{translatedLabel}</SlText>}
        end={[
          <SlTimeSelector
            id={label}
            key={1}
            times={state.times}
            changeAction={(type, time) => {
              dispatch(changeAlertTime({ type, time }));
            }}
          />,
        ]}
        horizontalLine
      >
        <div
          data-html
          data-tip={ReactDOMServer.renderToStaticMarkup(
            <SlText p7>
              <FormattedMessage id={state.tooltipCopyId} />
            </SlText>
          )}
        >
          <SlIcon width={26} height={26} svgFile={InfoIcon} />
        </div>
      </SlHeader>
      <Row middle="xs" className="alert-row">
        {state.loading ? (
          <SlPanelLoader />
        ) : (
          <>
            <Col lg={5}>
              <SlMap
                data={mapLocations}
                zoom={DEFAULT_WORLD_VIEW.zoom}
                lat={mapLocations?.length ? undefined : DEFAULT_WORLD_VIEW.lat}
                long={
                  mapLocations?.length ? undefined : DEFAULT_WORLD_VIEW.long
                }
                tooltip={(tooltip) => (
                  <MapTooltip
                    tooltip={tooltip}
                    label={label}
                    partnerId={partnerIdForMap}
                    hideLocationInfo={isCSPOutageSection}
                    hideDateInfo={isCSPOutageSection}
                  />
                )}
              >
                <OverlayUpdated recentDate={recentDate} />
              </SlMap>
            </Col>
            <Col lg={7}>
              <div className={"alert-barchart"}>
                <div className={"alert-barchart__header"}>
                  <SlText subtitle2m>
                    <FormattedMessage id="alert.alertData.alertHistory" />
                  </SlText>
                  <SlDropdown
                    selections={selections}
                    onSelect={(option, section) =>
                      handleDropdownSelect(option, section)
                    }
                    trigger={
                      <SlButton round>
                        <SlIcon svgFile={threeDotsIcon} />
                      </SlButton>
                    }
                  ></SlDropdown>
                </div>

                <div className="alert-barchart__inner">
                  <SlStackedBar
                    unit={state.chartUnit}
                    showToggle={false}
                    data={timeseries}
                  />
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>

      {headerTicket}
      {state.showTickets && tickets.filteredTickets.length ? (
        <SlText
          subtitle2m
          classNames="sl-alert-data__sub-header sl-typography--destressed"
        >
          <FormattedMessage id="alert.alertData.latestTickets" />
        </SlText>
      ) : null}
      {state.showTickets ? ticketView : null}
    </SlSection>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAlertData: () => {
      dispatch(getPartnerInfo());
      dispatch(
        alertQuery({ type: AlertDataLabels.CSPOutage, period: "hourly" })
      );
      dispatch(alertQuery({ type: AlertDataLabels.EvilTwin, period: "daily" }));
      dispatch(
        alertQuery({
          type: AlertDataLabels.TotalLocationsOffline,
          period: "hourly",
        })
      );
      dispatch(
        alertQuery({
          type: AlertDataLabels.LocationsOfflineOutage,
          period: "hourly",
        })
      );
    },
  };
};

export default connect(undefined, mapDispatchToProps)(AlertData);
