import React from "react";
import PropTypes from "prop-types";
import { SlIcon } from "../Icon/Icon";

import ExpandIcon from "../../../../../assets/icons/icon-full-screen.svg";
import ShrinkIcon from "../../../../../assets/icons/icon-not-full-screen.svg";
import cx from "classnames";

import "./expandMap.scss";

const ExpandMap = ({ handleExpandMap, expanded }) => {
  const iconProps = expanded
    ? {
        width: 56,
        height: 56,
        svgFile: ShrinkIcon,
      }
    : {
        width: 72,
        height: 22,
        svgFile: ExpandIcon,
      };
  const classes = cx("sl-expand-map-wrapper-wrapper", {
    "sl-expand-map-wrapper-wrapper--expanded": Boolean(expanded),
  });
  return (
    <div className={classes}>
      <div onClick={handleExpandMap} className="sl-expand-map-wrapper">
        <SlIcon {...iconProps} />
      </div>
    </div>
  );
};

ExpandMap.propTypes = {
  handleExpandMap: PropTypes.func.isRequired,
  expanded: PropTypes.bool,
};

export { ExpandMap };
