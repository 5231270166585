import { labelMap } from "../../../../../actions/graphQL_queries/alertQuery";
import moment from "moment";
import { Link } from "react-router-dom";
import React from "react";
import { GET_NOC_URL } from "../../../../../utils/constants";
import { useSelector } from "react-redux";
import { selectPrimaryDeployment } from "../../../../../selectors/alert/alert.selectors";
import { mapTooltipDateTimeFormat } from "../../../../../config";
import FormattedMessage from "../../../../../translations/FormattedMessage";

const getTimeZone = (timestamp) => {
  // https://github.com/moment/moment/issues/162
  const dateGenerated = moment(timestamp);
  return String(dateGenerated.local()._d).split(" ")[5].split("+")[0];
};

const getTimeStamp = (tooltipObject) => tooltipObject?.object?.timestamp;

const getNocUrlForLocation = (locationId, primaryDeployment) =>
  `${GET_NOC_URL(primaryDeployment)}/${locationId}`;

export const MapTooltip = ({
  tooltip,
  label,
  partnerId,
  hideTicketInfo = false,
  hideDateInfo = false,
  hideLocationInfo = false,
}) => {
  const primaryDeployment = useSelector(selectPrimaryDeployment);
  const typeQuery = `?type=${labelMap[label]}`;
  const timestamp = getTimeStamp(tooltip);
  const multipleDots = false;
  return (
    <>
      {multipleDots ? <p>helo ther</p> : ""}

      {!hideDateInfo && timestamp && (
        <p className="map-tooltip__time">
          {moment(timestamp).format(mapTooltipDateTimeFormat)}{" "}
          {getTimeZone(timestamp)}
        </p>
      )}

      {tooltip?.object?.ids && tooltip?.object?.ticketId && (
        <>
          {!hideTicketInfo && tooltip?.object?.ticketId && (
            <p className="map-tooltip__ticket">
              <FormattedMessage id="mapTooltip.ticket" />
              <Link
                to={`/alert/${partnerId}/${tooltip?.object?.ticketId}${typeQuery}`}
              >
                <br />
                {tooltip?.object?.ticketId}
              </Link>
            </p>
          )}
          {!hideLocationInfo && tooltip?.object?.ids[0]?.locationId && (
            <>
              <p className="map-tooltip__location">
                {tooltip?.object?.ids?.length === 1 ? (
                  <FormattedMessage id="mapTooltip.location" />
                ) : (
                  <FormattedMessage id="mapTooltip.locations" />
                )}
                <br />
                {tooltip?.object?.ids.map(({ locationId }) => (
                  <>
                    <a
                      href={getNocUrlForLocation(locationId, primaryDeployment)}
                      target="_blank"
                      rel="noreferrer"
                      className="text-link"
                    >
                      {locationId}
                    </a>
                    <br />
                  </>
                ))}
              </p>
            </>
          )}
        </>
      )}

      {!hideTicketInfo && tooltip?.object?.ids && tooltip?.object?.id && (
        <p className="map-tooltip__ticket">
          <FormattedMessage id="mapTooltip.ticket" />
          {tooltip?.object?.ids?.map((id) => (
            <>
              <br />
              <Link to={`/alert/${partnerId}/${id}${typeQuery}`}>{id}</Link>
            </>
          ))}
        </p>
      )}

      {tooltip?.object?.city && (
        <p className="map-tooltip__city">{tooltip?.object?.city}</p>
      )}
    </>
  );
};
