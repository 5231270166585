import React from "react";
import PropTypes from "prop-types";
import className from "classnames";

import "./selectedText.scss";

import { CheckMark } from "./CheckMark";

const SelectedText = ({ children, selected }) => {
  return (
    <div className="sl-selected-text-wrapper">
      <span
        className={className("sl-selected-text-checkmark", {
          "selected-off": !selected,
        })}
      >
        {selected ? <CheckMark /> : null}
      </span>
      <span className="sl-selected-text">{children}</span>
    </div>
  );
};

SelectedText.propTypes = {
  children: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default SelectedText;
