import moment from "moment";
import {
  PREDICT_SUMMARY_DATA__DATA_FETCHING,
  PREDICT_SUMMARY_DATA__DATA_RECEIVED,
  PREDICT_SUMMARY_DATA__DATA_ERRORED,
  LOCATIONS_MONITORED_DATA__DATA_FETCHING,
  LOCATIONS_MONITORED_DATA__DATA_RECEIVED,
  LOCATIONS_MONITORED_DATA__DATA_ERRORED,
  PREDICT_24HOUR_AGGREGATE_DATA__DATA_FETCHING,
  PREDICT_24HOUR_AGGREGATE_DATA__DATA_RECEIVED,
  PREDICT_24HOUR_AGGREGATE_DATA__DATA_ERRORED,
  PREDICT_7DAY_AGGREGATE_DATA__DATA_FETCHING,
  PREDICT_7DAY_AGGREGATE_DATA__DATA_RECEIVED,
  PREDICT_7DAY_AGGREGATE_DATA__DATA_ERRORED,
  PREDICT_SET_CALL_IN_VOLUME_PERIODS,
  PREDICT_SET_LOADING_CALL_IN_VOLUME_PERIODS,
  PREDICT_SET_HISTORICAL_TREND_DATA,
  PREDICT_SET_LOADING_HISTORICAL_TREND_DATA,
  PREDICT_SET_DATA_SCOPE_IN_DAYS,
  PREDICT_SET_CALL_IN_VOLUME_ACTIVE_PERIOD,
} from "../actions/predictActions";

const PREDICT_INIT_STATE = {
  dataPredictSummary: null,
  dataPredictSummary__isLoading: true,
  dataPredictSummary__errored: false,
  dataLocationsMonitored: null,
  dataLocationsMonitored__isLoading: true,
  dataLocationsMonitored__errored: false,
  data24hour: null,
  data24hour__isLoading: true,
  data24hour__errored: false,
  data7day: null,
  data7day__isLoading: true,
  data7day__errored: false,
  totalLocationsMonitored: 0,
  predictedCallVolume24Hours: 0,
  predictedCallVolume7Days: 0,
  callInVolumePrediction: {
    loading: false,
    activePeriod: null,
    periods: [],
  },
  historicalTrendData: {
    loading: false,
    dataScopeInDays: -30,
    data: [],
  },
};

// Selectors
const selectPredict = (state) => state.predict;
export const selectPeriodsWithTotalItems = (state) => {
  const periods = selectPredict(state)?.callInVolumePrediction?.periods;
  if (!periods || !periods.length) {
    return [];
  }
  return periods.map((period) => ({
    ...period,
    periodStart: moment(period.periodStart),
    periodEnd: moment(period.periodEnd),
    total: period.data
      ? period.data.reduce((acc, item) => acc + item.value, 0)
      : 0,
  }));
};
export const selectHistoricalTrend = (state) =>
  selectPredict(state).historicalTrendData;
export const selectActivePeriod = (state) =>
  selectPredict(state).callInVolumePrediction.activePeriod;

const predictReducer = (
  state = PREDICT_INIT_STATE,
  action = { type: null }
) => {
  let payload = action.payload;
  switch (action.type) {
    case PREDICT_SUMMARY_DATA__DATA_FETCHING:
      return {
        ...state,
        dataPredictSummary__isLoading: true,
        dataPredictSummary__errored: false,
      };
    case PREDICT_SUMMARY_DATA__DATA_RECEIVED:
      return {
        ...state,
        dataPredictSummary__isLoading: false,
        dataPredictSummary: payload.data,
        totalLocationsMonitored:
          payload.data.signal_p_monitored_locations_aggregate.aggregate.sum
            .count,
        predictedCallVolume24Hours:
          payload.data.predict_p_call_volume_prediction_24hour_aggregate
            .aggregate.sum.count,
        predictedCallVolume24HoursTimestamp:
          payload.data.pipelineTimestamp24Hour.ts,
        predictedCallVolume7Days:
          payload.data.predict_p_call_volume_prediction_7day_aggregate.aggregate
            .sum.count,
        predictedCallVolume7DaysTimestamp:
          payload.data.pipelineTimestamp7Day.ts,
      };
    case PREDICT_SUMMARY_DATA__DATA_ERRORED:
      return { ...state, dataPredictSummary__errored: true };
    case LOCATIONS_MONITORED_DATA__DATA_FETCHING:
      return {
        ...state,
        dataLocationsMonitored__isLoading: true,
        dataLocationsMonitored__errored: false,
      };
    case LOCATIONS_MONITORED_DATA__DATA_RECEIVED:
      return {
        ...state,
        dataLocationsMonitored__isLoading: false,
        dataLocationsMonitored: payload.data,
        totalLocationsMonitored:
          payload.data.signal_p_monitored_locations_aggregate.aggregate.sum
            .count,
      };
    case LOCATIONS_MONITORED_DATA__DATA_ERRORED:
      return { ...state, dataLocationsMonitored__errored: true };
    case PREDICT_24HOUR_AGGREGATE_DATA__DATA_FETCHING:
      return {
        ...state,
        data24hour__isLoading: true,
        data24hour__errored: false,
      };
    case PREDICT_24HOUR_AGGREGATE_DATA__DATA_RECEIVED:
      return {
        ...state,
        data24hour__isLoading: false,
        data24hour: payload.data,
        predictedCallVolume24Hours:
          payload.data.predict_p_call_volume_prediction_24hour_aggregate
            .aggregate.sum.count,
        predictedCallVolume24HoursTimestamp:
          payload.data.predict_p_pipeline_run_timestamp.ts,
      };
    case PREDICT_24HOUR_AGGREGATE_DATA__DATA_ERRORED:
      return { ...state, data24hour__errored: true };
    case PREDICT_7DAY_AGGREGATE_DATA__DATA_FETCHING:
      return { ...state, data7day__isLoading: true, data7day__errored: false };
    case PREDICT_7DAY_AGGREGATE_DATA__DATA_RECEIVED:
      return {
        ...state,
        data7day__isLoading: false,
        data7day: payload.data,
        predictedCallVolume7Days:
          payload.data.predict_p_call_volume_prediction_7day_aggregate.aggregate
            .sum.count,
        predictedCallVolume7DaysTimestamp:
          payload.data.predict_p_pipeline_run_timestamp.ts,
      };
    case PREDICT_7DAY_AGGREGATE_DATA__DATA_ERRORED:
      return { ...state, data7day__errored: true };
    case PREDICT_SET_CALL_IN_VOLUME_PERIODS:
      return {
        ...state,
        callInVolumePrediction: {
          ...state.callInVolumePrediction,
          periods: payload.data,
        },
      };
    case PREDICT_SET_LOADING_CALL_IN_VOLUME_PERIODS:
      return {
        ...state,
        callInVolumePrediction: {
          ...state.callInVolumePrediction,
          loading: payload.loading,
        },
      };
    case PREDICT_SET_CALL_IN_VOLUME_ACTIVE_PERIOD:
      return {
        ...state,
        callInVolumePrediction: {
          ...state.callInVolumePrediction,
          activePeriod: payload,
        },
      };
    case PREDICT_SET_HISTORICAL_TREND_DATA:
      return {
        ...state,
        historicalTrendData: {
          ...state.historicalTrendData,
          data: normalizeHistoricalTrendData(payload.data),
        },
      };
    case PREDICT_SET_LOADING_HISTORICAL_TREND_DATA:
      return {
        ...state,
        historicalTrendData: {
          ...state.historicalTrendData,
          loading: payload.loading,
        },
      };
    case PREDICT_SET_DATA_SCOPE_IN_DAYS:
      return {
        ...state,
        historicalTrendData: {
          ...state.historicalTrendData,
          dataScopeInDays: payload.dataScopeInDays,
        },
      };
    default:
      return state;
  }
};

// helpers
export const PeriodsLabels = {
  Next24Hours: "next24Hours",
  Next7Days: "next7Days",
  Last90Days: "last90Days",
};
const diffInHoursToPeriodLabel = [
  {
    diff: 24,
    label: PeriodsLabels.Next24Hours,
  },
  {
    diff: 7 * 24,
    label: PeriodsLabels.Next7Days,
  },
  {
    diff: 90 * 24,
    label: PeriodsLabels.Last90Days,
  },
];
const getPeriodLabel = (period) => {
  const diff = moment(period.periodEnd).diff(
    moment(period.periodStart),
    "hours"
  );
  return diffInHoursToPeriodLabel.find((d) => d.diff === diff)?.label || "";
};
export const processPeriodPayload = (periodPayload) => {
  return periodPayload.map((period) => ({
    ...period,
    label: getPeriodLabel(period),
  }));
};
const normalizeHistoricalTrendData = ({ days }) => {
  const items = days.map((item) => {
    const data = item.data
      .map((element) => {
        return {
          [element.labelId]: element.value,
        };
      })
      .reduce((acc, current) => ({
        ...acc,
        ...current,
      }));
    return {
      day: item.day,
      ...data,
    };
  });
  return items;
};

export default predictReducer;
