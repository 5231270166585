import React, { useState } from "react";
import classNames from "classnames";

import "./Input.scss";
import { SlIcon } from "../Icon/Icon";
import eyeOpen from "../../../../../assets/svg/icon-eye-open.svg";
import eyeClose from "../../../../../assets/svg/icon-eye-closed.svg";

export const SlInput = ({
  addItemHandler,
  valid,
  validateEmail,
  label,
  id,
  type,
  end,
  onChange,
  required = false,
}) => {
  const [focus, setFocus] = useState(false);
  const [_type, _setType] = useState(type ? type : "text");
  function submitEmail(ev) {
    if (valid && ev.key === "Enter") addItemHandler();
  }
  return (
    <div className={`sl-input sl-input--${focus ? "focus" : "unfocus"}`}>
      <label htmlFor={id}>{label}</label>
      <input
        type={_type}
        required={required}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onChange={(ev) => {
          if (validateEmail) {
            validateEmail(ev.target.value);
          }
          if (onChange) {
            onChange(ev);
          }
        }}
        onKeyDown={(ev) => {
          addItemHandler && submitEmail(ev);
        }}
        className={classNames("signal-admin-input", {
          "signal-admin-input-valid": valid,
        })}
        id={id}
      ></input>
      {end ? <div className={"sl-input__end"}>{end}</div> : null}
      {type === "password" && (
        <SlIcon
          width={21}
          handleClick={() =>
            _setType(_type === "password" ? "text" : "password")
          }
          svgFile={_type === "password" ? eyeOpen : eyeClose}
        />
      )}
    </div>
  );
};
