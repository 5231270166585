export const labelMap = {
  "CSP Outage": "ispOutageAlerts",
  "Evil Twin": "evilTwinAlerts",
  "Total Locations Offline": "totalLocationsOfflineAlerts",
  "Locations Offline Outage": "locationOfflineOutageAlerts",
  ispOutage: "ispOutageAlerts",
  evilTwin: "evilTwinAlerts",
  totalLocationsOffline: "totalLocationsOfflineAlerts",
  locationOfflineOutage: "locationOfflineOutageAlerts",
};

const daily = `daily {
      alarm,
      count,
      timestamp
    }`;

const hourly = `
  hourly {
    alarm,
    count,
    timestamp
  }`;

export const alertDataQuery = (params) => {
  const locations = `
    locations {
      ticketId,
      locationId,
      customerId,
      url,
      partnerId,
      status,
      isp,
      city,
      zip,
      country,
      latitude,
      longitude,
      timeStamp
    }`;

  return `
    query getData($id:ID) {
      partner(id:$id) {
        ${labelMap[params.type]} {
          tickets {
            id,
            geo {
              lat,
              long
            },
            city,
            country,
            status,
            cause,
            timestamp,
            severity,
            percentage,
            nominal,
            type,

          }
          ${labelMap[params.type] !== "ispOutageAlerts" ? locations : ``}
          ${params.period === "daily" ? daily : hourly}
        }
      }
    }`;
};

export const partnerInfo = `
query getData($id:ID) {
  partner(id:$id) {
    homesMonitored
    homesInAlert
  }
}`;

export const alertTicketQuery = (params) => {
  return `query getTicket ($id:ID, $ticketId: String) {
  partner(id:$id, ticketId: $ticketId ) {

  ${params.type ? `${params.type}{` : ""}
    ticket {
      id,
      geo {
        lat,
        long
      },
      city,
      country,
      status,
      cause,
      timestamp,
      severity,
      percentage,
      nominal,
      type,
      locations {
        ticketId,
        locationId,
        customerId,
        url,
        partnerId,
        status,
        isp,
        city,
        zip,
        country,
        latitude,
        longitude,
        timeStamp
      },
    ${params.period === "daily" ? daily : ""}
    ${params.period === "hourly" ? hourly : ""}
    ${params.period === "all" ? daily + hourly : ""}
  }
  ${params.type ? "}" : ""}
  }
}
`;
};

export const alertLocationHistory = `
  query getLocationHistory ($id:ID, $locationId: String) {
    partner(id:$id, locationId: $locationId ) {
      id
      locationAlarmHistory {
        offlineNetwork {type, from, to, fromISO, toISO},
        unstableNetwork {type, from, to, fromISO, toISO},
        evilTwin {type, from, to, fromISO, toISO},
        ispOutage {type, from, to, fromISO, toISO},
      }
    }
  }`;
