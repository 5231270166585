import { json } from "d3-fetch";

import { getVersionApiEndpoint } from "../utils/environments";

export const GETTING_VERSION = "GETTING_VERSION";
export const VERSION_ERROR = "VERSION_ERROR";
export const VERSION_LOADED = "VERSION_LOADED";

const getttingVersion = () => ({
  type: GETTING_VERSION,
});

const versionLoaded = (version) => ({
  type: VERSION_LOADED,
  payload: {
    version,
  },
});

const versionError = () => ({
  type: VERSION_ERROR,
});

//thunks

export const gettingVersionCall = () => {
  return async (dispatch, getState) => {
    dispatch(getttingVersion());
    try {
      const version = await json(getVersionApiEndpoint());
      dispatch(versionLoaded(version.apiVersion));
    } catch (error) {
      dispatch(versionError());
    }
  };
};
