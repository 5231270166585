import React from "react";
import PropTypes from "prop-types";
import AlertEnabledToggle from "../AlertEnabledToggle";
import { SlText } from "../../../communal/Atoms/Text/Text";

import "./descriptionToggle.scss";

const DescriptionToggle = ({
  on,
  description,
  handleToggleChange,
  toggleID,
  isEditingDisabled,
}) => {
  return (
    <div className="sl-admin-description-toggle">
      <SlText
        p7
        destressed
        classNames="sl-admin-description-toggle-description"
      >
        {description}
      </SlText>
      <AlertEnabledToggle
        on={on}
        handleToggleChange={handleToggleChange}
        toggleID={toggleID}
        isEditingDisabled={isEditingDisabled}
      />
    </div>
  );
};

DescriptionToggle.propTypes = {
  on: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  handleToggleChange: PropTypes.func.isRequired,
  toggleID: PropTypes.number.isRequired,
  isEditingDisabled: PropTypes.bool,
};

export default DescriptionToggle;
