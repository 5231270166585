import React from "react";

import "./ChartHeader.scss";

const SlChartHeader = ({ start, end, children }) => (
  <div className="sl-chart-header">
    {start && <div className="sl-chart-header__start">{start}</div>}
    {children && children}
    {end && <div className="sl-chart-header__end">{end}</div>}
  </div>
);

export { SlChartHeader };
