import { PAGINATION_CHANGE, SET_LENGTH } from "../actions/list_actions";

const Init_State = {
  counter: 0,
  page_length: null,
};

export default function pagination(
  state = Init_State,
  action = { type: null }
) {
  switch (action.type) {
    case PAGINATION_CHANGE:
      return { ...state, ...{ counter: action.payload.counter } };
    case SET_LENGTH:
      return { ...state, ...{ page_length: action.payload.length } };
    default:
      return state;
  }
}
