import { useHistory } from "react-router";

export const useRedirectToRoute = () => {
  const history = useHistory();

  return (name, extraParams) => {
    const params = Object.keys(extraParams || {});
    const route =
      extraParams !== undefined
        ? params.reduce(
            (current, param) =>
              current.replace(`:${param}`, extraParams[param]),
            name
          )
        : name;
    history.push(route);
  };
};
