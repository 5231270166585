import "regenerator-runtime/runtime";
import { combineReducers } from "redux";
import { graphqlReducer } from "iguazu-graphql";
import adminReducer from "./adminReducer";
import alertTicketReducer from "./alert/alertTicketReducer";
import list from "./list_reducer";
import mainReducer from "./mainReducer";
import meReducer from "./meReducer";
import monitorReducer from "./monitorReducer";
import monitorKPIReducer from "./monitorKPIReducer";
import monLASReducer from "./monLASReducer";
import monitorMap from "./monitorMapReducer";
import pagination from "./pagination_reducer";
import predictReducer from "./predictReducer";
import predictiveReducer from "./predictiveReducer";
import sidePanelReducer from "./sidePanelReducer";
import solveRingCharts_reducer from "./solveRingCharts_reducer.js";
import versionReducer from "./versionReducer";
import v2 from "./v2";
import alertReducer from "./alert/alertReducer";
import alertLASReducer from "./alert/alertLASReducer";
import locationReducer from "./locations/locationReducer";

const rootReducer = combineReducers({
  admin: adminReducer,
  alertTicket: alertTicketReducer,
  iguazuGraphQL: graphqlReducer,
  KPI: monitorKPIReducer,
  main: mainReducer,
  me: meReducer,
  monitor: monitorReducer,
  monitorMap: monitorMap,
  monitorLAS: monLASReducer,
  list,
  list_pagination: pagination,
  predict: predictReducer,
  predictive: predictiveReducer,
  sidePanel: sidePanelReducer,
  solveRingCharts: solveRingCharts_reducer,
  version: versionReducer,
  v2: v2,
  alert: alertReducer,
  location: locationReducer,
  alertLAS: alertLASReducer,
});

export default rootReducer;
