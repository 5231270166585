export function selectOptions(selections) {
  if (selections["Change Chart"]?.options?.BarChart) {
    let newSelections = {
      ...selections,
      ...{
        "Y Axis Measure": {
          changemenuOpen: false,
          options: {
            Percentage: selections["Y Axis Measure"]?.options?.Percentage,
          },
        },
      },
    };
    return newSelections;
  }
  return selections;
}
