import React, { useEffect, useRef } from "react";
import { useResizeChart } from "../../../../../../hooks/useResizeChart";
import TrendLine from "./TrendLine";
import PropTypes from "prop-types";

import "./trendline.scss";

const TrendLineComp = ({ id, data, locationNumbers, xAtt, yAtt, time }) => {
  let el = useRef(null);
  let initialRender = useRef(true);
  let chart = useRef(null);

  useEffect(() => {
    if (chart.current !== null && initialRender.current !== true) {
      chart.current.rerenderYaxis({
        yAtt: yAtt,
      });
    }
  }, [yAtt]);

  useEffect(() => {
    if (chart.current !== null && initialRender.current !== true) {
      chart.current.rerender({
        data: data,
        time: time,
      });
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (el.current != null && initialRender.current === true) {
      chart.current = new TrendLine({
        el: el.current,
        data: data,
        xAtt: xAtt,
        yAtt: yAtt,
        time: time,
        gcolorStart: "rgb(250, 20, 120)",
        totalNumber: locationNumbers,
      });

      chart.current.render();
      initialRender.current = false;

      return () => {
        chart.current.stopMotion();
      };
    }
    // eslint-disable-next-line
  }, [el.current]);
  useResizeChart(chart, el);
  return <div ref={el} className="signal-trendline" id={id}></div>;
};

TrendLineComp.propTypes = {
  id: PropTypes.string,
  data: PropTypes.array.isRequired,
  time: PropTypes.string.isRequired,
  xAtt: PropTypes.string.isRequired,
  yAtt: PropTypes.string.isRequired,
};

export default TrendLineComp;
