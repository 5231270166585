import producer from "immer";

import {
  REMOVE_TICKET_ERR,
  SET_TICKET_ERR,
} from "../../actions/alert/alertTicketActions";

export const Init_State = {
  ticketErr: false,
};

export const TicketStatus = {
  Resolved: "resolved",
  Ongoing: "ongoing",
  Reported: "reported",
};

const alertTicektReducer = producer((draftState, action = { type: null }) => {
  switch (action.type) {
    case SET_TICKET_ERR:
      draftState.ticketErr = true;
      return;
    case REMOVE_TICKET_ERR:
      draftState.ticketErr = false;
      return;
    default:
      return;
  }
}, Init_State);

export default alertTicektReducer;
