import React from "react";
import classnames from "classnames";
import { SlText } from "../Text/Text";

import "./Button.scss";
const SlButton = ({
  children,
  className,
  onClick,
  color,
  dark,
  backgroundOpacity,
  backgroundOpacityHighlighted,
  destressed,
  round = false,
  fill,
  size,
  disabled = false,
  noHover = false,
  start,
  end,
  id,
}) => {
  let style = {};

  if (color) {
    style.borderColor = `var(--color-${color})`;
  }

  if (fill === "outline") {
    style.borderColor = `var(--color-${color})`;
  }

  if (fill === "solid") {
    style.borderColor = `var(--color-${color})`;
  }

  if (fill === "clear") {
    style.borderColor = "transparent";
  }

  if (noHover) {
    style.pointerEvents = "none";
  }

  if (size === "full") {
    style.width = "100%";
  } else if (size === "default") {
    style.width = "172px";
  } else if (size) {
    style.width = size;
  }

  if (start || end) {
    style.display = "flex";
    style.flexDirection = "row";
    style.justifyContent = "space-between";
    style.alignItems = "center";
    style.flexWrap = "nowrap";
  } else {
    style.display = "block";
  }

  return (
    <>
      <button
        disabled={disabled}
        style={style}
        color={color}
        onClick={onClick}
        start={start}
        end={end}
        id={id}
        className={classnames(
          "sl-button",
          {
            "sl-button--round": round,
            "sl-button--dark": dark,
            "sl-button--background-opacity-0p1": backgroundOpacity === 0.1,
            "sl-button--background-opacity-0p2": backgroundOpacity === 0.2,
            "sl-button--background-opacity-highlighted-0p1":
              backgroundOpacityHighlighted === 0.1,
            "sl-button--background-opacity-highlighted-0p2":
              backgroundOpacityHighlighted === 0.2,
          },
          className
        )}
      >
        {start && start}
        {children}
        {end && end}
      </button>
    </>
  );
};

export { SlButton };
