import { createBrowserHistory } from "history";
import { OktaAuth } from "@okta/okta-auth-js";

import { OKTA_REDIRECT_URI, OKTA_LOGOUT_URI } from "./constants";
import { getOktaClientId, getOktaIssuer } from "./environments";

const history = createBrowserHistory();

export const authClient = new OktaAuth({
  history,
  issuer: getOktaIssuer(),
  clientId: getOktaClientId(),
  redirectUri: OKTA_REDIRECT_URI,
  scopes: ["openid", "email", "signal"],
  responseType: "id_token",
  postLogoutRedirectUri: OKTA_LOGOUT_URI,
});

export function parseToken(tok) {
  try {
    return JSON.parse(atob(tok.split(".")[1]));
  } catch (error) {
    console.warn("PARSE TOKEN FAILED: ", tok);
  }
}
