import React from "react";
import ReactDOM from "react-dom";
import ReduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";

import App from "./containers/App";
import reducers from "./reducers";
import { loadState } from "./reducers/localStorage";

import "./style/global.scss";
import "./utils/axios";
import ReactGA from "react-ga";
import { GA_TRACKING_ID } from "./utils/constants";
import "./translations/i18n";

ReactGA.initialize(GA_TRACKING_ID);

const store = createStore(
  reducers,
  {
    v2: loadState("v2_auth"),
  },
  composeWithDevTools(applyMiddleware(ReduxThunk))
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);
