import React from "react";
import PropTypes from "prop-types";
import "./thresholdControl.scss";
import { SlRangeSlider } from "../RangeSlider/RangeSlider";
import { formatPercentage } from "../RangeSlider/helpers";
import FormattedMessage from "../../../../../translations/FormattedMessage";

const ThresholdControl = ({ value, handleValueChange, isEditingDisabled }) => {
  return (
    <div className="sl-admin-threshold">
      <div className="sl-admin-threshold-label">
        <FormattedMessage id="admin.alerts.alertThresholdTitle" />
      </div>
      <div className="sl-admin-threshold-description">
        <FormattedMessage id="admin.alerts.alertThresholdDescription" />
      </div>
      <SlRangeSlider
        valueFormatter={formatPercentage}
        onChange={handleValueChange}
        value={value}
        min={1}
        max={100}
        step={1}
        isDisabled={isEditingDisabled}
      />
    </div>
  );
};

ThresholdControl.propTypes = {
  value: PropTypes.number.isRequired,
  handleValueChange: PropTypes.func.isRequired,
  isEditingDisabled: PropTypes.bool,
};

export default ThresholdControl;
