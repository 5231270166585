export const TURN_ON_OFF_LAYER = "TURN_ON_OFF_LAYER";
export const TURN_OFF_LAYER = "TURN_OFF_LAYER";
export const SHOW_HIDE_MAP_LAYER_SELECTOR = "SHOW_MAP_LAYER_SELECTOR";

export const turnOnOffLayer = (layer) => ({
  type: TURN_ON_OFF_LAYER,
  layer,
});

export const turnOffLayer = (layer) => ({
  type: TURN_OFF_LAYER,
  layer,
});

export const showHideMapLayerSelector = () => ({
  type: SHOW_HIDE_MAP_LAYER_SELECTOR,
});
