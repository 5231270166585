import * as d3 from "d3";

class BaseXYChart {
  constructor(options) {
    this.colorMap = {
      good: "rgba(233, 233, 233, 0.05)",
      warning: "#939597",
      alarm: "#FA1478",
      alarmTotal: "#FA1478",
      warningTotal: "#939597",
      goodTotal: "rgba(233, 233, 233, 0.05)",
    };
    this.margin = { l: 40, t: 20, r: 20, b: 20 };
    this.el = options.el;
    this.data = options.data;
    this.height =
      this.el.getBoundingClientRect().height - (this.margin.t + this.margin.b);
    this.width =
      this.el.getBoundingClientRect().width - (this.margin.l + this.margin.r);
    this.timeInterval =
      options.timeInterval === undefined ? "30 Day" : options.timeInterval;
    this.xAtt = "time";
    this.totalNumber =
      options.totalNumber === undefined ? 0 : options.totalNumber;
    this.yAtt = options.yAtt === undefined ? "value" : options.yAtt;
    this.timeInterval =
      options.timeInterval === undefined ? "30D" : options.timeInterval;
    this.keys = null;
    this.makeTotalData();
    this.makeKeys();
  }

  makeTotalData() {
    this.data = this.data.map((d) => {
      d.alarmTotal = Math.round(d.alarm * this.totalNumber);
      d.goodTotal = this.totalNumber - d.alarmTotal;
      d.warningTotal = d.warning * this.totalNumber;
      return d;
    });
  }

  makeKeys() {
    switch (this.yAtt) {
      case "value":
        this.keys = ["alarm", "warning", "good"];
        return;
      case "total":
        this.keys = ["alarmTotal", "warningTotal", "goodTotal"];
        return;
      default:
        return;
    }
  }

  formatYaxis() {
    switch (this.yAtt) {
      case "value":
        return d3.format(".0%");
      case "total":
        return d3.format(".0f");
      default:
        return;
    }
  }

  makeSVG() {
    this.SVG = d3
      .select(this.el)
      .append("svg")
      .attr("class", "Sl-stacked-bar-chart-chart-svg")
      .attr("height", this.height + (this.margin.t + this.margin.b))
      .attr("width", this.width + (this.margin.l + this.margin.r));

    this.mainG = this.SVG.append("g")
      .attr("class", "Sl-stacked-bar-chart-chart-mainG")
      .attr("transform", `translate(${this.margin.l},${this.margin.r})`);
  }

  createStackedData() {
    const stack = d3.stack().keys(this.keys);
    this.stackedData = stack(this.data);
  }

  makeScales() {
    const yMax = this.yAtt === "total" ? this.totalNumber : 1;
    this.yScale = d3
      .scaleLinear()
      .domain([0, yMax])
      .range([this.height, 0])
      .nice(3);
    const padding = this.timeInterval === "7D" ? 0.8 : 0.6;

    this.xScale = d3
      .scaleBand()
      .domain(this.data.map((d) => d.time))
      .range([0, this.width])
      .padding(padding);
  }

  makeAxis() {
    const length = this.data.length;
    const leftMiddle = Math.floor(length / 4);
    const rightMiddle = length - 1 - leftMiddle;
    const tickIndexes =
      this.timeInterval === "7 Day"
        ? this.data.map((d, i) => i)
        : [
            0,
            Math.floor(length / 4),
            Math.floor(length / 2),
            rightMiddle,
            length - 1,
          ];
    const xTicks = tickIndexes.map((d) => {
      return length === 0
        ? null
        : this.data[d] === undefined
        ? null
        : this.data[d][this.xAtt];
    });

    const xAxis = d3
      .axisBottom(this.xScale)
      .tickValues(xTicks)
      .tickFormat(this.getXAxisFormat());

    this.mainG
      .append("g")
      .attr("class", "bar-x-axis")
      .style("color", "rgb(147, 149, 151)")
      .style("font-family", "Wigrum")
      .style("font-size", "12px")
      .attr("transform", `translate(0,${this.height})`)
      .call(xAxis)
      .call((g) =>
        g
          .selectAll(".tick text")
          .attr("font-size", "12px")
          .attr("font-weight", "400")
          .attr("font-family", "Wigrum")
          .attr("opacity", 0.65)
      );

    const yAxis = d3
      .axisLeft(this.yScale)
      .ticks(3)
      .tickFormat((d) => this.formatYaxis()(d));

    this.mainG
      .append("g")
      .attr("class", "bar-y-axis")
      .style("color", "rgb(147, 149, 151)")
      .style("font-family", "Wigrum")
      .style("font-size", "10px")
      .call(yAxis)
      .call((g) =>
        g
          .selectAll(".tick text")
          .attr("font-size", "10px")
          .attr("font-weight", "400")
          .attr("font-family", "Wigrum")
          .attr("opacity", 0.65)
      );

    this.mainG.select(".bar-x-axis").selectAll("line").remove();
    this.mainG.select(".bar-y-axis").selectAll("line").remove();
    this.mainG.selectAll(".domain").remove();
  }

  reMakeAxis() {
    const length = this.stackedData[0].length;
    const leftMiddle = Math.floor(length / 4);
    const rightMiddle = length - 1 - leftMiddle;
    const tickIndexes =
      this.timeInterval === "7 Day"
        ? this.data.map((d, i) => i)
        : [
            0,
            Math.floor(length / 4),
            Math.floor(length / 2),
            rightMiddle,
            length - 1,
          ];
    const xTicks = tickIndexes.map((d) => {
      return length === 0
        ? null
        : this.stackedData[0][d] === undefined
        ? null
        : this.stackedData[0][d].data[this.xAtt];
    });
    const xAxis = d3
      .axisBottom(this.xScale)
      .tickValues(xTicks)
      .tickFormat(this.getXAxisFormat());

    this.mainG
      .select(".bar-x-axis")
      .style("color", "rgb(147, 149, 151)")
      .style("font-family", "Wigrum")
      .style("font-size", "10px")
      .attr("transform", `translate(0,${this.height})`)
      .call(xAxis)
      .call((g) =>
        g
          .selectAll(".tick text")
          .attr("font-size", "10px")
          .attr("font-weight", "400")
          .attr("font-family", "Wigrum")
          .attr("opacity", 0.65)
      );

    const yAxis = d3
      .axisLeft(this.yScale)
      .ticks(3)
      .tickFormat((d) => this.formatYaxis()(d));

    this.mainG
      .select(".bar-y-axis")
      .style("color", "rgb(147, 149, 151)")
      .style("font-family", "Wigrum")
      .style("font-size", "12px")
      .call(yAxis)
      .call((g) =>
        g
          .selectAll(".tick text")
          .attr("font-size", "12px")
          .attr("font-weight", "400")
          .attr("font-family", "Wigrum")
          .attr("opacity", 0.65)
      );

    this.mainG.select(".bar-x-axis").selectAll("line").remove();
    this.mainG.select(".bar-y-axis").selectAll("line").remove();
    this.mainG.selectAll(".domain").remove();
  }

  getXAxisFormat() {
    switch (this.timeInterval) {
      case "30 Day":
        return d3.timeFormat("%b %d");
      case "7 Day":
        return d3.timeFormat("%b %d");
      case "24 Hr":
        return d3.timeFormat("%H:%M %p");
      default:
        return;
    }
  }

  getXAxisFormatPopover() {
    switch (this.timeInterval) {
      case "30 Day":
        return d3.timeFormat("%b %d");
      case "7 Day":
        return d3.timeFormat("%b %d");
      case "24 Hr":
        return d3.timeFormat("%H:%M");
      default:
        return;
    }
  }
}

export default BaseXYChart;
