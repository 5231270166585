import { GraphQLClient } from "graphql-request";
import Token from "../helpers/Token/Token";

import { API_URL_KAPPA, GET_CLOUD_GRAPHQL_URL } from "../utils/constants";
import { isRegionSpecificSignal } from "../utils/environments";

import { gettingVersionCall } from "./version_actions";

export const CHANGE_SELECTED_PARTNER = "CHANGE_SELECTED_PARTNER";
export const CHANGE_DARK_MODE = "CHANGE_DARK_MODE";
export const CHANGE_PARTNER_TIME = "CHANGE_PARTNER_TIME";
export const LOADING_ME_DATA = "LOADING_ME_DATA";
export const LOADED_ME_DATA = "LOADED_ME_DATA";
export const LOADED_ME_DATA_ERROR = "LOADED_ME_DATA_ERROR";
export const SET_DEPLOYMENT = "SET_DEPLOYMENT";
export const SET_ME_NAME = "SET_ME_NAME";
export const SET_PARTNER_IDS = "SET_PARTNER_IDS";
export const SET_PARTNERS = "SET_PARTNERS";
export const SET_EMAIL = "SET_EMAIL";
export const SET_TOKEN = "SET_TOKEN";
export const SET_ROLE = "SET_ROLE";
export const SET_USER_NAME = "SET_USER_NAME";
export const SET_USER_DEPLOYMENT = "SET_USER_DEPLOYMENT";
export const PARTNER_NOT_FOUND = "PARTNER_NOT_FOUND";
export const RESET_PARTNERS = "RESET_PARTNERS";

export function setEmail(email) {
  return {
    type: SET_EMAIL,
    payload: {
      email,
    },
  };
}

export function setToken(token) {
  return {
    type: SET_TOKEN,
    payload: {
      token,
    },
  };
}

export function setPartnerIds(partnerIds) {
  return {
    type: SET_PARTNER_IDS,
    payload: {
      partnerIds,
    },
  };
}

export function setRole(role) {
  return {
    type: SET_ROLE,
    payload: {
      role,
    },
  };
}

export function setPartners(partners) {
  return {
    type: SET_PARTNERS,
    payload: {
      partners,
    },
  };
}

export function resetPartners(partners) {
  return {
    type: RESET_PARTNERS,
    payload: {
      partners,
    },
  };
}

export function setDeployment(deployment) {
  return {
    type: SET_DEPLOYMENT,
    payload: {
      deployment,
    },
  };
}

export function changeSelectedPartner(new_partner) {
  return {
    type: CHANGE_SELECTED_PARTNER,
    payload: {
      new_partner,
    },
  };
}

export function changPartnerTime(time, partnerID) {
  return {
    type: CHANGE_PARTNER_TIME,
    payload: {
      time,
      partnerID,
    },
  };
}

export function setUserName(name) {
  return {
    type: SET_USER_NAME,
    payload: {
      name,
    },
  };
}

export function partnerNotFound() {
  return {
    type: PARTNER_NOT_FOUND,
  };
}

export const loadingMeData = () => ({
  type: LOADING_ME_DATA,
});

export const loadedMeData = () => ({
  type: LOADED_ME_DATA,
});

export const loadedMeDataError = (payload) => ({
  payload,
  type: LOADED_ME_DATA_ERROR,
});

export const changeDarkMode = () => ({
  type: CHANGE_DARK_MODE,
});

export const setMeName = (name) => ({
  type: SET_ME_NAME,
  payload: {
    name,
  },
});

export const setUserDep = (deployment) => ({
  type: SET_USER_DEPLOYMENT,
  payload: {
    deployment,
  },
});

const roleQuery = `query {
                         me {
                           role
                           partnerIds
                           name
                         }
                       }`;

const adminQuery = `query{
                          deployment {
                            name

                          }
                           partners {
                               id
                               name,
                               primaryDeployment
                           }
                        }`;

const ispName = `query getDeployment($id:ID){
                         partner(id: $id){
                           primaryDeployment
                           name
                         }
                       }`;

export const updatePartner = (partnerId) => {
  return (dispatch, getState) => {
    const { me } = getState();
    const selectedPartner = me.partners.find((p) => p.id === partnerId);
    const new_selected_partner = {
      time: new Date(),
      id: partnerId,
      name: selectedPartner.name,
      primaryDeployment: selectedPartner.primaryDeployment,
    };
    dispatch(changeSelectedPartner(new_selected_partner));
  };
};

export const getUserAndPartners = () => {
  return async function (dispatch) {
    await dispatch(gettingVersionCall());
    await dispatch(getUserRole());
    await dispatch(getPartners("gamma"));
    if (API_URL_KAPPA && !isRegionSpecificSignal()) {
      await dispatch(getPartners("kappa"));
    }
    dispatch(loadedMeData());
  };
};

export const getPartners = (primaryDeployment) => {
  return async function (dispatch, getState) {
    const oktaToken = new Token(localStorage["okta-token-storage"]);
    const token = oktaToken.idToken;

    const endpoint = GET_CLOUD_GRAPHQL_URL(primaryDeployment);

    const graphQLClient = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
    if (["admin", "adminReadOnly"].includes(getState().me.role)) {
      try {
        const response2 = await graphQLClient.request(adminQuery);

        const adminData = response2;
        let partners = adminData.partners.map((d) => ({
          ...d,
          ...{
            time: null,
          },
        }));
        const deployment = {
          name: adminData.deployment.name,
          id: adminData.deployment.name,
        };

        let selectedPartner = partners.find(
          (d) => d.id === "5aabf8f04062ce5c1e9447ac"
        );
        selectedPartner =
          selectedPartner === undefined ? partners[0] : selectedPartner;

        selectedPartner.time = new Date();
        partners = partners.sort((a, b) =>
          a.name.toUpperCase() < b.name.toUpperCase()
            ? -1
            : a.name.toUpperCase() > b.name.toUpperCase()
            ? 1
            : 0
        );

        dispatch(setDeployment(deployment));

        dispatch(changeSelectedPartner(selectedPartner));

        dispatch(setPartners(partners));
      } catch (error) {
        dispatch(loadedMeDataError(primaryDeployment));
        return null;
      }
    } else {
      try {
        const partnerIds = getState().me.partnerIds.filter(
          (value, index, array) => array.indexOf(value) == index
        );

        const promises = partnerIds.map(async (id) => {
          const response = await graphQLClient
            .request(ispName, { id })
            .catch((error) => console.log(error));
          if (!response?.partner) {
            return null;
          }

          const partnerToAdd = {
            ...response?.partner,
            ...{
              time: new Date(),
              id,
            },
          };
          return partnerToAdd;
        });
        const partners = await Promise.all(promises);
        const filteredPartners = partners.filter((p) => p !== null);
        const currentPartners = getState().me.partners;
        const mergedPartners = [...currentPartners, ...filteredPartners];

        dispatch(changeSelectedPartner(mergedPartners[0]));
        dispatch(setPartners(mergedPartners));
      } catch (error) {
        dispatch(loadedMeDataError(primaryDeployment));
        return null;
      }
    }
  };
};

export const getUserRole = () => {
  return async function (dispatch) {
    dispatch(loadingMeData());
    const oktaToken = new Token(localStorage["okta-token-storage"]);

    const token = oktaToken.idToken;
    dispatch(setToken(token));
    dispatch(setEmail(oktaToken.email));

    let gammaPartnerIds = [];
    try {
      const gammaGraphQl = new GraphQLClient(GET_CLOUD_GRAPHQL_URL("gamma"), {
        headers: {
          authorization: `Bearer ${token}`,
        },
      });
      const response = await gammaGraphQl.request(roleQuery);
      let data = response;

      const role = data.me.role;
      const name = data.me.name;
      gammaPartnerIds = data.me.partnerIds || [];
      dispatch(setRole(role));
      dispatch(setMeName(name));
    } catch (error) {
      console.log("error", error);
      dispatch(loadedMeDataError("userRole"));
      return null;
    }

    let kappaPartnerIds = [];
    if (!isRegionSpecificSignal()) {
      try {
        const kappaGraphQl = new GraphQLClient(GET_CLOUD_GRAPHQL_URL("kappa"), {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });
        const response = await kappaGraphQl.request(roleQuery);
        let data = response;

        kappaPartnerIds = data.me.partnerIds || [];
      } catch (error) {
        console.error(`KAPPA USER ROLE API ERROR. Check meActions.js`);
      }
    }
    const partnerIds = [...new Set([...gammaPartnerIds, ...kappaPartnerIds])];

    if (partnerIds) {
      dispatch(setPartnerIds(partnerIds));
    }
  };
};
