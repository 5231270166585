import {
  GETTING_VERSION,
  VERSION_ERROR,
  VERSION_LOADED,
} from "../actions/version_actions";

const INIT_STATE = {
  loadingVersion: false,
  version: null,
  versionError: false,
};

const versionReducer = (state = INIT_STATE, action = null) => {
  switch (action.type) {
    case GETTING_VERSION:
      return { ...state, loadingVersion: true };
    case VERSION_ERROR:
      return { ...state, versionError: true };
    case VERSION_LOADED:
      return {
        ...state,
        loadingVersion: false,
        version: action.payload.version,
      };
    default:
      return state;
  }
};

//selectors

export const versionSelector = (state) => {
  if (state.version === null) return null;
  let versionArray = state.version.split(".");
  versionArray = versionArray.map((num) => parseInt(num));
  let sum = 0;
  for (let t of versionArray) sum += t;
  return sum;
};

export default versionReducer;
