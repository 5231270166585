import { CHANGE_SELECTOR } from "../actions/list_actions";

export const Init_State = {
  selectors: {
    sort_by: {
      title: "SORT BY",
      options: [
        "Overall Score",
        "Poor Coverage",
        "Offline Networks",
        "High Congestion",
        "Unstable Networks",
      ],
      selectValue: "Overall Score",
    },
    ordering: {
      title: "ORDERING",
      options: ["Descending", "Ascending"],
      selectValue: "Descending",
    },
    tenant: {
      title: "TENANT",
      options: ["All"],
      selectValue: "All",
    },
  },
};

function list_reducer(state = Init_State, action = { type: null }) {
  switch (action.type) {
    case CHANGE_SELECTOR:
      return {
        ...state,
        ...{
          selectors: {
            ...state.selectors,
            ...{
              [action.payload.key]: {
                ...state.selectors[action.payload.key],
                ...{ selectValue: action.payload.value },
              },
            },
          },
        },
      };

    default:
      return state;
  }
}

export default list_reducer;
