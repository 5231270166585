import { CALL_VOLUME_PREDICTION_7DAY_AGGREGATE_QUERY } from "./graphQL_queries/predict";
import PredictService from "../services/PredictService";
import moment from "moment";
import { processPeriodPayload } from "../reducers/predictReducer";

export const PREDICT_SUMMARY_DATA__DATA_FETCHING =
  "PREDICT_SUMMARY_DATA__DATA_FETCHING";
const predictSummaryData__DataFetching = () => ({
  type: PREDICT_SUMMARY_DATA__DATA_FETCHING,
});
export const PREDICT_SUMMARY_DATA__DATA_ERRORED =
  "PREDICT_SUMMARY_DATA__DATA_ERRORED";
const predictSummaryData__DataErrored = () => ({
  type: PREDICT_SUMMARY_DATA__DATA_ERRORED,
});
export const PREDICT_SUMMARY_DATA__DATA_RECEIVED =
  "PREDICT_SUMMARY_DATA__DATA_RECEIVED";
const predictSummaryData__DataReceived = (data) => ({
  type: PREDICT_SUMMARY_DATA__DATA_RECEIVED,
  payload: {
    data,
  },
});

export const LOCATIONS_MONITORED_DATA__DATA_FETCHING =
  "LOCATIONS_MONITORED_DATA__DATA_FETCHING";
const locationsMonitoredData__DataFetching = () => ({
  type: LOCATIONS_MONITORED_DATA__DATA_FETCHING,
});
export const LOCATIONS_MONITORED_DATA__DATA_ERRORED =
  "LOCATIONS_MONITORED_DATA__DATA_ERRORED";
const locationsMonitoredData__DataErrored = () => ({
  type: LOCATIONS_MONITORED_DATA__DATA_ERRORED,
});
export const LOCATIONS_MONITORED_DATA__DATA_RECEIVED =
  "LOCATIONS_MONITORED_DATA__DATA_RECEIVED";
const locationsMonitoredData__DataReceived = (data) => ({
  type: LOCATIONS_MONITORED_DATA__DATA_RECEIVED,
  payload: {
    data,
  },
});

export const PREDICT_24HOUR_AGGREGATE_DATA__DATA_FETCHING =
  "PREDICT_24HOUR_AGGREGATE_DATA__DATA_FETCHING";
const predict24HourAggregateData__DataFetching = () => ({
  type: PREDICT_24HOUR_AGGREGATE_DATA__DATA_FETCHING,
});
export const PREDICT_24HOUR_AGGREGATE_DATA__DATA_ERRORED =
  "PREDICT_24HOUR_AGGREGATE_DATA__DATA_ERRORED";
const predict24HourAggregateData__DataErrored = () => ({
  type: PREDICT_24HOUR_AGGREGATE_DATA__DATA_ERRORED,
});
export const PREDICT_24HOUR_AGGREGATE_DATA__DATA_RECEIVED =
  "PREDICT_24HOUR_AGGREGATE_DATA__DATA_RECEIVED";
const predict24HourAggregateData__DataReceived = (data) => ({
  type: PREDICT_24HOUR_AGGREGATE_DATA__DATA_RECEIVED,
  payload: {
    data,
  },
});

export const PREDICT_7DAY_AGGREGATE_DATA__DATA_FETCHING =
  "PREDICT_7DAY_AGGREGATE_DATA__DATA_FETCHING";
const predict7DayAggregateData__DataFetching = () => ({
  type: PREDICT_7DAY_AGGREGATE_DATA__DATA_FETCHING,
});
export const PREDICT_7DAY_AGGREGATE_DATA__DATA_ERRORED =
  "PREDICT_7DAY_AGGREGATE_DATA__DATA_ERRORED";
const predict7DayAggregateData__DataErrored = () => ({
  type: PREDICT_7DAY_AGGREGATE_DATA__DATA_ERRORED,
});
export const PREDICT_7DAY_AGGREGATE_DATA__DATA_RECEIVED =
  "PREDICT_7DAY_AGGREGATE_DATA__DATA_RECEIVED";
const predict7DayAggregateData__DataReceived = (data) => ({
  type: PREDICT_7DAY_AGGREGATE_DATA__DATA_RECEIVED,
  payload: {
    data,
  },
});

export const PREDICT_SET_CALL_IN_VOLUME_PERIODS =
  "PREDICT_SET_CALL_IN_VOLUME_PERIODS";
export const predictSetCallInVolumePeriods = (data) => ({
  type: PREDICT_SET_CALL_IN_VOLUME_PERIODS,
  payload: {
    data,
  },
});
export const PREDICT_SET_CALL_IN_VOLUME_ACTIVE_PERIOD =
  "PREDICT_SET_CALL_IN_VOLUME_ACTIVE_PERIOD";
export const predictSetCallInVolumeActivePeriod = (period) => ({
  type: PREDICT_SET_CALL_IN_VOLUME_ACTIVE_PERIOD,
  payload: period,
});

export const PREDICT_SET_LOADING_CALL_IN_VOLUME_PERIODS =
  "PREDICT_SET_LOADING_CALL_IN_VOLUME_PERIODS";
export const predictSetLoadingCallInVolumePeriods = (isLoading) => ({
  type: PREDICT_SET_LOADING_CALL_IN_VOLUME_PERIODS,
  payload: {
    loading: isLoading,
  },
});

// data for Historical Trend chart
export const PREDICT_SET_HISTORICAL_TREND_DATA =
  "PREDICT_SET_HISTORICAL_TREND_DATA";
export const predictSetHistoricalTrendData = (data) => ({
  type: PREDICT_SET_HISTORICAL_TREND_DATA,
  payload: {
    data,
  },
});

export const PREDICT_SET_LOADING_HISTORICAL_TREND_DATA =
  "PREDICT_SET_LOADING_HISTORICAL_TREND_DATA";
export const predictSetLoadingHistoricalTrendData = (isLoading) => ({
  type: PREDICT_SET_LOADING_HISTORICAL_TREND_DATA,
  payload: {
    loading: isLoading,
  },
});
export const PREDICT_SET_DATA_SCOPE_IN_DAYS = "PREDICT_SET_DATA_SCOPE_IN_DAYS";
export const predictSetDataScopeInDays = (dataScopeInDays) => ({
  type: PREDICT_SET_DATA_SCOPE_IN_DAYS,
  payload: {
    dataScopeInDays,
  },
});

//thunk
export const getPredictSummaryData = () => {
  return async (dispatch, getState) => {
    dispatch(predictSummaryData__DataFetching());
    try {
      const service = new PredictService();
      const data = await service.getPredictSummaryData(
        getState().me.selectedPartner.id
      );
      dispatch(predictSummaryData__DataReceived(data));
    } catch (err) {
      console.log("====> query err: summary data", err);
      dispatch(predictSummaryData__DataErrored());
    }
  };
};

export const getCallInVolumeData = () => {
  return async (dispatch, getState) => {
    dispatch(predictSetLoadingCallInVolumePeriods(true));
    try {
      const service = new PredictService();
      const response = await service.getCallInVolumePeriods(
        getState().me.selectedPartner.id
      );
      const periods = response?.callInVolumePeriods || [];
      const parsedPeriods = processPeriodPayload(periods);
      dispatch(predictSetCallInVolumePeriods(parsedPeriods));
      dispatch(predictSetCallInVolumeActivePeriod(parsedPeriods[0].label));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(predictSetLoadingCallInVolumePeriods(false));
    }
  };
};

export const getHistoricalTrendData = () => {
  return async (dispatch, getState) => {
    dispatch(predictSetLoadingHistoricalTrendData(true));
    const scopeInDays = getState().predict.historicalTrendData.dataScopeInDays;
    const periodStart = moment().add(scopeInDays, "days");
    const periodEnd = moment();
    try {
      const service = new PredictService();
      const response = await service.getHistoricalTrendData(
        getState().me.selectedPartner.id,
        periodStart,
        periodEnd
      );
      dispatch(predictSetHistoricalTrendData(response?.historicalTrend));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(predictSetLoadingHistoricalTrendData(false));
    }
  };
};

//thunk
export const getLocationsMonitoredData = () => {
  return async (dispatch, getState) => {
    dispatch(locationsMonitoredData__DataFetching());
    try {
      const service = new PredictService();
      const data = await service.getLocationsMonitoredData(
        getState().me.selectedPartner.id
      );
      dispatch(locationsMonitoredData__DataReceived(data));
    } catch (err) {
      console.log("====> query err: locations monitored", err);
      dispatch(locationsMonitoredData__DataErrored());
    }
  };
};

//thunk
export const getPredict24HourData = () => {
  return async (dispatch, getState) => {
    dispatch(predict24HourAggregateData__DataFetching());
    try {
      const service = new PredictService();
      const data = service.getPredict24HourData(
        getState().me.selectedPartner.id
      );
      dispatch(predict24HourAggregateData__DataReceived(data));
    } catch (err) {
      console.log("====> query err: prediction 24hour aggregate", err);
      dispatch(predict24HourAggregateData__DataErrored());
    }
  };
};

//thunk
export const getPredict7DayData = () => {
  return async (dispatch, getState) => {
    dispatch(predict7DayAggregateData__DataFetching());
    try {
      const service = new PredictService();
      const data = await service.getPredict7DayData(
        CALL_VOLUME_PREDICTION_7DAY_AGGREGATE_QUERY(
          getState().me.selectedPartner.id
        )
      );
      dispatch(predict7DayAggregateData__DataReceived(data));
    } catch (err) {
      console.log("====> query err: prediction 7day aggregate", err);
      dispatch(predict7DayAggregateData__DataErrored());
    }
  };
};
