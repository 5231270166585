import React from "react";
import "./Icon.scss";
import SVG from "react-inlinesvg";
import PropTypes from "prop-types";

const SlIcon = ({
  color,
  img,
  svgPath,
  svgFile,
  className,
  handleClick,
  width,
  height,
}) => {
  const style = {
    fill: color ? `var(--color-${color})` : "inherit",
  };

  const regularIcon = (
    <div className={`sl-icon${className ? ` ${className}` : ""}`}>
      {svgPath}
      {img ? <img alt="" src={img} /> : null}
      {svgFile ? (
        <div className={`${color ? "with-color" : ""}`} style={style}>
          <SVG
            src={svgFile}
            width={width ? width : 22}
            height={height ? height : 22}
          />
        </div>
      ) : null}
    </div>
  );

  const buttonIcon = (
    <button
      type={"button"}
      className={`sl-icon${className ? ` ${className}` : ""}`}
      onClick={(e) => handleClick(e)}
    >
      <React.Fragment>
        {svgPath ? svgPath : null}
        {img ? <img alt="" src={img} /> : null}
        {svgFile ? (
          <div className={`${color ? "with-color" : ""}`} style={style}>
            <SVG
              style={style}
              src={svgFile}
              width={width ? `${width}px` : "auto"}
              height={"auto"}
            />
          </div>
        ) : null}
      </React.Fragment>
    </button>
  );

  return handleClick ? buttonIcon : regularIcon;
};

SlIcon.propTypes = {
  color: PropTypes.string,
  fill: PropTypes.string,
  img: PropTypes.string,
  svgFile: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};

export { SlIcon };
