import { request } from "graphql-request";
import { SIGNAL_MOCK_URL } from "../utils/constants";
import { getDataMockedThunk } from "./AsynchActions";
import { selectionQuery } from "./graphQL_queries/selectionFilters";
import { LAS_LOCATION_QUERY } from "./graphQL_queries/lasLocationQuery";
import { MON_LAS_SEARCH } from "./graphQL_queries/monLASSearch";

export const ADD_FILTER = "ADD_FILTER";
export const DELETE_FILTER = "DELETE_FILTER";
export const SHOW_FILTERS = "SHOW_FILTERS";
export const SHOW_HIDE_FILTERS = "SHOW_HIDE_FILTERS";
export const HIDE_FILTERS = "HIDE_FILTERS";
export const SET_FILTER_OPTIONS = "SET_FILTER_OPTIONS";
export const SET_FILTER_ERROR = "SET_FILTER_ERROR";
export const SET_MON_LOCATION_DATA = "SET_MON_LOCATION_DATA";
export const SET_SEARCH_TEXT = "SET_SEARCH_TEXT";
export const SET_MON_TABLE_PAGINATION = "SET_MON_TABLE_PAGINATION";
export const SET_MON_TABLE_SEARCH_FILTERS = "SET_MON_TABLE_SEARCH_FILTERS";
export const SET_MON_TABLE_SEARCH_LOCATIONS = "SET_MON_TABLE_SEARCH_LOCATIONS";

//actions
export const addFilter = (filter, keyList) => ({
  type: ADD_FILTER,
  payload: {
    filter,
    keyList,
  },
});

export const deleteFilter = (filter, keyList) => ({
  type: DELETE_FILTER,
  payload: {
    filter,
    keyList,
  },
});

export const showFilters = () => ({
  type: SHOW_FILTERS,
});

export const showHideFilters = () => ({
  type: SHOW_HIDE_FILTERS,
});

export const hideFilters = () => ({
  type: HIDE_FILTERS,
});

export const setFilterOptions = (data) => ({
  type: SET_FILTER_OPTIONS,
  payload: {
    data,
  },
});

export const setMonLocationDate = (data) => ({
  type: SET_MON_LOCATION_DATA,
  payload: {
    data,
  },
});

export const setMonTableSearchFilters = (filters) => ({
  type: SET_MON_TABLE_SEARCH_FILTERS,
  payload: {
    filters,
  },
});

export const setMonTableSearchLocations = (locations) => ({
  type: SET_MON_TABLE_SEARCH_LOCATIONS,
  payload: {
    locations,
  },
});

export const setFilterError = () => ({
  type: SET_FILTER_ERROR,
});

export const setSearchText = (text) => ({
  type: SET_SEARCH_TEXT,
  payload: {
    text,
  },
});

export const setMonTablePagination = (offset, first) => ({
  type: SET_MON_TABLE_PAGINATION,
  payload: {
    offset,
    first,
  },
});

export const getFiltersData = getDataMockedThunk(
  setFilterOptions,
  setFilterError,
  selectionQuery
);

//thunk
export const getLocationsThunkFilter = (filter, keyList) => {
  return async (dispatch, getState) => {
    dispatch(addFilter(filter, keyList));
    const rawFilters = getState().monitorLAS.selectedFilters.byName;
    const filters = Object.keys(rawFilters).map((key) => {
      return {
        typeOfFilter: rawFilters[key].typeOfFilter,
        value: rawFilters[key].name,
      };
    });

    const searchFilter = getState().monitorLAS.searchText;
    const first = getState().monitorLAS.first;
    const offset = getState().monitorLAS.offset;
    const variables = {
      filters: filters,
      search: searchFilter,
      first: first,
      offset: offset,
    };

    const endPoint = SIGNAL_MOCK_URL;
    const query = LAS_LOCATION_QUERY;
    try {
      const data = await request(endPoint, query, variables);
      const listData = data.monLocations;
      dispatch(setMonLocationDate(listData));
    } catch (err) {
      console.log("====>", err);
    }
  };
};

export const getLocationsThunkSearch = (searchText = "") => {
  return async (dispatch, getState) => {
    dispatch(setSearchText(searchText));
    const rawFilters = getState().monitorLAS.selectedFilters.byName;
    const filters = Object.keys(rawFilters).map((key) => {
      return {
        typeOfFilter: rawFilters[key].typeOfFilter,
        value: rawFilters[key].name,
      };
    });

    const searchFilter = getState().monitorLAS.searchText;
    const first = getState().monitorLAS.first;
    const offset = getState().monitorLAS.offset;
    const variables = {
      filters: filters,
      search: searchFilter,
      first: first,
      offset: offset,
    };

    const endPoint = SIGNAL_MOCK_URL;
    const query = LAS_LOCATION_QUERY;
    try {
      const data = await request(endPoint, query, variables);
      const listData = data.monLocations;
      dispatch(setMonLocationDate(listData));
    } catch (err) {
      console.log("====>", err);
    }
  };
};

export const deletFilterThunk = (filter, keyList) => {
  return async (dispatch, getState) => {
    dispatch(deleteFilter(filter, keyList));
    const rawFilters = getState().monitorLAS.selectedFilters.byName;
    const filters = Object.keys(rawFilters).map((key) => {
      return {
        typeOfFilter: rawFilters[key].typeOfFilter,
        value: rawFilters[key].name,
      };
    });

    const searchFilter = getState().monitorLAS.searchText;
    const first = getState().monitorLAS.first;
    const offset = getState().monitorLAS.offset;
    const variables = {
      filters: filters,
      search: searchFilter,
      first: first,
      offset: offset,
    };

    const endPoint = SIGNAL_MOCK_URL;
    const query = LAS_LOCATION_QUERY;
    try {
      const data = await request(endPoint, query, variables);
      const listData = data.monLocations;
      dispatch(setMonLocationDate(listData));
    } catch (err) {
      console.log("====>", err);
    }
  };
};

export const changePaginationThunk = (newOffset, newFirst) => {
  return async (dispatch, getState) => {
    dispatch(setMonTablePagination(newOffset, newFirst));
    const rawFilters = getState().monitorLAS.selectedFilters.byName;
    const filters = Object.keys(rawFilters).map((key) => {
      return {
        typeOfFilter: rawFilters[key].typeOfFilter,
        value: rawFilters[key].name,
      };
    });

    const searchFilter = getState().monitorLAS.searchText;
    const first = getState().monitorLAS.first;
    const offset = getState().monitorLAS.offset;
    const variables = {
      filters: filters,
      search: searchFilter,
      first: first,
      offset: offset,
    };

    const endPoint = SIGNAL_MOCK_URL;
    const query = LAS_LOCATION_QUERY;
    try {
      const data = await request(endPoint, query, variables);
      const listData = data.monLocations;
      dispatch(setMonLocationDate(listData));
    } catch (err) {
      console.log("====>", err);
    }
  };
};

export const setSearchTextThunk = (newText) => {
  return async (dispatch, getState) => {
    dispatch(setSearchText(newText));
    const variables = {
      search: newText,
    };

    const endPoint = SIGNAL_MOCK_URL;
    const query = MON_LAS_SEARCH;

    try {
      const data = await request(endPoint, query, variables);
      const filters = data.monLocationsFilters.filters;
      const locations = data.monLocationsFilters.locations.map((l) => ({
        value: l.locationId,
        typeOfFilter: "locationId",
      }));
      dispatch(setMonTableSearchFilters(filters));
      dispatch(setMonTableSearchLocations(locations));
      //dispatch(setMonLocationDate(listData));
    } catch (err) {
      console.log("========>", err);
    }
  };
};
