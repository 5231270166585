import React from "react";
import PropTypes from "prop-types";

import "./Slider.scss";

const SlSlider = ({
  min,
  max,
  value,
  handleValueChange,
  step,
  isDisabled,
  ...inputControlEvents
}) => {
  return (
    <input
      className="sl-slider"
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      disabled={isDisabled}
      onChange={(e) => {
        handleValueChange(e);
      }}
      {...inputControlEvents}
    />
  );
};

SlSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool,
};

export { SlSlider };
