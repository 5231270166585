import { GraphQLClient } from "graphql-request";
import { GET_CLOUD_GRAPHQL_URL, SIGNAL_MOCK_URL } from "../../utils/constants";
import { getOktaToken } from "../../helpers/Token/Token";

function graphQlQuery(query) {
  return async (dispatch, getState) => {
    const primaryDeployment = getState().me.selectedPartner.primaryDeployment;
    const endpoint = GET_CLOUD_GRAPHQL_URL(primaryDeployment);
    const oktaToken = getOktaToken(localStorage);
    const client = new GraphQLClient(endpoint, {
      headers: { Authorization: `Bearer ${oktaToken}` },
    });
    const variables = { id: getState().me.selectedPartner.id };
    await client.request(query, variables);
  };
}

function getdata(
  gettingDataAction,
  dataReceivedActions,
  dataErroAction,
  query
) {
  return (queryParams) => {
    return async (dispatch, getState) => {
      const primaryDeployment = getState().me.selectedPartner.primaryDeployment;

      dispatch(gettingDataAction(queryParams));
      const endpoint = GET_CLOUD_GRAPHQL_URL(primaryDeployment);
      const oktaToken = getOktaToken(localStorage);
      const client = new GraphQLClient(endpoint, {
        headers: { Authorization: `Bearer ${oktaToken}` },
      });
      const variables = {
        ...{ id: getState().me.selectedPartner.id },
        ...queryParams,
      };

      try {
        const data = await client.request(
          typeof query === "function" ? query(queryParams) : query,
          variables
        );
        if (Array.isArray(dataReceivedActions)) {
          dataReceivedActions.forEach((action) => {
            dispatch(action(data, variables));
          });
        } else {
          dispatch(dataReceivedActions(data));
        }
      } catch (err) {
        console.log("====> monitor header err", err);
        dispatch(dataErroAction());
      }
    };
  };
}

function getdataMocked(dataReceivedAction, dataErrorAction, query, params) {
  return async (dispatch) => {
    const endpoint = SIGNAL_MOCK_URL;
    const client = new GraphQLClient(endpoint);
    try {
      const data = await client.request(query);
      dispatch(dataReceivedAction(data, params));
    } catch (err) {
      throw err;
    }
  };
}

function getDataMockedThunk(
  dataReceivedAction,
  dataErrorAction,
  query,
  params
) {
  return () => {
    return async (dispatch) => {
      const endpoint = SIGNAL_MOCK_URL;
      const client = new GraphQLClient(endpoint);
      try {
        const data = await client.request(query);
        dispatch(dataReceivedAction(data, params));
      } catch (err) {
        throw err;
      }
    };
  };
}

export { getdata, getdataMocked, getDataMockedThunk, graphQlQuery };
