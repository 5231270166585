import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import { changeKPIOptionSelection } from "../../../../../actions/monNHMCActions";
import { SlDropdown } from "../../../communal/Molecules/Dropdown/Dropdown";
import { SlButton } from "../../../communal/Atoms/Button/Button";
import { SlIcon } from "../../../communal/Atoms/Icon/Icon";
import ThreeDotsIcon from "../../../../../assets/icons/three_dots.svg";

import { selectOptions } from "./selectors/options_selector";
import { useTranslation } from "react-i18next";

const mapStateToProps = (state, props) => ({
  ...props,
  selections: selectOptions(state.KPI.KPIs.byName[props.name].options),
});

const mapDispatchToProps = (dispatch) => ({
  changeOption: (name, option, section) => {
    dispatch(changeKPIOptionSelection(name, option, section));
  },
});

const mapSelectionsToDisplayLabel = {
  "Change Chart": "monitor.KPIs.menu.changeChart",
  "Y Axis Measure": "monitor.KPIs.menu.yAxisMeasure",
  Percentage: "monitor.KPIs.menu.percentage",
  Number: "monitor.KPIs.menu.number",
  TrendLine: "monitor.KPIs.menu.trendLine",
  BarChart: "monitor.KPIs.menu.barChart",
};

const KPIMenu = ({ selections, name, changeOption }) => {
  const { t } = useTranslation();

  const translateIfExists = useCallback(
    (key) =>
      mapSelectionsToDisplayLabel[key]
        ? t(mapSelectionsToDisplayLabel[key])
        : key,
    [t]
  );

  useEffect(() => {
    if (selections["Change Chart"]?.options?.BarChart) {
      if (
        selections["Y Axis Measure"]?.options?.Number === undefined &&
        !selections["Y Axis Measure"]?.options?.Percentage
      ) {
        changeOption(name, "Percentage", "Y Axis Measure");
      }
    }
  }, [selections, changeOption, name]);

  const getSelectionsWithTranslations = useCallback(() => {
    const selectionsCopy = { ...selections };
    const selectionsWithTranslations = {};
    Object.keys(selectionsCopy).forEach((key) => {
      const translatedKey = translateIfExists(key);
      selectionsWithTranslations[translatedKey] = selections[key];
    });

    Object.keys(selectionsWithTranslations).forEach((selectionKey) => {
      const translatedOptions = {};
      const selection = selectionsWithTranslations[selectionKey];
      Object.keys(selectionsWithTranslations[selectionKey].options).forEach(
        (option) => {
          const translatedOption = translateIfExists(option);
          translatedOptions[translatedOption] = selection.options[option];
          selectionsWithTranslations[selectionKey] = {
            ...selectionsWithTranslations[selectionKey],
            options: translatedOptions,
          };
          selectionsWithTranslations[selectionKey].options = translatedOptions;
        }
      );
    });

    return selectionsWithTranslations;
  }, [selections, translateIfExists]);

  const getOriginName = (name) => {
    const reversed = {};
    Object.keys(mapSelectionsToDisplayLabel).forEach((key) => {
      const value = t(mapSelectionsToDisplayLabel[key]);
      reversed[value] = key;
    });
    return reversed[name];
  };

  return (
    <div className="sl-kpi-menu">
      <SlDropdown
        selections={getSelectionsWithTranslations()}
        onSelect={(option, section) => {
          const originName = getOriginName(option);
          const originSection = getOriginName(section);
          changeOption(name, originName, originSection);
        }}
        trigger={
          <SlButton round>
            <SlIcon svgFile={ThreeDotsIcon} />
          </SlButton>
        }
      ></SlDropdown>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(KPIMenu);
