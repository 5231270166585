import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import UserMenu from "./UserMenu";
import ISPSelector from "./ISPSelector/ISPSelector";

import "./header.scss";
import { SlSection } from "../components/communal/Atoms/Section/Section";

import { useLocation } from "react-router";
import { SlText } from "../components/communal/Atoms/Text/Text";
import FormattedMessage from "../../translations/FormattedMessage";
import { useTranslation } from "react-i18next";

// @TODO: Add more mappings when adding new translations
const mapPageNameToCopyId = {
  monitor: "monitor.title",
  predict: "predict.title",
};

const Header = ({ name }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const getPageName = (urlElement) =>
    mapPageNameToCopyId[urlElement]
      ? t(mapPageNameToCopyId[urlElement])
      : urlElement;

  const pageTitle = getPageName(pathname.split("/")[1]);

  return (
    <div className="sl-main-header">
      <SlSection>
        <div className="sl-main-header__left">
          <a className="sl-main-header__logo" href="/">
            <SlText subtitle1m>
              <FormattedMessage id="Signal" />
            </SlText>
          </a>
          <SlText subtitle3 className="sl-main-header__page">
            {pageTitle}
          </SlText>
        </div>

        <div className="sl-main-header__right">
          <ISPSelector />
          <UserMenu userName={name} />
        </div>
      </SlSection>
    </div>
  );
};

Header.propTypes = {
  name: PropTypes.string,
};

const mapStateToProps = (state) => ({
  name: state.me.name,
});

export default connect(mapStateToProps, undefined)(Header);
