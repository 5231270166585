import LasSearchAndAction from "../../../components/communal/Molecules/LasSearchAndAction";
import { connect } from "react-redux";
import {
  getLocationsThunkFilter,
  setSearchTextAlert,
  showHideFilters,
} from "../../../../actions/alert/alertLASActions";

const mapStateToProps = (state) => ({
  showFilterOptions: state.alertLAS.showFilters,
  filterOptions: state.alertLAS.filtersSelectData,
  selectedTicket: state.alert.selectedTicket,
});

const mapDispatchToProps = (dispatch) => ({
  showHideFilters: () => {
    dispatch(showHideFilters());
  },
  toggleFilter: (filter, filterTree, active) => {
    dispatch(getLocationsThunkFilter(filter, filterTree, active));
  },
  setSearchNewText: (newText) => {
    dispatch(setSearchTextAlert(newText));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LasSearchAndAction);
