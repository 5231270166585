import {
  OKTA_CLIENT_ID,
  OKTA_ISSUER,
  OKTA_BASE_URL,
  SIGNAL_ENDPOINT_SPHERE,
  VERSION_ENDPOINT,
} from "./constants";

const environments = [
  {
    id: "ci",
    name: "CI",
    graphqlUrl:
      "https://signal-ext.us-west-2.datavinci.dev.plume.tech/reports/signal",
    notificationsUrl:
      "https://signal-ext.us-west-2.datavinci.dev.plume.tech/notifications",
    oktaIssuer: "https://dev-741674.oktapreview.com/oauth2/default",
    oktaBaseUrl: "https://dev-741674.oktapreview.com",
    oktaClientId: "0oa12oet8abmbyIde0h8",
    versionApiEndpoint:
      "https://signal-ext.us-west-2.datavinci.dev.plume.tech/reports/version",
    frontlineUrl: "https://ci.noc.plume.com/location",
  },
  {
    id: "gamma-dst",
    name: "Gamma-DST",
    graphqlUrl:
      "https://signal-ext.us-west-2.dst.global.plume.tech/reports/signal",
    notificationsUrl:
      "https://signal-ext.us-west-2.dst.global.plume.tech/notifications",
    oktaIssuer: "https://plume.okta.com/oauth2/default",
    oktaBaseUrl: "https://plume.okta.com",
    oktaClientId: "0oadbipranH3LgmZe357",
    versionApiEndpoint:
      "https://signal-ext.us-west-2.dst.global.plume.tech/reports/version",
    frontlineUrl: "https://gamma.noc.plume.com/location",
  },
  {
    id: "beta",
    name: "Beta",
    graphqlUrl:
      "https://signal-beta-ext.usw2.prod.datavinci.plumenet.io/reports/signal",
    notificationsUrl:
      "https://signal-beta-ext.usw2.prod.datavinci.plumenet.io/notifications",
    oktaIssuer: "https://plume.okta.com/oauth2/default",
    oktaBaseUrl: "https://plume.okta.com",
    oktaClientId: "0oadbini70Iov4IsU357",
    versionApiEndpoint:
      "https://signal-beta-ext.usw2.prod.datavinci.plumenet.io/reports/version",
    frontlineUrl: "https://beta.noc.plume.com/location",
  },
  {
    id: "gamma",
    name: "Gamma",
    graphqlUrl:
      "https://signal-gamma-ext.usw2.prod.datavinci.plumenet.io/reports/signal",
    notificationsUrl:
      "https://signal-gamma-ext.usw2.prod.datavinci.plumenet.io/notifications",
    oktaIssuer: "https://plume.okta.com/oauth2/default",
    oktaBaseUrl: "https://plume.okta.com",
    oktaClientId: "0oadbiqmaw4nafGoX357",
    versionApiEndpoint:
      "https://signal-gamma-ext.usw2.prod.datavinci.plumenet.io/reports/version",
    frontlineUrl: "https://gamma.noc.plume.com/location",
  },
  {
    id: "kappa",
    name: "Kappa",
    graphqlUrl:
      "https://signal-kappa-ext.euc1.prod.datavinci.plumenet.io/reports/signal",
    notificationsUrl:
      "https://signal-kappa-ext.euc1.prod.datavinci.plumenet.io/notifications",
    oktaIssuer: "https://plume.okta.com/oauth2/default",
    oktaBaseUrl: "https://plume.okta.com",
    oktaClientId: "0oadbiswq5ZGHwdRI357",
    versionApiEndpoint:
      "https://signal-kappa-ext.euc1.prod.datavinci.plumenet.io/reports/version",
    frontlineUrl: "https://kappa.noc.plume.com/location",
  },
  {
    id: "demo",
    name: "Demo",
    graphqlUrl:
      "https://signal-demo-ext.us-west-2.dst.global.plume.tech/reports/signal",
    notificationsUrl:
      "https://signal-demo-ext.us-west-2.dst.global.plume.tech/notifications",
    oktaIssuer: "https://plume.okta.com/oauth2/default",
    oktaBaseUrl: "https://plume.okta.com",
    oktaClientId: "0oadnilc7yYAy4pya357",
    versionApiEndpoint:
      "https://signal-demo-ext.us-west-2.dst.global.plume.tech/reports/version",
    frontlineUrl: "https://gamma.noc.plume.com/location",
  },
];

// function to lookup the deployment string from the URL, will return null if URL not compliant or not found
export const getEnvironment = () => {
  const hostnameBits = window.location.hostname.split(".");

  if (hostnameBits.length > 3) {
    // new URLS of kappa.signal.plume.com
    const envFromUrl = hostnameBits[hostnameBits.length - 4];

    for (const env of environments) {
      if (env.id === envFromUrl) {
        return env; // possible future enhancement to hold in store
      }
    }
    return null; // not found
  } else {
    // old URLs of signal.plume.com or localhost
    return null;
  }
};

const getEnvProp = (propName, fallbackValue) => {
  const env = getEnvironment();
  return (env && env[propName]) || fallbackValue;
};

export const isRegionSpecificSignal = () => {
  return getEnvironment() ? true : false;
};

export const getSphereName = () => {
  return getEnvProp("name", SIGNAL_ENDPOINT_SPHERE);
};

export const getOktaClientId = () => {
  return getEnvProp("oktaClientId", OKTA_CLIENT_ID);
};

export const getOktaBaseUrl = () => {
  return getEnvProp("oktaBaseUrl", OKTA_BASE_URL);
};

export const getOktaIssuer = () => {
  return getEnvProp("oktaIssuer", OKTA_ISSUER);
};

export const getVersionApiEndpoint = () => {
  return getEnvProp("versionApiEndpoint", VERSION_ENDPOINT);
};
