export const loadState = (state) => {
  return localStorage.getItem(state)
    ? JSON.parse(localStorage.getItem(state))
    : undefined;
};

export const persistV2Auth = (state) => {
  try {
    const v2 = JSON.stringify(state.v2);
    localStorage.setItem("v2_auth", v2);
  } catch (error) {
    // ...
  }
};

export const savemeInfo = (state) => {
  try {
    const me = JSON.stringify(state.me);
    localStorage.setItem("plume_signal_me_info", me);
  } catch (error) {
    //Ignore the erros
  }
};
