export const SET_TICKET_ERR = "SET_TICKET_ERR";
export const REMOVE_TICKET_ERR = "REMOVE_TICKET_ERR";

export const setTicketErr = () => ({
  type: SET_TICKET_ERR,
});

export const removeTicketErr = () => ({
  type: REMOVE_TICKET_ERR,
});
