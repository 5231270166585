import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import TrendLineComp from "../../../communal/Atoms/Charts/TrendLine";
import StackedBarChartComp from "../../../communal/Atoms/Charts/StackedBarChartComp";

import {
  getChartType,
  getChartData,
  getChartYAtt,
  AvailableCharts,
} from "../../../../../selectors";

const mapYAttToData = {
  Percentage: "value",
  Number: "total",
};

const Chart = ({ chart, data, locationNumbers, name, time, yAtt }) => {
  if (locationNumbers === 0) {
    return <div className="signal-trendline"></div>;
  }

  if (data.length === 0) {
    return <div className="signal-trendline"></div>;
  }

  switch (chart) {
    case AvailableCharts.TrendLine:
      return (
        <TrendLineComp
          data={data}
          xAtt="time"
          yAtt={mapYAttToData[yAtt]}
          time={time}
          locationNumbers={locationNumbers}
          id={name}
        />
      );
    case AvailableCharts.BarChart:
      return (
        <StackedBarChartComp
          data={data}
          time={time}
          yAtt={mapYAttToData[yAtt]}
          locationNumbers={locationNumbers}
        />
      );
    default:
      return (
        <TrendLineComp
          data={data}
          xAtt="time"
          yAtt={mapYAttToData[yAtt]}
          time={time}
          locationNumbers={locationNumbers}
        />
      );
  }
};

Chart.propTypes = {
  chart: PropTypes.string.isRequired,
  data: PropTypes.array,
  name: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  chart: getChartType(state, ownProps),
  data: getChartData(state, ownProps),
  yAtt: getChartYAtt(state, ownProps),
});

export default connect(mapStateToProps, undefined)(Chart);
