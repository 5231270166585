import React from "react";
import { MenuHeader } from "../../Atoms/MenuHeader/MenuHeader";
import { TextAndCheckMark } from "../../Atoms/TextAndCheckMark/TextAndCheckMark";

import { findHeader } from "./helpers";

import "./HeaderAndSelectedText.scss";

//
// data should be an object with the key as the header
//
//
const HeaderAndSelectedText = ({ data, optionClicked }) => {
  const header = findHeader(data);

  return (
    <div className="sl-header-and-selected-text-wrapper">
      <MenuHeader>{header}</MenuHeader>
      {Object.keys(data[header].options).map((key, i) => {
        return (
          <TextAndCheckMark
            key={i}
            onClick={optionClicked}
            children={null}
            selected={data[header].options[key]}
          >
            {key}
          </TextAndCheckMark>
        );
      })}
    </div>
  );
};

export { HeaderAndSelectedText };
